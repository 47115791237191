<template>
  <div
    class="space-y-4 border-t-[1px] border-t-neutral-300 bg-white px-4 py-3 text-left shadow-inner sm:bottom-0 sm:flex-none sm:space-y-0 sm:space-x-4 sm:px-6"
  >
    <BaseButton
      :variant="validVariant"
      :disabled="!formState.valid"
      @click.prevent="saveClickHandler()"
      class="w-full sm:w-auto"
      :label="saveLabel"
    >
      <template #iconBefore v-if="formState.loading">
        <div
          class="inline-block h-4 w-4 animate-spin rounded-full border-2 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
        >
          <!-- <span
            role="status"
            class="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
            >Loading...</span
          > -->
        </div>
      </template>
    </BaseButton>
    <BaseButton
      variant="plain"
      @click.prevent="cancelAction()"
      class="w-full sm:w-auto"
      :disabled="formState.loading"
      label="Cancel"
    >
    </BaseButton>
    <div class="inline-block">
      <FormKitMessages />
    </div>
    <!-- <span v-if="formState.loading">Saving...</span> -->
  </div>
</template>

<script lang="ts">
import BaseButton from "@/components/button/BaseButton.vue";
import { FormKitMessages } from "@formkit/vue";
import { computed, defineComponent } from "vue";

// TODO: "Saving" display is rough - improve for desktop and mobile
/** Component for standardized styling of save and cancel actions for forms */
export default defineComponent({
  name: "FormActions",
  props: {
    /** Action to be performed when save button is clicked */
    saveAction: {
      type: Function,
    },
    /** Optional property to change the label on the save button */
    saveLabel: {
      type: String,
      default: "Save",
    },
    /** Action to be performed when cancel button is clicked */
    cancelAction: {
      type: Function,
      required: true,
    },
    /** State object from the form this is being used inside */
    formState: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const validVariant = computed(() => (props.formState.dirty && props.formState.valid ? "valid" : "plain"));

    const saveClickHandler = () => {
      if (props.formState.loading) return;
      if (!props.saveAction) throw "saveAction on FormActions component is not defined";
      props.saveAction();
    };

    return { validVariant, saveClickHandler };
  },
  components: {
    BaseButton,
    FormKitMessages,
  },
});
</script>

<style module></style>
