import EntityAudits from "@casa/components/EntityAudits.vue";
import { EntityNotes } from "@mui/components/entity/note";
import { RouterView } from "vue-router";

// Troubleshoot your routes using this - https://paths.esm.dev

/** @type {import('vue-router').RouterOptions['routes']} */

const names = {
  abodes: "Abodes",
  abodeList: "AbodeList",
  abodeNew: "AbodeNew",
  abodeBase: "AbodeBase",
  abodeView: "AbodeView",
  abodeEdit: "AbodeEdit",
  abodeEditSchema: "AbodeEditSchema",
  abodeAppraisals: "AbodeAppraisals",
  abodeUtilities: "AbodeUtilities",
  abodeUtilityEdit: "AbodeUtilityEdit",
  abodeUtilityNew: "AbodeUtilityNew",
  abodeRooms: "AbodeRooms",
  abodeRoomEdit: "AbodeRoomEdit",
  abodeRoomEditSchema: "AbodeRoomEditSchema",
  abodeRoomNew: "AbodeRoomNew",
  abodeRoomSelect: "AbodeRoomNewSelect",
  abodeInspections: "AbodeInspections",
  abodeInspectionEdit: "AbodeInspectionEdit",
  abodeInspectionEditSchema: "AbodeInspectionEditSchema",
  abodeInspectionNew: "AbodeInspectionNew",
  abodeNotes: "AbodeNotes",
  abodeNoteEdit: "AbodeNoteEdit",
  abodeNoteNew: "AbodeNoteNew",
  abodeAudit: "AbodeAudit",
  abodeUserNew: "AbodeUserNew",
  abodeUsers: "AbodeUsers",
};

export { names as abodeRouteNames };

const abodeRoutes = [
  {
    path: "/abode",
    name: names.abodes,
    component: {
      render: () => <RouterView />,
    },
    redirect: { name: names.abodeList },
    children: [
      {
        path: "list",
        name: names.abodeList,
        meta: { title: "Abodes" },
        component: () => import("@casa/views/abode/AbodeList.vue"),
      },
      {
        path: "new",
        component: () => import("@casa/views/abode/AbodeBase.vue"),
        redirect: { name: names.abodeNew },
        props: true,
        children: [
          {
            path: "",
            name: names.abodeNew,
            meta: { title: "New Abode", isNew: true },
            component: () => import("@casa/views/abode/AbodeEdit.vue"),
          },
        ],
      },
      {
        path: ":id(\\d+)",
        name: names.abodeBase,
        component: () => import("@casa/views/abode/AbodeBase.vue"),
        redirect: { name: names.abodeView },
        children: [
          {
            path: "view",
            name: names.abodeView,
            meta: { title: "Abode View" },
            component: () => import("@casa/views/abode/AbodeView.vue"),
          },
          {
            path: "edit",
            name: names.abodeEdit,
            meta: { title: "Abode Edit", isForm: true },
            component: () => import("@casa/views/abode/AbodeEdit.vue"),
          },
          {
            path: "editSchema",
            name: names.abodeEditSchema,
            meta: { title: "Abode Edit - Schema", isForm: true },
            component: () => import("@casa/views/abode/AbodeEditSchema.vue"),
          },
          {
            path: "notes",
            name: names.abodeNotes,
            meta: { title: "Abode Notes" },
            component: {
              render: () => <EntityNotes idField="abodeId" routeNamePrefix="AbodeNote" />,
            },
          },
          {
            path: "appraisals",
            name: names.abodeAppraisals,
            meta: { title: "Abode Appraisals" },
            component: () => import("@casa/views/abode/AppraisalChildList.vue"),
          },
          {
            path: "utilities",
            name: names.abodeUtilities,
            meta: { title: "Abode Utility" },
            component: () => import("@casa/views/abode/UtilitiesChildList.vue"),
          },
          {
            path: "utilities/:utilityId(\\d+)",
            name: names.abodeUtilityEdit,
            meta: { title: "Edit Abode Utility", isForm: true },
            component: () => import("@casa/views/abode/AbodeUtility.vue"),
          },
          {
            path: "utilities/new",
            name: names.abodeUtilityNew,
            meta: { title: "Create Abode Utility", isForm: true },
            component: () => import("@casa/views/abode/AbodeUtility.vue"),
          },
          {
            path: "rooms",
            name: names.abodeRooms,
            meta: { title: "Abode Room" },
            component: () => import("@casa/views/abode/AbodeRooms.vue"),
          },
          // {
          //   path: "rooms/:roomId(\\d+)",
          //   name: names.abodeRoomEdit,
          //   meta: { title: "Edit Abode Room", isForm: true },
          //   component: () => import("@casa/views/abode/AbodeRoom.vue"),
          // },
          {
            path: "rooms",
            name: names.abodeRooms,
            meta: { title: "Abode Rooms" },
            component: () => import("@casa/views/abode/AbodeRooms.vue"),
          },
          {
            path: "rooms/:roomId(\\d+)/:type(Kitchen|Bedroom|Bathroom|Other|Living Room)",
            name: names.abodeRoomEdit,
            meta: { title: "Edit Abode Room", isForm: true },
            component: () => import("@casa/views/abode/AbodeRoom.vue"),
          },
          {
            path: "roomsSchema/:roomId(\\d+)/:type(Kitchen|Bedroom|Bathroom|Other|Living Room)",
            name: names.abodeRoomEditSchema,
            meta: { title: "Edit Abode Room - Schema", isForm: true },
            component: () => import("@casa/views/abode/AbodeRoomSchema.vue"),
          },
          {
            path: "rooms/new/:type(Kitchen|Bedroom|Bathroom|Other|Living Room)",
            name: names.abodeRoomNew,
            meta: { title: "Create Abode Room", isForm: true },
            component: () => import("@casa/views/abode/AbodeRoom.vue"),
          },
          {
            path: "rooms/select",
            name: names.abodeRoomSelect,
            meta: { title: "Create Abode Room", isForm: true },
            component: () => import("@casa/views/abode/AbodeRoomSelect.vue"),
          },
          {
            path: "inspections",
            name: names.abodeInspections,
            meta: { title: "Abode Inspection" },
            component: () => import("@casa/views/abode/AbodeInspections.vue"),
          },
          {
            path: "inspections/:inspectionId(\\d+)",
            name: names.abodeInspectionEdit,
            meta: { title: "Edit Abode Inspection", isForm: true },
            component: () => import("@casa/views/abode/AbodeInspection.vue"),
          },
          {
            path: "inspectionsSchema/:inspectionId(\\d+)",
            name: names.abodeInspectionEditSchema,
            meta: { title: "Edit Abode Inspection - Schema", isForm: true },
            component: () => import("@casa/views/abode/AbodeInspectionSchema.vue"),
          },
          {
            path: "inspection/new",
            name: names.abodeInspectionNew,
            meta: { title: "Create Abode Inspection", isForm: true },
            component: () => import("@casa/views/abode/AbodeInspection.vue"),
          },
          {
            path: "notes/:annotationId(\\d+)",
            name: names.abodeNoteEdit,
            meta: { title: "Edit Abode Note", isForm: true },
            component: () => import("@casa/views/abode/AbodeNote.vue"),
          },
          {
            path: "notes/new",
            name: names.abodeNoteNew,
            meta: { title: "Create Abode Note", isForm: true },
            component: () => import("@casa/views/abode/AbodeNote.vue"),
          },
          {
            path: "activity",
            name: names.abodeAudit,
            meta: { title: "Abode Audit Activity" },
            component: {
              render: () => <EntityAudits entityName="Abode" />,
            },
          },
          {
            path: "users",
            name: names.abodeUsers,
            meta: { title: "Abode Users" },
            component: () => import("@casa/views/abode/AbodeUsers.vue"),
          },
          {
            path: "users/new",
            name: names.abodeUserNew,
            meta: { title: "Add Abode User", isForm: true },
            component: () => import("@casa/views/abode/AbodeUser.vue"),
          },
        ],
      },
    ],
  },
];
export default abodeRoutes;
