<template>
  <div v-html="svgString"></div>
</template>

<script lang="ts">
import { PropType, computed, defineComponent } from "vue";
import { ViewerColor, getMapPin, viewerColors } from "@/components/viewer";

/** Creates the pin icon to display on the map */
export default defineComponent({
  name: "PinIcon",
  props: {
    /** Sets the color for the pin */
    viewerColor: {
      type: Object as PropType<ViewerColor>,
      default: () => viewerColors[0],
    },
  },
  setup(props) {
    // Use of v-html to inject markup should not normally be done.
    // In this case we're injecting a mix of *static* strings we control, so it's safe.
    const svgString = computed(() => getMapPin(props.viewerColor));
    return { svgString };
  },
});
</script>
