import { acceptHMRUpdate, defineStore } from "pinia";
import { computed, ref } from "vue";

/**
 * Returns a store indicating what the active breakpoint is, creates it if necessary.
 * Keep in mind that it is assumed that TheCssBreakpointDetector is somewhere in your application root.
 * The values will not be valid until TheCssBreakpointDetector has mounted.
 *
 */
export const useCssBreakpointStore = defineStore("CssBreakpointStore", () => {
  // state
  const isMobile = ref(false);
  const isSm = ref(false);
  const isMd = ref(false);
  const isLg = ref(false);
  const isXl = ref(false);
  const isTwoXl = ref(false);
  const isThreeXl = ref(false);
  const isFourXl = ref(false);

  return {
    //state
    isMobile,
    isSm,
    isMd,
    isLg,
    isXl,
    isTwoXl,
    isThreeXl,
    isFourXl,
  };
});

export type UseEntityDocumentConfigStore = ReturnType<typeof useCssBreakpointStore>;

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useCssBreakpointStore, import.meta.hot));
}
