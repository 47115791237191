<template>
  <FormKit
    type="form"
    id="theFormId"
    :value="document"
    v-model="document"
    @submit="handleSubmit"
    :actions="false"
    autocomplete="off"
  >
    <template #default="{ state: formState }">
      <div class="pb-5 sm:flex-1 sm:overflow-auto" v-if="document">
        <FormSection>
          <template #sectionName>{{ (isNew ? "New " : "Edit ") + entityDocumentConfigStore.documentLabel }}</template>
          <template #default>
            <FormKit
              type="dropdown"
              label="Type"
              name="type"
              :options="documentTypesArray"
              validation="required"
              :errors="typeErrors"
            />
            <FormKit
              type="file"
              name="file"
              label="File"
              :accept="document.type && documentTypes.get(document.type)?.extensions"
              file-remove-icon="close"
              file-item-icon="fileDoc"
              validation="required"
              @input.passive="fileSelected($event as unknown as Event)"
              :errors="fileErrors"
              title=" "
            />
            <FormKit type="text" label="Display Name" name="displayName" validation="required:trim|length:0,100" />
            <FormKit type="text" label="Comment" name="comment" validation="length:0,255" />
          </template>
        </FormSection>
      </div>
      <FormActions :save-action="saveAction" :cancel-action="cancelAction" :form-state="formState"></FormActions>
    </template>
  </FormKit>
</template>

<script lang="ts">
import type { IDocument, IDocumentCategory, IFileUpload, IFileUploadResponse } from "@/components/entity/document";
import { getDocumentByIdOrNew, saveDocument } from "@/components/entity/document/entityDocumentApi";
import { useEntityDocumentConfigStore } from "@/components/entity/document/entityDocumentConfigStore";
import { saveFileUpload } from "@/components/entity/document/fileUploadApi";
import { default as FormActions } from "@/components/form/FormActions.vue";
import { default as FormSection } from "@/components/form/FormSection.vue";
import { booleanOptions, childRouteCancelAction, enumToDropdown } from "@/utils/helpers";
import { useApi } from "@/utils/api";
import { setErrors, submitForm } from "@formkit/core";
import { PropType, defineComponent, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";

/** This is the create and edit screen for a document. It allows the upload of a document and its details. */
export default defineComponent({
  name: "EntityDocument",
  emits: [],
  props: {
    /** Name of the field ID (entityId) that the document belongs to */
    idField: {
      type: String,
      required: true,
    },
    /** Name of the route for the document list page for this entity */
    listName: {
      type: String,
      required: true,
    },
    /** An array of document categories that belong to this entity */
    documentCategory: {
      type: Object as PropType<IDocumentCategory>,
      required: true,
    },
    // documentLabel: {
    //   type: String,
    //   default: "Document",
    // },
    /** Object containing additionalIds and their values that the document should be tied to (E.g. {exampleEntityId: 3}) */
    additionalIds: {
      type: Object,
      default: () => {},
    },
  },
  setup(props, context) {
    const entityDocumentConfigStore = useEntityDocumentConfigStore();
    const route = useRoute();
    const router = useRouter();
    const isNew = !route.params.documentId;

    const documentTypes = props.documentCategory.types;
    if (!documentTypes) throw "There are no document types for this category";
    const documentTypesArray = enumToDropdown(documentTypes);

    /*
      Always fetch the entity data again in an Edit component.
      (All others should inject what's provided by the *Base.vue component)
      This reduces the probability of running into a handful of issues.
    */
    const {
      dataRef: document,
      statusRef,
      setStatus,
      exec: fetchDocument,
    } = useApi(getDocumentByIdOrNew, {
      initialData: {} as IDocument,
      responseAdapter: (response) => {
        if (response.data.fileName) {
          response.data.file = [{ name: response.data.fileName }];
        }

        return response.data;
      },
    });

    watch(
      () => route.params.documentId,
      (newValue, oldValue) => {
        if (isNew) {
          fetchDocument({ [props.idField]: parseInt(route.params.id as string) });
        } else if (!newValue) {
          return; // Catch route change invalidating the params.
        } else {
          fetchDocument({ id: newValue, [props.idField]: route.params.id });
        }
      },
      { immediate: true },
    );

    const getParams = () => {
      const { documentId, ...rest } = route.params;
      return rest;
    };

    const cancelAction = function () {
      childRouteCancelAction({ router, routeName: props.listName, params: getParams() });
    };

    const {
      dataRef: documentResult,
      // statusRef,
      exec: execSaveDocument,
    } = useApi(saveDocument, {
      initialData: {} as IDocument,
      responseAdapter: (response) => {
        cancelAction();
        return response.data;
      },
    });

    const { dataRef: fileUploadResult, exec: execSaveFileUpload } = useApi<IFileUpload, IFileUploadResponse>(
      saveFileUpload,
    );

    const handleSubmit = async (data: IDocument) => {
      //TODO:TS - [key: string]: any; covers the missing elements. May need changed if IDocument changes.
      try {
        if (isNew || data.file[0]?.file) {
          const uploadedDocs = await execSaveFileUpload({ files: data.file });
          if (uploadedDocs && !uploadedDocs[0]?.hasError) {
            data.fileUploadId = uploadedDocs[0]?.fileUploadId;
            data.fileSize = uploadedDocs[0]?.size;
            data.fileName = uploadedDocs[0]?.name;
            data.contentType = uploadedDocs[0]?.type;
          } else {
            throw new Error("File was not uploaded.");
          }
          for (let key in props.additionalIds) {
            data[key] = props.additionalIds[key];
          }
          data.category = props.documentCategory.id;
        }

        await execSaveDocument(data);
      } catch (errors) {
        console.error(errors);
        setErrors(
          "theFormId",
          ["There was an error saving; please try again."], // (optional) An array of form level errors
          //inputErrors // (optional) input level errors
        );
        return; // We can't re-throw, FormKit needs us to resolve.
      }
    };
    const saveAction = function () {
      submitForm("theFormId");
    };

    const typeErrors = ref<Array<string>>([]);
    const fileErrors = ref<Array<string>>([]);

    watch([() => document.value?.type, () => document.value?.file?.[0]], ([newType, newFile]) => {
      if (newType && newFile) {
        const exts = documentTypes.get(newType)?.extensions?.split(",");
        const fileName = newFile?.name;

        if (fileName && exts && !exts.some((x) => fileName.endsWith(x))) {
          const extsList = exts.join(", ");
          fileErrors.value = [`Format invalid for selected type. Please upload a ${extsList} file.`];
          typeErrors.value = ["Type is invalid for the selected file."];
        } else {
          fileErrors.value = [];
          typeErrors.value = [];
        }
      } else {
        fileErrors.value = [];
        typeErrors.value = [];
      }
    });

    const fileSelected = ($event: Event) => {
      const input = $event.target as HTMLInputElement;
      if (input.files?.[0]?.name && !document.value?.displayName) {
        if (!document.value) return;

        let name = input.files[0].name;
        const dot = input.files[0].name.lastIndexOf(".");
        if (dot > -1) {
          name = name.substring(0, dot);
        }
        document.value.displayName = name;
      }
    };

    return {
      fileSelected,
      cancelAction,
      saveAction,
      handleSubmit,
      document,
      isNew,
      booleanOptions,
      documentTypes,
      documentTypesArray,
      typeErrors,
      fileErrors,
      entityDocumentConfigStore,
    };
  },
  components: {
    FormActions,
    FormSection,
  },
});
</script>
