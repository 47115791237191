import { apiService } from "@mui/utils/api";
import { apiHosts } from "@casa/api/constants/apiHosts";
import { enums } from "@casa/constants/enums";

const axiosParams = {
  // Set different base URL based on the environment
  baseURL: import.meta.env.VITE_CASA_API_BASE_URL,
};
const entityAuditApi = apiService(apiHosts.CASA, axiosParams);

export { entityAuditApi };
