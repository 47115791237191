import { UseApiResult, UsePagedApiResult } from "@/utils/api/useApi.js";
import { watch } from "vue";
import { useRouter } from "vue-router";

type ResultType<T> = T extends UsePagedApiResult<infer S> ? S : T extends UseApiResult<infer S> ? S : never;
/**
 * Composable designed to wrap calls to useApi or usePagedApi and add an automatic redirect to the unathorized page if they fail with a 403.
 */
export function withAuthorization<T extends UsePagedApiResult<ResultType<T>> | UseApiResult<ResultType<T>>>(arg: T) {
  const { errorRef } = arg;
  const router = useRouter();
  watch(
    () => errorRef.value?.response?.status,
    (status) => {
      if (status === 403) {
        console.warn("403 unauthorized redirect.");
        router.replace({ name: "NotAuthorized" });
      }
    },
  );
  return arg;
}
