<template>
  <div class="min-w-max text-neutral-900">
    {{ label }}
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "AppNavAnchorLabel",
  components: {},
  props: {
    /** User visible label for the anchor */
    label: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    return {};
  },
});
</script>

<style module></style>
