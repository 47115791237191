<template>
  <LayoutAppContent>
    <header class="pt-3 md:pb-5">
      <div class="px-4 sm:px-6 md:flex md:items-end md:justify-between md:px-8">
        <div class="min-w-0 flex-1">
          <h2 class="text-large mt-2 font-bold leading-7 text-neutral-800" :class="$style.subheading">
            <!-- @slot Slot for the entity's subheading if it is not new -->
            <slot name="subheading" v-if="!isNew"></slot>
            <!-- @slot Slot for the entity's subheading if it is new -->
            <slot name="subheadingNew" v-if="isNew"></slot>
          </h2>
          <h1
            class="mt-1 text-2xl font-bold leading-7 text-neutral-800 sm:truncate sm:text-3xl sm:tracking-tight"
            :class="!showNavigation ? `mb-2 md:mb-0` : ``"
          >
            <!-- @slot Slot for the entity's heading if it is not new -->
            <slot name="heading" v-if="!isNew"></slot>
            <!-- @slot Slot for the entity's heading if it is new -->
            <slot name="headingNew" v-if="isNew"></slot>
          </h1>
        </div>
        <div class="mt-5 hidden md:flex lg:mt-0 lg:ml-4">
          <!-- empty container for future features -->
        </div>
      </div>
    </header>
    <div class="flex min-h-0 flex-1 flex-col items-stretch lg:flex-row">
      <TheEntityNav :navigation-items="navigationItems" v-if="showNavigation"></TheEntityNav>
      <main
        class="flex min-h-0 flex-1 border-neutral-300 bg-neutral-100 lg:border-t lg:border-l"
        :class="showNavigation ? `lg:rounded-tl-lg` : ``"
      >
        <!-- @slot Slot for main content -->
        <slot></slot>
      </main>
    </div>
  </LayoutAppContent>
</template>

<script lang="ts">
import LayoutAppContent from "@/components/layouts/LayoutAppContent.vue";
import type { EntityNavigationItem } from "@/components/common";
import TheEntityNav from "@/components/common/TheEntityNav.vue";
import { PropType, computed, defineComponent } from "vue";
import { useRoute } from "vue-router";

/** Base styling for the entity. Defines the headings and navigations for the entity.  */
export default defineComponent({
  name: "LayoutEntity",
  components: {
    LayoutAppContent,
    TheEntityNav,
  },
  props: {
    /** Array of navigation items that should be available from the entity. (Typically children of that entity, notes, documents, and audit history) */
    navigationItems: {
      type: Array as PropType<EntityNavigationItem[]>,
      required: true,
    },
  },
  setup() {
    const route = useRoute();

    const showNavigation = computed(() => !route.meta.isForm && !route.meta.isNew);
    const isNew = computed(() => route.meta.isNew);

    return { isNew, showNavigation, route };
  },
});
</script>

<style module>
.subheading a {
  @apply underline decoration-sky-300 underline-offset-2 hover:decoration-2;
}
</style>
