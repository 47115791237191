<template>
  <div class="flex-auto h-1 min-h-0 overflow-y-auto">
    <div class="px-3 py-3 prose md:px-6 lg:pt-5" :class="viewerStore.showSidebar ? 'visible' : 'invisible'">
      <!-- bold text-lg mb-1 -->
      <h4 class="flex items-center font-medium"><MagnifyingGlassIcon class="h-5 w-5 inline-block mr-2" />Search</h4>
      <p>Find locations using keywords with the search input.</p>

      <h4 class="flex items-center font-medium">
        <FunnelIcon class="h-5 w-5 inline-block mr-2" />Datasets and Filters
      </h4>
      <p>
        Datasets can be added or removed from the results via the "Show" checkbox on the Datasets and Filters tab or
        using the
        <EyeIcon class="w-4 h-4 inline-block" title="Hide/Show" /> button on the map.
      </p>
      <p class="hidden">Some datasets allow you to add additional filters specific to that type of data.</p>

      <!-- bold text-lg mt-5 mb-1 -->
      <h4 class="flex items-center font-medium"><Square3Stack3DIcon class="h-5 w-5 inline-block mr-2" />Layers</h4>
      <p>
        Geometry layers control the type of data you see on the map. Toggling these may make it easier to see a
        particular data point.
      </p>
      <p>Base layers control the type of map imagery you see (e.g. street, satellite, topographical).</p>
      <p>Reference layers provide additional information on the map but cannot be searched or filtered.</p>

      <!-- bold text-lg mt-5 mb-1 -->
      <h4 class="flex items-center font-medium"><ArrowUturnLeftIcon class="h-5 w-5 inline-block mr-2" />Reset</h4>
      <p>
        The reset button will remove all filters (including keywords), show all data sets, and zoom the map to its
        initial location.
      </p>

      <h4 class="flex items-center font-medium"><HomeIcon class="h-5 w-5 inline-block mr-2" />Home</h4>
      <p>The home button returns the map viewer to its initial view.</p>

      <h4 class="flex items-center font-medium"><WrenchIcon class="h-5 w-5 inline-block mr-2" />Tools</h4>
      <p>
        The measure distance tool allows you to determine the length of one or more linear segments. Click anywhere on
        the map to begin measuring. Then, clicking once will begin a new line segment and double-clicking will complete
        the measurement.
      </p>
      <p>
        The measure area tool calculates the area of a polygon. Click anywhere on the map to begin drawing your shape.
        Each single click will add a new vertex to the polygon. Double-click the last point to finish creating your
        polygon.
      </p>
      <p>The zoom to tool makes it easy to view common locations on the map.</p>
    </div>
  </div>
</template>

<script lang="ts">
import SkeletonButton from "@/components/common/SkeletonButton.vue";
import { useUser } from "@/utils/auth/authentication";
import { Authorize } from "@/utils/auth/authorization";
import { useAppSettingsStore } from "@/components/common";
import { EyeIcon, EyeSlashIcon } from "@heroicons/vue/16/solid";
import {
  ArrowUturnLeftIcon,
  ChevronDownIcon,
  FunnelIcon,
  HomeIcon,
  MagnifyingGlassIcon,
  Square3Stack3DIcon,
  WrenchIcon,
} from "@heroicons/vue/20/solid";
import { Bars3Icon, XMarkIcon } from "@heroicons/vue/24/solid";
import { computed, defineComponent, ref } from "vue";
import { useViewerStore } from "@/components/viewer/viewerStore";

/** Displays Viewer help / user guide
 */
export default defineComponent({
  name: "ViewerPanelHelp",
  components: {
    Authorize,
    Bars3Icon,
    XMarkIcon,
    SkeletonButton,
    ChevronDownIcon,
    EyeIcon,
    EyeSlashIcon,
    ArrowUturnLeftIcon,
    FunnelIcon,
    MagnifyingGlassIcon,
    Square3Stack3DIcon,
    WrenchIcon,
    HomeIcon,
  },
  setup(props, context) {
    const viewerStore = useViewerStore();
    const appSettingsStore = useAppSettingsStore();
    const { userRef } = useUser();

    return {
      appSettingsStore,
      userRef,
      viewerStore,
    };
  },
});
</script>

<style module></style>
