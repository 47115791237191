import { IAppraisal, IAppraisalNew } from "@casa/views/appraisal/appraisal";

export default {
  addAuthRequestFields,
  getInitialData,
  responseAdapter,
  requestAdapter,
  fromFormkit,
  toFormkit,
};

function addAuthRequestFields(entity: IAppraisal) {
  entity._authRequestFields = ["id", "status"];
  return entity;
}
function getInitialData() {
  const result = {} as IAppraisal; //TODO: ts
  return addAuthRequestFields(result);
}
function responseAdapter(response: { data: IAppraisal }) {
  addAuthRequestFields(response.data);
  return response.data;
}
function requestAdapter(data: IAppraisal) {
  // Add seconds to the time so that the BE will parse it correctly.
  if (data.appraisalTime?.length === 5) {
    data.appraisalTime += ":00";
  }

  if (!data.appraisalDate) {
    data.appraisalDate = null;
  }

  if (!data.appraisalTime) {
    data.appraisalTime = null;
  }
  return data;
}

// Convert server data for use in the page's form (which may have groupings, etc different from the server data)
function toFormkit<T extends IAppraisal | IAppraisalNew>(data: T) {}

// Convert formkit data to prep it for sending to the server (which is probably flatter than the fk data)
function fromFormkit(data: IAppraisal) {}
