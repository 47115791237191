<template>
  <div class="flex flex-shrink-0 self-center" v-if="canEdit || canDelete || $slots.default">
    <Menu as="div" class="relative inline-block text-left">
      <div>
        <MenuButton class="-m-2 flex items-center rounded-full p-2 text-neutral-400 hover:text-neutral-600">
          <span class="sr-only">Open actions</span>
          <EllipsisVerticalIcon class="h-5 w-5" aria-hidden="true" />
        </MenuButton>
      </div>

      <transition
        enter-active-class="transition ease-out duration-100"
        enter-from-class="transform opacity-0 scale-95"
        enter-to-class="transform opacity-100 scale-100"
        leave-active-class="transition ease-in duration-75"
        leave-from-class="transform opacity-100 scale-100"
        leave-to-class="transform opacity-0 scale-95"
      >
        <MenuItems
          class="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
        >
          <div class="py-1">
            <slot :item="item"></slot>
            <MenuItem v-slot="{ active }" v-if="canEdit && editTo">
              <router-link
                :to="editTo"
                :class="[active ? 'bg-neutral-100 text-neutral-900' : 'text-neutral-700', 'flex px-4 py-2 text-sm']"
              >
                <PencilIcon class="mr-3 h-5 w-5 text-neutral-400" aria-hidden="true" />
                <span>Edit</span></router-link
              >
            </MenuItem>
            <MenuItem v-slot="{ active }" v-if="canDelete">
              <button
                type="button"
                :class="[
                  active ? 'bg-neutral-100 text-neutral-900' : 'text-neutral-700',
                  'flex w-full px-4 py-2 text-sm',
                ]"
                @click="openConfirmDelete()"
              >
                <TrashIcon
                  class="mr-3 h-5 w-5"
                  :class="[active ? `text-red-600` : `text-neutral-400`]"
                  aria-hidden="true"
                />
                <span>{{ deleteLabel }}</span>
              </button>
            </MenuItem>
          </div>
        </MenuItems>
      </transition>
    </Menu>
  </div>
  <ConfirmDelete :entity-label="entityLabel" @deleteClick="deleteConfirmed" ref="confirmDeleteRef"></ConfirmDelete>
</template>

<script lang="ts">
// Note: When updating this description - you must also update the md file.

/** Component to easily include common menu actions in a ListCard. Actions include edit and delete.  */
export default { name: "ListCardMenu" };
</script>

<script setup lang="ts" generic="T">
import { ConfirmDelete } from "@/components/modals";
import { ListTableProps } from "@/components/list/genericList";
import injectionSymbols from "@/components/list/genericList/injectionSymbols";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import { EllipsisVerticalIcon, PencilIcon, TrashIcon } from "@heroicons/vue/20/solid";
import { inject, ref } from "vue";
import { RouteLocationRaw } from "vue-router";

const props = withDefaults(
  defineProps<{
    /** Whether or not the item is allowed to be edited */
    canEdit?: boolean;
    /** Route to the edit screen for this item */
    editTo?: RouteLocationRaw;
    /** Whether or not the item is allowed to be deleted */
    canDelete?: boolean;
    /** Function accepting the current item and performing the necessary call to delete it */
    deleteAction?: (itemToDelete: T) => Promise<any>;
    /** The item this card belongs to */
    item: T;
    /** Optional label to change the wording for the delete action. (E.g. 'Remove') */
    deleteLabel?: string;
  }>(),
  { canEdit: false, canDelete: false, deleteLabel: "Delete" },
);

const { entityLabel, entityLabelPlural, isChildList, createTo, createToAuth, isDefaultState, removeItem } = inject(
  injectionSymbols.GenericListPropsKey,
) as ListTableProps;

const confirmDeleteRef = ref<typeof ConfirmDelete | null>(null);
const openConfirmDelete = function () {
  confirmDeleteRef.value?.openConfirmation();
};

const deleteConfirmed = function () {
  if (!props.deleteAction) return;

  props.deleteAction(props.item).then(() => {
    removeItem(props.item);
  });
};
</script>

<style module></style>
