<template>
  <div
    :class="{
      'md:col-span-6': span === 6,
      'md:col-span-5': span === 5,
      'md:col-span-4': span === 4,
      'md:col-span-3': span === 3,
      'md:col-span-2': span === 2,
      'md:col-span-1': span === 1,
    }"
  >
    <slot></slot>
  </div>
</template>

<script lang="ts">
import { PropType, defineComponent } from "vue";
/**
 * Creates a div with the desired col-span. This is used to get around using tailwind in schema.
 * Not a component that should be used otherwise.
 * todo - maybe make the screen size also adjustable...?
 */
export default defineComponent({
  name: "ColSpan",
  props: {
    //todo - this may need to be a string instead of a number (depending on how the schema/json works )
    /** Number of col span you would like.
     * @values 1,2,3,4,5,6
     */
    span: {
      type: Number as PropType<1 | 2 | 3 | 4 | 5 | 6>,
      default: 2,
      validator: (value: number) => {
        return value > 0 && value < 7;
      },
    },
  },
  setup() {
    return {};
  },
});
</script>
