<template>
  <!-- Default Identity -->
  <div v-if="!agency" class="mb-4 text-center">
    <div class="pb-1 text-3xl font-bold uppercase" :class="[$style.hiAgencyPrimaryColor]">Identity</div>
  </div>
  <!-- Hawaii -->
  <div v-if="agency === 'hi'" :class="[$style.hiAgencyPrimaryColor]" class="mb-4 text-center">
    <div class="text-xs">Hawaii State Environmental Health Administration</div>
    <div class="pb-1 text-3xl uppercase" :class="[$style.hiAgencySecondaryColor]">
      EHA<b :class="[$style.hiAgencyPrimaryColor]">Connect</b>
    </div>
  </div>
  <!-- CC -->
  <div v-if="agency === 'cc'" :class="[$style.hiAgencyPrimaryColor]" class="mb-4 text-center">
    <div class="text-xs">Clark County Department of Environment and Sustainability</div>
    <div class="pb-1 text-3xl font-bold uppercase" :class="[$style.hiAgencyPrimaryColor]">Identity</div>
  </div>
  <!-- NV -->
  <div v-if="agency === 'nv'" :class="[$style.hiAgencyPrimaryColor]" class="mb-4 text-center">
    <div class="text-xs">Nevada Division of Environmental Protection</div>
    <div class="pb-1 text-3xl font-bold uppercase" :class="[$style.hiAgencyPrimaryColor]">Identity</div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
/**
 * Displays a different header based on the passed in agency.
 */
export default defineComponent({
  name: "AgencyHeader",
  props: {
    /**
     * Value for the agency that maps back to one of the sections.
     * @values hi, cc, nv
     */
    agency: {
      type: String,
      default: null,
    },
  },
  setup(props) {
    return {};
  },
});
</script>

<style module>
.hiAgencyPrimaryColor {
  color: rgb(31, 92, 102);
}
.hiAgencySecondaryColor {
  color: rgb(93, 240, 246);
}
</style>
