import { FormKitTypeDefinition } from "@formkit/core";
import {
  outer,
  inner,
  help,
  boxHelp,
  messages,
  message,
  prefix,
  suffix,
  fieldset,
  decorator,
  box,
  icon,
  legend,
  boxOption,
  boxOptions,
  boxWrapper,
  boxLabel,
  options,
  radios,
  $if,
  $extend,
  defaultIcon,
  disablesChildren as disables,
  $attrs,
  createSection,
} from "@formkit/inputs";

const boxOptionsWrapper = createSection("boxOptionsWrapper", () => ({
  $el: "div",
}));

/**
 * Input definition for a booleanRadio.
 * @public
 */
export const booleanRadio: FormKitTypeDefinition = {
  schema: outer(
    /**
     * Multi booleanRadio structure.
     */
    $attrs(
      {
        class: "$classes.fieldsetBooleanRadio",
      },
      fieldset(
        $attrs(
          {
            class: "$classes.legendBooleanRadio",
            for: "$id",
          },
          legend("$label"),
        ),
        // custom div wrapper
        boxOptionsWrapper(
          // div
          $attrs(
            { class: "$classes.optionsBooleanRadio" },
            $extend(
              boxOptions(
                // ul as div
                $attrs(
                  { class: "$classes.optionBooleanRadio" },
                  $extend(
                    boxOption(
                      // li as div
                      $attrs(
                        { class: "$classes.wrapperBooleanRadio" },
                        boxWrapper(
                          // label
                          $extend(
                            inner(
                              // div
                              // prefix(),
                              $extend(box(), {
                                bind: "$option.attrs",
                                attrs: {
                                  id: "$option.attrs.id",
                                  value: "$option.value",
                                  checked: "$fns.isChecked($option.value)",
                                },
                              }),
                              // CheckCircleIcon,
                              $if(
                                "$option.__original === true",
                                decorator(icon("trueOption")),
                                // $extend(decorator(), {
                                //   $el: CheckCircleIcon,
                                // })
                              ),
                              $if("$option.__original === false", decorator(icon("falseOption"))),
                              $if(
                                "$option.__original !== true && $option.__original !== false",
                                decorator(icon("nullOption")),
                              ),
                            ),
                            {
                              attrs: {
                                checked: "$fns.isChecked($option.value)",
                              },
                            },
                          ),
                          // $if("$option.label", boxLabel("$option.label")) //span
                          $attrs({ class: "$classes.optionLabel" }, boxLabel("$option.label")),
                        ),
                      ),
                    ),
                    { $el: "div" },
                  ),
                ),
              ),
              { $el: "div" },
            ),
          ),
        ),
      ),
    ),

    /**
     * The actual schema of the input, or a function that returns the schema.
     */
    // schema: outer(
    //   /**
    //    * Multi booleanRadio structure.
    //    */
    //   $attrs(
    //     {
    //       class: "$classes.fieldsetBooleanRadio",
    //     },
    //     fieldset(
    //       $attrs(
    //         {
    //           class: "$classes.legendBooleanRadio",
    //           for: "$id",
    //         },
    //         legend("$label")
    //       ),
    //       // TODO: Wrap the following within a new div
    //       boxOptionsWrapper(
    //         // div
    //         $attrs(
    //           { class: "$classes.optionsBooleanRadio" },
    //           $extend(
    //             boxOptions(
    //               // ul
    //               $attrs(
    //                 { class: "$classes.optionBooleanRadio" },
    //                 boxOption(
    //                   // li
    //                   $attrs(
    //                     { class: "$classes.wrapperBooleanRadio" },
    //                     boxWrapper(
    //                       // label
    //                       inner(
    //                         // div
    //                         prefix(),
    //                         $extend(box(), {
    //                           bind: "$option.attrs",
    //                           attrs: {
    //                             id: "$option.attrs.id",
    //                             value: "$option.value",
    //                             checked: "$fns.isChecked($option.value)",
    //                           },
    //                         }),
    //                         decorator(icon("decorator"))
    //                         // suffix()
    //                       ),
    //                       $if("$option.label", boxLabel("$option.label")) //span
    //                     )
    //                   )
    //                 )
    //               )
    //             ),
    //             { $el: "div" }
    //           )
    //         )
    //       )
    //     )
    //   ),

    // schema: outer(

    //   $if(
    //     "$options == undefined",
    //     /**
    //      * Single booleanRadio structure.
    //      */
    //     boxWrapper(
    //       inner(prefix(), box(), decorator(icon("decorator")), suffix()),
    //       $if("$label", boxLabel("$label"))
    //     ),
    //     /**
    //      * Multi booleanRadio structure.
    //      */
    //     $attrs(
    //       {
    //         class: "$classes.fieldsetBooleanRadio",
    //       },
    //       fieldset(
    //         $attrs(
    //           {
    //             class: "$classes.legendBooleanRadio",
    //             // 'data-has-value': '$_value !== "" && $_value !== undefined',
    //             for: "$id",
    //           },
    //           legend("$label")
    //         ),
    //         // help("$help"),
    //         // {
    //         //   $el: 'div',
    //         //   attrs: {
    //         //     class: '$classes.optionsWrapperBooleanRadio',
    //         //   },
    //         //   children: ['Hello world'],
    //         // },
    //         $attrs(
    //           { class: "$classes.optionsBooleanRadio" },
    //           boxOptions(
    //             $attrs(
    //               { class: "$classes.optionBooleanRadio" },
    //               boxOption(
    //                 boxWrapper(
    //                   inner(
    //                     prefix(),
    //                     $extend(box(), {
    //                       bind: "$option.attrs",
    //                       attrs: {
    //                         id: "$option.attrs.id",
    //                         value: "$option.value",
    //                         checked: "$fns.isChecked($option.value)",
    //                       },
    //                     }),
    //                     decorator(icon("decorator")),
    //                     suffix()
    //                   ),
    //                   $if("$option.label", boxLabel("$option.label"))
    //                 )
    //                 // boxHelp("$option.help")
    //               )
    //             )
    //           )
    //         )
    //       )
    //     )
    //   ),
    // Help text only goes under the input when it is a single.
    // $if("$options === undefined && $help", help("$help")),
    help("$help"),
    messages(message("$message.value")),
  ),
  /**
   * The type of node, can be a list, group, or input.
   */
  type: "input",
  /**
   * The family of inputs this one belongs too. For example "text" and "email"
   * are both part of the "text" family. This is primary used for styling.
   */
  family: "box",
  /**
   * An array of extra props to accept for this input.
   */
  props: ["options", "onValue", "offValue", "optionsLoader"],
  /**
   * Forces node.props.type to be this explicit value.
   */
  forceTypeProp: "radio",
  /**
   * Additional features that should be added to your input
   */
  features: [
    disables,
    options,
    radios,
    defaultIcon("trueOption", "checkCircleIcon"),
    defaultIcon("falseOption", "xCircleIcon"),
    defaultIcon("nullOption", "noSymbolIcon"),
  ],
};
