import { CreateAxiosDefaults } from "axios";

// TODO; make a type that can be reused in AuthClient

export interface AuthorizationClientConfig extends CreateAxiosDefaults {
  baseApiUrl: string;
  defaultSystemIdentifier: string;
  getAuthToken: Function;
  set: (values: any) => void;
}

let config: { [key: string]: any } = {
  baseApiUrl: window.location.origin + "/authorization/api",
  defaultSystemIdentifier: "NoDefault",
  getAuthToken: function () {
    console.log(
      "AuthorizationClientConfig - getAuthToken() has not been initialized. Auth tokens will not be used. Requests will be made to local app url",
    );
    return null;
  },
};

let provider = {
  set: (values: any) => Object.assign(config, values),
};

Object.keys(config).forEach((key) =>
  Object.defineProperty(provider, key, {
    get: () => config[key],
  }),
);

Object.seal(provider);
export default provider as AuthorizationClientConfig;
