<template>
  <!-- <BaseButton label="test" @click="testSum"></BaseButton> -->
  <FormKit
    type="repeater"
    :name="name"
    v-model="values"
    :draggable="draggable"
    :min="min"
    :down-control="downControl"
    :up-control="upControl"
    :sections-schema="{
      fieldset: {
        attrs: {
          class: 'border-none',
        },
      },
      legend: {
        attrs: {
          class: 'hidden',
        },
      },
      content: {
        attrs: {
          class: 'm-0 p-0',
        },
      },
      items: {
        attrs: {
          class: 'm-0 p-0 border-none',
        },
      },
      item: {
        attrs: {
          class: 'm-0 p-0',
        },
      },
      controls: {
        attrs: {
          class: '',
        },
      },
    }"
  >
    <template #default="{ index }">
      <slot
        :index="index"
        :outerForward="outerForward"
        :repeaterName="name"
        :outerIndex="index"
        :outerLengths="outerLengths"
        :triggeredFromOuter="triggeredFromOuter"
        :numericRollup="numericRollup"
      ></slot>
    </template>

    <template #removeControl="{ fns, index }">
      <div class="hidden">
        <FormKit
          type="button"
          :label="removeLabel"
          :id="'hiddenWrapperRemoveButton_' + name + index"
          @click="removeOuterItem(index)"
        />
        <!-- @click="fns.createRemover(index)()" -->
        <!--  -->
      </div>
    </template>

    <template #addButton="{ fns, index }">
      <div class="">
        <div
          class="flex flex-wrap items-center justify-items-center rounded-lg border-2 border-dashed border-neutral-300 p-4 md:flex-nowrap md:gap-4"
        >
          <FormKitSchema :schema="addSchema" :data="data" v-if="addSchema" />

          <BaseButton
            class="w-full md:w-auto md:flex-grow-0"
            variant="create-hollow"
            :label="addLabel"
            @click="appendOuterItem()"
            :disabled="disabled"
          >
            <!-- :label="addLabel" -->
            <!--     @click="fns.createAppend()()" -->
            <template #iconBefore><PlusIcon /></template
          ></BaseButton>
        </div>
      </div>
    </template>
  </FormKit>
</template>

<script lang="ts">
import { getNode } from "@formkit/core";
import { PlusIcon, XMarkIcon } from "@heroicons/vue/24/solid";
import { BaseButton } from "@/components/button";
import { clearInput } from "@/utils/schema";
import { noLabelSchema } from "@/utils/helpers";

import { PropType, computed, defineComponent, provide, ref, toRef } from "vue";
import injectionSymbols from "@/components/schema/injectionSymbols";

export default defineComponent({
  name: "OuterRepeater",
  emits: [],
  props: {
    /** name for the input (entire repeater) */
    name: {
      type: String,
      required: true,
    },
    /** this should be regular schema. To by pass not being to put regular schema in a prop - use a function that will return the schema and give that to the prop instead  */
    addSchema: {
      type: Array as PropType<any[]>,
      default: [],
    },
    /** takes in the value of the what the middle repeater should be named */
    nameForMiddle: {
      type: String,
    },
    /** takes in the value of the what the inner repeater should be named */
    nameForInner: {
      type: String,
    },
    addLabel: {
      type: String,
      default: "Add Item",
    },
    removeLabel: {
      type: String,
      default: "Remove",
    },
    disabled: {
      type: Boolean,
    },
    /** ability to forward a value todo - add more?*/
    outerForward: {
      type: String,
    },
    /** an array of ids to inputs that should be cleared on append */
    customInputIds: {
      type: Array as PropType<string[]>,
      default: [],
    },
    /** allows for ability to disable the clear functionality.*/
    clearInputsOnAppend: {
      type: Boolean,
      default: true,
    },
    //   //TODO - replace with emits?
    //  /** function to perform when something is appended  */
    //   onAppend: {
    //     type: Function,
    //   },
    //   /** function to perform when something is removed  */
    //   onRemove: {
    //     type: Function,
    //   },

    /** min determins if the repeater can be empty. if not set to zero, it will create a blank instance */
    min: {
      type: String,
      default: "0",
    },
    //todos
    draggable: {
      type: Boolean,
      default: false,
    },
    downControl: {
      type: Boolean,
      default: false,
    },
    upControl: {
      type: Boolean,
      default: false,
    },
    numericRollup: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const data = ref({
      noLabel: noLabelSchema(),
    });

    const values: any = ref([]);

    const outerLengths = computed(() => {
      //this function is important in determining disabled. it allows the middle repeater to know the number of items in the array per outer repeater.
      //it returns an array that can be used by the nestedRepeaterHeader to grab the number of elements for that index.
      //example - 2 counties, first with 2 materials, second with 4 materials. this returns [2,4]. can be accessed via index outerLengths[0] to get 2
      return values.value.map((child: { [x: string]: any }) => {
        const childKey = Object.keys(child)[0];
        const childArray = child[childKey];
        return childArray.length;
      });
    });

    const triggeredFromOuter = ref(false);

    //this one is not using the fns like the others because it is v-modeled to allow for pushing specific values
    const appendOuterItem = () => {
      return new Promise<void>((resolve, reject) => {
        triggeredFromOuter.value = true;
        if (props.nameForMiddle && props.nameForInner)
          values.value.push({ [props.nameForMiddle]: [{ [props.nameForInner]: [] }] });
        // if (props.onAppend) props.onAppend();
        resolve();
      })
        .then(() => {
          //clears the input fields if desired (default behavior) and if the ids are provided
          if (props.clearInputsOnAppend && props.customInputIds.length > 0) {
            props.customInputIds.forEach((id) => {
              clearInput(id);
            });
          }
        })
        .catch((error) => {
          console.error("Error occurred:", error);
        });
    };

    const removeOuterItem = (index: number) => {
      return new Promise<void>((resolve, reject) => {
        values.value.splice(index, 1);
        // if (props.onRemove) props.onRemove();
        resolve();
      })
        .then(() => {
          //optional space for after remove
        })
        .catch((error) => {
          console.error("Error occurred:", error);
        });
    };

    const validNumberInputRegex = /^[0-9]\d{0,2}(\d{3})*(\.\d+)?$/; //pulled from smart

    //todo - fix naming
    const sumWeightsByMiddle = (materialIndex: number, countyIndex: number = 0) => {
      const county = values.value[countyIndex];
      const countyKey = Object.keys(county)[0];

      // const county = Object.keys(schemaModel.value.schemaWrapperRepeater[0])[0];

      const material = county[countyKey][materialIndex];
      const materialKey = Object.keys(material)[0];
      const weights = material[materialKey];

      //TODO - switch to reduce
      let totalWeight: number = 0;
      if (weights)
        weights.forEach((element: any) => {
          if (element.schemaWeight && validNumberInputRegex.test(element.schemaWeight.toString())) {
            totalWeight += parseFloat(element.schemaWeight.toString());
          }
        });

      return totalWeight.toString();
      // if (addTonsString) {
      //   return totalWeight + " tonsf";
      // } else {
      //   return totalWeight.toString();
      // }
    };

    const sumAllMiddles = (countyIndex: any) => {
      const county = values.value[countyIndex];
      const countyKey = Object.keys(county)[0];

      let totalWeight: number = 0;
      const materials = county[countyKey];
      if (materials)
        materials.forEach((element: any, index: any) => {
          totalWeight += parseFloat(sumWeightsByMiddle(index, countyIndex));
        });

      return totalWeight + " tons";
    };

    function testSum() {
      console.log(sumWeightsByMiddle(0));
    }

    provide(injectionSymbols.SumInsideMiddleHelperKey, { sumWeightsByMiddle });
    provide(injectionSymbols.SumMiddlesHelperKey, { sumAllMiddles });

    return {
      data,
      values,
      appendOuterItem,
      removeOuterItem,
      outerLengths,
      triggeredFromOuter,
      testSum,
    };
  },
  components: {
    XMarkIcon,
    BaseButton,
    PlusIcon,
  },
});
</script>
