<template>
  <LayoutChildList>
    <Authorize permission-name="ReadDocument" :resource="{ [idField]: $route.params.id }" with-redirect>
      <ListCardsLocal
        :entity-label="entityDocumentConfigStore.documentLabel"
        :entity-label-plural="entityDocumentConfigStore.documentLabelPlural"
        :items="entityDocumentStore.documents"
        is-child-list
        :create-to.once="{ name: routeNamePrefix + 'New', params: { ...$route.params } }"
        :create-to-auth.once="createDocumentAR"
        @deleteClick="deleteDocument"
        create-label="Upload"
        hide-filters
      >
        <template #cards="cardsProps">
          <li
            v-for="item in cardsProps.items"
            :key="item.id"
            class="mx-4 bg-white px-4 py-6 shadow sm:rounded-lg sm:p-6"
          >
            <article :aria-labelledby="'listCard-title-' + item.id">
              <div>
                <div class="flex space-x-3">
                  <div class="flex-shrink-0">
                    <InitialBadge :names="item.displayName" size="lg" aria-hidden="true"></InitialBadge>
                  </div>
                  <div class="min-w-0 flex-1">
                    <p class="text-sm font-medium text-neutral-900">
                      {{ item.displayName }}
                      <!-- <a :href="question.author.href" class="hover:underline">{{ question.author.name }}</a> -->
                    </p>
                    <p class="text-sm text-neutral-500" :id="'listCard-title-' + item.id">
                      {{ item.type }}
                    </p>
                  </div>
                  <div class="flex flex-shrink-0 gap-x-4 self-center">
                    <a :href="download(item)" download class="px-2 text-neutral-400 hover:text-neutral-600"
                      ><ArrowDownTrayIcon class="h-5 w-5"
                    /></a>
                    <Authorize permission-name="DeleteDocument" :resource="{ [idField]: $route.params.id }">
                      <Menu as="div" class="relative inline-block text-left">
                        <div>
                          <MenuButton
                            class="-m-2 flex items-center rounded-full p-2 text-neutral-400 hover:text-neutral-600"
                          >
                            <span class="sr-only">Open actions</span>
                            <EllipsisVerticalIcon class="h-5 w-5" aria-hidden="true" />
                          </MenuButton>
                        </div>

                        <transition
                          enter-active-class="transition ease-out duration-100"
                          enter-from-class="transform opacity-0 scale-95"
                          enter-to-class="transform opacity-100 scale-100"
                          leave-active-class="transition ease-in duration-75"
                          leave-from-class="transform opacity-100 scale-100"
                          leave-to-class="transform opacity-0 scale-95"
                        >
                          <MenuItems
                            class="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                          >
                            <div class="py-1">
                              <MenuItem v-slot="{ active }">
                                <router-link
                                  :to="{
                                    name: routeNamePrefix + 'Edit',
                                    params: { ...$route.params, documentId: item.id },
                                  }"
                                  :class="[
                                    active ? 'bg-neutral-100 text-neutral-900' : 'text-neutral-700',
                                    'flex px-4 py-2 text-sm',
                                  ]"
                                >
                                  <PencilIcon class="mr-3 h-5 w-5 text-neutral-400" aria-hidden="true" />
                                  <span>Edit</span></router-link
                                >
                              </MenuItem>
                              <MenuItem v-slot="{ active }">
                                <button
                                  type="button"
                                  :class="[
                                    active ? 'bg-neutral-100 text-neutral-900' : 'text-neutral-700',
                                    'flex w-full px-4 py-2 text-sm',
                                  ]"
                                  @click="openConfirmDelete(item)"
                                >
                                  <TrashIcon
                                    class="mr-3 h-5 w-5"
                                    aria-hidden="true"
                                    :class="[active ? `text-red-600` : `text-neutral-400`]"
                                  />
                                  <span>Delete</span>
                                </button>
                              </MenuItem>
                            </div>
                          </MenuItems>
                        </transition>
                      </Menu>
                    </Authorize>
                  </div>
                </div>
                <!-- <h2
                  :id="'listCard-title-' + item.id"
                  class="mt-4 hidden text-base font-medium text-neutral-900"
                >
                  {{ item[idField] }}
                </h2> -->
              </div>
              <!-- Was mt-2 with the title. -->
              <div class="mt-4 space-y-4 whitespace-pre-wrap text-sm text-neutral-700">
                {{ item.comment }}
              </div>
              <!-- <div class="mt-6 flex justify-between space-x-8">
                    <div class="flex space-x-6">
                      <span class="inline-flex items-center text-sm">
                        <button type="button" class="inline-flex space-x-2 text-neutral-400 hover:text-neutral-500">
                          <HandThumbUpIcon class="h-5 w-5" aria-hidden="true" />
                          <span class="font-medium text-neutral-900">{{ question.likes }}</span>
                          <span class="sr-only">likes</span>
                        </button>
                      </span>
                      <span class="inline-flex items-center text-sm">
                        <button type="button" class="inline-flex space-x-2 text-neutral-400 hover:text-neutral-500">
                          <ChatBubbleLeftEllipsisIcon class="h-5 w-5" aria-hidden="true" />
                          <span class="font-medium text-neutral-900">{{ question.replies }}</span>
                          <span class="sr-only">replies</span>
                        </button>
                      </span>
                      <span class="inline-flex items-center text-sm">
                        <button type="button" class="inline-flex space-x-2 text-neutral-400 hover:text-neutral-500">
                          <EyeIcon class="h-5 w-5" aria-hidden="true" />
                          <span class="font-medium text-neutral-900">{{ question.views }}</span>
                          <span class="sr-only">views</span>
                        </button>
                      </span>
                    </div>
                    <div class="flex text-sm">
                      <span class="inline-flex items-center text-sm">
                        <button type="button" class="inline-flex space-x-2 text-neutral-400 hover:text-neutral-500">
                          <ShareIcon class="h-5 w-5" aria-hidden="true" />
                          <span class="font-medium text-neutral-900">Share</span>
                        </button>
                      </span>
                    </div>
                  </div> -->
            </article>
          </li>
        </template>
      </ListCardsLocal>
    </Authorize>
    <ConfirmDelete
      :entity-label="entityDocumentConfigStore.documentLabel"
      :entity-label-plural="entityDocumentConfigStore.documentLabelPlural"
      @deleteClick="deleteDocument"
      ref="confirmDeleteRef"
    ></ConfirmDelete>
  </LayoutChildList>
</template>

<script lang="ts">
import { ConfirmDelete } from "@/components/modals";
import { ListCardsLocal } from "@/components/list/listCards";
import { LayoutChildList } from "@/components/layouts";
import { InitialBadge } from "@/components/badge";

import { IDocument } from "@/components/entity/document";
import {
  deleteDocumentById,
  getDocumentDownloadLink,
  getDocuments,
} from "@/components/entity/document/entityDocumentApi";
import { useEntityDocumentConfigStore } from "@/components/entity/document/entityDocumentConfigStore";
import { useEntityDocumentStore } from "@/components/entity/document/entityDocumentStore";
import type { IPaging } from "@/utils/api";
import { useApi } from "@/utils/api";
import { longDateTimeString } from "@/utils/filters";

import { Authorize, useAuthorization } from "@/utils/auth/authorization";

import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import { ArrowDownTrayIcon, EllipsisVerticalIcon, PencilIcon, TrashIcon } from "@heroicons/vue/20/solid";
import { defineComponent, ref, watch } from "vue";
import { useRoute } from "vue-router";

/** Component to display the Document ListCards for an entity.
 *  Allows for the edit, delete, and download of the document (with authorization)
 */
export default defineComponent({
  name: "EntityDocuments",
  emits: [],
  components: {
    Authorize,
    LayoutChildList,
    ListCardsLocal,
    InitialBadge,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    EllipsisVerticalIcon,
    PencilIcon,
    TrashIcon,
    ArrowDownTrayIcon,
  },
  props: {
    /** The id field for the entity these documents belong to (E.g. exampleEntityId) */
    idField: {
      type: String,
      required: true,
    },
    /** The route name prefix for the entity document pages (E.g. "ExampleEntityDocument").
     * This prefix is the string that when combined with "Edit" or "New" will create the proper route name for each  */
    routeNamePrefix: {
      type: String,
      required: true,
    },
  },
  setup(props, context) {
    const entityDocumentStore = useEntityDocumentStore();
    const entityDocumentConfigStore = useEntityDocumentConfigStore();
    const handleRowClick = () => {};

    const { authorizationClient, AuthRequest } = useAuthorization();
    const route = useRoute();

    /* Optional way to validate things if not using the Authorize component */
    // const readDocumentAR = new AuthRequest();
    // readDocumentAR.permissionName = "ReadDocument";
    // authorizationClient.isAuthorizedRedirect(readDocumentAR, useRouter());

    const { exec: fetchDocuments } = useApi<IPaging<IDocument>>(getDocuments, {
      responseAdapter: (response) => response.data,
    });

    watch(
      () => route.params.id,
      (newValue, oldValue) => {
        if (!newValue) {
          entityDocumentStore.documents = [];
        } else {
          fetchDocuments({ [props.idField]: route.params.id }).then((result) => {
            result && entityDocumentStore.fill(result);
          });
        }
      },
      { immediate: true },
    );

    const { exec: execDeleteDocument } = useApi(deleteDocumentById, {
      responseAdapter: (response) => {
        return response.data;
      },
    });

    let itemToDelete: IDocument | null = null;
    async function deleteDocument() {
      if (!itemToDelete || !entityDocumentStore.documents) return;

      await execDeleteDocument({ id: itemToDelete.id, [props.idField]: route.params.id });

      //TODO: round trip to server to minimize paging issues??
      entityDocumentStore.remove(itemToDelete);
    }

    const confirmDeleteRef = ref<typeof ConfirmDelete | null>(null);
    const openConfirmDelete = function (item: IDocument) {
      itemToDelete = item;
      confirmDeleteRef.value?.openConfirmation();
    };

    const createDocumentAR = new AuthRequest();
    createDocumentAR.permissionName = "CreateDocument";
    createDocumentAR.resource = { [props.idField]: route.params.id };

    const download = (item: IDocument) => {
      return getDocumentDownloadLink(item.id);
    };

    return {
      download,
      createDocumentAR,
      longDateTimeString,
      handleRowClick,
      entityDocumentStore,
      entityDocumentConfigStore,
      deleteDocument,
      confirmDeleteRef,
      openConfirmDelete,
    };
  },
});
</script>
