<template>
  <TransitionRoot as="template" :show="openRef">
    <Dialog as="div" class="fixed inset-0 z-40 overflow-y-auto" @close="openRef = false">
      <div class="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0">
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="ease-in duration-200"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span class="hidden sm:inline-block sm:h-screen sm:align-middle" aria-hidden="true">&#8203;</span>
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enter-to="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leave-from="opacity-100 translate-y-0 sm:scale-100"
          leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <div
            class="relative inline-block transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6 sm:align-middle"
          >
            <div class="sm:flex sm:items-start">
              <div
                class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10"
              >
                <ExclamationCircleIcon class="h-6 w-6 text-red-600" aria-hidden="true" />
              </div>
              <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <DialogTitle as="h3" class="text-lg font-medium leading-6 text-gray-900">
                  {{ title }}
                </DialogTitle>
                <div class="mt-2">
                  <p class="text-sm text-gray-500">
                    <span v-if="canDelete && remove">{{ removeBodyText }}</span>
                    <span v-else-if="canDelete">
                      This {{ deleteBodyText }} will be <i>permanently</i> deleted. <br />This cannot be undone.
                    </span>
                    <span v-else>
                      {{ cannotDeleteLabel }}
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div class="mt-5 sm:mt-4 sm:ml-10 sm:flex sm:pl-4">
              <BaseButton
                v-if="canDelete"
                @click.once="
                  openRef = false;
                  $emit('deleteClick');
                "
                class="w-full sm:mr-3 sm:w-auto sm:text-sm"
                variant="danger"
                :label="deleteStringRef"
              >
              </BaseButton>
              <BaseButton
                class="mt-3 w-full sm:mt-0 sm:w-auto sm:text-sm"
                @click="openRef = false"
                ref="cancelButtonRef"
                label="Cancel"
              ></BaseButton>
            </div>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script lang="ts">
import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from "@headlessui/vue";
import { computed, ref } from "vue";

import BaseButton from "@/components/button/BaseButton.vue";
import { ExclamationCircleIcon } from "@heroicons/vue/24/outline";
import { defineComponent } from "vue";

/**
 * ConfirmDelete works in two modes - conditional and regular.
 * In conditional mode, it will execute an async method to determine whether something can be deleted.
 * In regular mode, it will immediately prompt the user to delete the entity.
 *
 * Events:
 *
 * `deleteClick` - Emitted when the "Delete" button is clicked. (Can also be labeled as "Remove" if specified in the props)
 */
export default defineComponent({
  name: "ConfirmDelete",
  emits: ["deleteClick"],
  components: {
    BaseButton,
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    ExclamationCircleIcon,
  },
  props: {
    /** Label for the entity to be deleted */
    entityLabel: {
      type: String,
      required: true,
    },
    /** String of comma seperated labels for any entities that will be deleted in casacade with the entity  */
    relatedEntitiesLabel: {
      type: String,
      default: null,
    },
    /**
     *  An async method returning a falsey value or a string explaining why the entity can't be deleted.
     */
    condition: {
      type: Function,
      default: null,
    },
    /** Props used when "Removing" something rather than deleting */
    remove: {
      type: Boolean,
      default: false,
    },
    /** Label for the parent entity */
    parentEntityLabel: {
      type: String,
      default: null,
    },
  },
  setup(props, { expose }) {
    const openRef = ref(false);
    const canDelete = ref(false);
    const cannotDeleteLabel = ref("");
    // TODO: Update this to better handle longer running conditions. Use flags and promises to show a spinner on the dialog.
    const openConfirmation = async () => {
      if (props.condition) {
        cannotDeleteLabel.value = await props.condition();
        canDelete.value = !cannotDeleteLabel.value;
      } else {
        canDelete.value = true;
      }
      openRef.value = true;
    };

    const deleteStringRef = ref("");
    if (props.remove) {
      deleteStringRef.value = "Remove";
    } else {
      deleteStringRef.value = "Delete";
    }

    const title = computed(() =>
      canDelete.value
        ? `${deleteStringRef.value} ${props.entityLabel}`
        : `Cannot ${deleteStringRef.value} ${props.entityLabel}`,
    );

    const deleteBodyText = computed(() =>
      props.relatedEntitiesLabel
        ? `${props.entityLabel.toLowerCase()}${props.relatedEntitiesLabel}`
        : props.entityLabel.toLowerCase(),
    );

    const removeBodyText = computed(
      () =>
        `This ${props.entityLabel.toLowerCase()} will be removed from this ${props.parentEntityLabel?.toLowerCase()}.`,
    );

    expose({ openConfirmation });
    return {
      openConfirmation,
      openRef,
      title,
      canDelete,
      cannotDeleteLabel,
      deleteStringRef,
      deleteBodyText,
      removeBodyText,
    };
  },
});
</script>
