/* 
// "FormKit" address - 
entity {
  prefixAddress {
    street
    city
    state
    zipCode
    country
  }
}

// address - 
entity {
  prefixStreet
  prefixCity
  prefixState
  prefixZipCode
  prefixCountry
}
*/

//TS:TODO replace with usage of Capitalize<>? - https://www.typescriptlang.org/docs/handbook/2/template-literal-types.html#capitalizestringtype
const fieldNames = ["city", "country", "state", "street", "zipCode"] as const;
type fields = (typeof fieldNames)[number];
const fieldNamesSuffix = ["City", "Country", "State", "Street", "ZipCode"] as const;
type fieldsSuffix = (typeof fieldNamesSuffix)[number];

export type PrefixedAddress<Prefix extends string> = {
  [P in `${Prefix}${fieldsSuffix}`]?: string;
};

export type Address = {
  [P in `${fields}`]?: string;
};

export type PrefixedAddressField<Prefix extends string> = {
  [P in `${Prefix}Address`]?: Address;
};

export type HasPrefixedAddress<Prefix extends string> = PrefixedAddress<Prefix> & PrefixedAddressField<Prefix>;
export type HasPlainAddress = PrefixedAddressField<"location"> & Address;

/**
 * Converts the passed in `data` object from it's FormkitForm back into its original form.
 * Accepts an object that has a "prefixed" address and divides the values from this property
 * back into their respective properties on the `data` object.
 * @param {HasPrefixedAddress<Prefix>} data Data object with the prefixed address
 * @param {Prefix} prefix prefix used on the address
 * @returns
 */
export function addressFromFormkit<Prefix extends string>(data: HasPrefixedAddress<Prefix>, prefix: Prefix) {
  const addressField = data[`${prefix}Address`] as Address | undefined;
  if (!addressField) return;

  for (let index in fieldNamesSuffix) {
    const fieldNamePrefixed = (prefix + fieldNamesSuffix[index]) as keyof PrefixedAddress<Prefix>;
    const fieldName = fieldNames[index];

    (data as PrefixedAddress<Prefix>)[fieldNamePrefixed] = addressField?.[fieldName];
  }

  delete data[`${prefix}Address`];
}

/**
 * Converts the passed in `data` object from it's original form into a Formkit compatabile form.
 * Accepts an object with a prefixed address that is seperated out into its individual parts and
 * then combines it into a object that FormKit will accept.
 * Appends this new object to the `data` object on a property named by the `prefix` followed by "Address"
 *
 * @param {HasPrefixedAddress<Prefix>} data Object containing the prefixed address
 * @param {Prefix} prefix Prefix for the `data` objects address
 */
export function addressToFormkit<Prefix extends string>(data: HasPrefixedAddress<Prefix>, prefix: Prefix) {
  (data[`${prefix}Address`] as PrefixedAddressField<Prefix>) = {
    street: data[`${prefix}Street`],
    city: data[`${prefix}City`],
    state: data[`${prefix}State`],
    zipCode: data[`${prefix}ZipCode`],
    country: data[`${prefix}Country`],
  } as Address;
}

/**
 * Converts the passed in `data` object from it's FormkitForm back into its original form.
 * Accepts an object that has a "plain" address (contains the `locationAddress` property) and
 * divides the values from this property back into their respective properties on the `data` object.
 * @param {HasPlainAddress} data Data object to be converted
 */
export function plainAddressFromFormkit(data: HasPlainAddress) {
  data.street = data.locationAddress?.street;
  data.city = data.locationAddress?.city;
  data.state = data.locationAddress?.state;
  data.zipCode = data.locationAddress?.zipCode;
  data.country = data.locationAddress?.country;
}

/**
 * Converts the passed in `data` object from it's original form into a Formkit compatabile form.
 * Accepts an object with a "plain" address (no prefix) that is seperated out into its individual
 * parts and then combines it into a object that FormKit will accept.
 * Appends this new object to the `data` object on a property named `locationAddress`
 * @param {HasPlainAddress} data Data object with plain address
 */
export function plainAddressToFormkit(data: HasPlainAddress) {
  data.locationAddress = {
    street: data.street,
    city: data.city,
    state: data.state,
    zipCode: data.zipCode,
    country: data.country,
  };
}
