<template>
  <div class="rounded-lg border border-neutral-300">
    <div class="rounded-t-lg bg-neutral-200 flex justify-between py-1.5 pl-2.5 pr-2">
      <div>{{ leftHeader }}</div>
      <div>{{ rightHeader }}</div>
    </div>
    <slot></slot>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
/** adjusts styling for when the repeater is not nested. used inside inner repeater */

export default defineComponent({
  name: "NonNestedRepeaterHeader",
  emits: [],
  props: {
    leftHeader: {
      type: String,
    },
    rightHeader: {
      type: String,
    },
  },
  setup(props, context) {},
  components: {},
});
</script>
