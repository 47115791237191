<template>
  <div class="mb-1 mt-1 w-full">
    <label for="search-field" class="sr-only">Search Locations</label>

    <div class="flex w-full">
      <div
        class="relative w-full text-neutral-400 rounded-md ring-1 focus-within:ring-2 ring-neutral-300 bg-white focus-within:ring-neutral-400 transition-all"
        v-auto-animate
      >
        <div class="pointer-events-none absolute inset-y-0 left-2.5 flex mt-3.5 sm:mt-2.5 items-start">
          <MagnifyingGlassIcon class="h-4 w-4 flex-shrink-0" aria-hidden="true" />
        </div>
        <input
          name="search-field"
          id="search-field"
          class="w-full rounded-md peer py-2 pl-8 pr-3 sm:text-sm text-neutral-900 bg-transparent placeholder-neutral-500 focus:placeholder-opacity-0 focus:outline-none focus:ring-0"
          type="search"
          autocomplete="off"
          autocorrect="off"
          spellcheck="false"
          :value="keywordsRef"
          aria-describedby="search-help"
          @input="keywordInputHandler(($event.target as HTMLInputElement)?.value)"
          placeholder="Keyword"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { TransitionChild, TransitionRoot } from "@headlessui/vue";
import { MagnifyingGlassIcon } from "@heroicons/vue/24/solid";
import { useSessionStorage } from "@vueuse/core";
import { useRouteQuery } from "@vueuse/router";
import { computed, defineComponent, inject, ref } from "vue";
import { onBeforeRouteUpdate, useRoute } from "vue-router";
import injectionSymbols from "@/components/viewer/injectionSymbols";
import { ListFilterLabels, ViewerListProps } from "@/components/viewer";
import { useViewerStore } from "@/components/viewer/viewerStore";
// import { useFocus } from "@vueuse/core";

/**
 * Provides a keyword search input component for filtering locations
 * Integrates with the `viewerStore` and updates as input is typed.
 */
export default defineComponent({
  name: "ViewerKeywordFilter",
  components: {
    TransitionChild,
    TransitionRoot,
    MagnifyingGlassIcon,
  },
  props: {
    /**
     * Optional default value. Default is "".
     */
    defaultId: {
      type: String,
      default: () => "",
    },
    /** Allows you to prevent a value (e.g. defaultId) from being sent in the query. Default is "" */
    ignoredId: {
      type: String,
      default: () => "",
    },
    /**
     * Optional name of the property to be searched on. Defaults to "keywords".
     */
    field: {
      type: String,
      default: () => "keywords",
    },
  },
  setup(props) {
    const route = useRoute();

    // For now, assume that all props are provided before rendering.
    const { filterChangeHandler } = inject(injectionSymbols.ViewerListPropsKey) as ViewerListProps;
    // const { registerAndUpdateFilters } = inject(injectionSymbols.ListFilterLabelsKey) as ListFilterLabels;

    const isQueryBlank = Object.entries(route.query).length === 0;
    const keywordsQueryRef = useRouteQuery(props.field, props.defaultId);

    const keywordsRef = ref(keywordsQueryRef.value);

    // registerAndUpdateFilters({
    //   label: "Keyword",
    //   value: keywordsRef.value.trim() !== "" ? [keywordsRef.value] : [],
    //   field: props.field,
    // });

    const isDefault = computed<boolean>(() => keywordsRef.value === props.defaultId);
    const isIgnored = computed<boolean>(() => keywordsRef.value === props.ignoredId);

    const keywordInputHandler = (filterValue: string) => {
      keywordsRef.value = filterValue;
      const queryValue: any = isIgnored.value ? undefined : filterValue; // TODO cleanup types.

      keywordsQueryRef.value = queryValue;
    };

    onBeforeRouteUpdate((to, from) => {
      //Somehow try/catch here fixes an edge case where the datasetsQueryRef doesn't update properly.
      try {
        if (to.query[props.field] === from.query[props.field]) return;

        const newValue = to.query[props.field];
        if (newValue) {
          keywordsRef.value = newValue.toString(); // TODO: Add support for arrays.
        } else if (Object.entries(to.query).length === 0) {
          keywordsRef.value = props.defaultId;
        } else {
          keywordsRef.value = props.ignoredId;
        }

        // registerAndUpdateFilters({
        //   label: "Keyword",
        //   value: keywordsRef.value.trim() !== "" ? [keywordsRef.value] : [],
        //   field: props.field,
        // });
        filterChangeHandler({
          field: props.field,
          filterValue: keywordsRef.value,
          isDefault: isDefault.value,
          isIgnored: isIgnored.value,
        });
      } catch (error) {
        console.log(error);
      }
    });

    if (!isIgnored.value) {
      filterChangeHandler({
        field: props.field,
        filterValue: keywordsRef.value,
        isDefault: isDefault.value,
        isIgnored: isIgnored.value,
      });
    }

    return {
      keywordsRef,
      isDefault,
      isIgnored,
      keywordInputHandler,
    };
  },
});
</script>

<style module></style>
