import { apiService } from "@/utils/api/index.js";
import type { IPagingApiAndNotifierConfig } from "@/utils/api/index.js";
import type { NotifyOptionsError, NotifyOptionsSuccess } from "@/components/notifications/index.js";
import { useEntityDocumentConfigStore } from "@/components/entity/document/entityDocumentConfigStore.js";
import type { IFileUpload, IFileUploadQuery } from "@/components/entity/document/index.js";

const URL = "FileUpload";

let api: ReturnType<typeof apiService<IFileUpload>>;

const getApi = () => {
  if (api) return api;

  const entityDocumentConfigStore = useEntityDocumentConfigStore();
  const axiosParams = {
    // Set different base URL based on the environment
    baseURL: entityDocumentConfigStore.baseURL,
  };
  api = apiService<IFileUpload>(entityDocumentConfigStore.apiHost, axiosParams);
  return api;
};

const getEntityName = () => {
  const entityDocumentConfigStore = useEntityDocumentConfigStore();
  return entityDocumentConfigStore.documentLabel;
};

/**
 * @param {String} keywords Matches against first name, last name, email

 */
// const getFileUploads = (keywords, abortMethod, config: IPagingApiAndNotifierConfig = {}) => {
//   return getApi().get(URL, {
//     params: { keywords },
//     abort: config?.abortMethod,
//     successConfig: { hide: true },
//     errorConfig: { hide: hideNotifications, fetched: getEntityName() },
//   });
// };

/**
 * @param {Object} params Query parameters, varies (TODO fix)

 */
const getFileUploads = (params: IFileUploadQuery, config: IPagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.hide = true;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.fetched = getEntityName();
    config?.errorNotifCb?.(options);
  };
  const url = config.pagingUrl ? config.pagingUrl : URL;

  return getApi().getPaged(url, {
    params,
    abort: config?.abortMethod,
    successNotifCb,
    errorNotifCb,
  });
};

/**
 * @param {Int} id
 */
const getFileUploadById = (id: string | number, config: IPagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.hide = true;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.fetched = getEntityName();
    config?.errorNotifCb?.(options);
  };

  return getApi().get(URL + "/" + id, {
    successNotifCb,
    errorNotifCb,
  });
};

const getNewFileUpload = async () => {
  //optionally make any other changes as necessary before returning.
  return { dataRef: {} };
};

const createFileUpload = (fileUpload: any, config: IPagingApiAndNotifierConfig = {}) => {
  //TODO:TS
  const data = new FormData();
  fileUpload.files.forEach((file: any) => data.append("files", file.file));

  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.hide = true;
    options.created = getEntityName();
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.created = getEntityName();
    config?.errorNotifCb?.(options);
  };

  return getApi().post(URL, data, {
    onUploadProgress: function (progressEvent: ProgressEvent) {
      // Do whatever you want with the native progress event
      // console.log("progress", progressEvent);
    },
    headers: {
      "Content-Type": "multipart/form-data",
    },
    successNotifCb,
    errorNotifCb,
  });
};

const updateFileUpload = (fileUpload: any, config: IPagingApiAndNotifierConfig = {}) => {
  //TODO:TS
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.updated = getEntityName();
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.updated = getEntityName();
    config?.errorNotifCb?.(options);
  };

  return getApi().put(URL, fileUpload, {
    successNotifCb,
    errorNotifCb,
  });
};

/**
 * @param {FileUpload} fileUpload This cannot be a 'ref' to a fileUpload
 */
const saveFileUpload = (fileUpload: any, config: IPagingApiAndNotifierConfig = {}) => {
  //TODO:TS
  if (fileUpload.id) {
    return updateFileUpload(fileUpload, config);
  } else {
    return createFileUpload(fileUpload, config);
  }
};

const deleteFileUploadById = (id: string | number, config: IPagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.deleted = getEntityName();
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.deleted = getEntityName();
    config?.errorNotifCb?.(options);
  };

  return getApi().delete(URL + "/" + id, {
    successNotifCb,
    errorNotifCb,
  });
};

export {
  deleteFileUploadById,
  getFileUploads,
  getFileUploadById,
  getNewFileUpload,
  createFileUpload,
  updateFileUpload,
  saveFileUpload,
};
