<template>
  <!-- <div :class="'md:col-span-' + span" class="m-0 p-0">
    <slot></slot>
  </div> -->
  <div
    class="m-0 p-0"
    :class="{
      'md:col-span-6': span === '6',
      'md:col-span-5': span === '5',
      'md:col-span-4': span === '4',
      'md:col-span-3': span === '3',
      'md:col-span-2': span === '2',
      'md:col-span-1': span === '1',
    }"
  >
    <slot></slot>
  </div>
</template>

<script lang="ts">
import { PropType, defineComponent } from "vue";

export default defineComponent({
  name: "RepeatedField",
  emits: [],
  props: {
    // todo make number?
    span: {
      type: String,
      default: "3",
    },
  },
  setup(props, context) {},
  components: {},
});
</script>
