import authorizationClient from "@/utils/auth/authorization/AuthorizationClient.js";
import { AUTHZ_KEY } from "@/utils/auth/authorization/constants.js";
import { useAppSettingsStore } from "@/components/common/index.js";
//import authorizeComponent from "@/utils/auth/authorization/authorizeComponent.vue";
import { App } from "vue";

const setBaseApiUrl = () => {
  const appSettingsStore = useAppSettingsStore();
  return window.location.origin + appSettingsStore.viteBaseUrl + "authorization/api";
};

const setSystemIdentifier = () => {
  const appSettingsStore = useAppSettingsStore();
  return appSettingsStore.systemIdentifier;
};

/** If `options.defaultSystemIdentifier` or `options.baseApiUrl` are provided, the authorization client config will use these values.
 * Otherwise, it will pull the `baseApiUrl` from the `appSettingsStore.viteBaseUrl` and the `defaultSystemIdentifier` from `appSettingsStore.systemIdentifier`
 */
export const authzPlugin = {
  install(app: App, options: any) {
    if (!options.defaultSystemIdentifier) {
      options.defaultSystemIdentifier = setSystemIdentifier();
    }
    if (!options.baseApiUrl) {
      options.baseApiUrl = setBaseApiUrl();
    }
    authorizationClient.config.set(options);
    //Vue.component("VAuthorize", authorizeComponent);
    app.provide(AUTHZ_KEY, authorizationClient);

    if (options && options.useGlobalProperties !== false) {
      app.config.globalProperties.$authz = authorizationClient;
    }
  },
};
