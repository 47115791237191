import { plainAddressFromFormkit, plainAddressToFormkit } from "@mui/components/fk";

import { IAbode, IAbodeNew } from "@casa/views/abode/abode";

export default {
  addAuthRequestFields,
  getInitialData,
  responseAdapter,
  requestAdapter,
  fromFormkit,
  toFormkit,
};

function addAuthRequestFields(entity: IAbode) {
  entity._authRequestFields = ["id", "status"];
  return entity;
}
function getInitialData() {
  const result = {} as IAbode; //TODO: ts
  return addAuthRequestFields(result);
}
function responseAdapter(response: { data: IAbode }) {
  addAuthRequestFields(response.data);
  //TODO: TS
  return response.data;
}
function requestAdapter(data: IAbode) {
  return data;
}

// Convert server data for use in the page's form (which may have groupings, etc different from the server data)
function toFormkit<T extends IAbode | IAbodeNew>(data: T) {
  plainAddressToFormkit(data);
}

// Convert formkit data to prep it for sending to the server (which is probably flatter than the fk data)
function fromFormkit(data: IAbode) {
  plainAddressFromFormkit(data as IAbode);
}
