<template>
  <th
    scope="col"
    class="sticky top-0 border-0 bg-neutral-50 p-0 text-right"
    :class="[isSrOnly || noSort ? `` : `cursor-pointer`]"
    :aria-sort="sort"
  >
    <span v-if="isSrOnly" class="sr-only">{{ label }}</span>
    <span
      v-if="noSort"
      class="group flex h-full w-full items-center border-b border-transparent px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-neutral-600"
      :class="[right ? 'justify-end' : 'justify-start']"
    >
      <span>{{ label }}</span>
    </span>
    <button
      v-if="!isSrOnly && !noSort"
      @click="clickHandler($event)"
      class="group flex h-full w-full items-center border-b border-transparent px-6 py-3 text-xs font-medium uppercase tracking-wider text-neutral-600"
      :class="[
        isDescending !== undefined ? 'border-neutral-400' : '',
        right ? 'justify-end text-right' : 'justify-start text-left',
      ]"
    >
      <span>{{ label }}</span>
      <span :class="[right ? 'order-first' : 'order-last']">
        <BarsArrowUpIcon
          v-if="!isDescending"
          class="h-5 w-5 flex-shrink-0 group-hover:visible group-focus-visible:visible"
          :class="[
            isDescending === false ? `visible text-neutral-500` : `invisible text-neutral-400`,
            right ? `mr-3` : `ml-3`,
          ]"
        ></BarsArrowUpIcon>
        <BarsArrowDownIcon
          v-if="isDescending"
          class="h-5 w-5 flex-shrink-0 group-hover:visible group-focus-visible:visible"
          :class="[isDescending ? `visible text-neutral-500` : `invisible text-neutral-400`, right ? `mr-3` : `ml-3`]"
        ></BarsArrowDownIcon>
      </span>
    </button>
  </th>
</template>

<script lang="ts">
// TODO: Modify to use better https://v3.vuejs.org/guide/component-props.html#prop-validation

// see https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/columnheader_role for sort info.
import type { ListTableProps } from "@/components/list/genericList";
import injectionSymbols from "@/components/list/genericList/injectionSymbols";
import { TransitionChild, TransitionRoot } from "@headlessui/vue";
import { BarsArrowDownIcon, BarsArrowUpIcon } from "@heroicons/vue/24/solid";
import { computed, defineComponent, inject } from "vue";

/** Component for the columns in a list table. Allows for the sorting of the column */
export default defineComponent({
  name: "ListTableSortColumn",
  components: {
    TransitionChild,
    TransitionRoot,
    BarsArrowUpIcon,
    BarsArrowDownIcon,
  },
  props: {
    /** Label for the column */
    label: {
      type: String,
      default: null,
    },
    /** Field required for the API fetch for sorting */
    field: {
      type: String,
      required: true,
    },
    /** Use this if the column is just for screen readers */
    isSrOnly: {
      type: Boolean,
      default: false,
    },
    /** Use this to disable the sort feature for the column */
    noSort: {
      type: Boolean,
      default: false,
    },
    /** Use this to right align the column name  */
    right: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { sortChangeHandler, sortByMapRef } = inject(injectionSymbols.GenericListPropsKey) as ListTableProps;

    const sort = computed(() => {
      switch (sortByMapRef?.value[props.field]?.isDescending) {
        case true:
          return "descending";
        case false:
          return "ascending";
        default:
          return "none";
      }
    });

    const isDescending = computed(() => sortByMapRef.value[props.field]?.isDescending);

    const clickHandler = ($event: KeyboardEvent | PointerEvent | MouseEvent) => {
      sortChangeHandler($event, props.field);
    };

    return {
      clickHandler,
      sort,
      isDescending,
    };
  },
});
</script>

<style module></style>
