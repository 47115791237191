<template>
  <pre class="text-sm">{{ JSON.stringify(obj, (k, v) => (v === undefined ? "_undefined" : v), 4) }}</pre>
</template>

<script lang="ts">
import { defineComponent } from "vue";
// import { Badge, FormSection } from "@/components";

/** Helper that enables you to see any object's state (including undefined fields) in JSON on the UI.
Handy for your primary entity, "errors" obj, or anything else. */
export default defineComponent({
  name: "ToJson",
  props: {
    /** Object whose state you would like to see */
    obj: {
      required: true,
    },
  },
  setup() {
    return {};
  },
  // components: {Badge, FormSection}
});
</script>
