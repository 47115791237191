/**
 * Returns a FormKit section schema that will hide the control's label
 *
 */
export const noLabelSchema = () => {
  return {
    label: {
      attrs: {
        class: "sr-only",
      },
    },
    // outer: {
    //   attrs: {
    //     class: "mb-0",
    //   },
    // },
  };
};
