<template>
  <div
    :class="{
      'sm:col-span-6': span === 6,
      'sm:col-span-5': span === 5,
      'sm:col-span-4': span === 4,
      'sm:col-span-3': span === 3,
      'sm:col-span-2': span === 2,
      'sm:col-span-1': span === 1,
    }"
  >
    <!-- @slot Slot to place your field in  -->
    <slot></slot>
  </div>
</template>

<script lang="ts">
import { PropType, defineComponent } from "vue";
/**
 * Styles field in manner accepted by FieldSetBase for four column field sets.
 */
export default defineComponent({
  name: "FieldCol",
  props: {
    /** Number of col span you would like.
     * @values 1,2,3,4,5,6
     */
    span: {
      type: Number as PropType<1 | 2 | 3 | 4 | 5 | 6>,
      default: 2,
      validator: (value: number) => {
        return value > 0 && value < 7;
      },
    },
  },
  setup() {
    return {};
  },
});
</script>

<style module></style>
