<template>
  <LayoutAppContent>
    <header class="pt-3 md:pb-5">
      <div class="px-4 sm:px-6 md:flex md:items-end md:justify-between md:px-8">
        <div class="min-w-0 flex-1">
          <h2 class="text-large font-bold leading-7 text-neutral-800">Analytics</h2>
          <h1
            class="mt-1 mb-2 text-2xl font-bold leading-7 text-neutral-800 sm:truncate sm:text-3xl sm:tracking-tight md:mb-0"
          >
            <!-- @slot Slot to define the heading for the page (such as the entity name and type of analytic) -->
            <slot name="heading"></slot>
          </h1>
        </div>
        <div class="mt-5 md:flex lg:mt-0 lg:ml-4">
          <BaseButton
            @click="$emit('changeFiltersClick')"
            variant="priority-subtle"
            label="Change Filters"
            :disabled="!canChangeFilters"
            v-if="!hideFilterChange"
          />
        </div>
      </div>
    </header>
    <div class="flex min-h-0 flex-1 flex-col items-stretch lg:flex-row">
      <main class="flex min-h-0 flex-1 border-neutral-300 bg-neutral-100 sm:border-t sm:border-l">
        <!-- @slot Slot for main content  -->
        <slot></slot>
      </main>
    </div>
  </LayoutAppContent>
</template>

<script lang="ts">
import LayoutAppContent from "@/components/layouts/LayoutAppContent.vue";
import { defineComponent } from "vue";

/** Base component for the Analytics pages
 *
 * Events:
 *
 * `changeFiltersClick` - Emitted when the "Change Filters" button is clicked.
 */
export default defineComponent({
  name: "LayoutAnalytics",
  emits: ["changeFiltersClick"],
  components: {
    LayoutAppContent,
  },
  props: {
    /** Determines if the component will allow filter change */
    canChangeFilters: {
      type: Boolean,
    },
    /** Determines when to hide the filter change */
    hideFilterChange: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    // const route = useRoute();
    // const isNew = computed(() => route.meta.isNew);

    return {};
  },
});
</script>

<style module>
/* .subheading a {
  @apply hover:underline;
} */
</style>
