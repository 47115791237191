import { IPagingApiAndNotifierConfig, apiService } from "@mui/utils/api";
import { serializeQueryParams } from "@mui/utils/helpers";
import { NotifyOptionsError, NotifyOptionsSuccess } from "@mui/components/notifications";
import { apiHosts } from "@casa/api/constants/apiHosts";

const PARENT_URL = (parentId: string | number, parentEntity: string) =>
  `${parentEntity}/${parentId}/materials-permitted`;
const URL = "materials-permitted";

const ENTITY_NAME = "Material Permitted";
const PARENT_ENTITY = {
  permitVersion: "permit-versions",
};

const axiosParams = {
  // Set different base URL based on the environment
  baseURL: import.meta.env.VITE_CASA_API_BASE_URL,
};
const api = apiService(apiHosts.CASA, axiosParams);

/**
 * @param {Object} params Query parameters, varies (TODO improve this)

 * @param {ApiOptions} config
 */
const getMaterialsPermitted = (params: any, config: IPagingApiAndNotifierConfig = {}) => {
  //TODO:TS
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.hide = true;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.fetched = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };
  const url = config.pagingUrl ? config.pagingUrl : URL;
  return api.get(url, {
    params,
    paramsSerializer: serializeQueryParams,
    abort: config?.abortMethod,
    successNotifCb,
    errorNotifCb,
  });
};

const createMaterialPermittedForPermitVersion = (materialPermitted: any, config: IPagingApiAndNotifierConfig = {}) => {
  //TODO:TS
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.updated = ENTITY_NAME; // Spcial case where a "Create" is always treated as an Update because it performs CUD.
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.created = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };

  return api.post(PARENT_URL(materialPermitted.permitVersionId, PARENT_ENTITY.permitVersion), materialPermitted, {
    successNotifCb,
    errorNotifCb,
  });
};

export { getMaterialsPermitted, createMaterialPermittedForPermitVersion };
