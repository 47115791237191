<template>
  <div
    class="flex items-center justify-center rounded-full bg-neutral-300 font-medium uppercase text-neutral-600"
    :class="[
      size === 'sm' ? 'h-6 w-6 text-sm' : '',
      size === 'base' ? 'h-8 w-8 text-base' : '',
      size === 'lg' ? 'h-10 w-10 text-lg' : '',
      size === '2xl' ? ' h-16 w-16 text-2xl ' : '',
    ]"
  >
    {{ label }}
  </div>
</template>

<script lang="ts">
import { PropType, computed, defineComponent } from "vue";

type BadgeNames = string | Array<string | null> | null;

/**
 * Component for badge that only displays initials. This component will convert names to initials if provided in that form.
 */
export default defineComponent({
  name: "InitialBadge",
  props: {
    /**
     * A full name string - "John Doe". Or an array with first/last - ["John", "Doe"]
     */
    names: {
      type: [String, Array<String | null>, null] as PropType<BadgeNames>,
    },
    /**
     * Initials to be displayed. This will take priority over the `names` prop if both are provided.
     */
    initials: {
      type: String,
    },
    /**
     * Optional size specification
     * @values sm, base, lg, 2xl
     */
    size: {
      type: String as PropType<"sm" | "base" | "lg" | "2xl">,
      default: "sm",
      validator: (val: string) => ["sm", "base", "lg", "2xl"].includes(val),
    },
  },
  setup(props) {
    const getFirstLetters = (names: BadgeNames | undefined) => {
      if (!names || !names[0]) return "";
      let arr;
      if (Array.isArray(names)) {
        arr = names;
      } else {
        arr = names.split(" ");
      }
      let result = arr[0] ? arr[0][0] : "";
      if (arr[1]) result += arr[1][0];
      return result;
    };

    const label = computed(() => (props.initials ? props.initials : getFirstLetters(props.names)));
    return { label };
  },
});
</script>

<style module></style>
