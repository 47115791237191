<template>
  <FieldGroup :label="label">
    <FormKit type="group" :name="fkName">
      <FormKit type="text" label="Street" name="street" validation="all_or_none_in_group_required|length:0,100" />
      <FieldSet :columns="3">
        <template #1>
          <FormKit type="text" label="City" name="city" validation="all_or_none_in_group_required|length:0,25" />
        </template>
        <template #2>
          <FormKit
            type="dropdown"
            label="State"
            name="state"
            :options="stateArray"
            validation="all_or_none_in_group_required"
            selection-removable
          />
        </template>
        <template #3>
          <FormKit type="text" label="Zip Code" name="zipCode" validation="all_or_none_in_group_required|length:0,10" />
        </template>
      </FieldSet>
      <FormKit
        v-if="withCountry"
        type="text"
        label="Country"
        name="country"
        validation="all_or_none_in_group_required"
      />
    </FormKit>
  </FieldGroup>
</template>
<script lang="ts">
import type { FormKitProOptionsProp } from "@formkit/pro"; // Do not remove this line. It's necessary for this component to build.
import { PropType, defineComponent } from "vue";
/**
 * Component to add an address section with all the fields necessary to a form.
 * Validates that if one of the address fields are filled out, they all must be.
 */
export default defineComponent({
  name: "AddressInput",
  props: {
    /**
     * Label to go above the entire address section
     */
    label: {
      type: String,
      required: true,
    },
    /**
     * Name that distinguishes the address for that entity (E.g. `mailAddress` or `locationAddress`)
     */
    fkName: {
      type: String,
      default: () => "address",
    },
    /**
     * Should be a FormKit compatible array of desired states to chose from
     */
    stateArray: {
      type: Array as PropType<any[]>,
      default: null,
    },
    /**
     * If provided as `true`, this will add the country field to the address. Otherwise, it will be left off.
     */
    withCountry: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    return {};
  },
  components: {},
});
</script>
