/**
 * Converts the first character in a string to Upper Case
 *
 * @param {string} string Value to modify
 * @returns {string} If the input is falsey, returns the input. Otherwise returns a capitalized string.
 */
export const capitalizeString = (string: string) => {
  if (!string) {
    return string;
  }

  return string.charAt(0).toUpperCase() + string.slice(1);
};
