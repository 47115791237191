import { IPagingApiAndNotifierConfig, apiService } from "@mui/utils/api";
import { NotifyOptionsError, NotifyOptionsSuccess } from "@mui/components/notifications";
import { apiHosts } from "@casa/api/constants/apiHosts";
import { IInspection, IInspectionNew, IInspectionQuery } from "@casa/views/abode/abode";
import { AxiosResponse } from "axios";
import { serializeQueryParams } from "@/utils/helpers";

const URL = (abodeId: string | number) => `abodes/${abodeId}/inspections`;
const ENTITY_NAME = "Inspection";

const axiosParams = {
  // Set different base URL based on the environment
  baseURL: import.meta.env.VITE_INSIGHT_API_BASE_URL,
};
const api = apiService<IInspection>(apiHosts.INSIGHT, axiosParams);

/**
 * @param {Object} params Query parameters, varies (TODO improve this)
 * @param {abortCallback} abortMethod Callback for when a request has been aborted
 */
const getInspections = (params: IInspectionQuery, config: IPagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.hide = true;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.fetched = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };

  const url = config.pagingUrl ? config.pagingUrl : URL(params.abodeId);

  return api.getPaged(url, {
    params,
    paramsSerializer: serializeQueryParams,
    abort: config?.abortMethod,
    successNotifCb,
    errorNotifCb,
  });
};

/**
 * @param {Int} id
 */

type InspectionIds = {
  id?: string | number;
  abodeId: string | number;
};
const getInspectionById = (ids: InspectionIds, config: IPagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.hide = true;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.fetched = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };
  return api.get(URL(ids.abodeId) + "/" + ids.id, {
    successNotifCb,
    errorNotifCb,
  });
};

const getNewInspection = async (ids: InspectionIds) => {
  //optionally make any other changes as necessary before returning.
  return { data: { ...ids, inspectionItems: [{ key: -Date.now() }] } } as AxiosResponse<IInspectionNew>;
};

const getInspectionByIdOrNew = (ids: InspectionIds, config: IPagingApiAndNotifierConfig = {}) => {
  if (!ids.id) {
    return getNewInspection(ids);
  } else {
    return getInspectionById(ids, config) as Promise<AxiosResponse<IInspectionNew, any>>;
  }
};

const createInspection = (inspection: IInspection, config: IPagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.created = ENTITY_NAME;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.created = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };

  return api.post(URL(inspection.abodeId), inspection, {
    successNotifCb,
    errorNotifCb,
  });
};

const updateInspection = (inspection: IInspection, config: IPagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.updated = ENTITY_NAME;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.updated = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };
  return api.put(URL(inspection.abodeId) + "/" + inspection.id, inspection, {
    successNotifCb,
    errorNotifCb,
  });
};

/**
 * @param {Inspection} inspection This cannot be a 'ref' to a inspection
 */
const saveInspection = (inspection: IInspection, config: IPagingApiAndNotifierConfig = {}) => {
  if (inspection.id) {
    return updateInspection(inspection, config);
  } else {
    return createInspection(inspection, config);
  }
};

/**
 * @param {Int} id
 */
const deleteInspectionById = (fa: IInspection, config: IPagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.deleted = ENTITY_NAME;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.deleted = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };

  return api.delete(URL(fa.abodeId) + "/" + fa.id, {
    successNotifCb,
    errorNotifCb,
  });
};

export {
  deleteInspectionById,
  getInspections,
  getInspectionById,
  getNewInspection,
  getInspectionByIdOrNew,
  createInspection,
  updateInspection,
  saveInspection,
};
