import { useAppSettingsStore } from "@/components/common/index.js";
import { definePrivateState } from "@/utils/helpers/definePrivateState.js";
import { acceptHMRUpdate } from "pinia";
import { computed, ref } from "vue";

/**
 * Returns a config store for entity documents, creates it if necessary.
 *
 * The `baseURL` value will be the value of `privateState.baseURL` if it exists, otherwise it will be pulled from `appSettingsStore.baseURL`
 *
 * The `apiHost` value will be the value of `privateState.apiHost` if it exists, otherwise it will be pulled from `appSettingsStore.apiHost`
 *
 */
export const useEntityDocumentConfigStore = definePrivateState(
  "EntityDocumentConfigStore",
  () => ({
    baseURL: null as string | null,
    apiHost: null as symbol | null,
  }),
  (privateState) => {
    // state
    const documentLabel = ref("Document");
    const documentLabelPlural = ref("Documents");

    // getters
    const baseURL = computed(() => {
      if (privateState.baseURL) return privateState.baseURL;
      const appSettingsStore = useAppSettingsStore();
      return appSettingsStore.baseURL;
    });

    const apiHost = computed(() => {
      if (privateState.apiHost) return privateState.apiHost;
      const appSettingsStore = useAppSettingsStore();
      return appSettingsStore.apiHost;
    });

    // actions
    /**
     * Sets the provided value to the `privateState.baseURL`
     * @param {string|null } val New value for the `baseURL`
     */
    function setBaseURL(val: string | null) {
      privateState.baseURL = val;
    }
    /**
     * Sets the provided value to the `privateState.apiHost`
     * @param {symbol|null } val New value for the `apiHost`
     */
    function setApiHost(val: symbol | null) {
      privateState.apiHost = val;
    }

    return {
      //state
      documentLabel,
      documentLabelPlural,
      //getters
      baseURL,
      apiHost,
      //actions
      setBaseURL,
      setApiHost,
    };
  },
);

export type UseEntityDocumentConfigStore = ReturnType<typeof useEntityDocumentConfigStore>;

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useEntityDocumentConfigStore, import.meta.hot));
}
