<template>
  <div class="pt-4">
    <div class="pr-3 text-base font-light tracking-normal text-neutral-700">
      {{ label }}
    </div>
    <div class="mb-4 border-l border-neutral-300 pl-6 pt-2">
      <!-- @slot Field components you would like to be rendered as a group (**not a FormKit group) -->
      <slot></slot>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

/** Standardized styling to render related fields as a group (**not a FormKit group).
 *  Adds a border to left side to visually seperate these fields from other fields on the form.
 */
export default defineComponent({
  name: "FieldGroup",
  props: {
    /**
     * label to be displayed at the top of the group
     */
    label: {
      type: String,
      required: true,
    },
  },
  setup() {
    return {};
  },
});
</script>

<style module></style>
