<template>
  <tr class="">
    <td colspan="100" class="text-large bg-red-100 p-8 text-center font-bold text-neutral-700 md:text-xl">
      <ExclamationCircleIcon class="inline-block h-5 w-5 align-text-bottom md:h-6 md:w-6"></ExclamationCircleIcon>
      Error during search - please try again.
    </td>
  </tr>
</template>

<script lang="ts">
import { ExclamationCircleIcon } from "@heroicons/vue/24/solid";
import { defineComponent } from "vue";
/** Component to be displayed when there was an error with the list results  */
export default defineComponent({
  name: "ListTableErrorRow",
  components: {
    ExclamationCircleIcon,
  },
  setup(props) {
    return {};
  },
});
</script>

<style module></style>
