<template>
  <div class="h-full overflow-auto bg-neutral-100">
    <div class="">
      <!-- content -->
      <div class="mx-auto mb-5 mt-5 max-w-5xl px-2" v-if="!abodeNavItem.isAuthPending">
        <div
          class="mx-0 mb-4 mt-4 bg-sky-50 shadow sm:mx-5 sm:my-5 sm:rounded-lg md:mx-8 md:my-8 xl:mx-auto xl:max-w-5xl"
        >
          <!-- Card Content goes here -->
          <div class="justify-between px-4 py-5 sm:p-10 lg:flex">
            <div>
              <h1 class="text-2xl font-extrabold">
                {{ appSettingsStore.applicationTitle }}
                <span class="font-semibold">({{ appSettingsStore.pageTitleBase }})</span>
              </h1>
              <h2 class="mt-2 text-xl text-neutral-500 md:mt-1">
                <span class="font-semibold">Welcome, {{ userRef.firstName }}</span>
              </h2>
              <div class="prose prose-neutral mt-4 prose-li:marker:text-neutral-400 md:mt-5">
                <p>
                  <router-link :to="{ name: abodeNavItem.name }" v-if="abodeNavItem.isAuthorized" class="text-sky-500">
                    <span>View abode information</span></router-link
                  ><span v-else>View abode information</span>
                  managed in the CASA system. Abodes include detached single family homes, condominiums, duplexes, and
                  other types of dwellings.
                </p>
              </div>
            </div>
            <div class="mt-6 items-start justify-start gap-5 space-y-5 sm:flex sm:space-y-0 lg:mt-0">
              <LogoutButton :as="BaseButton" variant="priority-hollow" class="w-full sm:w-auto" label="Sign Out" />
            </div>
          </div>
          <div class="flex justify-center" v-if="false">
            <h2 class="mt-2 mb-4 text-xl text-sky-500 md:mt-1 md:mb-6">
              View all
              <span class="font-semibold">
                <router-link :to="{ name: abodeNavItem.name }" v-if="abodeNavItem.isAuthorized">
                  <span class="cursor-pointer font-semibold hover:underline">{{ abodeNavItem.label }}</span>
                </router-link></span
              >
            </h2>
          </div>
        </div>
        <!-- <div class="pb-5">
            <h3 class="text-base font-semibold leading-6 text-neutral-900">Active Handlers by Generator Status</h3>
            <dl class="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
              <router-link
                :to="{ name: routeNames.handlerList, query: { federalWasteGeneratorIn: [id], status: 'Active' } }"
                v-for="[id, item] in generatorsWithCounts"
                :key="id"
                class="rounded-lg bg-white px-4 py-5 shadow sm:p-6 border border-transparent transition-all hover:border-sky-200 hover:bg-sky-100 focus:border-sky-200 focus:bg-sky-100"
              >
                <dt class="truncate text-sm font-medium text-neutral-500">{{ item.longDescription }}</dt>
                <dd class="mt-1 text-3xl font-semibold tracking-tight text-neutral-900">{{ item.count }}</dd>
              </router-link>
            </dl>
          </div>

          <div>
            <h3 class="text-base font-semibold leading-6 text-neutral-900">Active Handlers by County</h3>
            <dl class="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
              <router-link
                :to="{ name: routeNames.handlerList, query: { countyCodeIn: [id], status: 'Active' } }"
                v-for="[id, item] in countyCodesWithCounts"
                :key="id"
                class="rounded-lg bg-white px-4 py-5 shadow sm:p-6 border border-transparent transition-all hover:border-sky-200 hover:bg-sky-100 focus:border-sky-200 focus:bg-sky-100"
              >
                <dt class="truncate text-sm font-medium text-neutral-500">{{ item.description }} County</dt>
                <dd class="mt-1 text-3xl font-semibold tracking-tight text-neutral-900">{{ item.count }}</dd>
              </router-link>
            </dl>
          </div> -->
      </div>

      <SimpleMap class="h-96 w-full bg-neutral-800" :topo-json="hiTopoJson" />
    </div>
    <footer class="sticky top-[100vh] mt-20 flex h-10 flex-col items-center p-2">
      <MdrnAttribution />
    </footer>
  </div>
</template>

<script lang="ts">
import { InitialBadge } from "@mui/components/badge";
import { BaseButton } from "@mui/components/button";
import { MdrnAttribution, NoneFoundFlag, useAppNav, useAppSettingsStore } from "@mui/components/common";
import { LoginButton, LogoutButton, RegisterButton, useUser } from "@mui/utils/auth/authentication";
import { useAuthorization } from "@mui/utils/auth/authorization";

import hiTopoJson from "@casa/constants/hawaii-counties-topo.json";
import { routeNames } from "@casa/routes";
import { additionalMetadata, metadataContext } from "@casa/views/viewer/viewerSettings";
import { EnvelopeIcon, PhoneIcon } from "@heroicons/vue/20/solid";
import { ChevronRightIcon } from "@heroicons/vue/24/outline";
import { FaceSmileIcon, PlusIcon } from "@heroicons/vue/24/solid";
import { SimpleMap } from "@mui/components/simpleMap";
import { useViewerStore } from "@mui/components/viewer";
import { computed, defineComponent, onMounted, ref, watch } from "vue";

export default defineComponent({
  name: "HomeInternal",
  setup() {
    const viewerStore = useViewerStore();
    viewerStore.metadataContext = metadataContext;
    viewerStore.additionalMetadata = additionalMetadata;
    viewerStore.hydrateDatasetMetadata();
    const { userRef } = useUser();
    const appSettingsStore = useAppSettingsStore();

    const { authorizationClient, AuthRequest, handlePreempts } = useAuthorization();
    const { navigationItems, userNavigationItems } = useAppNav();

    // Example usage of throttledAuthorize -
    // authorizationClient.throttledAuthorize(isBureauAR).then((result) => {
    //   isBureau.value = result?.isSuccess;
    // }, handlePreempts);

    // authorizationClient.throttledAuthorize(isHealthDistrictAR).then((result) => {
    //   isHealthDistrict.value = result?.isSuccess;
    // }, handlePreempts);

    type userClaims = {
      type: string;
      value: string;
    };

    const isInternal = computed(
      () => Boolean(userRef.value?.claims?.some((x: userClaims) => x.type === "role" && x.value === "Internal")), //TODO:TS
    );

    const isAuthenticated = computed(() => userRef.value.isAuthenticated);
    const isExternalAuthenticated = computed(() => isAuthenticated?.value && !isInternal.value);

    watch(
      isAuthenticated,
      (newValue) => {
        if (newValue) {
          // execGetOrganizations({ expand: ["RecyclingReports"] });
          // execGetFacilitySites();
        }
      },
      { immediate: true },
    );

    const rrAccessRequest = ref({ entityName: "", requestText: "" });
    rrAccessRequest.value.entityName = "Organization";

    const drAccessRequest = ref({ entityName: "", requestText: "" });
    drAccessRequest.value.entityName = "Facility Site";

    //TODO: probably not the best approach.
    const abodeNavItem = computed(
      () => navigationItems.find((x) => x.label === "Casas")!.items.find((x) => x.name === routeNames.abodes)!,
    );

    return {
      appSettingsStore,
      abodeNavItem,
      navigationItems,
      isExternalAuthenticated,
      isInternal,
      userRef,
      isAuthenticated,
      drAccessRequest,
      rrAccessRequest,
      BaseButton,
      viewerStore,
      hiTopoJson,
      // getFacilitySites,
    };
  },
  components: {
    BaseButton,
    InitialBadge,
    LoginButton,
    RegisterButton,
    PlusIcon,
    ChevronRightIcon,
    MdrnAttribution,
    FaceSmileIcon,
    NoneFoundFlag,
    PhoneIcon,
    EnvelopeIcon,
    LogoutButton,
  },
});
</script>
