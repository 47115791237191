<template>
  <div class="">
    <div class="text-large bg-red-100 p-8 text-center font-bold text-neutral-700 md:text-xl">
      <ExclamationCircleIcon class="inline-block h-5 w-5 align-text-bottom md:h-6 md:w-6"></ExclamationCircleIcon>
      Error during search - please try again.
    </div>
  </div>
</template>

<script lang="ts">
import { ExclamationCircleIcon } from "@heroicons/vue/24/solid";
import { defineComponent } from "vue";

/** Component to be displayed when there was an error fetching the entity for the ListCard */
export default defineComponent({
  name: "ListCardError",
  components: {
    ExclamationCircleIcon,
  },
  setup(props) {
    return {};
  },
});
</script>

<style module></style>
