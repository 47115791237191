<template>
  <div class="mx-0 mt-6 sm:mx-5" :class="[isLg && `lg:mx-10`, isXl && `xl:mx-10`, is2xl && `2xl:mx-10`]">
    <div
      :class.once="[
        isLg && `lg:grid-cols-3 lg:grid`,
        isXl && `xl:grid-cols-3 xl:grid`,
        is2xl && `2xl:grid-cols-3 2xl:grid`,
      ]"
    >
      <div
        class="mb-2 mt-5 pl-2"
        :class.once="[
          isLg && `lg:col-span-3 lg:mt-0`,
          isXl && `xl:col-span-3 xl:mt-0`,
          is2xl && `2xl:col-span-3 2xl:mt-0`,
        ]"
      >
        <h3 class="text-lg font-medium leading-6 text-neutral-900">
          <!-- @slot Name of the form section - displayed at the top of the form -->
          <slot name="sectionName"></slot>
        </h3>
        <p
          class="mt-1 block text-sm text-neutral-600"
          :class.once="[isLg && `lg:hidden`, isXl && `xl:hidden`, is2xl && `2xl:hidden`]"
          v-if="$slots.sectionDescription"
        >
          <!-- @slot used to add additional/helpful details to the form. -->
          <slot name="sectionDescription"></slot>
        </p>
      </div>
      <div
        :class.once="[
          isLg && `lg:col-span-2 lg:mt-0`,
          isXl && `xl:col-span-2 xl:mt-0`,
          is2xl && `2xl:col-span-2 2xl:mt-0`,
        ]"
      >
        <div class="bg-white shadow sm:overflow-visible sm:rounded-md">
          <div class="px-4 py-5 sm:p-6">
            <!-- @slot Put one or more FieldSet or other form components here -->
            <slot><b>Put one or more FieldSet or other form components here</b></slot>
          </div>
        </div>
      </div>
      <div
        class="hidden"
        :class.once="[
          isLg && `lg:col-span-1 lg:ml-6 lg:block`,
          isXl && `xl:col-span-1 xl:ml-6 xl:block`,
          is2xl && `2xl:col-span-1 2xl:ml-6 2xl:block`,
        ]"
      >
        <div class="px-4 sm:px-0">
          <p class="mt-1 text-sm text-neutral-600" v-if="$slots.sectionDescription">
            <!-- @slot used to add additional/helpful details to the form. -->
            <slot name="sectionDescription"></slot>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { PropType, computed, defineComponent } from "vue";
/**
 * Template for a form section - allows for consistent styling across forms.
 */
export default defineComponent({
  name: "FormSection",
  // emits: ["makeDirty"],
  props: {
    /**
     * Determines the break point for the form
     * @values lg, xl, 2xl
     */
    breakAt: {
      type: String as PropType<"lg" | "xl" | "2xl">,
      default: "lg",
      validator: (val: string) => ["lg", "xl", "2xl"].includes(val),
    },
  },
  setup(props, context) {
    // Remember: Tailwind won't JIT compile incomplete class names, so don't refactor this to build class names.
    const isLg = computed(() => props.breakAt === "lg");
    const isXl = computed(() => props.breakAt === "xl");
    const is2xl = computed(() => props.breakAt === "2xl");

    return { isLg, isXl, is2xl };
  },
});
</script>

<style module></style>
