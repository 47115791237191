<template>
  <TransitionRoot as="template" :show="openRef">
    <Dialog as="div" class="fixed inset-0 z-40 overflow-y-auto" @close="openRef = false">
      <div class="flex min-h-screen items-end justify-center px-4 pb-20 pt-4 text-center sm:block sm:p-0">
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="ease-in duration-200"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <DialogOverlay class="fixed inset-0 bg-neutral-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span class="hidden sm:inline-block sm:h-screen sm:align-middle" aria-hidden="true">&#8203;</span>
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enter-to="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leave-from="opacity-100 translate-y-0 sm:scale-100"
          leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <div
            class="relative inline-block transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6 sm:align-middle"
          >
            <div class="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
              <button
                type="button"
                class="rounded-md bg-white text-neutral-400 hover:text-neutral-500 focus:outline-none focus:ring-2 focus:ring-neutral-500 focus:ring-offset-2"
                @click="
                  openRef = false;
                  $emit('cancelClick');
                "
              >
                <span class="sr-only">Close</span>
                <XMarkIcon class="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div class="sm:flex sm:items-start">
              <div
                class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-neutral-100 sm:mx-0 sm:h-10 sm:w-10"
              >
                <QuestionMarkCircleIcon class="h-6 w-6 text-neutral-600" aria-hidden="true" />
              </div>
              <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left w-full sm:w-1/2">
                <DialogTitle as="h3" class="text-lg font-medium leading-6 text-neutral-900">
                  {{ props.title }}
                </DialogTitle>
                <div class="mt-2">
                  <p class="text-sm text-neutral-500">
                    <!-- @slot Slot for the dialog description. By default, the `description` prop will be used. -->
                    <slot name="description">{{ props.description }}</slot>
                  </p>
                </div>
                <div class="mt-6">
                  <ul class="space-y-2">
                    <li class="block" v-for="option in props.options">
                      <BaseButton
                        @click="
                          openRef = false;
                          $emit('okClick', option);
                        "
                        class="w-full sm:text-sm"
                        :label="props.optionLabel(option)"
                      >
                      </BaseButton>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script lang="ts">
// Note: When updating this description - you must also update the md file.

/** Component used to force a user to make a selection from a list of options
 *
 * Events:
 *
 * `okClick` - Triggered when a selection is made. This will return the selected object or string value.
 *
 * `cancelClick` - Triggered when the "X" icon is used to close the dialog without making a selection.
 */
export default defineComponent({ name: "SelectionDialog" });
type OptionLabelFunction<T> = (value: T) => string;
</script>

<script setup lang="ts" generic="T extends string | object">
import BaseButton from "@/components/button/BaseButton.vue";
import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from "@headlessui/vue";
import { QuestionMarkCircleIcon, XMarkIcon } from "@heroicons/vue/24/outline";
import { defineComponent, ref } from "vue";

defineEmits<{ okClick: [value: T]; cancelClick: [] }>();

const props = withDefaults(
  defineProps<{
    /** Title to be shown at the top of the dialog */
    title: string;
    /** More detailed explanation of the message */
    description?: string;
    /** Optional label to be used in place of cancel */
    cancelLabel?: string;
    /** Options are rendered as buttons. If they aren't strings, you'll need to provide an `optionLabel` function. */
    options: Array<T>;
    /** Optional function that takes one of your options and returns the label shown on the UI. */
    optionLabel: OptionLabelFunction<T>;
  }>(),
  {
    description: undefined,
    cancelLabel: "Cancel",
    optionLabel: (value: T) => {
      if (typeof value === "string") return value;
      else return "no option label defined";
    },
  },
);

const openRef = ref(false);
const openSelector = () => {
  openRef.value = true;
};

defineExpose({ openSelector });
</script>
