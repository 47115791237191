<template>
  <a :href="href" class="relative flex" :class="$style.link" :aria-current="isActive ? 'page' : undefined">
    <AppNavAnchorLabel :label="label" />
  </a>
</template>

<script lang="ts">
import AppNavAnchorLabel from "@/components/common/AppNavAnchorLabel.vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "AppNavAnchor",
  components: { AppNavAnchorLabel },
  props: {
    /** The href (usually from the router-linke) */
    href: {
      type: String,
      required: true,
    },
    /** Flag from router-link indicating whether the link is active. */
    isActive: {
      type: Boolean,
      required: true,
    },
    /** User visible label for the anchor */
    label: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    return {};
  },
});
</script>

<style module>
.link:hover:before {
  visibility: visible;
  width: 100%;
}
.link:before {
  content: "";
  position: absolute;
  width: 0;
  height: 2px;
  bottom: 0;
  left: 0;
  @apply bg-sky-300;
  visibility: hidden;
  transition: all 0.2s ease-in-out;
}
.link:hover {
  transition: all 0.2s;
  text-decoration: none;
}
</style>
