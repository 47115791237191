<template>
  <div ref="mapElement"></div>
</template>

<script lang="ts">
import olMap from "ol/Map";
import View from "ol/View";
import TopoJSON from "ol/format/TopoJSON";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import Fill from "ol/style/Fill";
import Style from "ol/style/Style";
import { PropType, defineComponent, onBeforeUnmount, onMounted, ref } from "vue";

/** A simple map designed for use on landing page  */
export default defineComponent({
  name: "SimpleMap",
  props: {
    /** A TopoJSON object. */
    topoJson: {
      type: [Object],
      required: true,
    },
  },
  setup(props) {
    const mapElement = ref<HTMLElement | null>(null);
    let bounce: NodeJS.Timeout;

    onMounted(() => {
      let landingMap: olMap | undefined = undefined;
      let vectorLayer: VectorLayer<VectorSource> | null = null;

      vectorLayer = new VectorLayer({
        source: new VectorSource({
          features: new TopoJSON().readFeatures(JSON.stringify(props.topoJson), {
            featureProjection: "EPSG:3857",
          }),
        }),
        style: new Style({
          fill: new Fill({
            color: "rgba(160, 160, 160, 1)",
          }),
          // stroke: new Stroke({
          //   color: "blue",
          //   width: 2,
          // }),
        }),
      });

      landingMap = new olMap({
        interactions: [],
        controls: [],
        target: mapElement.value!,
        view: new View({
          projection: "EPSG:3857",
        }),
        layers: [vectorLayer],
      });

      landingMap.getView().fit(vectorLayer.getSource()!.getExtent(), {
        padding: [150, 150, 150, 150],
        callback: () => {
          landingMap!.getView().fit(vectorLayer!.getSource()!.getExtent(), {
            padding: [25, 25, 25, 25],
            duration: 1500,
          });
        },
      });

      landingMap.on("change:size", (event) => {
        bounce && clearTimeout(bounce);
        bounce = setTimeout(() => {
          if (vectorLayer) {
            landingMap!.getView().fit(vectorLayer.getSource()!.getExtent(), {
              padding: [25, 25, 25, 25],
              duration: 500,
            });
          }
        }, 50);
      });
    });

    onBeforeUnmount(() => {
      clearTimeout(bounce);

      //   landingMap?.getView().dispose();
      //   landingMap?.getLayers()?.forEach((layer) => layer.dispose());
      //   landingMap?.dispose();
      //   landingMap = undefined;
    });

    return {
      mapElement,
    };
  },
});
</script>

<style module></style>
