/* 
  Insertion order determines presentation order on the UI. Keep the key equal to the "id". 
  Use the functions to get a new copy of an enum when you intend to modify it (e.g. toggle isInactive)  
*/
/*
  Reserved field names:
  isHiddenInSelect - used to hide options from SelectInput
  isInactive - used to disable an option in SelectInput while keeping selected if that's the current value.
*/

import { createEnum } from "@mui/utils/helpers";

export interface IEnumRecord {
  id: string;
  description: string;
  [key: string]: any;
}

export interface IEnumMap extends Map<string, IEnumRecord> {}

export type IEnums = {
  [Property in keyof typeof enumsCopy]: ReturnType<(typeof enumsCopy)[Property]>;
};

// Access to the methods that generate the enums, useful for modifying the properties on the enums without having to "deep copy" first.
export const enumsCopy = {
  abodeTypes,
  appraisalStatuses,
  affiliationOrganizationTypes,
  documentCategories,
  entityTypes,
  inspectionResults,
  inspectionChecklistItems,
  inspectionConditions,
  stateAbbreviations,
  utilityStatuses,
};

// TODO: Wrap the function calls in readonly?
// Reusable, "standard" enums.
export const enums = Object.entries(enumsCopy).reduce((enumObj, [key, func]) => {
  enumObj[key as keyof IEnums] = func() as any; // bypass TS stupidity.
  return enumObj;
}, {} as IEnums);

export type AbodeTypes =
  | "House"
  | "Duplex"
  | "Detached Bungalow"
  | "Condo"
  | "Apartment"
  | "Semi-Detached"
  | "Town Home"
  | "Tiny Home";
export function abodeTypes() {
  return createEnum(
    new Map<AbodeTypes, { id: string; description: string }>([
      [
        "House",
        {
          id: "House",
          description: "House",
        },
      ],
      [
        "Duplex",
        {
          id: "Duplex",
          description: "Duplex",
        },
      ],
      [
        "Detached Bungalow",
        {
          id: "Detached Bungalow",
          description: "Detached Bungalow",
        },
      ],
      [
        "Condo",
        {
          id: "Condo",
          description: "Condo",
        },
      ],
      [
        "Apartment",
        {
          id: "Apartment",
          description: "Apartment",
        },
      ],
      [
        "Town Home",
        {
          id: "Town Home",
          description: "Town Home",
        },
      ],
      [
        "Tiny Home",
        {
          id: "Tiny Home",
          description: "Tiny Home",
        },
      ],
      [
        "Semi-Detached",
        {
          id: "Semi-Detached",
          description: "Semi-Detached",
        },
      ],
    ]),
  );
}

export type AffiliationOrganizationTypes = "Organization Contact";
export function affiliationOrganizationTypes() {
  return createEnum(
    new Map<AffiliationOrganizationTypes, { id: string; description: string }>([
      [
        "Organization Contact",
        {
          id: "Organization Contact",
          description: "Organization Contact",
        },
      ],
    ]),
  );
}

export type AppraisalStatus = "Scheduled" | "Completed" | "Expired";
export function appraisalStatuses() {
  return createEnum(
    new Map<AppraisalStatus, { id: string; description: string }>([
      [
        "Scheduled",
        {
          id: "Scheduled",
          description: "Scheduled",
        },
      ],
      [
        "Completed",
        {
          id: "Completed",
          description: "Completed",
        },
      ],
      [
        "Expired",
        {
          id: "Expired",
          description: "Expired",
        },
      ],
    ]),
  );
}

export type DocumentCategories = "Appraisal";
export function documentCategories() {
  return createEnum(
    new Map<
      DocumentCategories,
      { id: string; description: string; types: Map<string, { id: string; description: string; extensions: string }> }
    >([
      [
        "Appraisal",
        {
          id: "Appraisal",
          description: "Appraisal",
          types: new Map([["Appraisal", { id: "Appraisal", description: "Appraisal", extensions: ".pdf" }]]),
        },
      ],
    ]),
  );
}

export type EntityTypes = "Abode" | "Appraisal" | "Custom";
export function entityTypes() {
  return createEnum(
    new Map<EntityTypes, { id: string; description: string }>([
      [
        "Abode",
        {
          id: "abode",
          description: "Abode",
        },
      ],
      [
        "Appraisal",
        {
          id: "appraisal",
          description: "Appraisal",
        },
      ],
      [
        "Custom",
        {
          id: "custom",
          description: "Custom",
        },
      ],
    ]),
  );
}

export type InspectionResult = "Pass" | "Fail";
export function inspectionResults() {
  return createEnum(
    new Map<InspectionResult, { id: string; description: string }>([
      [
        "Pass",
        {
          id: "Pass",
          description: "Pass",
        },
      ],
      [
        "Fail",
        {
          id: "Fail",
          description: "Fail",
        },
      ],
    ]),
  );
}

export type InspectionChecklistItems =
  | "Plumbing"
  | "HVAC"
  | "Roof"
  | "Water Heater"
  | "Basement"
  | "Structure"
  | "Asbestos"
  | "Garage"
  | "Foundation"
  | "Electrical";
export function inspectionChecklistItems() {
  return createEnum(
    new Map<InspectionChecklistItems, { id: string; description: string }>([
      [
        "Plumbing",
        {
          id: "Plumbing",
          description: "Plumbing",
        },
      ],
      [
        "HVAC",
        {
          id: "HVAC",
          description: "HVAC",
        },
      ],
      [
        "Roof",
        {
          id: "Roof",
          description: "Roof",
        },
      ],
      [
        "Water Heater",
        {
          id: "Water Heater",
          description: "Water Heater",
        },
      ],
      [
        "Basement",
        {
          id: "Basement",
          description: "Basement",
        },
      ],
      [
        "Structure",
        {
          id: "Structure",
          description: "Structure",
        },
      ],
      [
        "Asbestos",
        {
          id: "Asbestos",
          description: "Asbestos",
        },
      ],
      [
        "Garage",
        {
          id: "Garage",
          description: "Garage",
        },
      ],
      [
        "Foundation",
        {
          id: "Foundation",
          description: "Foundation",
        },
      ],
      [
        "Electrical",
        {
          id: "Electrical",
          description: "Electrical",
        },
      ],
    ]),
  );
}

export type InspectionConditions = "Poor" | "Fair" | "Good" | "Excellent" | "Present" | "None" | "Damaged" | "Safe";
export function inspectionConditions() {
  return createEnum(
    new Map<InspectionConditions, { id: string; description: string }>([
      [
        "Poor",
        {
          id: "Poor",
          description: "Poor",
        },
      ],
      [
        "Fair",
        {
          id: "Fair",
          description: "Fair",
        },
      ],
      [
        "Good",
        {
          id: "Good",
          description: "Good",
        },
      ],
      [
        "Excellent",
        {
          id: "Excellent",
          description: "Excellent",
        },
      ],
      [
        "Present",
        {
          id: "Present",
          description: "Present",
        },
      ],
      [
        "None",
        {
          id: "None",
          description: "None",
        },
      ],
      [
        "Damaged",
        {
          id: "Damaged",
          description: "Damaged",
        },
      ],
      [
        "Safe",
        {
          id: "Safe",
          description: "Safe",
        },
      ],
    ]),
  );
}

export type UtilityStatus = "Active" | "Inactive";
export function utilityStatuses() {
  return createEnum(
    new Map<UtilityStatus, { id: string; description: string }>([
      [
        "Active",
        {
          id: "Active",
          description: "Active",
        },
      ],
      [
        "Inactive",
        {
          id: "Inactive",
          description: "Inactive",
        },
      ],
    ]),
  );
}

//prettier-ignore
export type StateAbbreviations = "NV" | "AK" | "AL" | "AR" | "AZ" | "CA" | "CO" | "CT" | "DC" | "DE" | "FL" | "GA" | "HI" | "IA" | "ID" | "IL" | "IN" | "KS" | "KY" | "LA" | "MA" | "MD" | "ME" | "MI" | "MN" | "MO" | "MS" | "MT" | "NC" | "ND" | "NE" | "NH" | "NJ" | "NM" | "NY" | "OH" | "OK" | "OR" | "PA" | "PR" | "RI" | "SC" | "SD" | "TN" | "TX" | "UT" | "VT"  | "VA" | "VI" | "WA" | "WI" | "WV" | "WY";
export function stateAbbreviations() {
  return createEnum(
    new Map<StateAbbreviations, { id: string; description: string }>([
      ["NV", { id: "NV", description: "NV" }],
      ["AK", { id: "AK", description: "AK" }],
      ["AL", { id: "AL", description: "AL" }],
      ["AR", { id: "AR", description: "AR" }],
      ["AZ", { id: "AZ", description: "AZ" }],
      ["CA", { id: "CA", description: "CA" }],
      ["CO", { id: "CO", description: "CO" }],
      ["CT", { id: "CT", description: "CT" }],
      ["DC", { id: "DC", description: "DC" }],
      ["DE", { id: "DE", description: "DE" }],
      ["FL", { id: "FL", description: "FL" }],
      ["GA", { id: "GA", description: "GA" }],
      ["HI", { id: "HI", description: "HI" }],
      ["IA", { id: "IA", description: "IA" }],
      ["ID", { id: "ID", description: "ID" }],
      ["IL", { id: "IL", description: "IL" }],
      ["IN", { id: "IN", description: "IN" }],
      ["KS", { id: "KS", description: "KS" }],
      ["KY", { id: "KY", description: "KY" }],
      ["LA", { id: "LA", description: "LA" }],
      ["MA", { id: "MA", description: "MA" }],
      ["MD", { id: "MD", description: "MD" }],
      ["ME", { id: "ME", description: "ME" }],
      ["MI", { id: "MI", description: "MI" }],
      ["MN", { id: "MN", description: "MN" }],
      ["MO", { id: "MO", description: "MO" }],
      ["MS", { id: "MS", description: "MS" }],
      ["MT", { id: "MT", description: "MT" }],
      ["NC", { id: "NC", description: "NC" }],
      ["ND", { id: "ND", description: "ND" }],
      ["NE", { id: "NE", description: "NE" }],
      ["NH", { id: "NH", description: "NH" }],
      ["NJ", { id: "NJ", description: "NJ" }],
      ["NM", { id: "NM", description: "NM" }],
      ["NY", { id: "NY", description: "NY" }],
      ["OH", { id: "OH", description: "OH" }],
      ["OK", { id: "OK", description: "OK" }],
      ["OR", { id: "OR", description: "OR" }],
      ["PA", { id: "PA", description: "PA" }],
      ["PR", { id: "PR", description: "PR" }],
      ["RI", { id: "RI", description: "RI" }],
      ["SC", { id: "SC", description: "SC" }],
      ["SD", { id: "SD", description: "SD" }],
      ["TN", { id: "TN", description: "TN" }],
      ["TX", { id: "TX", description: "TX" }],
      ["UT", { id: "UT", description: "UT" }],
      ["VT", { id: "VT", description: "VT" }],
      ["VA", { id: "VA", description: "VA" }],
      ["VI", { id: "VI", description: "VI" }],
      ["WA", { id: "WA", description: "WA" }],
      ["WI", { id: "WI", description: "WI" }],
      ["WV", { id: "WV", description: "WV" }],
      ["WY", { id: "WY", description: "WY" }],
    ]),
  );
}
