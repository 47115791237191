<template>
  <BaseButton
    v-if="clickFunction"
    v-bind="$attrs"
    icon
    borderless
    variant="danger-subtle"
    :label="label"
    @click="handleClick"
  >
    <template #iconBefore><XMarkIcon class="w-5" /></template
  ></BaseButton>
  <BaseButton v-else v-bind="$attrs" icon borderless variant="danger-subtle" :label="label">
    <template #iconBefore><XMarkIcon class="w-5" /></template
  ></BaseButton>
</template>

<script lang="ts">
import BaseButton from "@/components/button/BaseButton.vue";
import { XMarkIcon } from "@heroicons/vue/24/solid";
import { PropType, defineComponent } from "vue";

/**
 * Pre-styled BaseButton component for commonly used "Remove" buttons.
 * Bypasses need for slot usage which is helpful for schema.
 */
export default defineComponent({
  name: "RemoveButton",

  props: {
    /**
     * Label to be displayed on button
     */
    label: {
      type: String,
    },
    /** Optional prop to pass in a function you want to be executed onClick
     * This is used to bypass FormKit schemas inability to handle @click properly
     */
    clickFunction: {
      type: Function as PropType<(...args: any[]) => void>,
    },
    /** Optional - This should be any arguements that you want to pass into your function.
     */
    functionArgs: {
      type: Array as PropType<any[]>,
      default: [],
    },
  },
  setup(props) {
    const handleClick = () => {
      if (props.clickFunction && props.functionArgs.length > 0) {
        props.clickFunction(...props.functionArgs);
      } else {
        props.clickFunction && props.clickFunction();
      }
    };

    return { handleClick };
  },
  components: {
    BaseButton,
    XMarkIcon,
  },
});
</script>

<style module></style>
