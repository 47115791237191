<template>
  <div class="flex items-center text-sm text-neutral-400">
    <svg
      class="mr-1 h-4 w-4"
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 68.06 65.83"
      style="enable-background: new 0 0 68.06 65.83"
      xml:space="preserve"
      alt="Logo"
    >
      <path
        style="fill: #361c54"
        d="M56.85,39.53c1.03,2.44,2.58,4.65,4.46,6.51c0.39,0.38,0.8,0.77,1.03,1.26c0.62,1.32-1.1,2.99-1.85,3.97
	c-0.97,1.28-2.04,2.49-3.19,3.62c-2.29,2.25-4.91,4.14-7.77,5.59c-1.02,0.52-2.07,0.98-3.14,1.38c-1.7,0.64-3.45,1.13-5.23,1.47
	c-7.34,1.42-15.13,0.22-21.56-3.64c-0.62-0.37-1.23-0.77-1.82-1.19c-4.3-3.05-7.88-7.16-10.21-11.9c-1.41-2.89-2.37-5.99-2.84-9.17
	c-0.68-4.68-0.39-9.5,1.07-14.02C7.89,17.02,12.21,11.4,17.69,7.5c4.09-2.91,8.87-4.78,13.86-5.38c0.43-0.05,0.86-0.09,1.28-0.13
	c0.68-0.05,1.37-0.08,2.05-0.09c7.09-0.07,14.37,2.36,19.88,6.84c2.77,2.25,5.1,5.01,7.19,7.89c0.39,0.53,0.67,1.22,0.46,1.85
	c-0.28,0.81-1.33,1.52-1.89,2.16c-0.71,0.8-1.37,1.64-1.97,2.53c-1.21,1.78-2.17,3.75-2.68,5.84c-0.74,3.02-0.52,6.25,0.47,9.2
	C56.5,38.67,56.67,39.11,56.85,39.53z"
      />
      <path
        style="fill: #ffffff"
        d="M20.6,18.78h6.95l5.51,14.51l5.83-14.51h7.02l4.1,27.14h-7.06L40.97,30.3H40.9l-6.52,15.62h-2.81L25.35,30.3
	h-0.07l-2.27,15.62h-7.02L20.6,18.78z"
      />
    </svg>
    <a
      class="mr-1 decoration-[#361C54] decoration-2 underline-offset-4 hover:text-neutral-900 hover:underline"
      href="https://www.modernary.tech"
    >
      Modernary</a
    >
    made
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
/** Attribution for Modernary - Used in footer  */
export default defineComponent({
  name: "MdrnAttribution",
  setup(props) {
    return {};
  },
});
</script>
