<template>
  <div
    class="max-w-screen-4xl flex-1 gap-6 space-y-4 overflow-auto p-0 pt-2 sm:p-5 lg:space-y-6 lg:px-6 lg:pt-6 xl:grid xl:gap-6 xl:space-y-0 2xl:grid-cols-7 2xl:gap-8"
    :class.once="[$slots.additional ? `xl:grid-cols-11` : `xl:grid-cols-7`]"
  >
    <div class="space-y-4 lg:space-y-6 xl:col-span-7 2xl:col-span-5">
      <!-- @slot Slot for the main content for the entity (typically a ReadCard component) -->
      <slot></slot>
    </div>
    <div
      v-if="$slots.additional"
      class="grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-6 xl:col-span-4 xl:block xl:space-y-4 2xl:col-span-2"
    >
      <!-- @slot Slot to allow additional content to be displayed (such as the notes widget) -->
      <slot name="additional"></slot>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
/** Base styling for viewing an entity's details (typically used on the view) */
export default defineComponent({
  name: "LayoutEntityRead",
  setup(props, context) {
    return {};
  },
});
</script>

<style module></style>
