import authorizationClient, { AuthRequest } from "@/utils/auth/authorization/AuthorizationClient.js";
import { AUTH_REQUEST_PREEMPTED } from "@/utils/auth/authorization/constants.js";
import { handlePreempts } from "@/utils/auth/authorization/handlePreempts.js";

export function useAuthorization() {
  return {
    authorizationClient,
    AuthRequest,
    AUTH_REQUEST_PREEMPTED,
    handlePreempts,
  };
}
