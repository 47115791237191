<template>
  <ListCellBase class="hidden text-sm" :class="className">
    <!-- @slot Slot for the contents -->
    <slot name="default"></slot>
  </ListCellBase>
</template>

<script lang="ts">
import { defineComponent, PropType, ref } from "vue";
import ListCellBase from "./ListCellBase.vue";

/** Used for a standard data column in a ListTable */
export default defineComponent({
  name: "ListCell",
  components: {
    ListCellBase,
  },
  props: {
    /** Specifies the TailWind breakpoint at which the cell becomes visible */
    breakpoint: {
      type: String as PropType<"sm" | "md" | "lg" | "xl" | "2xl" | "3xl" | "4xl">,
      required: true,
    },
    /** Darkens the text to 900 to emphasize it over other data in the table. */
    darken: {
      type: Boolean,
      default: false,
    },
    /** Aligns the cell contents to the right and prevents wrapping. */
    numeric: {
      type: Boolean,
      default: false,
    },
    /** Prevents wrapping */
    nowrap: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const className = ref(` ${props.breakpoint}:table-cell `);

    if (props.numeric) {
      className.value += " whitespace-nowrap tabular-nums text-right ";
    } else if (props.nowrap) {
      className.value += " whitespace-nowrap ";
    }

    if (props.darken) {
      className.value += " text-neutral-900 ";
    } else {
      className.value += " text-neutral-500 ";
    }

    return { className };
  },
});
</script>

<style module></style>
