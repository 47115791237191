<template>
  <!-- <div class="md:grid md:gap-4 md:grid-cols-10 border-none"> -->
  <div
    class="md:grid md:gap-4 border-none"
    :class="{
      'md:grid-cols-12': cols === '12',
      'md:grid-cols-11': cols === '11',
      'md:grid-cols-10': cols === '10',
      'md:grid-cols-9': cols === '9',
      'md:grid-cols-8': cols === '8',
      'md:grid-cols-7': cols === '7',
      'md:grid-cols-6': cols === '6',
      'md:grid-cols-5': cols === '5',
      'md:grid-cols-4': cols === '4',
      'md:grid-cols-3': cols === '3',
      'md:grid-cols-2': cols === '2',
      'md:grid-cols-1': cols === '1',
    }"
  >
    <slot></slot>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
/**
 * Creates a div with the desired grid cols. This is used to get around using tailwind in schema.
 * Not a component that should be used otherwise.
 */
export default defineComponent({
  name: "RepeatedBody",
  emits: [],
  props: {
    // todo make number?
    cols: {
      type: String,
      default: "10",
    },
  },
  setup(props, context) {},
  components: {},
});
</script>
