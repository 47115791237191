// Troubleshoot your routes using this - https://paths.esm.dev

/** @type {import('vue-router').RouterOptions['routes']} */

const names = {
  docGen: "DocGen",
};

export { names as docGenRouteNames };

const docGenRoutes = [
  {
    path: "/docGen",
    name: names.docGen,
    component: () => import("@casa/views/docGen/DocGen.vue"),
  },
];
export default docGenRoutes;
