<template>
  <BaseButton v-bind="$attrs" variant="create-hollow" :label="label">
    <template #iconBefore><PlusIcon /></template
  ></BaseButton>
</template>

<script lang="ts">
import BaseButton from "@/components/button/BaseButton.vue";
import { PlusIcon } from "@heroicons/vue/24/solid";
import { defineComponent } from "vue";

/**
 * Pre-styled BaseButton component for commonly used "Create" buttons.
 * Bypasses need for slot usage which is helpful for schema.
 */
export default defineComponent({
  name: "CreateButton",

  props: {
    /**
     * Label to be displayed on button
     */
    label: {
      type: String,
      // required: true,
    },
  },
  setup(props) {},
  components: {
    BaseButton,
    PlusIcon,
  },
});
</script>

<style module></style>
