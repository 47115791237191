<template>
  <SkeletonBlock class="w-20 py-0.5 pb-9"></SkeletonBlock>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import SkeletonBlock from "@/components/common/SkeletonBlock.vue";
/** Used as a placeholder while a button is being authorized/  Helps signifiy to the user that there is something there that just hasn't loaded yet. */
export default defineComponent({
  name: "SkeletonButton",
  components: { SkeletonBlock },
  setup(props) {
    return {};
  },
});
</script>

<style module></style>
