<template>
  <Disclosure
    as="nav"
    class="bg-white transition-all w-full data-[headlessui-state=open]:z-[999] data-[headlessui-state=open]:flex data-[headlessui-state=open]:h-screen data-[headlessui-state=open]:flex-col data-[headlessui-state=open]:md:block data-[headlessui-state=open]:md:h-auto"
    v-slot="{ open }"
  >
    <!-- default-open -->
    <!-- Header (has buttons, logo)-->
    <div class="border-b border-neutral-200 px-2 sm:pl-4 sm:pr-6 lg:pr-8" :class="[open ? `mx-0` : `mx-auto`]">
      <div class="relative flex h-14 justify-between md:h-16">
        <div class="absolute inset-y-0 left-0 flex items-center">
          <!-- Menu button -->
          <DisclosureButton
            class="inline-flex items-center justify-center rounded-md p-2 text-neutral-400 hover:bg-neutral-100 hover:text-neutral-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-neutral-500"
          >
            <span class="sr-only">Open main menu</span>
            <Bars3Icon v-if="!open" class="block h-6 w-6" aria-hidden="true" />
            <XMarkIcon v-else class="block h-6 w-6" aria-hidden="true" />
          </DisclosureButton>
          <!-- /Menu button -->
          <!-- Logo Container -->
          <router-link
            :to="{
              name: homeRouteName,
            }"
            class="flex flex-shrink-0 items-center ml-3 font-semibold text-neutral-500 text-lg"
          >
            <component :is="applicationTitleComponent" />
          </router-link>

          <!-- The button for the current page -->
          <div
            class="ml-0.5 items-center hidden md:flex"
            v-if="currentAppNavItem && currentAppNavItem.name !== homeRouteName && currentAppNavItem.isAuthorized"
          >
            <ChevronRightIcon
              class="ml-3 mr-3 mb-px inline h-5 w-5 flex-shrink-0 text-neutral-400"
              aria-hidden="true"
            />
            <router-link :to="{ name: currentAppNavItem.name }" v-slot="{ href, navigate, isActive }" custom>
              <AppNavAnchor
                :href="href"
                :label="currentAppNavItem.label"
                :isActive="isActive"
                @click="navigate"
                v-if="$router.currentRoute.value.name !== redirectName"
              />
              <AppNavAnchorLabel :label="currentAppNavItem.label" v-else />
            </router-link>
            <SkeletonButton v-if="currentAppNavItem.isAuthPending" />
          </div>
        </div>
        <div class="flex flex-1 items-center justify-center"></div>

        <div
          class="absolute inset-y-0 right-0 flex items-center pr-2 md:static md:inset-auto md:ml-6 md:pr-0"
          v-if="appSettingsStore.showLoginButtons"
        >
          <div v-if="!userRef.isAuthenticated">
            <LoginButton :as="BaseButton" label="Sign In" variant="priority-hollow"></LoginButton>
          </div>
          <div v-if="userRef.isAuthenticated">
            <!-- TODO: Add configurable support for Notifs. -->
            <!-- <button
              type="button"
              class="rounded-full bg-white p-1 text-neutral-400 transition-colors duration-150 ease-in hover:text-neutral-500 focus:outline-none focus:ring-2 focus:ring-neutral-500 focus:ring-offset-2"
            >
              <span class="sr-only">View notifications</span>
              <BellIcon class="h-6 w-6" aria-hidden="true" />
            </button> -->

            <!-- Profile dropdown -->
            <Menu as="div" class="relative ml-3" v-slot="{ open }">
              <div>
                <MenuButton
                  class="flex rounded-full bg-white p-1 text-sm text-neutral-400 transition-colors duration-150 ease-in focus:outline-none focus:ring-2 focus:ring-neutral-500 focus:ring-offset-0"
                  :class="[open ? `text-brand-500` : `hover:text-neutral-500`]"
                >
                  <span class="sr-only">Open user menu</span>
                  <!-- <UserCircleIcon class="h-6 w-6" aria-hidden="true"></UserCircleIcon> -->
                  <InitialBadge
                    :names="[userRef.firstName, userRef.lastName]"
                    size="base"
                    aria-hidden="true"
                  ></InitialBadge>
                </MenuButton>
              </div>
              <transition
                enter-active-class="transition ease-out duration-200"
                enter-from-class="transform opacity-0 scale-95"
                enter-to-class="transform opacity-100 scale-100"
                leave-active-class="transition ease-in duration-75"
                leave-from-class="transform opacity-100 scale-100"
                leave-to-class="transform opacity-0 scale-95"
              >
                <MenuItems
                  class="absolute right-0 z-20 mt-2 w-48 origin-top-right divide-y divide-neutral-100 rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                >
                  <div class="px-4 py-3">
                    <p class="text-xs text-neutral-600">Signed in as</p>
                    <p class="truncate text-sm font-medium text-neutral-900">
                      {{ userRef.firstName + " " + userRef.lastName }}
                    </p>
                  </div>
                  <!-- TODO: Add support for profiles, settings, etc. -->
                  <!-- TODO: Put the userNavigationItemsRef in here. -->
                  <!-- <div class="py-1">
                  <MenuItem as="div" v-slot="{ active }">
                    <a
                      href="#"
                      class="block px-4 py-2 text-sm text-neutral-700"
                      :class="[active ? 'bg-neutral-100' : '']"
                      >Your Profile</a
                    >
                  </MenuItem>
                  <MenuItem as="div" v-slot="{ active }">
                    <a
                      href="#"
                      class="block px-4 py-2 text-sm text-neutral-700"
                      :class="[active ? 'bg-neutral-100' : '']"
                      >Settings</a
                    >
                  </MenuItem>
                </div> -->
                  <div class="py-1">
                    <MenuItem as="div" v-slot="{ active }">
                      <LogoutButton
                        class="block w-full px-4 py-2 text-left text-sm text-neutral-700"
                        :class="[active ? 'bg-neutral-100' : '']"
                      ></LogoutButton>
                    </MenuItem>
                  </div>
                </MenuItems>
              </transition>
            </Menu>
          </div>
        </div>
      </div>
    </div>

    <!-- Main Menu -->
    <transition
      enter-active-class="transition ease-out duration-200"
      enter-from-class="opacity-0 -translate-y-1"
      enter-to-class="opacity-100 translate-y-0"
      leave-active-class="transition ease-in duration-150"
      leave-from-class="opacity-100 translate-y-0"
      leave-to-class="opacity-0 -translate-y-1"
    >
      <DisclosurePanel
        class="md:absolute h-full w-full md:max-h-[85vh] overflow-auto md:overflow-visible"
        v-slot="{ close }"
      >
        <div class="hidden md:block backdrop-blur-sm h-screen w-screen fixed -z-10"></div>
        <div
          class="px-2 py-3 sm:px-3 bg-white md:shadow-md grid grid-cols-1 gap-5 md:overflow-auto min-h-px h-full md:h-auto md:max-h-[85vh]"
          :class="areFavoritesEnabled ? 'md:grid-cols-[380px_auto]' : ''"
        >
          <div class="bg-neutral-200 rounded-md" v-if="areFavoritesEnabled">
            <div class="flex min-h-full items-center justify-center">
              <div class="text-center py-4">
                <NoneFoundFlag label="Favorite Items to See Them Here" />
              </div>
            </div>
          </div>
          <div class="space-y-1">
            <div class="w-full px-4 pb-6 min-h-[50vh]">
              <div class="columns-1 lg:columns-2 xl:columns-3 3xl:columns-4 min-h-px text-sm leading-6">
                <AppNavItemGroup
                  v-for="group in navigationItems"
                  :key="group.label"
                  :item-group="group"
                  :close="close"
                ></AppNavItemGroup>
              </div>
            </div>
          </div>
        </div>
      </DisclosurePanel>
    </transition>
  </Disclosure>
</template>

<script lang="ts">
import InitialBadge from "@/components/badge/InitialBadge.vue";
import BaseButton from "@/components/button/BaseButton.vue";
import AppNavAnchor from "@/components/common/AppNavAnchor.vue";
import AppNavItemGroup from "@/components/common/AppNavItemGroup.vue";
import SkeletonButton from "@/components/common/SkeletonButton.vue";
import { useAppNav, type AppNavItem } from "@/components/common/useAppNav";
import { LoginButton, LogoutButton, useUser } from "@/utils/auth/authentication";
import { Authorize } from "@/utils/auth/authorization";
import { Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/vue";

import { useAppSettingsStore } from "@/components/common";
import AppNavAnchorLabel from "@/components/common/AppNavAnchorLabel.vue";
import NoneFoundFlag from "@/components/common/NoneFoundFlag.vue";
import {
  Dialog,
  DialogOverlay,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import { ChevronDownIcon } from "@heroicons/vue/20/solid";
import { BellIcon, UserCircleIcon } from "@heroicons/vue/24/outline";
import { Bars3Icon, ChevronRightIcon, XMarkIcon } from "@heroicons/vue/24/solid";
import { DeepReadonly, defineComponent, ref, UnwrapNestedRefs, watch } from "vue";
import { useRouter } from "vue-router";

/** Component to display and control the overall navigation for the app.
 * Navigation items are pulled from the `navigationItems` in `useAppNav`
 */
export default defineComponent({
  name: "AppNav",
  components: {
    Authorize,
    AppNavAnchor,
    AppNavAnchorLabel,
    BellIcon,
    ChevronRightIcon,
    Dialog,
    DialogOverlay,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    TransitionChild,
    TransitionRoot,
    Bars3Icon,
    UserCircleIcon,
    XMarkIcon,
    AppNavItemGroup,
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    InitialBadge,
    LoginButton,
    LogoutButton,
    SkeletonButton,
    BaseButton,
    ChevronDownIcon,
    NoneFoundFlag,
  },
  setup(props, context) {
    const { areFavoritesEnabled, navigationItems, userNavigationItems, applicationTitleComponent, homeRouteName } =
      useAppNav();
    const appSettingsStore = useAppSettingsStore();
    const router = useRouter();

    const { userRef } = useUser();

    // Handy debugging tool -
    // router.afterEach((to, from, failure) => {
    //   if (!failure) console.log(to);
    // });

    const currentAppNavItem = ref<DeepReadonly<UnwrapNestedRefs<AppNavItem>>>();
    const redirectName = ref<string>();

    // Crawl the nav items and set the currentAppNavItem.
    // TODO: add support for userNavigationItems
    watch(
      router.currentRoute,
      (newValue, oldValue) => {
        // (the nav groups)
        for (let i = 0; i < navigationItems.length; ++i) {
          let group = navigationItems[i];
          // (the nav items within the group)
          for (let j = 0; j < group.items.length; ++j) {
            // (the currently matched routes)
            for (let k = 0; k < newValue.matched.length; ++k) {
              if (newValue.matched[k].name === group.items[j].name) {
                currentAppNavItem.value = group.items[j];
                redirectName.value =
                  /* @ts-ignore-error */ // Ignore the fact that the provided type for the currentRoute is wrong.
                  newValue.name === group.items[j].name ? newValue.name : newValue.matched[k].redirect?.name;
                return;
              }
            }
          }
        }
        currentAppNavItem.value = undefined;
      },
      { immediate: true },
    );

    return {
      redirectName,
      currentAppNavItem,
      areFavoritesEnabled,
      applicationTitleComponent,
      navigationItems,
      userNavigationItems,
      appSettingsStore,
      userRef,
      homeRouteName,
      BaseButton,
    };
  },
});
</script>

<style module></style>
