<template>
  <div class="px-2 sm:px-0">
    <h2 class="text-sm font-medium text-neutral-500">{{ label }}</h2>
    <ul role="list" class="mt-3 grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-1 md:gap-3">
      <!-- <SkeletonButton v-if="application.permit.isAuthorized === 'pending'" class="col-span-1" /> -->
      <!-- v-if="application.permit.isAuthorized === true" -->
      <!-- @slot Slot for main content -->
      <slot></slot>
    </ul>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
/** This component is used as a wrapper to contain RelatedEntity Components.
 **This components usage has been replaced. Future use needs to be re-evaluated. */
export default defineComponent({
  name: "RelatedEntities",
  emits: [],
  components: {},
  props: {
    /** Label to go above all the related entities */
    label: {
      type: String,
      required: true,
    },
  },
  setup(props, context) {
    return {};
  },
});
</script>

<style module></style>
