<template>
  <div class="h-full overflow-auto bg-neutral-100">
    <div class="">
      <!-- content -->
      <div class="mx-auto mt-10 grid grid-cols-1 gap-10 xl:grid-cols-2">External Auth'd User Landing</div>
    </div>
    <footer class="sticky top-[100vh] mt-20 flex h-10 flex-col items-center p-2">
      <MdrnAttribution />
    </footer>
  </div>
</template>

<script lang="ts">
import { MdrnAttribution } from "@mui/components/common";

import { noLabelSchema } from "@mui/utils/helpers";
import { defineComponent } from "vue";

// Home content for unauth'd users
export default defineComponent({
  name: "HomeExternal",
  setup() {
    const noLabel = noLabelSchema();

    return {
      noLabel,
    };
  },
  components: {
    MdrnAttribution,
  },
});
</script>
