import EntityAudits from "@casa/components/EntityAudits.vue";

import { RouterView } from "vue-router";

// Troubleshoot your routes using this - https://paths.esm.dev

const names = {
  organizations: "Organizations",
  organizationList: "OrganizationList",
  organizationNew: "OrganizationNew",
  organizationBase: "OrganizationBase",
  organizationView: "OrganizationView",
  organizationEdit: "OrganizationEdit",
  organizationAffiliations: "OrganizationAffiliations",
  organizationAffiliationEdit: "OrganizationAffiliationEdit",
  organizationAffiliationNew: "OrganizationAffiliationNew",
  // organizationUsers: "OrganizationUsers",
  // organizationUserNew: "OrganizationUserNew",
  organizationAudit: "OrganizationAudit",
};

export { names as organizationRouteNames };

/** @type {import('vue-router').RouterOptions['routes']} */
const organizationRoutes = [
  {
    path: "/organizations",
    name: names.organizations,
    component: {
      render: () => <RouterView />,
    },
    redirect: { name: names.organizationList },
    children: [
      {
        path: "list",
        name: names.organizationList,
        meta: { title: "Organizations" },
        component: () => import("@casa/views/organization/OrganizationList.vue"),
      },
      {
        path: "new",
        component: () => import("@casa/views/organization/OrganizationBase.vue"),
        redirect: { name: names.organizationNew },
        props: true,
        children: [
          {
            path: "",
            name: names.organizationNew,
            meta: { title: "New Organization", isNew: true },
            component: () => import("@casa/views/organization/OrganizationEdit.vue"),
          },
        ],
      },
      {
        path: ":id(\\d+)",
        name: names.organizationBase,
        component: () => import("@casa/views/organization/OrganizationBase.vue"),
        redirect: { name: "OrganizationView" },
        children: [
          {
            path: "view",
            name: names.organizationView,
            meta: { title: "Organization View" },
            component: () => import("@casa/views/organization/OrganizationView.vue"),
          },
          {
            path: "edit",
            name: names.organizationEdit,
            meta: { title: "Organization Edit", isForm: true },
            component: () => import("@casa/views/organization/OrganizationEdit.vue"),
          },
          {
            path: "affiliations",
            name: names.organizationAffiliations,
            meta: { title: "Facility Site Affiliations" },
            component: () => import("@casa/views/organization/OrganizationAffiliations.vue"),
          },
          {
            path: "affiliations/:affiliationId(\\d+)/:entityType(person)",
            name: names.organizationAffiliationEdit,
            meta: { title: "Edit Facility Site Affiliation", isForm: true },
            component: () => import("@casa/views/organization/OrganizationAffiliation.vue"),
          },
          {
            path: "affiliations/new",
            name: names.organizationAffiliationNew,
            meta: { title: "Create Facility Site Affiliation", isForm: true },
            component: () => import("@casa/views/organization/OrganizationAffiliation.vue"),
          },
          // {
          //   path: "users",
          //   name: names.organizationUsers,
          //   meta: { title: "Organization Users" },
          //   component: () => import("@casa/views/organization/OrganizationUsers.vue"),
          // },
          // {
          //   path: "users/new",
          //   name: names.organizationUserNew,
          //   meta: { title: "Add Organization User", isForm: true },
          //   component: () => import("@casa/views/organization/OrganizationUser.vue"),
          // },
          {
            path: "activity",
            name: names.organizationAudit,
            meta: { title: "Organization Audit Activity" },
            component: {
              render: () => <EntityAudits entityName="Organization" />,
            },
          },
          // {
          //   path: "documents",
          //   name: "OrganizationDocuments",
          //   meta: { title: "Organization Documents" },
          //   component: { render: () => <div>docs</div> },
          // },
        ],
      },
    ],
  },
];
export default organizationRoutes;
