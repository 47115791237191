import type { FormKitNode } from "@formkit/core";

// let run = 0;
/**
 * Validates whether all or none of the fields in a group have values
 * @param {FormKitNode} node Node to validate.
 * @returns {boolean} Returns true if either all fields in the group have values or none have values, otherwise false.
 */
const all_or_none_in_group_required = (node: FormKitNode) => {
  const accessVariableSoThatVueWillSeeItDependsOnNodeValue = node.value; // Weird, but necessary for the first field in the group to work.
  let hasValue = false;
  const childNames = node.parent?.children.map((x) => x.name);
  let someHaveValues = false;
  childNames?.forEach((childName) => {
    if (childName === node.name) {
      hasValue = Boolean(node.value);
    }
    const currentValue = node.at(childName)?.value;
    someHaveValues = someHaveValues || Boolean(currentValue);
  });

  // console.log(
  //   "all_or_none_in_group_required - " + run++ + " " + node.name + " = " + node.value,
  //   node
  // );
  // isValid = node.parent.children.some((x) => x.value && x.name !== node.name);

  return hasValue || !someHaveValues;
};
// all_or_none_in_group_required.blocking = true;
// all_or_none_in_group_required.debounce = 50;
all_or_none_in_group_required.skipEmpty = false;
// all_or_none_in_group_required.force = true;

export { all_or_none_in_group_required };
