<template>
  <!-- <BaseButton label="test from middle" @click="testWeights"></BaseButton> -->

  <div class="mb-1 flex items-end justify-between">
    <div class="flex items-center">
      <span class="text-lg">{{ frozenName }}</span>
    </div>
    <div class="flex items-center">
      <span class="text-neutral-700">
        <span v-if="numericRollup">{{ sumAllMiddles(index) }}</span> {{ rightHeader }}</span
      >
      <BaseButton
        class="ml-5 mr-4 h-8 w-8 px-1"
        icon
        borderless
        variant="danger-subtle"
        :label="removeLabel"
        @click="activateClick(index)"
      >
        <template #iconBefore><XMarkIcon class="w-5" /></template
      ></BaseButton>
    </div>
  </div>
  <FormKit
    :name="name"
    type="repeater"
    forward="forwardValuepls"
    draggable="false"
    :plugins="[addHandlers]"
    :down-control="false"
    :up-control="false"
    :sections-schema="{
      fieldset: {
        attrs: {
          class: 'border-none',
        },
      },
      legend: {
        attrs: {
          class: 'hidden',
        },
      },
      content: {
        attrs: {
          class: 'm-0 p-0',
        },
      },
      items: {
        attrs: {
          class: 'm-0 p-0 rounded-t-lg border-t border-r border-l border-neutral-300',
        },
      },
      item: {
        attrs: {
          class: 'm-0 p-0',
        },
      },
      controls: {
        attrs: {
          class: '',
        },
      },
    }"
  >
    <!-- hacky way to trigger the delete function. Left this because we don't have access to the fns in the two places we need. Refactor later? -->

    <template #removeControl="{ fns, index }">
      <div class="hidden">
        <FormKit
          type="button"
          :label="removeLabel"
          :id="'hiddenRemoveButton_' + outerIndex + '_' + repeaterName + '_' + index"
          @click="fns.removeMiddleItem(index)()"
        />
      </div>
      <!-- hiddenRemoveButton_nameOfEntireRepeater_indexOfOuter_indexOfThisItem(middle) -->
      <!-- this index belongs to material  -->
    </template>

    <template #addButton="{ fns, index }">
      <div class="rounded-b-lg border-b border-r border-l border-solid border-neutral-300 m-0 p-0">
        <div
          class="flex flex-wrap items-center justify-items-center border-t-2 border-dashed border-neutral-300 md:flex-nowrap md:gap-4 m-0 p-4"
        >
          <!-- way to add schema and bypass the single slot restriction -->
          <FormKitSchema :schema="addSchema" :data="data" v-if="addSchema" />

          <BaseButton
            class="w-full md:w-auto md:flex-grow-0"
            variant="create-hollow"
            :label="addLabel"
            :disabled="disabled"
            @click="fns.appendMiddleItem()()"
          >
            <!--  @click="fns.createAppend()()" -->
            <template #iconBefore><PlusIcon /></template
          ></BaseButton>
        </div>
      </div>
    </template>

    <template #default="{ index, value }">
      <slot
        :index="index"
        :parentIndex="parentIndex"
        :middleForward="middleForward"
        :triggeredFromMiddle="triggeredFromMiddle"
        :value="value"
        :repeaterName="repeaterName"
        :outerIndex="outerIndex"
        :outerLengths="outerLengths"
        :numericRollup="numericRollup"
      ></slot>
    </template>
  </FormKit>
</template>

<script lang="ts">
import { PropType, defineComponent, inject, ref, unref } from "vue";

import { FlagIcon as FlagIconOutline } from "@heroicons/vue/24/outline";
import { FlagIcon, PlusIcon, XMarkIcon } from "@heroicons/vue/24/solid";

import { FormKitNode, getNode } from "@formkit/core";
import { FormKitSchema } from "@formkit/vue";
import { BaseButton } from "@/components/button";
import { noLabelSchema } from "@/utils/helpers";
import { clearInput } from "@/utils/schema";
import injectionSymbols from "@/components/schema/injectionSymbols";

export default defineComponent({
  name: "MiddleRepeater",
  emits: [],
  props: {
    /** name for this repeater */
    name: {
      type: String,
      default: "MiddleRepeater",
    },
    removeLabel: {
      type: String,
      default: "Remove",
    },
    addLabel: {
      type: String,
      default: "Add Item",
    },
    /** this should be regular schema. To by pass not being to put regular schema in a prop - use a function that will return the schema and give that to the prop instead  */
    addSchema: {
      type: Array as PropType<any[]>,
      default: [],
    },
    nameForMiddle: {
      type: String,
    },
    index: {
      type: Number,
    },
    disabled: {
      type: Boolean,
    },
    rightHeader: {
      type: String,
    },
    /** the length of the outer repeater. gets forwarded  */

    outerLengths: {
      type: Array as PropType<any[]>,
      default: [],
    },
    outerIndex: {
      type: Number,
      default: 0,
    },
    //does it have to be string? possibly add a few for more options
    /** value to be forwarded down in the chain (bypasses $get not using dynamic) */
    middleForward: {
      type: String,
    },

    //an array of ids to inputs that should be cleared on append
    customInputIds: {
      type: Array as PropType<string[]>,
      default: [],
    },

    // allows for ability to disable the clear functionality.
    clearInputsOnAppend: {
      type: Boolean,
      default: true,
    },
    //   //TODO - do we still want this? Originally I thought I needed it for dynamic options but I didn't. Still seems useful though.
    //  /** function to perform when something is appended  */
    //   onAppend: {
    //     type: Function,
    //   },
    //   /** function to perform when something is removed  */
    //   onRemove: {
    //     type: Function,
    //   },
    repeaterName: {
      type: String,
      required: true,
    },
    numericRollup: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    //we need the sum all here

    const data = ref({
      noLabel: noLabelSchema(), //<- make global?
      index: props.index,
    });

    //hacky way to trigger remove event when the button is outside access to the function
    function activateClick(index: any) {
      let myButton = document.getElementById("hiddenWrapperRemoveButton_" + props.repeaterName + index);
      myButton?.click();
    }

    const { sumAllMiddles } = inject(injectionSymbols.SumMiddlesHelperKey) as any; //todo

    function testWeights() {
      console.log(sumAllMiddles(props.index));
    }

    const frozenName = unref(props.name);

    const parentIndex = props.index;

    const triggeredFromMiddle = ref(false);

    //these fns must be named differently amongst repeaters
    //this function is replacing formkits native append function, with their own code but with
    //additional functionality added (ability to inform a child that the button was clicked and
    //to clear input fields if desired)
    const addHandlers = (node: FormKitNode) => {
      node.context!.fns.appendMiddleItem = () => () => {
        //ref to indicate that the append function came from this component (as opposed to outer repeater)
        triggeredFromMiddle.value = true;

        //start - formkits original append
        const _value = node._value as Array<Record<string, unknown>>;
        _value.push({});
        node
          .input(_value, false) //end formkits original append
          .then(() => {
            //clears the input fields if desired (default behavior) and if the ids are provided
            if (props.clearInputsOnAppend && props.customInputIds.length > 0) {
              props.customInputIds.forEach((id) => {
                clearInput(id);
              });
            }
          })
          .catch((error) => {
            console.error("Error occurred:", error);
          });
      };

      node.context!.fns.removeMiddleItem = (index: number) => () => {
        //start - formkits original remove
        const _value = node._value as Array<Record<string, unknown>>;
        _value.splice(index, 1);
        node
          .input(_value, false) //end formkits original remove
          .then(() => {
            //optional space for additional actions
          })
          .catch((error) => {
            console.error("Error occurred:", error);
          });
      };
    };

    return {
      data,
      activateClick,
      frozenName,
      parentIndex,
      addHandlers,
      triggeredFromMiddle,
      sumAllMiddles,
    };
  },
  components: {
    BaseButton,
    XMarkIcon,
    FlagIcon,
    FlagIconOutline,
    PlusIcon,
    FormKitSchema,
  },
});
</script>
