<template>
  <div>
    <div class="flex space-x-3">
      <div class="flex-shrink-0" v-if="$slots.beginningBadge">
        <!-- @slot Slot for optional badge to be displayed to the left of `ValueOne` -->
        <slot name="beginningBadge"></slot>
      </div>
      <div class="min-w-0 flex-1">
        <p class="text-sm font-medium text-neutral-900">
          {{ valueOne }}
          <span v-if="valueTwo" class="text-sm text-neutral-500">&CenterDot; {{ valueTwo }}</span>
          <!-- @slot Slot for optional badge to be displayed to the right of `ValueOne` (will be the right of `valueTwo` if present)-->
          <slot name="endBadge"></slot>
        </p>
        <!-- @slot Slot for optional Sub-header to go underneath `valueOne`  -->
        <slot name="subHeader"></slot>
      </div>
      <div class="flex flex-shrink-0 self-center" v-if="$slots.menu">
        <!-- @slot Slot for the menu (containing actions such as edit, delete, download) -->
        <slot name="menu"></slot>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
/** Standardized styling for the header of a list card. */
export default defineComponent({
  name: "ListCardHeader",
  props: {
    /** Value to be displayed at the top of the ListCard */
    valueOne: {
      required: true,
    },
    /** Optional value to be displayed next to `valueOne` seperated by a center dot */
    valueTwo: {},
  },
  setup(props) {
    return {};
  },
});
</script>

<style module></style>
