import { Router } from "vue-router";

/**
 * Navigates back if routeName matches the previous path or replaces the current route with the given name and parameters.
 * @param {Router} router Vue Router
 * @param {string} routeName Name of the route to navigate to
 * @param {any} [params] Optional parameters to pass to the route.
 */
export const routeCancelAction = (router: Router, routeName: string, params?: any) => {
  if (router.resolve({ params, name: routeName }).fullPath === window.history.state.back) {
    router.back();
  } else {
    router.replace({ params, name: routeName });
  }
};

/**
 * Accepts a config object and utilizes its properties in the {@link routeCancelAction} to navigate back or to routeName given.
 * @param {{ router: Router; routeName: string; params: any }} config configuration object from which to pull router, routeName and params from
 * @param {Router} config.router Vue Router
 * @param {string} config.routeName  Name of the route to navigate to or replace with
 * @param {any} config.params Parameters to pass to the route
 */
export const childRouteCancelAction = (config: { router: Router; routeName: string; params: any }) => {
  routeCancelAction(config.router, config.routeName, config.params);
};

/**
 * Exits the edit route by navigating back to the list route if it's a new item or navigating back to the view route if it's an existing item.
 * @param {object} config Configuration object to pull router, viewRouteName, listRouteName, isNew, listParams, and viewParams from
 * @param {Router} config.router Vue Router
 * @param {string} config.viewRouteName Name of the view route for the entity if it already exits
 * @param {string} config.listRouteName Name of the list for the entity type if it does not already exist
 * @param {boolean} config.isNew Indicates whether the item being edited is new
 * @param {any} [config.listParams] Optional parameters for the list route
 * @param {any} config.viewParams Parameters for the view route
 */
export const editRouteCancelAction = (config: {
  router: Router;
  viewRouteName: string;
  listRouteName: string;
  isNew: boolean;
  listParams?: any;
  viewParams: any;
}) => {
  if (config.isNew) {
    routeCancelAction(config.router, config.listRouteName, config.listParams);
  } else {
    routeCancelAction(config.router, config.viewRouteName, config.viewParams);
  }
};

// router, routeName, params, isNew
/**
 * Navigates to a given route for saving an item
 * @param {object} config Configuration object to pull router, routeName, params and isNew from
 * @param {Router} config.router Vue Router
 * @param {string} config.routeName Name of the route to navigate to.
 * @param {any} config.params Parameters to pass to the route.
 * @param {boolean} [config.isNew=false] Optional property to indicates whether the item being saved is new; defaults to false.
 */
export const routeSaveAction = (config: { router: Router; routeName: string; params: any; isNew?: boolean }) => {
  const route = {
    name: config.routeName,
    params: config.params,
  };
  if (config.isNew) {
    config.router.replace(route);
  } else {
    config.router.push(route);
  }
};
