<template>
  <TransitionRoot as="template" :show="openRef">
    <Dialog as="div" class="fixed inset-0 z-40 overflow-y-auto" @close="openRef = false">
      <div class="flex min-h-screen items-end justify-center px-4 pb-20 pt-4 text-center sm:block sm:p-0">
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="ease-in duration-200"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span class="hidden sm:inline-block sm:h-screen sm:align-middle" aria-hidden="true">&#8203;</span>
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enter-to="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leave-from="opacity-100 translate-y-0 sm:scale-100"
          leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <div
            class="relative inline-block transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6 sm:align-middle"
          >
            <div class="sm:flex sm:items-start">
              <div
                class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-neutral-100 sm:mx-0 sm:h-10 sm:w-10"
                :class="{
                  'bg-sky-100': isNotification,
                  'bg-neutral-100': isConfirmation,
                  'bg-yellow-100': isWarning,
                }"
              >
                <InformationCircleIcon class="h-6 w-6 text-sky-600" aria-hidden="true" v-if="isNotification" />
                <QuestionMarkCircleIcon class="h-6 w-6 text-neutral-600" aria-hidden="true" v-if="isConfirmation" />
                <ExclamationCircleIcon class="h-6 w-6 text-yellow-600" aria-hidden="true" v-if="isWarning" />
              </div>
              <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                <DialogTitle as="h3" class="text-lg font-medium leading-6 text-gray-900">
                  {{ title }}
                </DialogTitle>
                <div class="mt-2">
                  <p class="text-sm text-gray-500">
                    <!-- @slot Slot for the dialog description. By default, the `description` prop will be used. -->
                    <slot name="description">{{ description }}</slot>
                  </p>
                </div>
              </div>
            </div>
            <div class="mt-5 sm:ml-10 sm:mt-4 sm:flex sm:pl-4">
              <BaseButton
                @click="
                  openRef = false;
                  $emit('okClick');
                "
                class="w-full sm:w-auto sm:text-sm"
                :variant="okVariant"
                :label="okLabel"
              >
              </BaseButton>
              <BaseButton
                class="mt-3 w-full sm:ml-3 sm:mt-0 sm:w-auto sm:text-sm"
                @click="
                  openRef = false;
                  $emit('cancelClick');
                "
                ref="cancelButtonRef"
                :label="cancelLabel"
                v-if="!isNotification"
              ></BaseButton>
            </div>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script lang="ts">
import BaseButton from "@/components/button/BaseButton.vue";
import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from "@headlessui/vue";
import { ExclamationCircleIcon, InformationCircleIcon, QuestionMarkCircleIcon } from "@heroicons/vue/24/outline";
import { PropType, computed, defineComponent, ref } from "vue";
/** Component for a confirm dialog - Used to alert the user of something and get their confirmation of the message.
 * Can also be used to handle failed validation for an action (E.g. user tries to "complete" a task with a missing required field. ConfirmDialog alerts them of this and directs them on where or how to fix it.)
 *
 * Events:
 *
 * `okClick` - Triggered when the "OK" button is clicked. (This button may be labeled something else, as defined in the `okLabel` prop)
 *
 * `cancelClick` - Triggered when the "Cancel" button is clicked. (This button may be labeled something else, as defined in the `cancelLabel` prop)
 */
export default defineComponent({
  name: "ConfirmDialog",
  emits: ["okClick", "cancelClick"],
  components: {
    BaseButton,
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    QuestionMarkCircleIcon,
    InformationCircleIcon,
    ExclamationCircleIcon,
  },
  props: {
    /** Title to be shown at the top of the dialog */
    title: {
      type: String,
      required: true,
    },
    /** More detailed explanation of the message */
    description: {
      type: String,
      default: null,
    },
    /** Optional label to be used instead of "OK" (Such as "Verify", "Submit", or "Edit") */
    okLabel: {
      type: String,
      default: "OK",
    },
    /** Optional label to be used in place of cancel */
    cancelLabel: {
      type: String,
      default: "Cancel",
    },
    /** Prop to control the style of the dialog
     * @values notify, confirm, warn
     */
    variant: {
      type: String as PropType<"notify" | "confirm" | "warn" | undefined>,
      default: "confirm",
      validator: (val: string | undefined) => Boolean(val) && ["notify", "confirm", "warn"].includes(val as string),
    },
  },
  setup(props, { expose }) {
    const openRef = ref(false);
    const openConfirmation = () => {
      openRef.value = true;
    };

    const okVariant = computed(() => {
      switch (props.variant) {
        case "confirm":
          return "create";
        case "warn":
          return "warn";
        case "notify":
          return "plain";
      }
    });

    const isNotification = computed(() => props.variant === "notify");
    const isConfirmation = computed(() => props.variant === "confirm");
    const isWarning = computed(() => props.variant === "warn");

    expose({ openConfirmation });
    return {
      openConfirmation,
      openRef,
      okVariant,
      isNotification,
      isConfirmation,
      isWarning,
    };
  },
});
</script>
