<template>
  <span
    class="inline-flex"
    :class.once="[
      bordered && `shadow-[0_0_0_2px_#fff]`,
      size === 'sm' && `rounded-full px-2 text-xs font-semibold leading-5`,
      size === 'md' && `items-center rounded-xl px-3 py-0.5 align-middle text-sm font-medium tracking-normal`,
    ]"
  >
    {{ label }}
  </span>
</template>

<script lang="ts">
import { PropType, defineComponent } from "vue";
/**
 * Component for commonly used badge. Typically used with statuses.
 */
export default defineComponent({
  name: "Badge",
  props: {
    /**
     * Text to display inside badge
     */
    label: {
      type: String,
    },
    /**
     * Optional size specification
     * @values sm, md
     */
    size: {
      type: String as PropType<"sm" | "md">,
      default: () => "md",
      validator: (val: string) => !val || ["sm", "md"].includes(val),
    },
    /**
     * Will apply a border to the badge if set to true
     */
    bordered: {
      type: Boolean,
      default: false,
    },
  },
});
</script>

<style module></style>
