<template>
  <ReadFieldBase v-bind="baseProps">
    <span v-show="(!$slots.default || childDataCount === 0) && !value?.length">—</span>
    <div class="pl-4" v-show="($slots.default && childDataCount > 0) || value?.length">
      <ul class="list-outside list-disc text-neutral-400">
        <!-- @Slot Place list items here (ReadFieldListItem) -->
        <slot
          ><ReadFieldListItem v-for="item in value" :label="item.label" :value="item.value" :is-email="item.isEmail"
        /></slot>
      </ul>
    </div>
  </ReadFieldBase>
</template>

<script lang="ts">
import { PropType, defineComponent, provide, ref } from "vue";
import ReadFieldBase from "@/components/read/ReadFieldBase.vue";
import ReadFieldListItem from "@/components/read/ReadFieldListItem.vue";
import injectionSymbols from "@/components/read/injectionSymbols";
/** This component allows you to display a value as a bulleted list.
 * Useful for when you have a lot of data for a particular item, making the standard 'label / value' style unsuitable.
 * This component can be used by passing in an array of all the values you want shown, or by individually adding `ReadFieldListItem` components in the designated slot   */
export default defineComponent({
  name: "ReadFieldList",
  components: {
    ReadFieldBase,
    ReadFieldListItem,
  },
  props: {
    /** Label for the list of data */
    label: {
      type: String,
      required: true,
    },
    /** Controls the column span for the item
     * @values 1, 2
     */
    span: {
      type: Number,
      default: 1,
    },
    /** Array of items (objects) you want to be displayed. Each item must include a value property.
     * Optionally, the object can also include a label property or an isEmail property (this should be used if it is an email to properly control the breakpoint)  */
    value: {
      type: Object as PropType<{ label?: string; value: any; isEmail?: boolean }[]>,
    },
  },
  setup(props, context) {
    const { value, ...baseProps } = props;

    const childDataCount = ref(0);
    const childUpdateHandler = (value: boolean) => {
      if (value) {
        ++childDataCount.value;
      } else if (childDataCount.value > 0) {
        --childDataCount.value;
      }
    };

    provide(injectionSymbols.ReadFieldListChildUpdateHandlerKey, childUpdateHandler);

    return {
      childDataCount,
      baseProps,
      childUpdateHandler,
    };
  },
});
</script>

<style module></style>
