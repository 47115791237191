<template>
  <div class="h-0 w-0 absolute" aria-hidden="true">
    <div ref="mobile" class="block sm:hidden"></div>
    <div ref="sm" class="hidden sm:max-md:block"></div>
    <div ref="md" class="hidden md:max-lg:block"></div>
    <div ref="lg" class="hidden lg:max-xl:block"></div>
    <div ref="xl" class="hidden xl:max-2xl:block"></div>
    <div ref="twoXl" class="hidden 2xl:max-3xl:block"></div>
    <div ref="threeXl" class="hidden 3xl:max-4xl:block"></div>
    <div ref="fourXl" class="hidden 4xl:block"></div>
  </div>
</template>

<script lang="ts">
import { useWindowSize, watchDebounced } from "@vueuse/core";
import { WatchStopHandle, defineComponent, onMounted, onUnmounted, ref } from "vue";
import { useCssBreakpointStore } from "@/components/common/cssBreakpointStore";

/**
 * This component enables us to decouple detection of the current breakpoint in JS from what resolution that breakpoint actually is.
 */
export default defineComponent({
  name: "TheCssBreakpointDetector",
  setup(props) {
    const { width } = useWindowSize();
    const cssBreakpointStore = useCssBreakpointStore();

    const mobile = ref<HTMLDivElement>();
    const sm = ref<HTMLDivElement>();
    const md = ref<HTMLDivElement>();
    const lg = ref<HTMLDivElement>();
    const xl = ref<HTMLDivElement>();
    const twoXl = ref<HTMLDivElement>();
    const threeXl = ref<HTMLDivElement>();
    const fourXl = ref<HTMLDivElement>();

    function setValues() {
      cssBreakpointStore.isMobile = Boolean(mobile?.value?.offsetParent);
      cssBreakpointStore.isSm = Boolean(sm?.value?.offsetParent);
      cssBreakpointStore.isMd = Boolean(md?.value?.offsetParent);
      cssBreakpointStore.isLg = Boolean(lg?.value?.offsetParent);
      cssBreakpointStore.isXl = Boolean(xl?.value?.offsetParent);
      cssBreakpointStore.isTwoXl = Boolean(twoXl?.value?.offsetParent);
      cssBreakpointStore.isThreeXl = Boolean(threeXl?.value?.offsetParent);
      cssBreakpointStore.isFourXl = Boolean(fourXl?.value?.offsetParent);
    }

    let destroyWatch: WatchStopHandle = () => {};
    onMounted(() => {
      setValues(); //Call it once to establish the initial state. Don't rely on "immediate" watch because it's debounced.

      destroyWatch = watchDebounced(
        width,
        (newValue, oldValue) => {
          if (newValue === oldValue) return;

          setValues();
        },
        { debounce: 50 },
      );
    });

    onUnmounted(() => {
      destroyWatch();
    });

    return { mobile, sm, md, lg, xl, twoXl, threeXl, fourXl };
  },
});
</script>

<style module></style>
