<template>
  <transition
    enter-active-class="transition ease-out duration-200"
    enter-from-class="opacity-0 -translate-y-1"
    enter-to-class="opacity-100 translate-y-0"
    leave-active-class="transition ease-in duration-150"
    leave-from-class="opacity-100 translate-y-0"
    leave-to-class="opacity-0 -translate-y-1"
  >
    <div class="sm:rounded-lg" v-show="areFiltersVisible">
      <section :aria-labelledby.once="sectiondHeadingIdRef" class="bg-white shadow sm:rounded-lg">
        <div class="flex items-center justify-between px-4 py-5 sm:px-6">
          <div class="flex-none">
            <h2 :id.once="sectiondHeadingIdRef" class="text-lg font-medium leading-6 text-neutral-900">
              {{ heading }}
            </h2>
            <p class="mt-1 max-w-2xl text-sm text-neutral-500" :class="{ hidden: !subHeading }">
              {{ subHeading }}
            </p>
          </div>
          <div class="ml-4 flex min-w-fit flex-none items-start justify-start gap-4">
            <!-- @slot Slot to place additional actions -->
            <slot name="actions"></slot>
            <BaseButton @click="$emit('resetClick')" variant="priority-subtle" label="Reset" />
            <!-- v-show="areFiltersVisible" -->
            <!-- <BaseButton
              @click="changeClick"
              variant="priority-subtle"
              label="Change Filters"
              v-show="!areFiltersVisible"
            /> -->
            <!-- <Authorize v-bind="editAuthRequest" hide-by-default>
            <template #pending><SkeletonButton /></template>
          </Authorize> -->
          </div>
        </div>

        <div>
          <div class="border-t border-neutral-200 px-4 py-5 sm:px-6">
            <div class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2 xl:grid-cols-3 3xl:grid-cols-4">
              <!-- @slot Slot for the main content / fields -->
              <slot></slot>
            </div>
          </div>
          <div class="block gap-4 space-y-4 p-4 sm:flex sm:space-y-0">
            <BaseButton
              v-if="!hidePrimaryAction"
              @click="primaryActionClick"
              variant="priority-hollow"
              class="w-full sm:py-4"
              :class="hasSecondaryAction ? 'basis-3/4' : ''"
              label="Run Report"
            >
              <template #iconBefore><PlayIcon /></template>
            </BaseButton>
            <BaseButton
              v-if="hasSecondaryAction"
              @click="$emit('secondaryActionClick')"
              variant="priority-hollow"
              class="w-full sm:py-4"
              :class="hidePrimaryAction ? '' : 'basis-1/4'"
              label="Export Data"
            >
              <template #iconBefore><ArrowDownTrayIcon /></template>
            </BaseButton>
          </div>
        </div>
      </section>
    </div>
  </transition>
</template>

<script lang="ts">
import BaseButton from "@/components/button/BaseButton.vue";
import ConfirmDelete from "@/components/modals/ConfirmDelete.vue";
import SkeletonButton from "@/components/common/SkeletonButton.vue";
import { Authorize } from "@/utils/auth/authorization";
import { getUniqueId } from "@/utils/helpers";
import { ArrowDownTrayIcon } from "@heroicons/vue/24/outline";
import { PlayIcon, TrashIcon } from "@heroicons/vue/24/solid";
import { defineComponent, inject, ref } from "vue";

/**
 *  Component to standardize styling and handle provided actions.
 * Typically used for the analytics filter controls.
 *
 * Events:
 *
 * `actionClick` - This event is emitted when the "Run Report" button is clicked.
 *
 * `resetClick` - This event is emitted when the "Reset" button is clicked.
 *
 * `secondaryActionClick` - This event is emitted when the "Export Data" button is clicked.
 */
export default defineComponent({
  name: "ActionCard",
  emits: ["actionClick", "resetClick", "secondaryActionClick"],
  components: {
    Authorize,
    BaseButton,
    ConfirmDelete,
    PlayIcon,
    TrashIcon,
    SkeletonButton,
    ArrowDownTrayIcon,
  },
  props: {
    /** Heading for the action card */
    heading: {
      type: String,
      required: true,
    },
    /** Sub-heading for the action card */
    subHeading: {
      type: String,
      default: null,
    },
    /** Lets the component know that it should display a second button if there is more than one action */
    hasSecondaryAction: { type: Boolean, default: false },
    /** Lets the component know if primary action should be hidden */
    hidePrimaryAction: { type: Boolean, default: false },
  },
  setup(props, context) {
    const sectiondHeadingIdRef = ref(getUniqueId("sectionHeading"));
    // const areFiltersVisible = ref(true);

    const { areFiltersVisible, hideFilters } = inject("analyticsFilters", {
      areFiltersVisible: ref(true),
      hideFilters: () => {},
    });

    const primaryActionClick = () => {
      context.emit("actionClick");
      if (hideFilters) {
        hideFilters();
      } else {
        areFiltersVisible.value = false;
      }
    };

    // const changeClick = () => {
    //   areFiltersVisible.value = !areFiltersVisible.value;
    // };

    return {
      sectiondHeadingIdRef,
      areFiltersVisible,
      // changeClick,
      primaryActionClick,
    };
  },
});
</script>

<style module></style>
