<template>
  <LayoutAppContent>
    <div class="p-5 text-center sm:pt-10">
      <h1 class="relative inline-block w-full p-12 text-center sm:w-1/2">
        <HandRaisedIcon class="mx-auto h-12 w-12 text-gray-400"></HandRaisedIcon>
        <span class="mt-4 block text-xl font-medium text-gray-900"> Not Authorized </span>
      </h1>
    </div>
  </LayoutAppContent>
</template>

<script lang="ts">
import LayoutAppContent from "@/components/layouts/LayoutAppContent.vue";
import { useUser } from "@/utils/auth/authentication";
import { HandRaisedIcon } from "@heroicons/vue/24/outline";
import { defineComponent, watch } from "vue";
import { useRouter } from "vue-router";
/**
 * Displays not authorized screen to user.
 */
export default defineComponent({
  name: "NotAuthorized",
  components: { HandRaisedIcon, LayoutAppContent },
  setup() {
    const user = useUser();
    const router = useRouter();

    watch(
      () => user.userRef,
      (newValue) => {
        if (newValue.value?.isAuthenticated) {
          console.warn("redirected to root");
          router.push({ path: "/" });
        }
      },
      { immediate: true, deep: true },
    );

    return {};
  },
});
</script>
