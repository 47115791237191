import { useAppSettingsStore } from "@/components/common/index.js";

// TODO: Learn about mocking Pinia and add tests.
/**
 * Generates the correct murn string for an entity based on their type and id
 *
 * @param {string} entityType Type of the entity
 * @param {string|number} entityId Id of the entity
 * @returns {string} Returns the murn string using the correct system identifier, entity type and id
 */
export function getMurnString(entityType: string, entityId: string | number) {
  const appSettingsStore = useAppSettingsStore();
  return `murn:${appSettingsStore.systemIdentifier}:${entityType}/${entityId}`;
}

/**
 * Compares a given murn string to what the murn string should be for a given entity
 *
 * @param {string} murn First murn string to be compared against
 * @param {string} entityType Type of the entity - used to derive second murn string to compared
 * @param {string|number} entityId Id of the entity - used to derive second murn string to compared
 * @returns {boolean} Returns true if the murn strings match
 */
export function compareToMurn(murn: string, entityType: string, entityId: string | number) {
  return getMurnString(entityType, entityId).toLowerCase() === murn.toLowerCase();
}
