import type { ListFilter, ListSortByArray, ListSortByField } from "@/components/list/genericList/index.js";
import { RemovableRef, useSessionStorage } from "@vueuse/core";
import { Ref, computed, ref } from "vue";
import type {
  IExecPaged,
  IPagingQuery,
  IPagingUrlConfig,
  QuerySortBy,
  QuerySortByProperty,
} from "@/utils/api/index.js";
// export interface IPageLink {
//   href: string;
//   rel: string[];
//   title: string | null;
// }

// export interface IPagingCounts {
//   take: number;
//   skip: number;
//   totalResultsCount: number;
//   count: number;
// }

// export interface IPagingRaw<Type> extends IPagingCounts {
//   href: string;
//   desc: boolean | null;
//   first: IPageLink;
//   last: IPageLink;
//   prev: IPageLink;
//   next: IPageLink;
//   value: Type[]; // Coming from the server. We remap this to _items in IPaging to reduce confusion with a Ref's value prop
// }

// export interface IPaging<Type> extends IPagingCounts {
//   href: string;
//   desc: boolean | null;
//   first: IPageLink;
//   last: IPageLink;
//   prev: IPageLink;
//   next: IPageLink;
//   _items: Type[]; // Coming from the server this will be "value". We remap it to reduce confusion with a Ref's value prop
// }

// export type PagedType<T> = T extends IPaging<infer S> ? S : never;

// export interface IPagingUrlConfig {
//   take?: number;
//   skip?: number;
// }

// export interface QuerySortBy {
//   [key: string]: QuerySortByProperty;
// }
// export interface QuerySortByProperty {
//   propertyName: string;
//   isDescending: boolean | undefined;
// }

// export interface IPagingQuery {
//   orderBy: QuerySortByProperty[];
//   take?: number;
//   skip?: number;
//   [key: string]: any;
// }

// export interface IPagingApiConfig {
//   pagingUrl?: string;
//   abortMethod?: (abort: Function) => void;
//   [key: string]: any;
// }

// export interface IPagingApiAndNotifierConfig extends IPagingApiConfig, INotifierConfig {}

// export type IExecPaged = (params: IPagingQuery, apiConfig: IPagingApiConfig) => Promise<any>;

/** Switches the `isDescending` property's value and then returns the item */
export const toggleSort = (item: QuerySortByProperty) => {
  switch (item.isDescending) {
    case true:
      item.isDescending = undefined;
      break;
    case false:
      item.isDescending = true;
      break;
    default:
      item.isDescending = false;
  }
  return item;
};

/**
 * @internal
 * If you don't want to use Session Storage for your sort by state, you may call this directly.
 */
export function getSortByDefaultMap(sortByDefault: ListSortByField[]) {
  const defaultMap: QuerySortBy = {};
  sortByDefault.forEach((x) => {
    defaultMap[x.field] = {
      propertyName: x.field,
      isDescending: Boolean(x.isDescending),
    };
  });
  return defaultMap;
}

/**
 * @internal
 * Load the default sortBy values from props, these are completely overridden (not merged) by localStorage when applicable.  */
export function getSortByMapRef(storeName: string, sortByDefault: ListSortByField[]) {
  return useSessionStorage<QuerySortBy>(storeName, getSortByDefaultMap(sortByDefault));
}

/**
 * @internal
 * If you don't want to use Session Storage for your sort by state, you may call this directly.
 */
export function queryToSortByMap(sortByDefault: ListSortByArray) {
  const defaultMap: QuerySortBy = {};

  sortByDefault.forEach((x) => {
    const isDescending = x?.[0] === "-";
    const field = isDescending ? x.slice(1) : x;
    defaultMap[field] = {
      propertyName: field,
      isDescending: isDescending,
    };
  });
  return defaultMap;
}

/** @internal */
export function routeQueryToSortByQuery(sortByDefault: ListSortByArray) {
  return sortByDefault.map((x) => {
    const isDescending = x?.[0] === "-";
    const field = isDescending ? x.slice(1) : x;
    return {
      propertyName: field,
      isDescending: isDescending,
    };
  });
}

/** Takes a paging URL and injects updated paging values (if specified). */
function configurePagedUrl(url: string, config: IPagingUrlConfig) {
  const takeRegx = /(take=[\d]+)/gi;
  const skipRegx = /(skip=[\d]+)/gi;

  //intentional !=
  if (config.take != null) {
    url.replace(takeRegx, `take=${config.take}`);
  }

  //intentional !=
  if (config.skip != null) {
    url.replace(skipRegx, `skip=${config.skip}`);
  }

  return url;
}

/** @returns Returns a debouncedSearch method, and query object. */
export function usePaging(config: {
  additionalFilters: { [key: string]: any };
  sortByMapRef: RemovableRef<QuerySortBy>;
  sortByQueryRef: Ref<ListSortByArray>;
  execPaged: IExecPaged;
  exec: (...args: any[]) => Promise<any>;
  defaultTake: number;
  defaultSort: ListSortByArray;
}) {
  const { additionalFilters, sortByMapRef, sortByQueryRef, exec, execPaged, defaultTake, defaultSort } = config;
  const query: IPagingQuery = {
    orderBy: [],
    ...additionalFilters,
  };

  // Initialize query orderBy using mapped sortBy values
  for (let key in sortByMapRef.value) {
    if (sortByMapRef.value[key].isDescending !== undefined) {
      query.orderBy.push(sortByMapRef.value[key]);
    }
  }

  /* 
  Tracking the totalResultsCount enables us to know whether someone else has added or removed a record from our result set.
  This is critical when paging as changes in the result set may cause results to be hidden or duplicated.
  When doing a paged query, if we detect that the totalResultsCount has changed, we start over.
  */
  let existingTotalResultsCount: number | undefined = undefined;
  let abortSearch: Function | null = null;
  let currentTimeout: NodeJS.Timeout | undefined = undefined;
  const isBouncingRef = ref(false);
  const debouncedSearch = (isPaged = false) => {
    clearTimeout(currentTimeout);

    if (isPaged) {
      abortSearch?.();
      return execPaged(query, {
        abortMethod: (abort) => {
          abortSearch = abort;
        },
      })
        .then((result) => {
          if (result?.totalResultsCount !== undefined && result?.totalResultsCount !== existingTotalResultsCount) {
            // Force rerun
            abortSearch?.();
            query.take = defaultTake;
            query.skip = 0;
            return exec(query, {
              abortMethod: (abort: Function) => {
                abortSearch = abort;
              },
            }).then((result) => {
              existingTotalResultsCount = result?.totalResultsCount;

              return result;
            }); //errors are caught below.
          }
          return result;
        })
        .catch((error) => {
          existingTotalResultsCount = undefined;
        });
    } else {
      isBouncingRef.value = true;
      currentTimeout = setTimeout(async () => {
        abortSearch?.();
        query.take = defaultTake;
        query.skip = 0;
        exec(query, {
          abortMethod: (abort: Function) => {
            abortSearch = abort;
          },
        })
          .then((result) => {
            existingTotalResultsCount = result?.totalResultsCount;

            return result;
          })
          .catch((error) => {
            existingTotalResultsCount = undefined;
          });

        isBouncingRef.value = false;
      }, 150);
    }
  };

  const filterState: Ref<{ [key: string]: ListFilter }> = ref({});
  const isDefaultState = computed(() => {
    // This only works because we have a simple array of strings.
    let result = JSON.stringify(defaultSort) === JSON.stringify(sortByQueryRef?.value);
    if (!result) return result;

    for (let key in filterState.value) {
      if (!filterState.value[key].isDefault) {
        result = false;
        break;
      }
    }
    return result;
  });

  return { debouncedSearch, isBouncingRef, query, filterState, isDefaultState };
}
