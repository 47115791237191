let nextNumber = 1;

/**
 * Provides a string having an incremented numeric suffix
 * @param {string} [prefix] Optional prefix to add to returned unique id
 * @returns prefix_number or _number
 */
export function getUniqueId(prefix?: string) {
  // const crypto = window.crypto;
  // const [randomNumber] = crypto.getRandomValues(new Uint32Array(1));
  return (prefix ? prefix : "") + "_" + nextNumber++;
}

/**
 * This is exclusively for testing to enable repeatable IDs for snapshots. Do not import/export this outside of tests.
 */
export function resetUniqueId() {
  nextNumber = 1;
}
