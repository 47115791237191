<template>
  <!-- <BaseButton label="test from inner" @click="testWeights"></BaseButton> -->

  <div
    class="mb-2 flex items-center justify-between bg-neutral-200 py-1.5 pl-2.5 pr-2 md:mb-0 md:bg-neutral-100"
    :class="{ 'rounded-t-lg': index === 0 }"
  >
    <div>{{ leftHeader ?? frozenName }}</div>
    <div class="flex items-center pr-2">
      <span v-if="numericRollup">{{ sumWeightsByMiddle(index, outerIndex) + " " + rightHeader }} </span>
      <span v-else> {{ rightHeader }}</span>

      <!-- button index is for middle (material)  -->
      <BaseButton
        class="ml-5 h-8 w-8 px-1"
        icon
        borderless
        variant="danger-subtle"
        :label="removeLabel"
        :disabled="index === 0 && outerLengths.length > 0 && outerIndex > -1 && outerLengths[outerIndex] === 1"
        @click="activateClick(index)"
      >
        <!-- todo - always disabled on 0? -->
        <template #iconBefore><XMarkIcon class="w-5" /></template
      ></BaseButton>
    </div>
  </div>
  <slot></slot>
</template>

<script lang="ts">
import { XMarkIcon } from "@heroicons/vue/24/solid";
import { BaseButton } from "@/components/button";
import { PropType, defineComponent, inject, unref } from "vue";
import injectionSymbols from "@/components/schema/injectionSymbols";
/** adjusts styling for when the repeater is nested. used inside inner repeater */
export default defineComponent({
  name: "NestedRepeaterHeader",
  emits: [],
  props: {
    /** left side header content  */
    leftHeader: {
      type: String,
    },
    /** right side header content  */
    rightHeader: {
      type: String,
    },
    /** the name of this repeater. If provided this will override the middle and outer repeater names. (used for non nested) */
    name: {
      type: String,
    },
    /** This is the name that was given to the entire repeater (passed as the name value to the outer repeater). used to make unqiue ids. not to be confused with name for outer, which is used to determine the  third repeater value (ex. county) - required if nested */
    repeaterName: {
      type: String,
      default: "OuterRepeater",
    },
    /** the name of this repeater if triggered/created by the outer repeater */
    nameForOuter: {
      type: String,
      default: "OuterRepeater",
    },
    /** the name of this repeater if triggered/created by the middle repeater */
    nameForMiddle: {
      type: String,
      default: "MiddleRepeater",
    },
    removeLabel: {
      type: String,
      default: "Remove",
    },
    addLabel: {
      type: String,
      default: "Add Item",
    },
    nested: {
      type: Boolean,
      default: false,
    },
    /** this is the index of the middle repeater item the inner repeater sits inside. (example - the index of the material in respect to the county) */
    index: {
      type: Number,
    },
    /* used to determined if should be disabled - req'd if nested - (used in the NestedRepeaterHeader component) */
    // length: {
    //   type: Number,
    // },
    // default is false because we are not using a cameFromOuter prop -> therefore assumed if we don't specify it came from the middle repeater, it would have come from the outer repeater
    // should we add a cameFromOuter?
    cameFromMiddle: {
      type: Boolean,
      default: false,
    },
    /** this is an array of lengths for the the outer repeater (example - array of number of materials per county) - used in determining disabled */
    outerLengths: {
      type: Array as PropType<any[]>,
      default: [],
    },
    /** used to grab the corresponding length from the outerLengths prop for whichever outer repeater this is sitting inside  */
    outerIndex: {
      type: Number,
      default: -1,
    },
    //TODO
    // /** function to perform when something is appended  */
    // onAppend: {
    //   type: Function,
    // },
    // /** function to perform when something is removed  */
    // onRemove: {
    //   type: Function,
    // },
    //todo - props / customizations
    draggable: {
      type: Boolean,
      default: false,
    },
    downControl: {
      type: Boolean,
      default: false,
    },
    upControl: {
      type: Boolean,
      default: false,
    },

    numericRollup: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    function activateClick(index: any) {
      let myButton = document.getElementById(
        "hiddenRemoveButton_" + props.outerIndex + "_" + props.repeaterName + "_" + index,
      );
      myButton?.click();
    }
    // const frozenName = props.parentName ? unref(props.parentName) : unref(props.name);
    const frozenName = props.cameFromMiddle === true ? unref(props.nameForMiddle) : unref(props.nameForOuter);

    const { sumWeightsByMiddle } = inject(injectionSymbols.SumInsideMiddleHelperKey) as any; //todo

    // function testWeights() {
    //   console.log(sumWeightsByMiddle(0));
    // }

    return { activateClick, frozenName, sumWeightsByMiddle };
  },
  components: {
    XMarkIcon,
    BaseButton,
  },
});
</script>
