import { enumToDropdown } from "@/utils/helpers/index.js";

export type Value<M> = M extends Map<any, infer V> ? V : never;
export type Key<M> = M extends Map<infer K, any> ? K : never;

export type EnumValueOrUndefined<T extends Key<M> | undefined, M extends Map<Key<M>, Value<M>>> = T extends Key<M>
  ? Value<M>
  : undefined;

/**
 * Accepts a map and returns a type safe object that can be used to access the individual enum values, full dropdown, or full map.
 *
 * @param {M} enumMap Map to be converted into enum object.
 * @returns {Object} Object with get and toDropdown methods and a enums property to access the original map
 */
export const createEnum = <M extends Map<Key<M> extends string ? Key<M> : never, Value<M>>>(enumMap: M) => {
  /**
   * Accepts a key and returns the enum value or undefined.
   *
   * @param {T} key
   */
  function get<T extends (Key<M> extends string ? Key<M> : never) | undefined>(key: T): EnumValueOrUndefined<T, M> {
    if (key === undefined) return undefined as EnumValueOrUndefined<T, M>;
    const status = enumMap.get(key);
    if (!status) throw new Error("Key not found");
    return status as EnumValueOrUndefined<T, M>;
  }

  const result = {
    get,
    /**
     * Passes the enum map to the {@link enumToDropdown} function to return a FormKit compatible array.
     *
     * @param {any} [id] Optional id to insert as the first record along with a description
     * @param {string} [description] Optional description to insert as the first record along with an id
     */
    toDropDown(id?: any, description?: string) {
      return enumToDropdown(enumMap, id, description);
    },
    enums: enumMap,
  };
  return result;
};
