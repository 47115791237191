import type { FormKitNode } from '@formkit/core'

/**
  * @privateRemarks
  * This file was generated by the FormKit CLI and should not be manually
  * edited unless you’d like to "eject" from the CLI’s ability to update it.
  *
  * @checksum - 414989b0c8c92a6926c83954a5610663f8f65dbe4a541a99abd806ae1fbf825e
  * @variables - radius=rounded-md,spacing=2,scale=base
  * @theme - mui
  **/

 /**
  * This is the theme function itself, it should be imported and used as the
  * config.rootClasses function. For example:
  *
  * ```js
  * import { theme } from './formkit.theme'
  * import { defineFormKitConfig } from '@formkit/vue'
  *
  * export default defineFormKitConfig({
  *   config: {
  *     rootClasses: theme
  *   }
  * })
  * ```
  **/
 export function rootClasses (sectionName: string, node: FormKitNode): Record<string, boolean> {
   const key = `${node.props.type}__${sectionName}`
   const semanticKey = `formkit-${sectionName}`
   const familyKey = node.props.family ? `family:${node.props.family}__${sectionName}` : ''
   const memoKey = `${key}__${familyKey}`
   if (!(memoKey in classes)) {
     const sectionClasses = classes[key] ?? globals[sectionName] ?? {}
     sectionClasses[semanticKey] = true
     if (familyKey in classes) {
       classes[memoKey] = { ...classes[familyKey],  ...sectionClasses }
     } else {
       classes[memoKey] = sectionClasses
     }
   }
   return classes[memoKey] ?? { [semanticKey]: true }
 }

/**
 * These classes have already been merged with globals using tailwind-merge
 * and are ready to be used directly in the theme.
 **/
const classes: Record<string, Record<string, boolean>> = {
  "family:button__outer": {
    "group": true,
    "mb-4": true,
    "data-[disabled]:opacity-50": true,
    "data-[disabled]:select-none": true,
    "data-[disabled]:pointer-events-none": true
  },
  "family:button__wrapper": {
    "mb-1": true
  },
  "family:button__label": {
    "block": true,
    "mb-1": true,
    "font-medium": true,
    "text-sm": true,
    "text-neutral-700": true,
    "dark:text-neutral-100": true
  },
  "family:button__prefixIcon": {
    "flex": true,
    "shrink-0": true,
    "items-center": true,
    "text-base": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "[&>svg]:w-full": true,
    "text-neutral-100": true,
    "dark:text-neutral-100": true,
    "-ml-1": true,
    "mr-2": true
  },
  "family:button__suffixIcon": {
    "flex": true,
    "items-center": true,
    "text-base": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "shrink-0": true,
    "[&>svg]:w-full": true,
    "-mr-1": true,
    "ml-2": true,
    "text-neutral-100": true,
    "dark:text-neutral-100": true
  },
  "family:button__input": {
    "inline-flex": true,
    "items-center": true,
    "bg-sky-600": true,
    "text-white": true,
    "text-sm": true,
    "font-normal": true,
    "py-3": true,
    "px-6": true,
    "rounded-md": true,
    "focus-visible:outline-2": true,
    "focus-visible:outline-sky-600": true,
    "focus-visible:outline-offset-2": true,
    "disabled:bg-neutral-400": true,
    "group-data-[disabled]:cursor-not-allowed": true,
    "formkit-loading:before:w-4": true,
    "formkit-loading:before:h-4": true,
    "formkit-loading:before:mr-2": true,
    "formkit-loading:before:border-2": true,
    "formkit-loading:before:rounded-3xl": true,
    "formkit-loading:before:border-white": true,
    "formkit-loading:before:animate-spin": true,
    "dark:border-neutral-100": true,
    "dark:text-neutral-100": true
  },
  "family:button__help": {
    "text-xs": true,
    "text-neutral-500": true,
    "whitespace-pre-line": true,
    "dark:text-neutral-100": true
  },
  "family:button__messages": {
    "list-none": true,
    "p-0": true,
    "mt-1": true,
    "mb-0": true
  },
  "family:button__message": {
    "text-red-600": true,
    "mb-1": true,
    "text-sm": true,
    "dark:text-red-400": true
  },
  "family:box__outer": {
    "group": true,
    "mb-4": true,
    "data-[disabled]:opacity-50": true,
    "data-[disabled]:select-none": true,
    "data-[disabled]:pointer-events-none": true
  },
  "family:box__wrapper": {
    "flex": true,
    "items-center": true,
    "mb-1": true,
    "group-data-[multiple]:mb-0": true
  },
  "family:box__fieldset": {
    "border": true,
    "border-neutral-400": true,
    "rounded-md": true,
    "px-2": true,
    "pb-1": true
  },
  "family:box__help": {
    "text-xs": true,
    "text-neutral-500": true,
    "whitespace-pre-line": true,
    "dark:text-neutral-100": true,
    "mb-2": true,
    "mt-1.5": true
  },
  "family:box__inner": {
    "": true
  },
  "family:box__input": {
    "appearance-none": true,
    "border-none": true,
    "focus:outline-none": true,
    "focus:ring-0": true,
    "focus:shadow-none": true,
    "sm:text-sm": true,
    "text-black": true,
    "placeholder:italic": true,
    "transition-colors": true,
    "[color-scheme:light]": true,
    "dark:[color-scheme:dark]": true,
    "dark:placeholder:text-neutral-500": true,
    "dark:text-neutral-100": true,
    "absolute": true,
    "w-0": true,
    "h-0": true,
    "overflow-hidden": true,
    "opacity-0": true,
    "peer": true
  },
  "family:box__decorator": {
    "block": true,
    "relative": true,
    "h-5": true,
    "w-5": true,
    "mr-2": true,
    "bg-white": true,
    "bg-gradient-to-b": true,
    "from-transparent": true,
    "to-neutral-200": true,
    "ring-1": true,
    "ring-neutral-400": true,
    "peer-checked:ring-sky-500": true,
    "text-transparent": true,
    "peer-focus:ring-2": true,
    "peer-checked:text-sky-500": true,
    "peer-disabled:cursor-not-allowed": true,
    "dark:border-neutral-100": true
  },
  "family:box__decoratorIcon": {
    "flex": true,
    "p-[3px]": true,
    "w-full": true,
    "h-full": true,
    "absolute": true,
    "top-1/2": true,
    "left-1/2": true,
    "-translate-y-1/2": true,
    "-translate-x-1/2": true
  },
  "family:box__options": {
    "": true
  },
  "family:box__option": {
    "mb-1.5": true,
    "last:mb-0": true,
    "data-[disabled]:opacity-50": true,
    "group-data-[disabled]:data-[disabled]:opacity-100": true
  },
  "family:box__label": {
    "text-sm": true,
    "mt-1": true,
    "select-none": true,
    "text-neutral-700": true,
    "dark:text-neutral-100": true
  },
  "family:box__messages": {
    "list-none": true,
    "p-0": true,
    "mt-1": true,
    "mb-0": true
  },
  "family:box__message": {
    "text-red-600": true,
    "mb-1": true,
    "text-sm": true,
    "dark:text-red-400": true
  },
  "family:text__outer": {
    "group": true,
    "mb-4": true,
    "data-[disabled]:opacity-50": true,
    "data-[disabled]:select-none": true,
    "data-[disabled]:pointer-events-none": true
  },
  "family:text__label": {
    "block": true,
    "mb-1": true,
    "font-medium": true,
    "text-sm": true,
    "text-neutral-700": true,
    "dark:text-neutral-100": true
  },
  "family:text__prefixIcon": {
    "flex": true,
    "shrink-0": true,
    "items-center": true,
    "text-base": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "[&>svg]:w-full": true,
    "ml-2": true,
    "mr-0": true,
    "my-auto": true,
    "text-neutral-700": true,
    "dark:text-neutral-100": true
  },
  "family:text__suffixIcon": {
    "flex": true,
    "items-center": true,
    "text-base": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "shrink-0": true,
    "[&>svg]:w-full": true,
    "mr-2": true,
    "ml-1": true,
    "my-auto": true,
    "text-neutral-700": true,
    "dark:text-neutral-100": true
  },
  "family:text__inner": {
    "flex": true,
    "items-center": true,
    "w-full": true,
    "ring-1": true,
    "ring-neutral-300": true,
    "enabled:hover:ring-neutral-400": true,
    "focus-within:ring-neutral-400": true,
    "focus-within:ring-2": true,
    "[&>label:first-child]:focus-within:text-sky-500": true,
    "rounded-md": true,
    "mb-1": true,
    "dark:border-neutral-100": true,
    "group-data-[disabled]:!cursor-not-allowed": true,
    "data-[disabled]:!cursor-not-allowed": true,
    "disabled:!cursor-not-allowed": true
  },
  "family:text__input": {
    "appearance-none": true,
    "focus:outline-none": true,
    "focus:ring-0": true,
    "focus:shadow-none": true,
    "sm:text-sm": true,
    "placeholder:italic": true,
    "transition-colors": true,
    "[color-scheme:light]": true,
    "dark:[color-scheme:dark]": true,
    "dark:placeholder:text-neutral-500": true,
    "bg-transparent": true,
    "w-full": true,
    "px-3": true,
    "py-2": true,
    "border-none": true,
    "text-base": true,
    "text-neutral-700": true,
    "placeholder-neutral-400": true,
    "hover:border-neutral-400": true,
    "dark:text-neutral-100": true,
    "data-[disabled]:cursor-default": true
  },
  "family:text__help": {
    "text-xs": true,
    "text-neutral-500": true,
    "whitespace-pre-line": true,
    "dark:text-neutral-100": true
  },
  "family:text__messages": {
    "list-none": true,
    "p-0": true,
    "mt-1": true,
    "mb-0": true
  },
  "family:text__message": {
    "text-red-600": true,
    "mb-1": true,
    "text-sm": true,
    "dark:text-red-400": true
  },
  "family:dropdown__outer": {
    "group": true,
    "mb-4": true,
    "data-[disabled]:opacity-50": true,
    "data-[disabled]:select-none": true,
    "data-[disabled]:pointer-events-none": true
  },
  "family:dropdown__wrapper": {
    "": true
  },
  "family:dropdown__label": {
    "block": true,
    "mb-1": true,
    "font-medium": true,
    "text-sm": true,
    "text-neutral-700": true,
    "dark:text-neutral-100": true
  },
  "family:dropdown__inner": {
    "relative": true,
    "flex": true,
    "ring-1": true,
    "ring-neutral-300": true,
    "focus-within:ring-neutral-400": true,
    "focus-within:ring-2": true,
    "rounded-md": true,
    "mb-1": true,
    "data-[disabled]:focus-within:ring-neutral-300": true,
    "disabled:focus-within:ring-1": true,
    "[&>span:first-child]:focus-within:text-sky-500": true,
    "dark:border-white": true
  },
  "family:dropdown__prefixIcon": {
    "flex": true,
    "shrink-0": true,
    "items-center": true,
    "text-base": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "[&>svg]:w-full": true,
    "ml-2": true,
    "mr-0": true,
    "my-auto": true,
    "text-neutral-700": true,
    "dark:text-neutral-100": true
  },
  "family:dropdown__suffixIcon": {
    "flex": true,
    "items-center": true,
    "text-base": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "shrink-0": true,
    "[&>svg]:w-full": true,
    "mr-2": true,
    "ml-1": true,
    "my-auto": true,
    "text-neutral-700": true,
    "dark:text-neutral-100": true
  },
  "family:dropdown__input": {
    "appearance-none": true,
    "border-none": true,
    "focus:outline-none": true,
    "focus:ring-0": true,
    "focus:shadow-none": true,
    "sm:text-sm": true,
    "text-black": true,
    "placeholder:italic": true,
    "transition-colors": true,
    "[color-scheme:light]": true,
    "dark:[color-scheme:dark]": true,
    "dark:placeholder:text-neutral-500": true,
    "bg-transparent": true,
    "w-full": true,
    "px-3": true,
    "py-2": true,
    "dark:text-neutral-100": true
  },
  "family:dropdown__selection": {
    "flex": true,
    "w-full": true,
    "dark:text-neutral-100": true
  },
  "family:dropdown__listboxButton": {
    "flex": true,
    "w-10": true,
    "self-stretch": true,
    "justify-center": true,
    "mx-auto": true,
    "dark:text-neutral-100": true
  },
  "family:dropdown__selectIcon": {
    "flex": true,
    "self-stretch": true,
    "grow-0": true,
    "shrink-0": true,
    "w-10": true,
    "[&>svg]:w-full": true,
    "[&>svg]:m-auto": true,
    "[&>svg]:max-w-[1rem]": true,
    "[&>svg]:max-h-4": true
  },
  "family:dropdown__closeIcon": {
    "flex": true,
    "self-stretch": true,
    "grow-0": true,
    "shrink-0": true,
    "w-10": true,
    "[&>svg]:w-full": true,
    "[&>svg]:m-auto": true,
    "[&>svg]:max-w-[1rem]": true,
    "[&>svg]:max-h-4": true
  },
  "family:dropdown__loaderIcon": {
    "inline-flex": true,
    "items-center": true,
    "w-4": true,
    "text-neutral-600": true,
    "animate-spin": true,
    "dark:text-neutral-100": true,
    "ml-auto": true
  },
  "family:dropdown__dropdownWrapper": {
    "my-2": true,
    "w-full": true,
    "shadow-lg": true,
    "rounded-md": true,
    "[&::-webkit-scrollbar]:hidden": true
  },
  "family:dropdown__listbox": {
    "bg-white": true,
    "shadow-lg": true,
    "rounded-md": true,
    "border": true,
    "overflow-hidden": true,
    "dark:bg-neutral-900": true,
    "dark:border-neutral-100": true
  },
  "family:dropdown__listitem": {
    "pl-7": true,
    "relative": true,
    "text-neutral-700": true,
    "hover:bg-neutral-300": true,
    "data-[is-active]:bg-neutral-300": true,
    "aria-selected:bg-sky-600": true,
    "aria-selected:text-white": true,
    "data-[is-active]:aria-selected:bg-sky-700": true,
    "dark:text-neutral-100": true,
    "dark:data-[is-active]:bg-neutral-700": true,
    "dark:data-[is-active]:before:ring-neutral-400": true
  },
  "family:dropdown__selectedIcon": {
    "block": true,
    "absolute": true,
    "top-1/2": true,
    "left-2": true,
    "w-3": true,
    "-translate-y-1/2": true,
    "dark:text-neutral-400": true
  },
  "family:dropdown__option": {
    "p-2.5": true
  },
  "family:dropdown__optionLoading": {
    "pl-2.5": true,
    "text-neutral-400": true
  },
  "family:dropdown__emptyMessageInner": {
    "flex": true,
    "items-center": true,
    "justify-center": true,
    "text-sm": true,
    "p-2": true,
    "text-center": true,
    "w-full": true,
    "text-neutral-500": true,
    "[&>span]:mr-3": true,
    "[&>span]:ml-0": true,
    "dark:text-neutral-200": true
  },
  "family:dropdown__help": {
    "text-xs": true,
    "text-neutral-500": true,
    "whitespace-pre-line": true,
    "dark:text-neutral-100": true
  },
  "family:dropdown__messages": {
    "list-none": true,
    "p-0": true,
    "mt-1": true,
    "mb-0": true
  },
  "family:dropdown__message": {
    "text-red-600": true,
    "mb-1": true,
    "text-sm": true,
    "dark:text-red-400": true
  },
  "family:dropdown__loadMoreInner": {
    "flex": true,
    "items-center": true,
    "justify-center": true,
    "text-sm": true,
    "p-2": true,
    "text-center": true,
    "w-full": true,
    "text-sky-500": true,
    "formkit-loading:text-neutral-500": true,
    "cursor-pointer": true,
    "[&>span]:mr-3": true,
    "[&>span]:ml-0": true
  },
  "family:dropdown__placeholder": {
    "px-2.5": true,
    "text-neutral-400": true,
    "italic": true,
    "text-sm": true
  },
  "family:dropdown__selector": {
    "flex": true,
    "w-full": true,
    "justify-between": true,
    "items-center": true,
    "min-h-[2.25em]": true,
    "[&u]": true,
    "cursor-default": true
  },
  "family:date__inner": {
    "flex": true,
    "items-center": true,
    "max-w-md": true,
    "ring-1": true,
    "ring-neutral-400": true,
    "focus-within:ring-neutral-400": true,
    "focus-within:ring-2": true,
    "[&>label:first-child]:focus-within:text-sky-500": true,
    "rounded": true,
    "mb-1": true
  },
  "family:date__input": {
    "appearance-none": true,
    "focus:outline-none": true,
    "focus:ring-0": true,
    "focus:shadow-none": true,
    "sm:text-sm": true,
    "placeholder:italic": true,
    "transition-colors": true,
    "[color-scheme:light]": true,
    "dark:[color-scheme:dark]": true,
    "dark:placeholder:text-neutral-500": true,
    "dark:text-neutral-100": true,
    "w-full": true,
    "px-3": true,
    "py-2": true,
    "border-none": true,
    "text-neutral-700": true,
    "placeholder-neutral-400": true
  },
  "button__outer": {
    "group": true,
    "mb-4": true,
    "data-[disabled]:opacity-50": true,
    "data-[disabled]:select-none": true,
    "data-[disabled]:pointer-events-none": true
  },
  "button__wrapper": {
    "": true
  },
  "button__label": {
    "block": true,
    "mb-1": true,
    "font-medium": true,
    "text-sm": true,
    "text-neutral-700": true,
    "dark:text-neutral-100": true
  },
  "button__prefixIcon": {
    "flex": true,
    "shrink-0": true,
    "items-center": true,
    "text-base": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "[&>svg]:w-full": true
  },
  "button__suffixIcon": {
    "flex": true,
    "items-center": true,
    "text-base": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "shrink-0": true,
    "[&>svg]:w-full": true
  },
  "button__input": {
    "appearance-none": true,
    "border-none": true,
    "focus:outline-none": true,
    "focus:ring-0": true,
    "focus:shadow-none": true,
    "sm:text-sm": true,
    "text-black": true,
    "placeholder:italic": true,
    "transition-colors": true,
    "[color-scheme:light]": true,
    "dark:[color-scheme:dark]": true,
    "dark:placeholder:text-neutral-500": true,
    "dark:text-neutral-100": true
  },
  "button__help": {
    "text-xs": true,
    "text-neutral-500": true,
    "whitespace-pre-line": true,
    "dark:text-neutral-100": true
  },
  "button__messages": {
    "list-none": true,
    "p-0": true,
    "mt-1": true,
    "mb-0": true
  },
  "button__message": {
    "text-red-600": true,
    "mb-1": true,
    "text-sm": true,
    "dark:text-red-400": true
  },
  "checkbox__outer": {
    "group": true,
    "mb-4": true,
    "data-[disabled]:opacity-50": true,
    "data-[disabled]:select-none": true,
    "data-[disabled]:pointer-events-none": true
  },
  "checkbox__wrapper": {
    "": true
  },
  "checkbox__fieldset": {
    "border": true,
    "border-neutral-400": true,
    "rounded-md": true,
    "px-2": true,
    "pb-1": true
  },
  "checkbox__legend": {
    "font-bold": true,
    "text-md": true,
    "text-neutral-600": true,
    "dark:text-neutral-100": true
  },
  "checkbox__help": {
    "text-xs": true,
    "text-neutral-500": true,
    "whitespace-pre-line": true,
    "dark:text-neutral-100": true
  },
  "checkbox__inner": {
    "": true
  },
  "checkbox__options": {
    "": true
  },
  "checkbox__option": {
    "": true
  },
  "checkbox__decorator": {
    "rounded-md": true
  },
  "checkbox__label": {
    "block": true,
    "mb-1": true,
    "font-medium": true,
    "text-sm": true,
    "text-neutral-700": true,
    "dark:text-neutral-100": true
  },
  "checkbox__optionHelp": {
    "text-neutral-500": true,
    "text-xs": true
  },
  "checkbox__messages": {
    "list-none": true,
    "p-0": true,
    "mt-1": true,
    "mb-0": true
  },
  "checkbox__message": {
    "text-red-600": true,
    "mb-1": true,
    "text-sm": true,
    "dark:text-red-400": true
  },
  "color__outer": {
    "group": true,
    "mb-4": true,
    "data-[disabled]:opacity-50": true,
    "data-[disabled]:select-none": true,
    "data-[disabled]:pointer-events-none": true
  },
  "color__wrapper": {
    "": true
  },
  "color__label": {
    "block": true,
    "mb-1": true,
    "font-medium": true,
    "text-sm": true,
    "text-neutral-700": true,
    "dark:text-neutral-100": true
  },
  "color__prefixIcon": {
    "flex": true,
    "shrink-0": true,
    "items-center": true,
    "text-base": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "[&>svg]:w-full": true
  },
  "color__suffixIcon": {
    "flex": true,
    "items-center": true,
    "text-base": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "shrink-0": true,
    "[&>svg]:w-full": true
  },
  "color__inner": {
    "flex": true,
    "max-w-[5.5em]": true,
    "w-full": true,
    "prefix-icon:max-w-[7.5em]": true,
    "suffix-icon:prefix-icon:max-w-[10em]": true
  },
  "color__input": {
    "focus:outline-none": true,
    "focus:ring-0": true,
    "focus:shadow-none": true,
    "sm:text-sm": true,
    "text-black": true,
    "placeholder:italic": true,
    "transition-colors": true,
    "[color-scheme:light]": true,
    "dark:[color-scheme:dark]": true,
    "dark:placeholder:text-neutral-500": true,
    "dark:text-neutral-100": true,
    "appearance-none": true,
    "w-full": true,
    "cursor-pointer": true,
    "disabled:cursor-default": true,
    "border-none": true,
    "rounded-md": true,
    "p-0": true,
    "m-0": true,
    "bg-transparent": true,
    "[&::-webkit-color-swatch-wrapper]:p-0": true,
    "[&::-webkit-color-swatch]:border-none": true
  },
  "color__help": {
    "text-xs": true,
    "text-neutral-500": true,
    "whitespace-pre-line": true,
    "dark:text-neutral-100": true
  },
  "color__messages": {
    "list-none": true,
    "p-0": true,
    "mt-1": true,
    "mb-0": true
  },
  "color__message": {
    "text-red-600": true,
    "mb-1": true,
    "text-sm": true,
    "dark:text-red-400": true
  },
  "date__outer": {
    "group": true,
    "mb-4": true,
    "data-[disabled]:opacity-50": true,
    "data-[disabled]:select-none": true,
    "data-[disabled]:pointer-events-none": true
  },
  "date__wrapper": {
    "": true
  },
  "date__label": {
    "block": true,
    "mb-1": true,
    "font-medium": true,
    "text-sm": true,
    "text-neutral-700": true,
    "dark:text-neutral-100": true
  },
  "date__prefixIcon": {
    "flex": true,
    "shrink-0": true,
    "items-center": true,
    "text-base": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "[&>svg]:w-full": true
  },
  "date__suffixIcon": {
    "flex": true,
    "items-center": true,
    "text-base": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "shrink-0": true,
    "[&>svg]:w-full": true
  },
  "date__inner": {
    "": true
  },
  "date__input": {
    "appearance-none": true,
    "border-none": true,
    "focus:outline-none": true,
    "focus:ring-0": true,
    "focus:shadow-none": true,
    "sm:text-sm": true,
    "text-black": true,
    "placeholder:italic": true,
    "transition-colors": true,
    "[color-scheme:light]": true,
    "dark:[color-scheme:dark]": true,
    "dark:placeholder:text-neutral-500": true,
    "dark:text-neutral-100": true
  },
  "date__help": {
    "text-xs": true,
    "text-neutral-500": true,
    "whitespace-pre-line": true,
    "dark:text-neutral-100": true
  },
  "date__messages": {
    "list-none": true,
    "p-0": true,
    "mt-1": true,
    "mb-0": true
  },
  "date__message": {
    "text-red-600": true,
    "mb-1": true,
    "text-sm": true,
    "dark:text-red-400": true
  },
  "datetime-local__outer": {
    "group": true,
    "mb-4": true,
    "data-[disabled]:opacity-50": true,
    "data-[disabled]:select-none": true,
    "data-[disabled]:pointer-events-none": true
  },
  "datetime-local__wrapper": {
    "": true
  },
  "datetime-local__label": {
    "block": true,
    "mb-1": true,
    "font-medium": true,
    "text-sm": true,
    "text-neutral-700": true,
    "dark:text-neutral-100": true
  },
  "datetime-local__prefixIcon": {
    "flex": true,
    "shrink-0": true,
    "items-center": true,
    "text-base": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "[&>svg]:w-full": true
  },
  "datetime-local__suffixIcon": {
    "flex": true,
    "items-center": true,
    "text-base": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "shrink-0": true,
    "[&>svg]:w-full": true
  },
  "datetime-local__inner": {
    "": true
  },
  "datetime-local__input": {
    "appearance-none": true,
    "border-none": true,
    "focus:outline-none": true,
    "focus:ring-0": true,
    "focus:shadow-none": true,
    "sm:text-sm": true,
    "text-black": true,
    "placeholder:italic": true,
    "transition-colors": true,
    "[color-scheme:light]": true,
    "dark:[color-scheme:dark]": true,
    "dark:placeholder:text-neutral-500": true,
    "dark:text-neutral-100": true
  },
  "datetime-local__help": {
    "text-xs": true,
    "text-neutral-500": true,
    "whitespace-pre-line": true,
    "dark:text-neutral-100": true
  },
  "datetime-local__messages": {
    "list-none": true,
    "p-0": true,
    "mt-1": true,
    "mb-0": true
  },
  "datetime-local__message": {
    "text-red-600": true,
    "mb-1": true,
    "text-sm": true,
    "dark:text-red-400": true
  },
  "email__outer": {
    "group": true,
    "mb-4": true,
    "data-[disabled]:opacity-50": true,
    "data-[disabled]:select-none": true,
    "data-[disabled]:pointer-events-none": true
  },
  "email__wrapper": {
    "": true
  },
  "email__label": {
    "block": true,
    "mb-1": true,
    "font-medium": true,
    "text-sm": true,
    "text-neutral-700": true,
    "dark:text-neutral-100": true
  },
  "email__prefixIcon": {
    "flex": true,
    "shrink-0": true,
    "items-center": true,
    "text-base": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "[&>svg]:w-full": true
  },
  "email__suffixIcon": {
    "flex": true,
    "items-center": true,
    "text-base": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "shrink-0": true,
    "[&>svg]:w-full": true
  },
  "email__inner": {
    "": true
  },
  "email__input": {
    "appearance-none": true,
    "border-none": true,
    "focus:outline-none": true,
    "focus:ring-0": true,
    "focus:shadow-none": true,
    "sm:text-sm": true,
    "text-black": true,
    "placeholder:italic": true,
    "transition-colors": true,
    "[color-scheme:light]": true,
    "dark:[color-scheme:dark]": true,
    "dark:placeholder:text-neutral-500": true,
    "dark:text-neutral-100": true
  },
  "email__help": {
    "text-xs": true,
    "text-neutral-500": true,
    "whitespace-pre-line": true,
    "dark:text-neutral-100": true
  },
  "email__messages": {
    "list-none": true,
    "p-0": true,
    "mt-1": true,
    "mb-0": true
  },
  "email__message": {
    "text-red-600": true,
    "mb-1": true,
    "text-sm": true,
    "dark:text-red-400": true
  },
  "file__outer": {
    "group": true,
    "mb-4": true,
    "data-[disabled]:opacity-50": true,
    "data-[disabled]:select-none": true,
    "data-[disabled]:pointer-events-none": true
  },
  "file__wrapper": {
    "": true
  },
  "file__label": {
    "block": true,
    "mb-1": true,
    "font-medium": true,
    "text-sm": true,
    "text-neutral-700": true,
    "dark:text-neutral-100": true
  },
  "file__prefixIcon": {
    "flex": true,
    "shrink-0": true,
    "items-center": true,
    "text-base": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "[&>svg]:w-full": true
  },
  "file__suffixIcon": {
    "flex": true,
    "items-center": true,
    "text-base": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "shrink-0": true,
    "[&>svg]:w-full": true
  },
  "file__inner": {
    "relative": true,
    "cursor-pointer": true,
    "formkit-multiple:[&>button]:absolute": true
  },
  "file__input": {
    "appearance-none": true,
    "border-none": true,
    "focus:outline-none": true,
    "focus:ring-0": true,
    "focus:shadow-none": true,
    "sm:text-sm": true,
    "placeholder:italic": true,
    "transition-colors": true,
    "[color-scheme:light]": true,
    "dark:[color-scheme:dark]": true,
    "dark:placeholder:text-neutral-500": true,
    "dark:text-neutral-100": true,
    "cursor-pointer": true,
    "text-transparent": true,
    "absolute": true,
    "top-0": true,
    "right-0": true,
    "left-0": true,
    "bottom-0": true,
    "opacity-0": true,
    "z-[2]": true
  },
  "file__fileList": {
    "shrink": true,
    "grow": true,
    "peer": true,
    "px-3": true,
    "py-2": true,
    "formkit-multiple:data-[has-multiple=\"true\"]:mb-6": true
  },
  "file__fileItemIcon": {
    "w-4": true,
    "mr-2": true,
    "shrink-0": true
  },
  "file__fileItem": {
    "flex": true,
    "items-center": true,
    "text-neutral-800": true,
    "mb-1": true,
    "last:mb-0": true,
    "dark:text-neutral-100": true
  },
  "file__fileName": {
    "break-all": true,
    "grow": true,
    "text-ellipsis": true
  },
  "file__fileRemove": {
    "flex": true,
    "items-center": true,
    "text-neutral-700": true,
    "text-[0px]": true,
    "peer-data-[has-multiple]:text-neutral-500": true,
    "peer-data-[has-multiple]:hover:text-red-500": true,
    "peer-data-[has-multiple]:text-sm": true,
    "peer-data-[has-multiple]:shrink-0": true,
    "peer-data-[has-multiple]:mr-3": true,
    "appearance-none": true,
    "outline-none": true,
    "relative": true,
    "z-[2]": true,
    "dark:text-neutral-100": true
  },
  "file__fileRemoveIcon": {
    "block": true,
    "text-base": true,
    "w-3": true,
    "relative": true,
    "z-[2]": true
  },
  "file__noFiles": {
    "flex": true,
    "w-full": true,
    "items-center": true,
    "px-3": true,
    "py-2": true,
    "text-neutral-400": true
  },
  "file__noFilesIcon": {
    "w-4": true,
    "mr-2": true
  },
  "file__help": {
    "text-xs": true,
    "text-neutral-500": true,
    "whitespace-pre-line": true,
    "dark:text-neutral-100": true
  },
  "file__messages": {
    "list-none": true,
    "p-0": true,
    "mt-1": true,
    "mb-0": true
  },
  "file__message": {
    "text-red-600": true,
    "mb-1": true,
    "text-sm": true,
    "dark:text-red-400": true
  },
  "form__form": {
    "flex": true,
    "flex-1": true,
    "flex-col": true,
    "overflow-auto": true,
    "sm:overflow-hidden": true
  },
  "form__actions": {
    "": true
  },
  "form__summaryInner": {
    "group/summary": true,
    "border": true,
    "border-neutral-900": true,
    "rounded-none": true,
    "py-2": true,
    "px-3": true,
    "dark:border-neutral-100": true
  },
  "form__summaryHeader": {
    "text-lg": true,
    "text-neutral-900": true,
    "mb-2": true,
    "dark:text-neutral-100": true
  },
  "form__messages": {
    "list-none": true,
    "p-0": true,
    "mt-1": true,
    "mb-0": true
  },
  "form__message": {
    "text-red-600": true,
    "mb-1": true,
    "text-sm": true,
    "dark:text-red-400": true,
    "group-[]/summary:text-sm": true
  },
  "month__outer": {
    "group": true,
    "mb-4": true,
    "data-[disabled]:opacity-50": true,
    "data-[disabled]:select-none": true,
    "data-[disabled]:pointer-events-none": true
  },
  "month__wrapper": {
    "": true
  },
  "month__label": {
    "block": true,
    "mb-1": true,
    "font-medium": true,
    "text-sm": true,
    "text-neutral-700": true,
    "dark:text-neutral-100": true
  },
  "month__prefixIcon": {
    "flex": true,
    "shrink-0": true,
    "items-center": true,
    "text-base": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "[&>svg]:w-full": true
  },
  "month__suffixIcon": {
    "flex": true,
    "items-center": true,
    "text-base": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "shrink-0": true,
    "[&>svg]:w-full": true
  },
  "month__inner": {
    "": true
  },
  "month__input": {
    "appearance-none": true,
    "border-none": true,
    "focus:outline-none": true,
    "focus:ring-0": true,
    "focus:shadow-none": true,
    "sm:text-sm": true,
    "text-black": true,
    "placeholder:italic": true,
    "transition-colors": true,
    "[color-scheme:light]": true,
    "dark:[color-scheme:dark]": true,
    "dark:placeholder:text-neutral-500": true,
    "dark:text-neutral-100": true
  },
  "month__help": {
    "text-xs": true,
    "text-neutral-500": true,
    "whitespace-pre-line": true,
    "dark:text-neutral-100": true
  },
  "month__messages": {
    "list-none": true,
    "p-0": true,
    "mt-1": true,
    "mb-0": true
  },
  "month__message": {
    "text-red-600": true,
    "mb-1": true,
    "text-sm": true,
    "dark:text-red-400": true
  },
  "number__outer": {
    "group": true,
    "mb-4": true,
    "data-[disabled]:opacity-50": true,
    "data-[disabled]:select-none": true,
    "data-[disabled]:pointer-events-none": true
  },
  "number__wrapper": {
    "": true
  },
  "number__label": {
    "block": true,
    "mb-1": true,
    "font-medium": true,
    "text-sm": true,
    "text-neutral-700": true,
    "dark:text-neutral-100": true
  },
  "number__prefixIcon": {
    "flex": true,
    "shrink-0": true,
    "items-center": true,
    "text-base": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "[&>svg]:w-full": true
  },
  "number__suffixIcon": {
    "flex": true,
    "items-center": true,
    "text-base": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "shrink-0": true,
    "[&>svg]:w-full": true
  },
  "number__inner": {
    "": true
  },
  "number__input": {
    "appearance-none": true,
    "border-none": true,
    "focus:outline-none": true,
    "focus:ring-0": true,
    "focus:shadow-none": true,
    "sm:text-sm": true,
    "text-black": true,
    "placeholder:italic": true,
    "transition-colors": true,
    "[color-scheme:light]": true,
    "dark:[color-scheme:dark]": true,
    "dark:placeholder:text-neutral-500": true,
    "dark:text-neutral-100": true
  },
  "number__help": {
    "text-xs": true,
    "text-neutral-500": true,
    "whitespace-pre-line": true,
    "dark:text-neutral-100": true
  },
  "number__messages": {
    "list-none": true,
    "p-0": true,
    "mt-1": true,
    "mb-0": true
  },
  "number__message": {
    "text-red-600": true,
    "mb-1": true,
    "text-sm": true,
    "dark:text-red-400": true
  },
  "password__outer": {
    "group": true,
    "mb-4": true,
    "data-[disabled]:opacity-50": true,
    "data-[disabled]:select-none": true,
    "data-[disabled]:pointer-events-none": true
  },
  "password__wrapper": {
    "": true
  },
  "password__label": {
    "block": true,
    "mb-1": true,
    "font-medium": true,
    "text-sm": true,
    "text-neutral-700": true,
    "dark:text-neutral-100": true
  },
  "password__prefixIcon": {
    "flex": true,
    "shrink-0": true,
    "items-center": true,
    "text-base": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "[&>svg]:w-full": true
  },
  "password__suffixIcon": {
    "flex": true,
    "items-center": true,
    "text-base": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "shrink-0": true,
    "[&>svg]:w-full": true
  },
  "password__inner": {
    "": true
  },
  "password__input": {
    "appearance-none": true,
    "border-none": true,
    "focus:outline-none": true,
    "focus:ring-0": true,
    "focus:shadow-none": true,
    "sm:text-sm": true,
    "text-black": true,
    "placeholder:italic": true,
    "transition-colors": true,
    "[color-scheme:light]": true,
    "dark:[color-scheme:dark]": true,
    "dark:placeholder:text-neutral-500": true,
    "dark:text-neutral-100": true
  },
  "password__help": {
    "text-xs": true,
    "text-neutral-500": true,
    "whitespace-pre-line": true,
    "dark:text-neutral-100": true
  },
  "password__messages": {
    "list-none": true,
    "p-0": true,
    "mt-1": true,
    "mb-0": true
  },
  "password__message": {
    "text-red-600": true,
    "mb-1": true,
    "text-sm": true,
    "dark:text-red-400": true
  },
  "radio__outer": {
    "group": true,
    "mb-4": true,
    "data-[disabled]:opacity-50": true,
    "data-[disabled]:select-none": true,
    "data-[disabled]:pointer-events-none": true
  },
  "radio__wrapper": {
    "": true
  },
  "radio__fieldset": {
    "border": true,
    "border-neutral-400": true,
    "rounded-md": true,
    "px-2": true,
    "pb-1": true
  },
  "radio__legend": {
    "font-bold": true,
    "text-md": true,
    "text-neutral-600": true,
    "dark:text-neutral-100": true
  },
  "radio__help": {
    "text-xs": true,
    "text-neutral-500": true,
    "whitespace-pre-line": true,
    "dark:text-neutral-100": true
  },
  "radio__inner": {
    "": true
  },
  "radio__options": {
    "": true
  },
  "radio__option": {
    "": true
  },
  "radio__decorator": {
    "rounded-full": true
  },
  "radio__decoratorIcon": {
    "w-5": true,
    "p-[5px]": true
  },
  "radio__label": {
    "block": true,
    "mb-1": true,
    "font-medium": true,
    "text-sm": true,
    "text-neutral-700": true,
    "dark:text-neutral-100": true
  },
  "radio__optionHelp": {
    "text-neutral-500": true,
    "text-xs": true
  },
  "radio__messages": {
    "list-none": true,
    "p-0": true,
    "mt-1": true,
    "mb-0": true
  },
  "radio__message": {
    "text-red-600": true,
    "mb-1": true,
    "text-sm": true,
    "dark:text-red-400": true
  },
  "range__outer": {
    "group": true,
    "mb-4": true,
    "data-[disabled]:opacity-50": true,
    "data-[disabled]:select-none": true,
    "data-[disabled]:pointer-events-none": true
  },
  "range__wrapper": {
    "": true
  },
  "range__label": {
    "block": true,
    "mb-1": true,
    "font-medium": true,
    "text-sm": true,
    "text-neutral-700": true,
    "dark:text-neutral-100": true
  },
  "range__prefixIcon": {
    "flex": true,
    "shrink-0": true,
    "items-center": true,
    "text-base": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "[&>svg]:w-full": true
  },
  "range__suffixIcon": {
    "flex": true,
    "items-center": true,
    "text-base": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "shrink-0": true,
    "[&>svg]:w-full": true
  },
  "range__inner": {
    "relative": true,
    "!border-none": true,
    "!ring-0": true,
    "!px-0": true,
    "!bg-transparent": true
  },
  "range__input": {
    "appearance-none": true,
    "border-none": true,
    "focus:outline-none": true,
    "focus:ring-0": true,
    "focus:shadow-none": true,
    "sm:text-sm": true,
    "text-black": true,
    "placeholder:italic": true,
    "transition-colors": true,
    "[color-scheme:light]": true,
    "dark:[color-scheme:dark]": true,
    "dark:placeholder:text-neutral-500": true,
    "dark:text-neutral-100": true,
    "[&::-webkit-slider-runnable-track]:bg-neutral-300": true,
    "[&::-webkit-slider-runnable-track]:h-1.5": true,
    "[&::-webkit-slider-runnable-track]:rounded-md": true,
    "dark:[&::-webkit-slider-runnable-track]:bg-neutral-700": true,
    "[&::-moz-range-track]:bg-neutral-300": true,
    "[&::-moz-range-track]:h-1.5": true,
    "[&::-moz-range-track]:rounded-md": true,
    "dark:[&::-moz-range-track]:bg-neutral-700": true,
    "[&::-webkit-slider-thumb]:appearance-none": true,
    "[&::-webkit-slider-thumb]:w-3.5": true,
    "[&::-webkit-slider-thumb]:h-3.5": true,
    "[&::-webkit-slider-thumb]:bg-neutral-600": true,
    "[&::-webkit-slider-thumb]:rounded-full": true,
    "[&::-webkit-slider-thumb]:relative": true,
    "[&::-webkit-slider-thumb]:top-1/2": true,
    "[&::-webkit-slider-thumb]:-translate-y-1/2": true,
    "[&::-webkit-slider-thumb]:focus-visible:ring-2": true,
    "dark:[&::-webkit-slider-thumb]:bg-neutral-400": true,
    "[&::-moz-range-thumb]:appearance-none": true,
    "[&::-moz-range-thumb]:border-none": true,
    "[&::-moz-range-thumb]:w-3.5": true,
    "[&::-moz-range-thumb]:h-3.5": true,
    "[&::-moz-range-thumb]:bg-neutral-600": true,
    "[&::-moz-range-thumb]:rounded-full": true,
    "[&::-moz-range-thumb]:focus-visible:ring-2": true,
    "dark:[&::-moz-range-thumb]:bg-neutral-400": true
  },
  "range__help": {
    "text-xs": true,
    "text-neutral-500": true,
    "whitespace-pre-line": true,
    "dark:text-neutral-100": true
  },
  "range__messages": {
    "list-none": true,
    "p-0": true,
    "mt-1": true,
    "mb-0": true
  },
  "range__message": {
    "text-red-600": true,
    "mb-1": true,
    "text-sm": true,
    "dark:text-red-400": true
  },
  "search__outer": {
    "group": true,
    "mb-4": true,
    "data-[disabled]:opacity-50": true,
    "data-[disabled]:select-none": true,
    "data-[disabled]:pointer-events-none": true
  },
  "search__wrapper": {
    "": true
  },
  "search__label": {
    "block": true,
    "mb-1": true,
    "font-medium": true,
    "text-sm": true,
    "text-neutral-700": true,
    "dark:text-neutral-100": true
  },
  "search__prefixIcon": {
    "flex": true,
    "shrink-0": true,
    "items-center": true,
    "text-base": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "[&>svg]:w-full": true
  },
  "search__suffixIcon": {
    "flex": true,
    "items-center": true,
    "text-base": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "shrink-0": true,
    "[&>svg]:w-full": true
  },
  "search__inner": {
    "": true
  },
  "search__input": {
    "appearance-none": true,
    "border-none": true,
    "focus:outline-none": true,
    "focus:ring-0": true,
    "focus:shadow-none": true,
    "sm:text-sm": true,
    "text-black": true,
    "placeholder:italic": true,
    "transition-colors": true,
    "[color-scheme:light]": true,
    "dark:[color-scheme:dark]": true,
    "dark:placeholder:text-neutral-500": true,
    "dark:text-neutral-100": true
  },
  "search__help": {
    "text-xs": true,
    "text-neutral-500": true,
    "whitespace-pre-line": true,
    "dark:text-neutral-100": true
  },
  "search__messages": {
    "list-none": true,
    "p-0": true,
    "mt-1": true,
    "mb-0": true
  },
  "search__message": {
    "text-red-600": true,
    "mb-1": true,
    "text-sm": true,
    "dark:text-red-400": true
  },
  "select__outer": {
    "group": true,
    "mb-4": true,
    "data-[disabled]:opacity-50": true,
    "data-[disabled]:select-none": true,
    "data-[disabled]:pointer-events-none": true
  },
  "select__wrapper": {
    "": true
  },
  "select__label": {
    "block": true,
    "mb-1": true,
    "font-medium": true,
    "text-sm": true,
    "text-neutral-700": true,
    "dark:text-neutral-100": true
  },
  "select__prefixIcon": {
    "flex": true,
    "shrink-0": true,
    "items-center": true,
    "text-base": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "[&>svg]:w-full": true,
    "ml-2": true,
    "text-neutral-700": true,
    "dark:text-neutral-100": true
  },
  "select__suffixIcon": {
    "flex": true,
    "items-center": true,
    "text-base": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "shrink-0": true,
    "[&>svg]:w-full": true,
    "mr-2": true,
    "text-neutral-700": true,
    "dark:text-neutral-100": true
  },
  "select__inner": {
    "flex": true,
    "relative": true,
    "items-center": true,
    "rounded-md": true,
    "mb-1": true,
    "ring-1": true,
    "ring-neutral-300": true,
    "enabled:hover:ring-neutral-400": true,
    "focus-within:ring-neutral-400": true,
    "focus-within:ring-2": true,
    "[&>span:first-child]:focus-within:text-sky-500": true,
    "dark:border-neutral-100": true
  },
  "select__input": {
    "appearance-none": true,
    "focus:outline-none": true,
    "focus:ring-0": true,
    "focus:shadow-none": true,
    "sm:text-sm": true,
    "placeholder:italic": true,
    "transition-colors": true,
    "[color-scheme:light]": true,
    "dark:[color-scheme:dark]": true,
    "dark:placeholder:text-neutral-500": true,
    "dark:text-neutral-100": true,
    "w-full": true,
    "pl-3": true,
    "pr-8": true,
    "py-2": true,
    "border-none": true,
    "rounded-md": true,
    "bg-none": true,
    "text-base": true,
    "text-neutral-700": true,
    "placeholder-neutral-400": true,
    "formkit-multiple:p-0": true,
    "data-[placeholder=true]:text-neutral-400": true,
    "formkit-multiple:data-[placeholder=true]:text-inherit": true,
    "disabled:bg-none": true,
    "dark:data-[placeholder]:text-neutral-100": true
  },
  "select__selectIcon": {
    "flex": true,
    "p-[3px]": true,
    "shrink-0": true,
    "w-5": true,
    "mr-2": true,
    "-ml-[1.5em]": true,
    "h-full": true,
    "pointer-events-none": true,
    "[&>svg]:w-[1em]": true,
    "text-neutral-700": true,
    "dark:text-neutral-100": true
  },
  "select__option": {
    "formkit-multiple:p-3": true,
    "formkit-multiple:text-sm": true,
    "text-neutral-700": true,
    "dark:text-neutral-100": true
  },
  "select__help": {
    "text-xs": true,
    "text-neutral-500": true,
    "whitespace-pre-line": true,
    "dark:text-neutral-100": true
  },
  "select__messages": {
    "list-none": true,
    "p-0": true,
    "mt-1": true,
    "mb-0": true
  },
  "select__message": {
    "text-red-600": true,
    "mb-1": true,
    "text-sm": true,
    "dark:text-red-400": true
  },
  "submit__outer": {
    "group": true,
    "mb-4": true,
    "data-[disabled]:opacity-50": true,
    "data-[disabled]:select-none": true,
    "data-[disabled]:pointer-events-none": true
  },
  "submit__wrapper": {
    "": true
  },
  "submit__label": {
    "block": true,
    "mb-1": true,
    "font-medium": true,
    "text-sm": true,
    "text-neutral-700": true,
    "dark:text-neutral-100": true
  },
  "submit__prefixIcon": {
    "flex": true,
    "shrink-0": true,
    "items-center": true,
    "text-base": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "[&>svg]:w-full": true
  },
  "submit__suffixIcon": {
    "flex": true,
    "items-center": true,
    "text-base": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "shrink-0": true,
    "[&>svg]:w-full": true
  },
  "submit__input": {
    "appearance-none": true,
    "border-none": true,
    "focus:outline-none": true,
    "focus:ring-0": true,
    "focus:shadow-none": true,
    "sm:text-sm": true,
    "text-black": true,
    "placeholder:italic": true,
    "transition-colors": true,
    "[color-scheme:light]": true,
    "dark:[color-scheme:dark]": true,
    "dark:placeholder:text-neutral-500": true,
    "dark:text-neutral-100": true
  },
  "submit__help": {
    "text-xs": true,
    "text-neutral-500": true,
    "whitespace-pre-line": true,
    "dark:text-neutral-100": true
  },
  "submit__messages": {
    "list-none": true,
    "p-0": true,
    "mt-1": true,
    "mb-0": true
  },
  "submit__message": {
    "text-red-600": true,
    "mb-1": true,
    "text-sm": true,
    "dark:text-red-400": true
  },
  "tel__outer": {
    "group": true,
    "mb-4": true,
    "data-[disabled]:opacity-50": true,
    "data-[disabled]:select-none": true,
    "data-[disabled]:pointer-events-none": true
  },
  "tel__wrapper": {
    "": true
  },
  "tel__label": {
    "block": true,
    "mb-1": true,
    "font-medium": true,
    "text-sm": true,
    "text-neutral-700": true,
    "dark:text-neutral-100": true
  },
  "tel__prefixIcon": {
    "flex": true,
    "shrink-0": true,
    "items-center": true,
    "text-base": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "[&>svg]:w-full": true
  },
  "tel__suffixIcon": {
    "flex": true,
    "items-center": true,
    "text-base": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "shrink-0": true,
    "[&>svg]:w-full": true
  },
  "tel__inner": {
    "": true
  },
  "tel__input": {
    "appearance-none": true,
    "border-none": true,
    "focus:outline-none": true,
    "focus:ring-0": true,
    "focus:shadow-none": true,
    "sm:text-sm": true,
    "text-black": true,
    "placeholder:italic": true,
    "transition-colors": true,
    "[color-scheme:light]": true,
    "dark:[color-scheme:dark]": true,
    "dark:placeholder:text-neutral-500": true,
    "dark:text-neutral-100": true
  },
  "tel__help": {
    "text-xs": true,
    "text-neutral-500": true,
    "whitespace-pre-line": true,
    "dark:text-neutral-100": true
  },
  "tel__messages": {
    "list-none": true,
    "p-0": true,
    "mt-1": true,
    "mb-0": true
  },
  "tel__message": {
    "text-red-600": true,
    "mb-1": true,
    "text-sm": true,
    "dark:text-red-400": true
  },
  "text__outer": {
    "group": true,
    "mb-4": true,
    "data-[disabled]:opacity-50": true,
    "data-[disabled]:select-none": true,
    "data-[disabled]:pointer-events-none": true
  },
  "text__wrapper": {
    "": true
  },
  "text__label": {
    "block": true,
    "mb-1": true,
    "font-medium": true,
    "text-sm": true,
    "text-neutral-700": true,
    "dark:text-neutral-100": true
  },
  "text__prefixIcon": {
    "flex": true,
    "shrink-0": true,
    "items-center": true,
    "text-base": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "[&>svg]:w-full": true
  },
  "text__suffixIcon": {
    "flex": true,
    "items-center": true,
    "text-base": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "shrink-0": true,
    "[&>svg]:w-full": true
  },
  "text__inner": {
    "": true
  },
  "text__input": {
    "appearance-none": true,
    "border-none": true,
    "focus:outline-none": true,
    "focus:ring-0": true,
    "focus:shadow-none": true,
    "sm:text-sm": true,
    "text-black": true,
    "placeholder:italic": true,
    "transition-colors": true,
    "[color-scheme:light]": true,
    "dark:[color-scheme:dark]": true,
    "dark:placeholder:text-neutral-500": true,
    "dark:text-neutral-100": true
  },
  "text__help": {
    "text-xs": true,
    "text-neutral-500": true,
    "whitespace-pre-line": true,
    "dark:text-neutral-100": true
  },
  "text__messages": {
    "list-none": true,
    "p-0": true,
    "mt-1": true,
    "mb-0": true
  },
  "text__message": {
    "text-red-600": true,
    "mb-1": true,
    "text-sm": true,
    "dark:text-red-400": true
  },
  "textarea__outer": {
    "group": true,
    "mb-4": true,
    "data-[disabled]:opacity-50": true,
    "data-[disabled]:select-none": true,
    "data-[disabled]:pointer-events-none": true
  },
  "textarea__wrapper": {
    "": true
  },
  "textarea__label": {
    "block": true,
    "mb-1": true,
    "font-medium": true,
    "text-sm": true,
    "text-neutral-700": true,
    "dark:text-neutral-100": true
  },
  "textarea__prefixIcon": {
    "flex": true,
    "shrink-0": true,
    "items-center": true,
    "text-base": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "[&>svg]:w-full": true,
    "ml-2": true,
    "mr-0": true,
    "mt-2": true,
    "text-neutral-700": true,
    "dark:text-white": true
  },
  "textarea__suffixIcon": {
    "flex": true,
    "items-center": true,
    "text-base": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "shrink-0": true,
    "[&>svg]:w-full": true,
    "mr-2": true,
    "ml-0": true,
    "mt-2": true,
    "text-neutral-700": true,
    "dark:text-white": true
  },
  "textarea__inner": {
    "flex": true,
    "rounded-md": true,
    "mb-1": true,
    "ring-1": true,
    "ring-neutral-300": true,
    "focus-within:ring-neutral-400": true,
    "focus-within:ring-2": true,
    "[&>label:first-child]:focus-within:text-sky-500": true
  },
  "textarea__input": {
    "appearance-none": true,
    "focus:outline-none": true,
    "focus:ring-0": true,
    "focus:shadow-none": true,
    "sm:text-sm": true,
    "placeholder:italic": true,
    "transition-colors": true,
    "[color-scheme:light]": true,
    "dark:[color-scheme:dark]": true,
    "dark:placeholder:text-neutral-500": true,
    "dark:text-neutral-100": true,
    "block": true,
    "w-full": true,
    "h-32": true,
    "px-3": true,
    "py-3": true,
    "border-none": true,
    "text-base": true,
    "text-neutral-700": true,
    "placeholder-neutral-400": true,
    "focus:shadow-outline": true,
    "bg-transparent": true
  },
  "textarea__help": {
    "text-xs": true,
    "text-neutral-500": true,
    "whitespace-pre-line": true,
    "dark:text-neutral-100": true
  },
  "textarea__messages": {
    "list-none": true,
    "p-0": true,
    "mt-1": true,
    "mb-0": true
  },
  "textarea__message": {
    "text-red-600": true,
    "mb-1": true,
    "text-sm": true,
    "dark:text-red-400": true
  },
  "time__outer": {
    "group": true,
    "mb-4": true,
    "data-[disabled]:opacity-50": true,
    "data-[disabled]:select-none": true,
    "data-[disabled]:pointer-events-none": true
  },
  "time__wrapper": {
    "": true
  },
  "time__label": {
    "block": true,
    "mb-1": true,
    "font-medium": true,
    "text-sm": true,
    "text-neutral-700": true,
    "dark:text-neutral-100": true
  },
  "time__prefixIcon": {
    "flex": true,
    "shrink-0": true,
    "items-center": true,
    "text-base": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "[&>svg]:w-full": true
  },
  "time__suffixIcon": {
    "flex": true,
    "items-center": true,
    "text-base": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "shrink-0": true,
    "[&>svg]:w-full": true
  },
  "time__inner": {
    "": true
  },
  "time__input": {
    "appearance-none": true,
    "border-none": true,
    "focus:outline-none": true,
    "focus:ring-0": true,
    "focus:shadow-none": true,
    "sm:text-sm": true,
    "text-black": true,
    "placeholder:italic": true,
    "transition-colors": true,
    "[color-scheme:light]": true,
    "dark:[color-scheme:dark]": true,
    "dark:placeholder:text-neutral-500": true,
    "dark:text-neutral-100": true,
    "max-h-9": true
  },
  "time__help": {
    "text-xs": true,
    "text-neutral-500": true,
    "whitespace-pre-line": true,
    "dark:text-neutral-100": true
  },
  "time__messages": {
    "list-none": true,
    "p-0": true,
    "mt-1": true,
    "mb-0": true
  },
  "time__message": {
    "text-red-600": true,
    "mb-1": true,
    "text-sm": true,
    "dark:text-red-400": true
  },
  "url__outer": {
    "group": true,
    "mb-4": true,
    "data-[disabled]:opacity-50": true,
    "data-[disabled]:select-none": true,
    "data-[disabled]:pointer-events-none": true
  },
  "url__wrapper": {
    "": true
  },
  "url__label": {
    "block": true,
    "mb-1": true,
    "font-medium": true,
    "text-sm": true,
    "text-neutral-700": true,
    "dark:text-neutral-100": true
  },
  "url__prefixIcon": {
    "flex": true,
    "shrink-0": true,
    "items-center": true,
    "text-base": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "[&>svg]:w-full": true
  },
  "url__suffixIcon": {
    "flex": true,
    "items-center": true,
    "text-base": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "shrink-0": true,
    "[&>svg]:w-full": true
  },
  "url__inner": {
    "": true
  },
  "url__input": {
    "appearance-none": true,
    "border-none": true,
    "focus:outline-none": true,
    "focus:ring-0": true,
    "focus:shadow-none": true,
    "sm:text-sm": true,
    "text-black": true,
    "placeholder:italic": true,
    "transition-colors": true,
    "[color-scheme:light]": true,
    "dark:[color-scheme:dark]": true,
    "dark:placeholder:text-neutral-500": true,
    "dark:text-neutral-100": true
  },
  "url__help": {
    "text-xs": true,
    "text-neutral-500": true,
    "whitespace-pre-line": true,
    "dark:text-neutral-100": true
  },
  "url__messages": {
    "list-none": true,
    "p-0": true,
    "mt-1": true,
    "mb-0": true
  },
  "url__message": {
    "text-red-600": true,
    "mb-1": true,
    "text-sm": true,
    "dark:text-red-400": true
  },
  "week__outer": {
    "group": true,
    "mb-4": true,
    "data-[disabled]:opacity-50": true,
    "data-[disabled]:select-none": true,
    "data-[disabled]:pointer-events-none": true
  },
  "week__wrapper": {
    "": true
  },
  "week__label": {
    "block": true,
    "mb-1": true,
    "font-medium": true,
    "text-sm": true,
    "text-neutral-700": true,
    "dark:text-neutral-100": true
  },
  "week__prefixIcon": {
    "flex": true,
    "shrink-0": true,
    "items-center": true,
    "text-base": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "[&>svg]:w-full": true
  },
  "week__suffixIcon": {
    "flex": true,
    "items-center": true,
    "text-base": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "shrink-0": true,
    "[&>svg]:w-full": true
  },
  "week__inner": {
    "": true
  },
  "week__input": {
    "appearance-none": true,
    "border-none": true,
    "focus:outline-none": true,
    "focus:ring-0": true,
    "focus:shadow-none": true,
    "sm:text-sm": true,
    "text-black": true,
    "placeholder:italic": true,
    "transition-colors": true,
    "[color-scheme:light]": true,
    "dark:[color-scheme:dark]": true,
    "dark:placeholder:text-neutral-500": true,
    "dark:text-neutral-100": true
  },
  "week__help": {
    "text-xs": true,
    "text-neutral-500": true,
    "whitespace-pre-line": true,
    "dark:text-neutral-100": true
  },
  "week__messages": {
    "list-none": true,
    "p-0": true,
    "mt-1": true,
    "mb-0": true
  },
  "week__message": {
    "text-red-600": true,
    "mb-1": true,
    "text-sm": true,
    "dark:text-red-400": true
  },
  "autocomplete__outer": {
    "group": true,
    "mb-4": true,
    "data-[disabled]:opacity-50": true,
    "data-[disabled]:select-none": true,
    "data-[disabled]:pointer-events-none": true
  },
  "autocomplete__wrapper": {
    "": true
  },
  "autocomplete__label": {
    "block": true,
    "mb-1": true,
    "font-medium": true,
    "text-sm": true,
    "text-neutral-700": true,
    "dark:text-neutral-100": true
  },
  "autocomplete__inner": {
    "[&>div>[data-value]]:absolute": true,
    "[&>div>[data-value]]:mb-0": true
  },
  "autocomplete__prefixIcon": {
    "flex": true,
    "shrink-0": true,
    "items-center": true,
    "text-base": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "[&>svg]:w-full": true
  },
  "autocomplete__suffixIcon": {
    "flex": true,
    "items-center": true,
    "text-base": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "shrink-0": true,
    "[&>svg]:w-full": true
  },
  "autocomplete__input": {
    "appearance-none": true,
    "border-none": true,
    "focus:outline-none": true,
    "focus:ring-0": true,
    "focus:shadow-none": true,
    "sm:text-sm": true,
    "text-black": true,
    "placeholder:italic": true,
    "transition-colors": true,
    "[color-scheme:light]": true,
    "dark:[color-scheme:dark]": true,
    "dark:placeholder:text-neutral-500": true,
    "dark:text-neutral-100": true,
    "peer": true
  },
  "autocomplete__selections": {
    "flex": true,
    "absolute": true,
    "inset-0": true,
    "group-data-[multiple]:static": true,
    "group-data-[empty]:hidden": true,
    "group-data-[multiple]:mt-2": true
  },
  "autocomplete__selectionWrapper": {
    "flex": true,
    "rounded-md": true,
    "bg-neutral-100": true,
    "mb-2": true,
    "me-2": true,
    "text-neutral-600": true,
    "dark:bg-neutral-700": true,
    "dark:group-data-[multiple]:border-neutral-100": true
  },
  "autocomplete__selection": {
    "static": true,
    "flex": true,
    "left-0": true,
    "top-0": true,
    "right-0": true,
    "bottom-0": true,
    "mt-0": true
  },
  "autocomplete__loaderIcon": {
    "inline-flex": true,
    "items-center": true,
    "w-4": true,
    "text-neutral-600": true,
    "animate-spin": true,
    "dark:text-neutral-100": true
  },
  "autocomplete__removeSelection": {
    "appearance-none": true,
    "flex": true,
    "self-stretch": true,
    "p-0": true,
    "cursor-pointer": true,
    "text-neutral-600": true,
    "dark:text-neutral-200": true
  },
  "autocomplete__listboxButton": {
    "": true
  },
  "autocomplete__selectIcon": {
    "text-neutral-600": true,
    "dark:text-neutral-100": true
  },
  "autocomplete__dropdownWrapper": {
    "": true
  },
  "autocomplete__listbox": {
    "group": true
  },
  "autocomplete__listitem": {
    "": true
  },
  "autocomplete__selectedIcon": {
    "": true
  },
  "autocomplete__option": {
    "grow": true,
    "text-ellipsis": true,
    "px-3": true,
    "py-2": true,
    "sm:text-sm": true
  },
  "autocomplete__emptyMessage": {
    "": true
  },
  "autocomplete__emptyMessageInner": {
    "": true
  },
  "autocomplete__help": {
    "text-xs": true,
    "text-neutral-500": true,
    "whitespace-pre-line": true,
    "dark:text-neutral-100": true
  },
  "autocomplete__messages": {
    "list-none": true,
    "p-0": true,
    "mt-1": true,
    "mb-0": true
  },
  "autocomplete__message": {
    "text-red-600": true,
    "mb-1": true,
    "text-sm": true,
    "dark:text-red-400": true
  },
  "colorpicker__outer": {
    "group": true,
    "max-w-[20em]": true,
    "min-w-0": true,
    "flex-grow": true,
    "text-base": true,
    "mb-4": true,
    "data-[disabled]:select-none": true,
    "data-[disabled]:opacity-50": true,
    "data-[disabled]:pointer-events-none": true
  },
  "colorpicker__wrapper": {
    "flex": true,
    "flex-col": true,
    "items-start": true,
    "justify-start": true,
    "last:mb-0": true
  },
  "colorpicker__label": {
    "block": true,
    "mb-1": true,
    "font-medium": true,
    "text-sm": true,
    "text-neutral-700": true,
    "dark:text-neutral-100": true
  },
  "colorpicker__inner": {
    "relative": true,
    "inline-flex": true,
    "!w-auto": true,
    "py-2": true,
    "px-3": true,
    "rounded-md": true,
    "group-data-[inline]:border-none": true,
    "group-data-[inline]:p-0": true,
    "group-data-[inline]:outline-none": true,
    "group-data-[inline]:!ring-0": true,
    "group-data-[inline]:!w-full": true,
    "group-data-[inline]:rounded-none": true,
    "group-data-[disabled]:!cursor-not-allowed": true
  },
  "colorpicker__swatchPreview": {
    "flex": true,
    "items-center": true,
    "rounded-md": true
  },
  "colorpicker__canvasSwatchPreviewWrapper": {
    "mr-2": true,
    "relative": true,
    "before:content-['']": true,
    "before:absolute": true,
    "before:inset-0": true,
    "before:shadow-[inset_0_0_0_1px_rgba(0,0,0,0.2)]": true,
    "before:z-[2]": true,
    "before:rounded-md": true
  },
  "colorpicker__canvas": {
    "block": true,
    "w-full": true,
    "rounded-md": true
  },
  "colorpicker__canvasSwatchPreview": {
    "text-base": true,
    "aspect-square": true,
    "shrink-0": true,
    "grow": true,
    "!w-[1.5em]": true,
    "rounded-md": true
  },
  "colorpicker__valueString": {
    "inline-block": true,
    "text-sm": true,
    "text-neutral-900": true,
    "font-mono": true,
    "dark:text-neutral-100": true
  },
  "colorpicker__panel": {
    "absolute": true,
    "top-full": true,
    "left-0": true,
    "z-20": true,
    "flex": true,
    "w-[100vw]": true,
    "max-w-[18.5em]": true,
    "touch-manipulation": true,
    "flex-col": true,
    "border": true,
    "rounded-md": true,
    "bg-white": true,
    "p-2": true,
    "dark:bg-neutral-900": true,
    "group-data-[inline]:static": true,
    "group-data-[inline]:z-auto": true,
    "group-data-[inline]:w-full": true,
    "group-data-[inline]:max-w-none": true,
    "group-data-[inline]:bg-transparent": true,
    "group-data-[inline]:border": true,
    "border-neutral-900": true,
    "dark:border-neutral-100": true,
    "[@media(max-width:431px)_and_(hover:none)]:group-[&:not([data-inline])]:!fixed": true,
    "[@media(max-width:431px)_and_(hover:none)]:group-[&:not([data-inline])]:top-auto": true,
    "[@media(max-width:431px)_and_(hover:none)]:group-[&:not([data-inline])]:max-w-none": true,
    "[@media(max-width:431px)_and_(hover:none)]:group-[&:not([data-inline])]:bottom-0": true,
    "[@media(max-width:431px)_and_(hover:none)]:group-[&:not([data-inline])]:left-0": true,
    "[@media(max-width:431px)_and_(hover:none)]:group-[&:not([data-inline])]:rounded-none": true
  },
  "colorpicker__panelClose": {
    "flex": true,
    "justify-end": true,
    "items-center": true,
    "text-neutral-900": true,
    "mb-1.5": true,
    "border-b": true,
    "border-neutral-900": true,
    "pb-2": true,
    "dark:text-neutral-100": true,
    "dark:border-neutral-100": true
  },
  "colorpicker__closeIcon": {
    "w-[1.75rem]": true,
    "aspect-square": true,
    "p-1": true,
    "rounded-md": true,
    "border": true,
    "border-neutral-900": true,
    "dark:border-neutral-100": true,
    "[&>svg]:w-full": true,
    "[&>svg]:aspect-square": true,
    "[&>svg]:max-w-none": true,
    "[&>svg]:max-h-none": true
  },
  "colorpicker__control": {
    "absolute": true,
    "bg-white": true,
    "shadow-[0_0_0_2px_rgba(255,255,255,1),0_0_0_3px_rgba(0,0,0,0.2)]": true,
    "-translate-y-1/2": true,
    "-translate-x-1/2": true,
    "pointer-events-none": true,
    "focus-visible:outline-none": true,
    "focus-visible:ring-2": true,
    "dark:bg-neutral-900": true,
    "data-[prevent-focus-style]:outline-none": true,
    "data-[prevent-focus-style]:ring-0": true,
    "data-[prevent-focus-style]:shadow-[0_0_0_2px_rgba(255,255,255,1),0_0_0_3px_rgba(0,0,0,0.2)]": true
  },
  "colorpicker__controlGroup": {
    "grid": true,
    "[grid-template-areas:'a_a_a'_'b_c_e'_'b_d_e']": true,
    "mb-2": true
  },
  "colorpicker__LS": {
    "[grid-area:a]": true,
    "relative": true,
    "mb-2": true
  },
  "colorpicker__canvasLS": {
    "aspect-[2/1]": true,
    "cursor-pointer": true,
    "rounded-none": true
  },
  "colorpicker__controlLS": {
    "w-[10px]": true,
    "h-[10px]": true,
    "rounded-full": true
  },
  "colorpicker__preview": {
    "[grid-area:b]": true,
    "relative": true,
    "inline-flex": true,
    "w-[2em]": true,
    "aspect-square": true,
    "overflow-hidden": true,
    "rounded-md": true,
    "after:content-['']": true,
    "after:absolute": true,
    "after:inset-0": true,
    "after:rounded-md": true,
    "after:shadow-[inset_0_0_0_1px_rgba(0,0,0,0.2)]": true
  },
  "colorpicker__hue": {
    "[grid-area:c]": true,
    "relative": true,
    "inline-flex": true,
    "h-3/4": true,
    "ml-2": true
  },
  "colorpicker__canvasHue": {
    "rounded-none": true
  },
  "colorpicker__controlHue": {
    "w-[4px]": true,
    "top-1/2": true,
    "h-[calc(100%-2px)]": true,
    "rounded-none": true
  },
  "colorpicker__alpha": {
    "[grid-area:d]": true,
    "relative": true,
    "inline-flex": true,
    "h-3/4": true,
    "ml-2": true
  },
  "colorpicker__canvasAlpha": {
    "rounded-none": true
  },
  "colorpicker__controlAlpha": {
    "w-[4px]": true,
    "top-1/2": true,
    "h-[calc(100%-2px)]": true,
    "rounded-none": true
  },
  "colorpicker__eyeDropper": {
    "[grid-area:e]": true,
    "w-[2em]": true,
    "ml-2": true,
    "self-center": true,
    "justify-center": true,
    "justify-self-center": true,
    "inline-flex": true,
    "content-center": true,
    "items-center": true,
    "aspect-square": true,
    "cursor-pointer": true,
    "rounded-md": true,
    "border": true,
    "border-neutral-900": true,
    "text-neutral-900": true,
    "dark:border-neutral-100": true,
    "dark:text-neutral-100": true
  },
  "colorpicker__eyeDropperIcon": {
    "w-auto": true,
    "[&>svg]:w-[1em]": true
  },
  "colorpicker__formatField": {
    "flex": true,
    "items-center": true,
    "justify-center": true,
    "grow": true
  },
  "colorpicker__colorInputGroup": {
    "flex": true,
    "items-center": true,
    "justify-center": true,
    "grow": true
  },
  "colorpicker__fieldGroup": {
    "flex": true,
    "flex-col": true,
    "items-center": true,
    "justify-center": true,
    "w-full": true,
    "mr-1": true,
    "[&>input]:p-1": true,
    "[&>input]:text-sm": true,
    "[&>input]:text-neutral-900": true,
    "[&>input]:m-0": true,
    "[&>input]:grow": true,
    "[&>input]:shrink": true,
    "[&>input]:w-full": true,
    "[&>input]:border": true,
    "[&>input]:border-neutral-900": true,
    "[&>input]:rounded-md": true,
    "[&>input]:text-center": true,
    "[&>input]:appearance-none": true,
    "[&>input::-webkit-outer-spin-button]:appearance-none": true,
    "[&>input::-webkit-inner-spin-button]:appearance-none": true,
    "[&>input::-webkit-inner-spin-button]:m-0": true,
    "[&>input:focus]:outline-none": true,
    "[&>input:focus]:ring-2": true,
    "max-[431px]:[&>input]:text-base": true,
    "dark:[&>input]:text-neutral-100": true,
    "dark:[&>input]:border-neutral-100": true
  },
  "colorpicker__colorField": {
    "bg-transparent": true,
    "text-neutral-900": true,
    "border": true,
    "border-neutral-900": true,
    "dark:text-neutral-100": true,
    "dark:border-neutral-100": true
  },
  "colorpicker__fieldLabel": {
    "text-xs": true,
    "text-neutral-900": true,
    "mt-2": true,
    "dark:text-neutral-100": true
  },
  "colorpicker__formatSwitcher": {
    "flex": true,
    "justify-end": true,
    "self-start": true,
    "shrink-0": true,
    "p-1": true,
    "mt-0.5": true,
    "text-neutral-900": true,
    "select-none": true,
    "dark:text-neutral-100": true
  },
  "colorpicker__switchIcon": {
    "[&>svg]:w-3": true
  },
  "colorpicker__swatches": {
    "inline-flex": true,
    "flex-wrap": true,
    "w-full": true,
    "justify-self-center": true,
    "mx-auto": true,
    "pt-2": true,
    "pb-2": true,
    "mt-2": true,
    "-mb-2": true,
    "border-t": true,
    "border-neutral-900": true,
    "overflow-auto": true,
    "max-h-[200px]": true,
    "select-none": true,
    "dark:border-neutral-100": true,
    "first:-mt-2": true,
    "first:last:-mb-2": true,
    "first:border-t-0": true
  },
  "colorpicker__swatchGroup": {
    "flex": true,
    "flex-wrap": true,
    "w-full": true,
    "mb-2": true,
    "last:mb-0": true
  },
  "colorpicker__swatchGroupLabel": {
    "ml-1": true,
    "block": true,
    "w-full": true,
    "text-sm": true,
    "text-neutral-900": true,
    "dark:text-neutral-100": true
  },
  "colorpicker__swatch": {
    "relative": true,
    "text-base": true,
    "w-[calc((100%/10)-0.5em)]": true,
    "max-w-[22px]": true,
    "m-[0.16em]": true,
    "cursor-pointer": true,
    "before:content-['']": true,
    "before:absolute": true,
    "before:inset-0": true,
    "before:shadow-[inset_0_0_0_1px_rgba(0,0,0,0.2)]": true,
    "before:pointer-events-none": true,
    "before:z-[2]": true,
    "dark:before:shadow-[inset_0_0_0_1px_rgba(255,255,255,0.2)]": true,
    "data-[active=true]:after:content-['']": true,
    "data-[active=true]:after:block": true,
    "data-[active=true]:after:absolute": true,
    "data-[active=true]:after:w-1.5": true,
    "data-[active=true]:after:h-1.5": true,
    "data-[active=true]:after:top-1/2": true,
    "data-[active=true]:after:left-1/2": true,
    "data-[active=true]:after:pointer-events-none": true,
    "data-[active=true]:after:rounded-full": true,
    "data-[active=true]:after:-translate-x-1/2": true,
    "data-[active=true]:after:-translate-y-1/2": true,
    "data-[active=true]:after:bg-white": true,
    "data-[active=true]:after:z-[2]": true,
    "data-[active=true]:after:shadow-[0_0_0_1px_rgba(0,0,0,0.33)]": true,
    "[&>canvas]:block": true,
    "[&>canvas]:w-full": true,
    "[&>canvas]:aspect-square": true,
    "[&>canvas:focus-visible]:outline-none": true,
    "[&>canvas:focus-visible]:ring-2": true
  },
  "colorpicker__help": {
    "text-xs": true,
    "text-neutral-500": true,
    "whitespace-pre-line": true,
    "dark:text-neutral-100": true,
    "mt-1.5": true,
    "group-data-[inline]:-mt-1": true,
    "group-data-[inline]:mb-2": true
  },
  "colorpicker__messages": {
    "list-none": true,
    "p-0": true,
    "mt-1": true,
    "mb-0": true
  },
  "colorpicker__message": {
    "text-red-600": true,
    "mb-1": true,
    "text-sm": true,
    "dark:text-red-400": true
  },
  "datepicker__outer": {
    "group": true,
    "mb-4": true,
    "data-[disabled]:opacity-50": true,
    "data-[disabled]:select-none": true,
    "data-[disabled]:pointer-events-none": true
  },
  "datepicker__wrapper": {
    "": true
  },
  "datepicker__label": {
    "block": true,
    "mb-1": true,
    "font-medium": true,
    "text-sm": true,
    "text-neutral-700": true,
    "dark:text-neutral-100": true
  },
  "datepicker__inner": {
    "relative": true
  },
  "datepicker__prefixIcon": {
    "flex": true,
    "shrink-0": true,
    "items-center": true,
    "text-base": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "[&>svg]:w-full": true
  },
  "datepicker__suffixIcon": {
    "flex": true,
    "items-center": true,
    "text-base": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "shrink-0": true,
    "[&>svg]:w-full": true
  },
  "datepicker__removeSelection": {
    "appearance-none": true,
    "flex": true,
    "self-stretch": true,
    "p-0": true,
    "cursor-pointer": true,
    "text-neutral-700": true,
    "dark:text-neutral-200": true
  },
  "datepicker__clearIcon": {
    "flex": true,
    "self-stretch": true,
    "grow-0": true,
    "shrink-0": true,
    "w-6": true,
    "[&>svg]:w-full": true,
    "[&>svg]:mr-2": true,
    "[&>svg]:my-auto": true,
    "[&>svg]:max-w-[1rem]": true,
    "[&>svg]:max-h-4": true
  },
  "datepicker__overlay": {
    "dark:text-neutral-100": true,
    "text-neutral-700": true
  },
  "datepicker__overlayInner": {
    "": true
  },
  "datepicker__overlayPlaceholder": {
    "dark:text-neutral-100": true,
    "text-neutral-400": true
  },
  "datepicker__overlayChar": {
    "text-neutral-900": true,
    "dark:text-neutral-100": true
  },
  "datepicker__overlayEnum": {
    "text-neutral-900": true,
    "dark:text-neutral-100": true
  },
  "datepicker__overlayLiteral": {
    "text-neutral-900": true,
    "dark:text-neutral-100": true
  },
  "datepicker__openButton": {
    "appearance-none": true,
    "border-0": true,
    "bg-transparent": true,
    "flex": true,
    "p-0": true,
    "self-stretch": true,
    "cursor-pointer": true,
    "focus-visible:outline-none": true,
    "focus-visible:text-white": true,
    "focus-visible:bg-sky-500": true
  },
  "datepicker__calendarIcon": {
    "flex": true,
    "w-10": true,
    "grow-0": true,
    "shrink-0": true,
    "self-stretch": true,
    "select-none": true,
    "[&>svg]:w-full": true,
    "[&>svg]:m-auto": true,
    "[&>svg]:max-h-[1em]": true,
    "[&>svg]:max-w-[1em]": true,
    "text-neutral-700": true,
    "dark:text-neutral-100": true
  },
  "datepicker__panelWrapper": {
    "absolute": true,
    "top-[calc(100%_+_0.5em)]": true,
    "drop-shadow-[0_0_1.25em_rgba(0,0,0,.25)]": true,
    "rounded-md": true,
    "p-5": true,
    "bg-white": true,
    "z-10": true,
    "text-neutral-700": true,
    "dark:bg-neutral-900": true,
    "dark:border-neutral-100": true
  },
  "datepicker__panelHeader": {
    "grid": true,
    "grid-cols-[2.5em_1fr_2.5em]": true,
    "justify-center": true,
    "items-center": true,
    "border-b-2": true,
    "mb-4": true,
    "pb-4": true,
    "dark:border-neutral-100": true
  },
  "datepicker__monthsHeader": {
    "flex": true,
    "items-center": true,
    "justify-center": true,
    "col-start-2": true,
    "col-end-2": true
  },
  "datepicker__yearsHeader": {
    "flex": true,
    "items-center": true,
    "justify-center": true,
    "col-start-2": true,
    "col-end-2": true,
    "dark:text-neutral-100": true
  },
  "datepicker__timeHeader": {
    "flex": true,
    "items-center": true,
    "justify-center": true,
    "col-start-2": true,
    "col-end-2": true
  },
  "datepicker__daysHeader": {
    "flex": true,
    "items-center": true,
    "justify-center": true
  },
  "datepicker__prev": {
    "mr-auto": true,
    "px-3": true,
    "py-1": true,
    "hover:bg-neutral-100": true,
    "hover:rounded-lg": true,
    "col-start-1": true,
    "col-end-1": true
  },
  "datepicker__next": {
    "ml-auto": true,
    "px-3": true,
    "py-1": true,
    "hover:bg-neutral-100": true,
    "hover:rounded": true,
    "col-start-3": true,
    "col-end-3": true
  },
  "datepicker__prevLabel": {
    "hidden": true
  },
  "datepicker__nextLabel": {
    "hidden": true
  },
  "datepicker__prevIcon": {
    "flex": true,
    "w-3": true,
    "select-none": true,
    "[&>svg]:w-full": true,
    "dark:text-neutral-100": true
  },
  "datepicker__nextIcon": {
    "flex": true,
    "w-3": true,
    "select-none": true,
    "[&>svg]:w-full": true,
    "dark:text-neutral-100": true
  },
  "datepicker__months": {
    "flex": true,
    "flex-wrap": true
  },
  "datepicker__month": {
    "flex": true,
    "items-center": true,
    "justify-center": true,
    "w-[calc(33%_-_1em)]": true,
    "m-2": true,
    "p-2": true,
    "rounded-md": true,
    "bg-neutral-200": true,
    "aria-selected:bg-sky-500": true,
    "aria-selected:text-white": true,
    "focus:outline": true,
    "focus:outline-2": true,
    "focus:outline-sky-500": true,
    "focus:outline-offset-2": true,
    "focus:bg-white": true,
    "focus:text-black": true,
    "data-[is-extra=true]:opacity-25": true,
    "disabled:opacity-50": true,
    "disabled:cursor-default": true,
    "disabled:pointer-events-none": true,
    "group-data-[disabled]:opacity-50": true,
    "group-data-[disabled]:pointer-events-none": true,
    "dark:text-neutral-100": true,
    "dark:aria-selected:!bg-neutral-100": true,
    "dark:aria-selected:!text-black": true,
    "dark:focus:bg-black": true
  },
  "datepicker__years": {
    "flex": true,
    "flex-wrap": true,
    "max-w-[35em]": true
  },
  "datepicker__year": {
    "flex": true,
    "items-center": true,
    "justify-center": true,
    "w-[calc(20%_-_1em)]": true,
    "m-2": true,
    "p-2": true,
    "rounded-md": true,
    "bg-neutral-200": true,
    "aria-selected:bg-sky-500": true,
    "aria-selected:text-white": true,
    "focus:outline": true,
    "focus:outline-2": true,
    "focus:outline-sky-500": true,
    "focus:outline-offset-2": true,
    "focus:bg-white": true,
    "focus:text-black": true,
    "data-[is-extra=true]:opacity-25": true,
    "disabled:opacity-50": true,
    "disabled:cursor-default": true,
    "disabled:pointer-events-none": true,
    "group-data-[disabled]:opacity-50": true,
    "group-data-[disabled]:pointer-events-none": true,
    "dark:text-neutral-100": true,
    "dark:aria-selected:!bg-neutral-100": true,
    "dark:aria-selected:!text-black": true,
    "ark:focus:bg-black": true
  },
  "datepicker__weekDays": {
    "flex": true
  },
  "datepicker__weekDay": {
    "flex": true,
    "w-[2.25em]": true,
    "h-[1em]": true,
    "m-1": true,
    "items-center": true,
    "justify-center": true,
    "rounded-md": true,
    "font-medium": true,
    "lowercase": true,
    "dark:text-neutral-100": true
  },
  "datepicker__week": {
    "flex": true,
    "disabled:opacity-50": true,
    "disabled:cursor-default": true,
    "disabled:pointer-events-none": true,
    "group-data-[disabled]:opacity-50": true,
    "group-data-[disabled]:pointer-events-none": true
  },
  "datepicker__dayCell": {
    "flex": true,
    "items-center": true,
    "justify-center": true,
    "w-[2.25em]": true,
    "h-[2.25em]": true,
    "m-1": true,
    "p-2": true,
    "rounded-md": true,
    "bg-neutral-200": true,
    "aria-selected:bg-sky-500": true,
    "aria-selected:text-white": true,
    "focus:outline": true,
    "focus:outline-2": true,
    "focus:outline-sky-500": true,
    "focus:outline-offset-2": true,
    "focus:bg-white": true,
    "focus:text-black": true,
    "data-[is-extra=true]:opacity-25": true,
    "disabled:opacity-50": true,
    "disabled:cursor-default": true,
    "disabled:pointer-events-none": true,
    "data-[disabled]:opacity-50": true,
    "data-[disabled]:pointer-events-none": true,
    "dark:text-neutral-100": true,
    "dark:bg-neutral-700": true,
    "dark:aria-selected:bg-neutral-100": true,
    "dark:aria-selected:text-black": true,
    "dark:focus:bg-black": true
  },
  "datepicker__timeInput": {
    "w-full": true,
    "border-2": true,
    "border-neutral-300": true,
    "rounded-md": true,
    "p-2": true,
    "my-[2em]": true,
    "focus-visible:outline-sky-500": true,
    "[color-scheme:light]": true,
    "dark:[color-scheme:dark]": true,
    "dark:text-neutral-100": true,
    "dark:border-neutral-100": true
  },
  "datepicker__dayButton": {
    "appearance-none": true,
    "cursor-pointer": true,
    "px-3": true,
    "py-1": true,
    "border-2": true,
    "rounded-lg": true,
    "mx-1": true,
    "hover:border-sky-500": true,
    "dark:text-neutral-100": true,
    "dark:border-neutral-100": true
  },
  "datepicker__monthButton": {
    "appearance-none": true,
    "cursor-pointer": true,
    "px-3": true,
    "py-1": true,
    "border-2": true,
    "rounded-lg": true,
    "mx-1": true,
    "hover:border-sky-500": true,
    "dark:text-neutral-100": true,
    "dark:border-neutral-100": true
  },
  "datepicker__yearButton": {
    "appearance-none": true,
    "cursor-pointer": true,
    "px-3": true,
    "py-1": true,
    "border-2": true,
    "rounded-lg": true,
    "mx-1": true,
    "hover:border-sky-500": true,
    "dark:text-neutral-100": true,
    "dark:border-neutral-100": true
  },
  "datepicker__help": {
    "text-xs": true,
    "text-neutral-500": true,
    "whitespace-pre-line": true,
    "dark:text-neutral-100": true
  },
  "datepicker__messages": {
    "list-none": true,
    "p-0": true,
    "mt-1": true,
    "mb-0": true
  },
  "datepicker__message": {
    "text-red-600": true,
    "mb-1": true,
    "text-sm": true,
    "dark:text-red-400": true
  },
  "dropdown__outer": {
    "group": true,
    "mb-4": true,
    "data-[disabled]:opacity-50": true,
    "data-[disabled]:select-none": true,
    "data-[disabled]:pointer-events-none": true
  },
  "dropdown__wrapper": {
    "": true
  },
  "dropdown__label": {
    "block": true,
    "mb-1": true,
    "font-medium": true,
    "text-sm": true,
    "text-neutral-700": true,
    "dark:text-neutral-100": true
  },
  "dropdown__inner": {
    "": true
  },
  "dropdown__prefixIcon": {
    "flex": true,
    "shrink-0": true,
    "items-center": true,
    "text-base": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "[&>svg]:w-full": true
  },
  "dropdown__suffixIcon": {
    "flex": true,
    "items-center": true,
    "text-base": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "shrink-0": true,
    "[&>svg]:w-full": true
  },
  "dropdown__selector": {
    "rounded-md": true,
    "outline-neutral-400": true,
    "dark:text-neutral-100": true,
    "dark:data-[placeholder]:text-neutral-500": true
  },
  "dropdown__optionLoading": {
    "text-neutral-700": true
  },
  "dropdown__selectionWrapper": {
    "": true
  },
  "dropdown__selection": {
    "text-neutral-700": true
  },
  "dropdown__selectionsWrapper": {
    "flex": true,
    "w-[calc(100%_-_35px)]": true,
    "overflow-hidden": true,
    "text-neutral-700": true,
    "dark:text-neutral-100": true
  },
  "dropdown__selections": {
    "inline-flex": true,
    "items-center": true,
    "px-2.5": true
  },
  "dropdown__selectionsItem": {
    "whitespace-nowrap": true,
    "mr-1": true,
    "last:mr-0": true
  },
  "dropdown__truncationCount": {
    "flex": true,
    "items-center": true,
    "whitespace-nowrap": true,
    "justify-center": true,
    "rounded": true,
    "text-white": true,
    "bg-neutral-500": true,
    "font-bold": true,
    "text-xs": true,
    "px-1": true,
    "py-0.5": true
  },
  "dropdown__tagsWrapper": {
    "max-w-[calc(100%_-_35px)]": true
  },
  "dropdown__tags": {
    "flex": true,
    "items-center": true,
    "flex-wrap": true,
    "gap-1": true,
    "mx-2": true,
    "my-1.5": true
  },
  "dropdown__tag": {
    "flex": true,
    "items-center": true,
    "rounded-full": true,
    "bg-neutral-200": true,
    "text-xs": true,
    "text-black": true,
    "py-1": true,
    "px-2.5": true,
    "cursor-default": true
  },
  "dropdown__tagLabel": {
    "px-1": true
  },
  "dropdown__removeSelection": {
    "text-neutral-700": true,
    "cursor-pointer": true
  },
  "dropdown__selectIcon": {
    "text-neutral-600": true,
    "dark:text-neutral-100": true
  },
  "dropdown__listbox": {
    "": true
  },
  "dropdown__emptyMessage": {
    "": true
  },
  "dropdown__emptyMessageInner": {
    "": true
  },
  "dropdown__listItem": {
    "": true
  },
  "dropdown__selectedIcon": {
    "": true
  },
  "dropdown__option": {
    "py-2": true,
    "sm:text-sm": true
  },
  "dropdown__help": {
    "text-xs": true,
    "text-neutral-500": true,
    "whitespace-pre-line": true,
    "dark:text-neutral-100": true
  },
  "dropdown__messages": {
    "list-none": true,
    "p-0": true,
    "mt-1": true,
    "mb-0": true
  },
  "dropdown__message": {
    "text-red-600": true,
    "mb-1": true,
    "text-sm": true,
    "dark:text-red-400": true
  },
  "mask__outer": {
    "group": true,
    "mb-4": true,
    "data-[disabled]:opacity-50": true,
    "data-[disabled]:select-none": true,
    "data-[disabled]:pointer-events-none": true
  },
  "mask__wrapper": {
    "": true
  },
  "mask__label": {
    "block": true,
    "mb-1": true,
    "font-medium": true,
    "text-sm": true,
    "text-neutral-700": true,
    "dark:text-neutral-100": true
  },
  "mask__inner": {
    "relative": true
  },
  "mask__prefixIcon": {
    "flex": true,
    "shrink-0": true,
    "items-center": true,
    "text-base": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "[&>svg]:w-full": true
  },
  "mask__suffixIcon": {
    "flex": true,
    "items-center": true,
    "text-base": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "shrink-0": true,
    "[&>svg]:w-full": true
  },
  "mask__input": {
    "appearance-none": true,
    "border-none": true,
    "focus:outline-none": true,
    "focus:ring-0": true,
    "focus:shadow-none": true,
    "sm:text-sm": true,
    "placeholder:italic": true,
    "transition-colors": true,
    "[color-scheme:light]": true,
    "dark:[color-scheme:dark]": true,
    "dark:placeholder:text-neutral-500": true,
    "dark:text-neutral-100": true,
    "text-neutral-700": true,
    "group-data-[has-overlay]:!caret-neutral-900": true,
    "dark:group-data-[has-overlay]:!caret-neutral-100": true
  },
  "mask__overlay": {
    "dark:text-neutral-100": true,
    "text-neutral-700": true
  },
  "mask__overlayInner": {
    "": true
  },
  "mask__overlayPlaceholder": {
    "text-neutral-900": true,
    "dark:text-neutral-100": true
  },
  "mask__overlayChar": {
    "text-neutral-900": true,
    "dark:text-neutral-100": true
  },
  "mask__overlayEnum": {
    "text-neutral-900": true,
    "dark:text-neutral-100": true
  },
  "mask__overlayLiteral": {
    "text-neutral-900": true,
    "dark:text-neutral-100": true
  },
  "mask__help": {
    "text-xs": true,
    "text-neutral-500": true,
    "whitespace-pre-line": true,
    "dark:text-neutral-100": true
  },
  "mask__messages": {
    "list-none": true,
    "p-0": true,
    "mt-1": true,
    "mb-0": true
  },
  "mask__message": {
    "text-red-600": true,
    "mb-1": true,
    "text-sm": true,
    "dark:text-red-400": true
  },
  "rating__outer": {
    "group": true,
    "mb-4": true,
    "data-[disabled]:opacity-50": true,
    "data-[disabled]:select-none": true,
    "data-[disabled]:pointer-events-none": true
  },
  "rating__wrapper": {
    "": true
  },
  "rating__label": {
    "block": true,
    "mb-1": true,
    "font-medium": true,
    "text-sm": true,
    "text-neutral-700": true,
    "dark:text-neutral-100": true
  },
  "rating__inner": {
    "relative": true,
    "flex": true,
    "items-center": true,
    "w-[8em]": true,
    "disabled:bg-transparent": true
  },
  "rating__prefixIcon": {
    "flex": true,
    "shrink-0": true,
    "items-center": true,
    "text-base": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "[&>svg]:w-full": true
  },
  "rating__suffixIcon": {
    "flex": true,
    "items-center": true,
    "text-base": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "shrink-0": true,
    "[&>svg]:w-full": true
  },
  "rating__itemsWrapper": {
    "w-full": true
  },
  "rating__onItems": {
    "text-yellow-400": true
  },
  "rating__onItemWrapper": {
    "[&>*]:w-full": true,
    "[&>svg]:h-auto": true,
    "[&>svg]:max-w-none": true,
    "[&>svg]:max-h-none": true
  },
  "rating__offItems": {
    "text-neutral-500": true
  },
  "rating__offItemWrapper": {
    "[&>*]:w-full": true,
    "[&>svg]:h-auto": true,
    "[&>svg]:max-w-none": true,
    "[&>svg]:max-h-none": true
  },
  "repeater__fieldset": {
    "border": true,
    "border-neutral-400": true,
    "rounded-md": true,
    "py-4": true,
    "px-5": true
  },
  "repeater__help": {
    "text-xs": true,
    "text-neutral-500": true,
    "whitespace-pre-line": true,
    "dark:text-neutral-100": true,
    "mb-2": true,
    "mt-1.5": true
  },
  "repeater__inner": {
    "": true
  },
  "repeater__items": {
    "": true
  },
  "repeater__item": {
    "flex": true,
    "w-full": true,
    "mb-1": true,
    "rounded-md": true,
    "border": true,
    "border-neutral-200": true
  },
  "repeater__content": {
    "grow": true,
    "p-3": true,
    "flex": true,
    "flex-col": true,
    "align-center": true
  },
  "repeater__controls": {
    "flex": true,
    "flex-col": true,
    "items-center": true,
    "justify-center": true,
    "p-3": true
  },
  "repeater__upControl": {
    "text-neutral-700": true,
    "hover:text-sky-500": true,
    "disabled:hover:text-inherit": true,
    "disabled:opacity-25": true,
    "dark:text-neutral-200": true
  },
  "repeater__removeControl": {
    "text-neutral-700": true,
    "hover:text-sky-500": true,
    "disabled:hover:text-inherit": true,
    "disabled:opacity-25": true,
    "dark:text-neutral-200": true
  },
  "repeater__insertControl": {
    "text-neutral-700": true,
    "hover:text-sky-500": true,
    "disabled:hover:text-inherit": true,
    "disabled:opacity-25": true,
    "dark:text-neutral-200": true
  },
  "repeater__downControl": {
    "text-neutral-700": true,
    "hover:text-sky-500": true,
    "disabled:hover:text-inherit": true,
    "disabled:opacity-25": true,
    "dark:text-neutral-200": true
  },
  "repeater__controlLabel": {
    "absolute": true,
    "opacity-0": true,
    "pointer-events-none": true
  },
  "repeater__moveDownIcon": {
    "block": true,
    "w-3": true,
    "my-1": true
  },
  "repeater__moveUpIcon": {
    "block": true,
    "w-3": true,
    "my-1": true
  },
  "repeater__removeIcon": {
    "block": true,
    "w-5": true,
    "my-1": true
  },
  "repeater__addIcon": {
    "block": true,
    "w-5": true,
    "my-1": true
  },
  "repeater__messages": {
    "list-none": true,
    "p-0": true,
    "mt-1": true,
    "mb-0": true
  },
  "repeater__message": {
    "text-red-600": true,
    "mb-1": true,
    "text-sm": true,
    "dark:text-red-400": true
  },
  "slider__outer": {
    "group": true,
    "mb-4": true,
    "data-[disabled]:opacity-50": true,
    "data-[disabled]:select-none": true,
    "data-[disabled]:pointer-events-none": true
  },
  "slider__wrapper": {
    "": true
  },
  "slider__label": {
    "block": true,
    "mb-1": true,
    "font-medium": true,
    "text-sm": true,
    "text-neutral-700": true,
    "dark:text-neutral-100": true
  },
  "slider__help": {
    "text-xs": true,
    "text-neutral-500": true,
    "whitespace-pre-line": true,
    "dark:text-neutral-100": true,
    "mt-0": true,
    "mb-1": true
  },
  "slider__prefixIcon": {
    "flex": true,
    "shrink-0": true,
    "items-center": true,
    "text-base": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "[&>svg]:w-full": true,
    "text-neutral-700": true,
    "dark:text-neutral-100": true
  },
  "slider__Icon": {
    "text-neutral-700": true,
    "dark:text-neutral-100": true
  },
  "slider__sliderInner": {
    "flex": true,
    "items-center": true,
    "py-1": true,
    "[&>.formkit-max-value]:mb-0": true,
    "[&>.formkit-max-value]:ml-8": true,
    "[&>.formkit-max-value]:shrink": true,
    "[&>.formkit-max-value]:grow-0": true,
    "[&>.formkit-icon]:bg-none": true,
    "[&>.formkit-icon]:border-none": true,
    "[&>.formkit-icon]:p-0": true,
    "[&>.formkit-icon]:w-4": true,
    "[&>.formkit-prefix-icon]:mr-2": true,
    "[&>.formkit-suffix-icon]:ml-2": true,
    "[&[data-has-mark-labels=\"true\"]_.formkit-track]:mb-4": true
  },
  "slider__track": {
    "grow": true,
    "relative": true,
    "z-[3]": true,
    "py-1": true,
    "mb-2": true,
    "right-2": true
  },
  "slider__chart": {
    "relative": true,
    "z-[2]": true,
    "mb-2": true,
    "flex": true,
    "justify-between": true,
    "items-center": true,
    "w-full": true,
    "aspect-[3/1]": true
  },
  "slider__chartBar": {
    "absolute": true,
    "bottom-0": true,
    "h-full": true,
    "bg-neutral-400": true,
    "opacity-[.66]": true,
    "data-[active=false]:opacity-[.25]": true
  },
  "slider__trackWrapper": {
    "px-[2px]": true,
    "rounded-full": true,
    "bg-neutral-200": true
  },
  "slider__trackInner": {
    "h-[6px]": true,
    "mx-[2px]": true,
    "relative": true
  },
  "slider__fill": {
    "h-full": true,
    "rounded-full": true,
    "absolute": true,
    "top-0": true,
    "mx-[-4px]": true,
    "bg-sky-500": true
  },
  "slider__marks": {
    "absolute": true,
    "pointer-events-none": true,
    "left-0": true,
    "right-0": true,
    "top-0": true,
    "bottom-0": true
  },
  "slider__mark": {
    "absolute": true,
    "top-1/2": true,
    "w-[3px]": true,
    "h-[3px]": true,
    "rounded-full": true,
    "-translate-x-1/2": true,
    "-translate-y-1/2": true,
    "bg-neutral-400": true,
    "data-[active=true]:bg-white": true
  },
  "slider__markLabel": {
    "absolute": true,
    "top-[calc(100%+0.5em)]": true,
    "left-1/2": true,
    "text-neutral-400": true,
    "text-[0.66em]": true,
    "-translate-x-1/2": true
  },
  "slider__handles": {
    "m-0": true,
    "p-0": true,
    "list-none": true
  },
  "slider__handle": {
    "group": true,
    "w-4": true,
    "h-4": true,
    "rounded-full": true,
    "bg-white": true,
    "absolute": true,
    "top-1/2": true,
    "left-0": true,
    "z-[2]": true,
    "-translate-x-1/2": true,
    "-translate-y-1/2": true,
    "shadow-[inset_0_0_0_1px_rgba(0,0,0,0.1),0_1px_2px_0_rgba(0,0,0,0.8)]": true,
    "focus-visible:outline-0": true,
    "focus-visible:ring-2": true,
    "ring-sky-500": true,
    "data-[is-target=\"true\"]:z-[3]": true
  },
  "slider__handleInner": {
    "": true
  },
  "slider__tooltip": {
    "absolute": true,
    "bottom-full": true,
    "left-1/2": true,
    "-translate-x-1/2": true,
    "-translate-y-[4px]": true,
    "bg-sky-500": true,
    "text-white": true,
    "py-1": true,
    "px-2": true,
    "text-xs": true,
    "leading-none": true,
    "whitespace-nowrap": true,
    "rounded-sm": true,
    "opacity-0": true,
    "pointer-events-none": true,
    "transition-opacity": true,
    "after:content-[\"\"]": true,
    "after:absolute": true,
    "after:top-full": true,
    "after:left-1/2": true,
    "after:-translate-x-1/2": true,
    "after:-translate-y-[1px]": true,
    "after:border-4": true,
    "after:border-transparent": true,
    "after:border-t-sky-500": true,
    "group-hover:opacity-100": true,
    "group-focus-visible:opacity-100": true,
    "group-data-[show-tooltip=\"true\"]:opacity-100": true
  },
  "slider__linkedValues": {
    "flex": true,
    "items-start": true,
    "justify-between": true
  },
  "slider__minValue": {
    "grow": true,
    "max-w-[45%]": true,
    "mb-0": true,
    "relative": true,
    "[&_.formkit-inner::after]:content-[\"\"]": true,
    "[&_.formkit-inner::after]:absolute": true,
    "[&_.formkit-inner::after]:left-[105%]": true,
    "[&_.formkit-inner::after]:-translate-y-1/2": true,
    "[&_.formkit-inner::after]:w-[10%]": true,
    "[&_.formkit-inner::after]:h-[1px]": true,
    "[&_.formkit-inner::after]:bg-neutral-500": true
  },
  "slider__maxValue": {
    "grow": true,
    "max-w-[45%]": true,
    "mb-0": true,
    "relative": true
  },
  "slider__messages": {
    "list-none": true,
    "p-0": true,
    "mt-1": true,
    "mb-0": true
  },
  "slider__message": {
    "text-red-600": true,
    "mb-1": true,
    "text-sm": true,
    "dark:text-red-400": true
  },
  "taglist__outer": {
    "group": true,
    "mb-4": true,
    "data-[disabled]:opacity-50": true,
    "data-[disabled]:select-none": true,
    "data-[disabled]:pointer-events-none": true
  },
  "taglist__wrapper": {
    "": true
  },
  "taglist__label": {
    "block": true,
    "mb-1": true,
    "font-medium": true,
    "text-sm": true,
    "text-neutral-700": true,
    "dark:text-neutral-100": true
  },
  "taglist__prefixIcon": {
    "flex": true,
    "shrink-0": true,
    "items-center": true,
    "text-base": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "[&>svg]:w-full": true
  },
  "taglist__suffixIcon": {
    "flex": true,
    "items-center": true,
    "text-base": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "shrink-0": true,
    "[&>svg]:w-full": true
  },
  "taglist__tags": {
    "flex": true,
    "items-center": true,
    "flex-wrap": true,
    "w-full": true,
    "gap-1": true,
    "mx-2": true,
    "my-1.5": true
  },
  "taglist__tagWrapper": {
    "mr-1": true,
    "focus:outline-none": true,
    "focus:text-white": true,
    "[&>div]:focus:bg-sky-500": true,
    "[&>div>button]:focus:text-white": true
  },
  "taglist__tag": {
    "flex": true,
    "items-center": true,
    "rounded-full": true,
    "bg-neutral-200": true,
    "text-xs": true,
    "text-black": true,
    "py-1": true,
    "px-2.5": true,
    "cursor-default": true
  },
  "taglist__tagLabel": {
    "px-1": true
  },
  "taglist__removeSelection": {
    "text-neutral-700": true
  },
  "taglist__input": {
    "appearance-none": true,
    "border-none": true,
    "focus:outline-none": true,
    "focus:ring-0": true,
    "focus:shadow-none": true,
    "sm:text-sm": true,
    "text-black": true,
    "placeholder:italic": true,
    "transition-colors": true,
    "[color-scheme:light]": true,
    "dark:[color-scheme:dark]": true,
    "dark:placeholder:text-neutral-500": true,
    "dark:text-neutral-100": true,
    "px-1": true,
    "py-1": true,
    "w-[0%]": true,
    "grow": true
  },
  "taglist__loaderIcon": {
    "inline-flex": true,
    "items-center": true,
    "w-4": true,
    "text-neutral-600": true,
    "animate-spin": true,
    "dark:text-neutral-100": true
  },
  "taglist__listboxButton": {
    "flex": true,
    "w-full": true,
    "justify-between": true,
    "items-center": true,
    "min-h-[2.25em]": true,
    "[&u]": true,
    "cursor-default": true
  },
  "taglist__selectIcon": {
    "text-neutral-700": true,
    "dark:text-neutral-100": true
  },
  "taglist__dropdownWrapper": {
    "": true
  },
  "taglist__listbox": {
    "": true
  },
  "taglist__emptyMessage": {
    "": true
  },
  "taglist__emptyMessageInner": {
    "": true
  },
  "taglist__listItem": {
    "": true
  },
  "taglist__selectedIcon": {
    "": true
  },
  "taglist__option": {
    "py-2": true,
    "sm:text-sm": true
  },
  "taglist__help": {
    "text-xs": true,
    "text-neutral-500": true,
    "whitespace-pre-line": true,
    "dark:text-neutral-100": true
  },
  "taglist__messages": {
    "list-none": true,
    "p-0": true,
    "mt-1": true,
    "mb-0": true
  },
  "taglist__message": {
    "text-red-600": true,
    "mb-1": true,
    "text-sm": true,
    "dark:text-red-400": true
  },
  "toggle__wrapper": {
    "flex": true,
    "flex-wrap": true,
    "items-center": true,
    "mb-1": true
  },
  "toggle__altLabel": {
    "block": true,
    "w-full": true,
    "mb-1": true,
    "font-medium": true,
    "text-sm": true,
    "text-neutral-700": true
  },
  "toggle__inner": {
    "inline-block": true,
    "mr-2": true
  },
  "toggle__input": {
    "appearance-none": true,
    "border-none": true,
    "focus:outline-none": true,
    "focus:ring-0": true,
    "focus:shadow-none": true,
    "sm:text-sm": true,
    "text-black": true,
    "placeholder:italic": true,
    "transition-colors": true,
    "[color-scheme:light]": true,
    "dark:[color-scheme:dark]": true,
    "dark:placeholder:text-neutral-500": true,
    "dark:text-neutral-100": true,
    "peer": true,
    "absolute": true,
    "opacity-0": true,
    "pointer-events-none": true
  },
  "toggle__track": {
    "p-0.5": true,
    "min-w-[3em]": true,
    "relative": true,
    "rounded-full": true,
    "transition-all": true,
    "bg-neutral-400": true,
    "peer-checked:bg-sky-500": true,
    "peer-checked:[&>div:last-child]:left-full": true,
    "peer-checked:[&>div:last-child]:-translate-x-full": true,
    "peer-checked:[&>div:first-child:not(:last-child)]:left-0": true,
    "peer-checked:[&>div:first-child:not(:last-child)]:translate-x-0": true
  },
  "toggle__innerLabel": {
    "text-[10px]": true,
    "font-medium": true,
    "absolute": true,
    "left-full": true,
    "top-1/2": true,
    "-translate-x-full": true,
    "-translate-y-1/2": true,
    "px-1": true,
    "text-neutral-700": true
  },
  "toggle__thumb": {
    "relative": true,
    "left-0": true,
    "aspect-square": true,
    "rounded-full": true,
    "transition-all": true,
    "w-5": true,
    "bg-neutral-100": true
  },
  "toggle__thumbIcon": {
    "text-neutral-700": true
  },
  "toggle__valueLabel": {
    "font-medium": true,
    "text-sm": true,
    "text-neutral-700": true
  },
  "toggle__help": {
    "text-xs": true,
    "text-neutral-500": true,
    "whitespace-pre-line": true,
    "dark:text-neutral-100": true
  },
  "toggle__messages": {
    "list-none": true,
    "p-0": true,
    "mt-1": true,
    "mb-0": true
  },
  "toggle__message": {
    "text-red-600": true,
    "mb-1": true,
    "text-sm": true,
    "dark:text-red-400": true
  },
  "togglebuttons__options": {
    "group/options": true,
    "inline-flex": true,
    "data-[vertical=true]:flex-col": true
  },
  "togglebuttons__option": {
    "group/option": true
  },
  "togglebuttons__input": {
    "appearance-none": true,
    "border-none": true,
    "focus:outline-none": true,
    "focus:ring-0": true,
    "focus:shadow-none": true,
    "sm:text-sm": true,
    "text-black": true,
    "placeholder:italic": true,
    "transition-colors": true,
    "[color-scheme:light]": true,
    "dark:[color-scheme:dark]": true,
    "dark:placeholder:text-neutral-500": true,
    "dark:text-neutral-100": true,
    "!px-4": true,
    "group-data-[vertical=true]/options:w-full": true,
    "justify-center": true,
    "bg-neutral-200": true,
    "disabled:opacity-50": true,
    "disabled:cursor-not-allowed": true,
    "group-data-[disabled]:disabled:opacity-50": true,
    "aria-[pressed=true]:bg-white": true,
    "aria-[pressed=true]:text-black": true,
    "dark:aria-[pressed=true]:bg-white": true,
    "dark:aria-[pressed=true]:text-black": true,
    "group-[]/option:!rounded-none": true,
    "group-data-[vertical=false]/options:group-first/option:!rounded-md": true,
    "group-data-[vertical=true]/options:group-first/option:!rounded-md": true,
    "group-data-[vertical=false]/options:group-first/option:!rounded-tr-none": true,
    "group-data-[vertical=false]/options:group-first/option:!rounded-br-none": true,
    "group-data-[vertical=true]/options:group-first/option:!rounded-bl-none": true,
    "group-data-[vertical=true]/options:group-first/option:!rounded-br-none": true,
    "group-data-[vertical=false]/options:group-last/option:!rounded-md": true,
    "group-data-[vertical=true]/options:group-last/option:!rounded-md": true,
    "group-data-[vertical=false]/options:group-last/option:!rounded-tl-none": true,
    "group-data-[vertical=false]/options:group-last/option:!rounded-bl-none": true,
    "group-data-[vertical=true]/options:group-last/option:!rounded-tl-none": true,
    "group-data-[vertical=true]/options:group-last/option:!rounded-tr-none": true,
    "group-data-[vertical=false]/options:group-[]/option:!border-r-0": true,
    "group-data-[vertical=false]/options:group-last/option:!border-r": true,
    "group-data-[vertical=false]/options:group-[]/option:aria-[pressed=true]:border-x-neutral-100": true,
    "group-data-[vertical=false]/options:group-first/option:aria-[pressed=true]:border-l-neutral-900": true,
    "group-data-[vertical=false]/options:group-last/option:aria-[pressed=true]:border-r-neutral-900": true,
    "dark:group-data-[vertical=false]/options:group-[]/option:aria-[pressed=true]:border-x-neutral-900": true,
    "dark:group-data-[vertical=false]/options:group-first/option:aria-[pressed=true]:border-l-neutral-100": true,
    "dark:group-data-[vertical=false]/options:group-last/option:aria-[pressed=true]:border-r-neutral-100": true,
    "group-data-[vertical=true]/options:group-[]/option:!border-b-0": true,
    "group-data-[vertical=true]/options:group-last/option:!border-b": true,
    "group-data-[vertical=true]/options:group-[]/option:aria-[pressed=true]:border-y-neutral-100": true,
    "group-data-[vertical=true]/options:group-first/option:aria-[pressed=true]:border-t-neutral-900": true,
    "group-data-[vertical=true]/options:group-last/option:aria-[pressed=true]:border-b-neutral-900": true,
    "dark:group-data-[vertical=true]/options:group-[]/option:aria-[pressed=true]:border-y-neutral-900": true,
    "dark:group-data-[vertical=true]/options:group-first/option:aria-[pressed=true]:border-t-neutral-100": true,
    "dark:group-data-[vertical=true]/options:group-last/option:aria-[pressed=true]:border-b-neutral-100": true
  },
  "togglebuttons__inputInner": {
    "text-black": true
  },
  "transferlist__outer": {
    "group": true,
    "mb-4": true,
    "data-[disabled]:opacity-50": true,
    "data-[disabled]:select-none": true,
    "data-[disabled]:pointer-events-none": true,
    "[&_.dnd-placeholder]:bg-sky-500": true,
    "[&_.dnd-placeholder]:text-white": true,
    "[&_.dnd-placeholder_svg]:text-white": true,
    "[&_.dnd-children-hidden]:w-full": true,
    "[&_.dnd-children-hidden]:p-0": true,
    "[&_.dnd-children-hidden]:flex": true,
    "[&_.dnd-children-hidden]:flex-col": true,
    "[&_.dnd-children-hidden]:border-none": true,
    "[&_.dnd-children-hidden_span]:hidden": true,
    "[&_.dnd-children-hidden_.formkit-transferlist-option]:hidden": true,
    "[&_.dnd-multiple-selections_span]:inline-block": true,
    "[&_.dnd-multiple-selections_.formkit-transferlist-option]:inline-block": true,
    "dark:[&_.dnd-placeholder]:bg-neutral-100": true,
    "dark:[&_.dnd-placeholder]:text-neutral-900": true
  },
  "transferlist__fieldset": {
    "max-w-2xl": true
  },
  "transferlist__legend": {
    "font-bold": true,
    "text-md": true,
    "text-neutral-600": true,
    "dark:text-neutral-100": true
  },
  "transferlist__help": {
    "text-xs": true,
    "text-neutral-500": true,
    "whitespace-pre-line": true,
    "dark:text-neutral-100": true,
    "pb-2": true
  },
  "transferlist__wrapper": {
    "flex": true,
    "max-h-[350px]": true,
    "flex-col": true,
    "sm:flex-row": true,
    "justify-between": true,
    "w-full": true,
    "max-w-none": true
  },
  "transferlist__transferlist": {
    "sm:w-3/5": true,
    "shadow-md": true,
    "flex": true,
    "flex-col": true,
    "min-h-[350px]": true,
    "max-h-[350px]": true,
    "border": true,
    "rounded": true,
    "overflow-hidden": true,
    "select-none": true,
    "bg-neutral-50": true,
    "dark:bg-neutral-900": true,
    "dark:border-neutral-100": true
  },
  "transferlist__transferlistHeader": {
    "flex": true,
    "justify-between": true,
    "items-center": true,
    "border-b": true,
    "p-3": true,
    "text-neutral-700": true,
    "dark:border-neutral-100": true,
    "dark:text-neutral-100": true
  },
  "transferlist__transferlistHeaderLabel": {
    "": true
  },
  "transferlist__transferlistHeaderItemCount": {
    "ml-auto": true,
    "text-sm": true,
    "text-neutral-700": true,
    "dark:text-neutral-100": true
  },
  "transferlist__transferlistControls": {
    "bg-white": true,
    "px-3": true,
    "py-2": true,
    "border-b": true,
    "dark:border-neutral-100": true,
    "dark:bg-neutral-900": true
  },
  "transferlist__transferlistSearch": {
    "flex": true,
    "border": true,
    "rounded": true,
    "items-center": true,
    "text-neutral-600": true,
    "dark:text-neutral-100": true,
    "dark:border-neutral-100": true
  },
  "transferlist__transferlistSearchInput": {
    "border-none": true,
    "p-1": true,
    "w-full": true,
    "bg-transparent": true,
    "outline-none": true,
    "text-sm": true
  },
  "transferlist__transferlistListItems": {
    "list-none": true,
    "bg-neutral-50": true,
    "h-full": true,
    "sm:max-w-xs": true,
    "overflow-x-hidden": true,
    "overflow-y-auto": true,
    "text-neutral-600": true,
    "dark:bg-neutral-900": true,
    "dark:text-neutral-100": true
  },
  "transferlist__transferlistListItem": {
    "aria-selected:bg-sky-600": true,
    "aria-selected:data-[is-active=true]:bg-sky-600": true,
    "aria-selected:text-white": true,
    "aria-selected:data-[is-active=true]:text-white": true,
    "first:-mt-px": true,
    "first:border-t": true,
    "py-2": true,
    "px-3": true,
    "flex": true,
    "relative": true,
    "border-b": true,
    "bg-white": true,
    "data-[is-active=true]:text-sky-500": true,
    "data-[is-active=true]:bg-neutral-100": true,
    "cursor-pointer": true,
    "group-data-[is-max=true]:cursor-not-allowed": true,
    "items-center": true,
    "dark:bg-neutral-900": true,
    "dark:text-neutral-100": true,
    "dark:aria-selected:bg-neutral-700": true,
    "dark:data-[is-active=true]:bg-neutral-700": true,
    "dark:ring-neutral-100": true
  },
  "transferlist__selectedIcon": {
    "w-3": true,
    "absolute": true,
    "left-3": true,
    "select-none": true,
    "dark:text-neutral-100": true
  },
  "transferlist__transferlistOption": {
    "text-sm": true
  },
  "transferlist__transferListLoadMore": {
    "": true
  },
  "transferlist__source": {
    "": true
  },
  "transferlist__sourceHeader": {
    "": true
  },
  "transferlist__sourceHeaderLabel": {
    "": true
  },
  "transferlist__sourceHeaderItemCount": {
    "": true
  },
  "transferlist__sourceControls": {
    "": true
  },
  "transferlist__sourceSearchInput": {
    "": true
  },
  "transferlist__sourceSearchClear": {
    "": true
  },
  "transferlist__sourceListItems": {
    "group-data-[is-max=true]:opacity-50": true
  },
  "transferlist__sourceListItem": {
    "": true
  },
  "transferlist__sourceOption": {
    "": true
  },
  "transferlist__sourceEmptyMessage": {
    "appearance-none": true,
    "border-none": true,
    "w-full": true,
    "p-0": true,
    "m-0": true,
    "text-center": true,
    "text-neutral-500": true,
    "italic": true,
    "dark:text-neutral-100": true
  },
  "transferlist__sourceLoadMore": {
    "": true
  },
  "transferlist__sourceLoadMoreInner": {
    "": true
  },
  "transferlist__target": {
    "": true
  },
  "transferlist__targetHeader": {
    "": true
  },
  "transferlist__targetHeaderLabel": {
    "": true
  },
  "transferlist__targetHeaderItemCount": {
    "": true
  },
  "transferlist__targetListItems": {
    "": true
  },
  "transferlist__targetListItem": {
    "": true
  },
  "transferlist__targetOption": {
    "": true
  },
  "transferlist__targetEmptyMessage": {
    "appearance-none": true,
    "border-none": true,
    "w-full": true,
    "p-0": true,
    "m-0": true,
    "text-center": true,
    "text-neutral-500": true,
    "italic": true,
    "dark:text-neutral-100": true
  },
  "transferlist__targetLoadMore": {
    "": true
  },
  "transferlist__emptyMessageInner": {
    "flex": true,
    "items-center": true,
    "justify-center": true,
    "p-2": true,
    "text-sm": true
  },
  "transferlist__loadMoreInner": {
    "": true
  },
  "transferlist__transferControls": {
    "flex": true,
    "sm:flex-col": true,
    "justify-center": true,
    "mx-auto": true,
    "my-2": true,
    "sm:mx-2": true,
    "sm:my-auto": true,
    "border": true,
    "rounded": true,
    "text-neutral-600": true,
    "dark:border-neutral-100": true
  },
  "transferlist__controlLabel": {
    "hidden": true
  },
  "transferlist__transferlistButton": {
    "text-sm": true,
    "disabled:cursor-not-allowed": true,
    "disabled:bg-neutral-200": true,
    "disabled:opacity-50": true,
    "first:rounded-l": true,
    "last:rounded-r": true,
    "sm:first:rounded-t": true,
    "sm:last:rounded-b": true,
    "appearance-none": true,
    "p-2": true,
    "m-0": true,
    "cursor-pointer": true,
    "h-10": true,
    "border-none": true,
    "rounded-none": true,
    "bg-neutral-50": true,
    "hover:outline": true,
    "disabled:hover:outline-none": true,
    "hover:outline-1": true,
    "hover:outline-black": true,
    "hover:text-sky-500": true,
    "disabled:hover:text-current": true,
    "hover:z-10": true,
    "dark:text-neutral-100": true,
    "dark:disabled:!text-neutral-500": true,
    "dark:bg-neutral-900": true
  },
  "transferlist__transferButtonForwardAll": {
    "": true
  },
  "transferlist__transferButtonForward": {
    "": true
  },
  "transferlist__transferButtonBackward": {
    "": true
  },
  "transferlist__transferButtonBackwardAll": {
    "": true
  },
  "transferlist__fastForwardIcon": {
    "w-10": true,
    "flex": true,
    "select-none": true,
    "[&>svg]:m-auto": true,
    "[&>svg]:w-full": true,
    "[&>svg]:max-w-[1rem]": true,
    "[&>svg]:max-h-[1rem]": true,
    "rotate-90": true,
    "sm:rotate-0": true
  },
  "transferlist__moveRightIcon": {
    "w-10": true,
    "flex": true,
    "select-none": true,
    "[&>svg]:m-auto": true,
    "[&>svg]:w-full": true,
    "[&>svg]:max-w-[1rem]": true,
    "[&>svg]:max-h-[1rem]": true,
    "rotate-90": true,
    "sm:rotate-0": true
  },
  "transferlist__moveLeftIcon": {
    "w-10": true,
    "flex": true,
    "select-none": true,
    "[&>svg]:m-auto": true,
    "[&>svg]:w-full": true,
    "[&>svg]:max-w-[1rem]": true,
    "[&>svg]:max-h-[1rem]": true,
    "rotate-90": true,
    "sm:rotate-0": true
  },
  "transferlist__rewindIcon": {
    "w-10": true,
    "flex": true,
    "select-none": true,
    "[&>svg]:m-auto": true,
    "[&>svg]:w-full": true,
    "[&>svg]:max-w-[1rem]": true,
    "[&>svg]:max-h-[1rem]": true,
    "rotate-90": true,
    "sm:rotate-0": true
  },
  "transferlist__messages": {
    "list-none": true,
    "p-0": true,
    "mb-0": true,
    "mt-2": true
  },
  "transferlist__message": {
    "text-red-600": true,
    "mb-1": true,
    "text-sm": true,
    "dark:text-red-400": true
  },
  "barcode__barcodeIcon": {
    "flex": true,
    "w-10": true,
    "grow-0": true,
    "shrink-0": true,
    "self-stretch": true,
    "select-none": true,
    "[&>svg]:w-full": true,
    "[&>svg]:m-auto": true,
    "[&>svg]:max-h-[1em]": true,
    "[&>svg]:max-w-[1em]": true,
    "text-neutral-700": true,
    "dark:text-neutral-300": true
  },
  "barcode__prefixIcon": {
    "flex": true,
    "shrink-0": true,
    "items-center": true,
    "text-base": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "[&>svg]:w-full": true
  },
  "barcode__suffixIcon": {
    "flex": true,
    "items-center": true,
    "text-base": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "shrink-0": true,
    "[&>svg]:w-full": true
  },
  "barcode__dialog": {
    "border-none": true,
    "outline-none": true,
    "overflow-clip": true,
    "p-0": true,
    "bg-black": true,
    "rounded-none": true,
    "w-[100%-2rem]": true,
    "max-w-[30rem]": true,
    "[&::backdrop]:bg-neutral-800/50": true
  },
  "barcode__video": {
    "w-full": true,
    "aspect-square": true,
    "object-cover": true,
    "block": true,
    "pointer-events-none": true
  },
  "barcode__closeIcon": {
    "cursor-pointer": true,
    "absolute": true,
    "bg-white": true,
    "color-neutral-700": true,
    "w-[1.5em]": true,
    "h-[1.5em]": true,
    "rounded-md": true,
    "flex": true,
    "top-2": true,
    "right-2": true,
    "z-20": true,
    "[&>svg]:w-[1.25em]": true,
    "[&>svg]:h-[1.25em]": true,
    "[&>svg]:m-auto": true
  },
  "barcode__overlay": {
    "text-neutral-900": true,
    "dark:text-neutral-100": true,
    "absolute": true,
    "top-1/2": true,
    "left-1/2": true,
    "w-[min(20em,75%)]": true,
    "aspect-square": true,
    "-translate-x-1/2": true,
    "-translate-y-1/2": true,
    "rounded-none": true,
    "pointer-events-none": true,
    "shadow-[0_0_0_999em_rgba(0,0,0,0.5)]": true
  },
  "barcode__overlayDecorators": {
    "absolute": true,
    "inset-0": true,
    "z-10": true
  },
  "barcode__overlayDecoratorTopLeft": {
    "absolute": true,
    "w-[1.5rem]": true,
    "h-[1.5rem]": true,
    "rounded-none": true,
    "top-0": true,
    "left-0": true,
    "border-l-4": true,
    "border-t-4": true,
    "rounded-tr-none": true,
    "rounded-bl-none": true
  },
  "barcode__overlayDecoratorTopRight": {
    "absolute": true,
    "w-[1.5rem]": true,
    "h-[1.5rem]": true,
    "rounded-none": true,
    "top-0": true,
    "right-0": true,
    "border-r-4": true,
    "border-t-4": true,
    "rounded-tl-none": true,
    "rounded-br-none": true
  },
  "barcode__overlayDecoratorBottomRight": {
    "absolute": true,
    "w-[1.5rem]": true,
    "h-[1.5rem]": true,
    "rounded-none": true,
    "bottom-0": true,
    "right-0": true,
    "border-r-4": true,
    "border-b-4": true,
    "rounded-tr-none": true,
    "rounded-bl-none": true
  },
  "barcode__overlayDecoratorBottomLeft": {
    "absolute": true,
    "w-[1.5rem]": true,
    "h-[1.5rem]": true,
    "rounded-none": true,
    "bottom-0": true,
    "left-0": true,
    "border-l-4": true,
    "border-b-4": true,
    "rounded-tl-none": true,
    "rounded-br-none": true
  },
  "multi-step__outer": {
    "group": true,
    "mb-4": true,
    "data-[disabled]:opacity-50": true,
    "data-[disabled]:select-none": true,
    "data-[disabled]:pointer-events-none": true,
    "group/multistep": true,
    "max-w-[32rem]": true
  },
  "multi-step__wrapper": {
    "group/wrapper": true,
    "data-[tab-style=\"tab\"]:rounded-none": true
  },
  "multi-step__tabs": {
    "flex": true,
    "items-center": true,
    "group-data-[tab-style=\"tab\"]/wrapper:overflow-auto": true,
    "group-data-[tab-style=\"tab\"]/wrapper:border": true,
    "group-data-[tab-style=\"tab\"]/wrapper:border-b-0": true,
    "group-data-[tab-style=\"tab\"]/wrapper:border-neutral-900": true,
    "group-data-[tab-style=\"tab\"]/wrapper:rounded-none": true,
    "group-data-[tab-style=\"tab\"]/wrapper:rounded-bl-none": true,
    "group-data-[tab-style=\"tab\"]/wrapper:rounded-br-none": true,
    "dark:group-data-[tab-style=\"tab\"]/wrapper:border-neutral-100": true,
    "group-data-[tab-style=\"progress\"]/wrapper:my-6": true,
    "group-data-[tab-style=\"progress\"]/wrapper:justify-around": true,
    "group-data-[tab-style=\"progress\"]/wrapper:overflow-visible": true,
    "group-data-[tab-style=\"progress\"]/wrapper:group-data-[hide-labels=\"true\"]/wrapper:mb-3.5": true
  },
  "multi-step__tab": {
    "group/tab": true,
    "group-data-[tab-style=\"tab\"]/wrapper:relative": true,
    "group-data-[tab-style=\"tab\"]/wrapper:flex": true,
    "group-data-[tab-style=\"tab\"]/wrapper:grow": true,
    "group-data-[tab-style=\"tab\"]/wrapper:text-sm": true,
    "group-data-[tab-style=\"tab\"]/wrapper:items-center": true,
    "group-data-[tab-style=\"tab\"]/wrapper:justify-center": true,
    "group-data-[tab-style=\"tab\"]/wrapper:cursor-pointer": true,
    "group-data-[tab-style=\"tab\"]/wrapper:text-neutral-700": true,
    "group-data-[tab-style=\"tab\"]/wrapper:bg-neutral-50": true,
    "group-data-[tab-style=\"tab\"]/wrapper:py-3.5": true,
    "group-data-[tab-style=\"tab\"]/wrapper:px-4": true,
    "group-data-[tab-style=\"tab\"]/wrapper:border-r": true,
    "group-data-[tab-style=\"tab\"]/wrapper:border-b": true,
    "group-data-[tab-style=\"tab\"]/wrapper:border-neutral-900": true,
    "group-data-[tab-style=\"tab\"]/wrapper:last:border-r-0": true,
    "group-data-[tab-style=\"tab\"]/wrapper:data-[active=true]:bg-white": true,
    "group-data-[tab-style=\"tab\"]/wrapper:data-[active=true]:border-b-transparent": true,
    "group-data-[tab-style=\"tab\"]/wrapper:data-[active=true]:z-10": true,
    "dark:group-data-[tab-style=\"tab\"]/wrapper:text-neutral-300": true,
    "dark:group-data-[tab-style=\"tab\"]/wrapper:bg-neutral-950/20": true,
    "dark:group-data-[tab-style=\"tab\"]/wrapper:data-[active=true]:bg-transparent": true,
    "dark:group-data-[tab-style=\"tab\"]/wrapper:data-[active=true]:border-b-transparent": true,
    "dark:group-data-[tab-style=\"tab\"]/wrapper:border-neutral-100": true,
    "group-data-[tab-style=\"progress\"]/wrapper:flex": true,
    "group-data-[tab-style=\"progress\"]/wrapper:flex-col": true,
    "group-data-[tab-style=\"progress\"]/wrapper:items-center": true,
    "group-data-[tab-style=\"progress\"]/wrapper:grow": true,
    "group-data-[tab-style=\"progress\"]/wrapper:shrink-0": true,
    "group-data-[tab-style=\"progress\"]/wrapper:relative": true,
    "group-data-[tab-style=\"progress\"]/wrapper:before:block": true,
    "group-data-[tab-style=\"progress\"]/wrapper:before:text-sm": true,
    "group-data-[tab-style=\"progress\"]/wrapper:before:w-[1.25rem]": true,
    "group-data-[tab-style=\"progress\"]/wrapper:before:h-[1.25rem]": true,
    "group-data-[tab-style=\"progress\"]/wrapper:before:border-4": true,
    "group-data-[tab-style=\"progress\"]/wrapper:before:border-neutral-300": true,
    "group-data-[tab-style=\"progress\"]/wrapper:before:rounded-full": true,
    "group-data-[tab-style=\"progress\"]/wrapper:before:bg-white": true,
    "group-data-[tab-style=\"progress\"]/wrapper:before:z-10": true,
    "dark:group-data-[tab-style=\"progress\"]/wrapper:before:border-neutral-700": true,
    "dark:group-data-[tab-style=\"progress\"]/wrapper:before:bg-neutral-950": true,
    "group-data-[tab-style=\"progress\"]/wrapper:after:block": true,
    "group-data-[tab-style=\"progress\"]/wrapper:after:h-1": true,
    "group-data-[tab-style=\"progress\"]/wrapper:after:w-full": true,
    "group-data-[tab-style=\"progress\"]/wrapper:after:absolute": true,
    "group-data-[tab-style=\"progress\"]/wrapper:after:top-[0.5em]": true,
    "group-data-[tab-style=\"progress\"]/wrapper:after:left-[calc(50%+0.5em)]": true,
    "group-data-[tab-style=\"progress\"]/wrapper:after:bg-neutral-300": true,
    "group-data-[tab-style=\"progress\"]/wrapper:data-[valid=true]:data-[visited=true]:after:bg-neutral-900": true,
    "group-data-[tab-style=\"progress\"]/wrapper:last:after:hidden": true,
    "dark:group-data-[tab-style=\"progress\"]/wrapper:after:bg-neutral-700": true,
    "dark:group-data-[tab-style=\"progress\"]/wrapper:data-[valid=true]:data-[visited=true]:after:bg-neutral-300": true
  },
  "multi-step__tabLabel": {
    "group-data-[tab-style=\"progress\"]/wrapper:absolute": true,
    "group-data-[tab-style=\"progress\"]/wrapper:text-neutral-800": true,
    "group-data-[tab-style=\"progress\"]/wrapper:top-full": true,
    "group-data-[tab-style=\"progress\"]/wrapper:w-full": true,
    "group-data-[tab-style=\"progress\"]/wrapper:whitespace-nowrap": true,
    "group-data-[tab-style=\"progress\"]/wrapper:text-xs": true,
    "dark:group-data-[tab-style=\"progress\"]/wrapper:text-neutral-300": true
  },
  "multi-step__badge": {
    "bg-neutral-900": true,
    "absolute": true,
    "font-mono": true,
    "font-bold": true,
    "flex": true,
    "items-center": true,
    "justify-center": true,
    "aspect-square": true,
    "[line-height:1.25rem]": true,
    "text-white": true,
    "rounded-full": true,
    "dark:bg-neutral-300": true,
    "dark:text-neutral-900": true,
    "group-data-[tab-style=\"tab\"]/wrapper:text-[0.66rem]": true,
    "group-data-[tab-style=\"tab\"]/wrapper:p-1.5": true,
    "group-data-[tab-style=\"tab\"]/wrapper:w-5": true,
    "group-data-[tab-style=\"tab\"]/wrapper:h-5": true,
    "group-data-[tab-style=\"tab\"]/wrapper:top-1.5": true,
    "group-data-[tab-style=\"tab\"]/wrapper:right-1.5": true,
    "group-data-[tab-style=\"progress\"]/wrapper:w-[1.25rem]": true,
    "group-data-[tab-style=\"progress\"]/wrapper:h-[1.25rem]": true,
    "group-data-[tab-style=\"progress\"]/wrapper:p-1": true,
    "group-data-[tab-style=\"progress\"]/wrapper:text-[10px]": true,
    "group-data-[tab-style=\"progress\"]/wrapper:[line-height:0]": true,
    "group-data-[tab-style=\"progress\"]/wrapper:z-10": true
  },
  "multi-step__validStepIcon": {
    "w-full": true,
    "h-full": true,
    "mt-0.5": true
  },
  "multi-step__steps": {
    "px-10": true,
    "pt-8": true,
    "pb-4": true,
    "bg-white": true,
    "border": true,
    "border-neutral-900": true,
    "rounded-none": true,
    "dark:bg-transparent": true,
    "dark:border-neutral-100": true,
    "group-data-[tab-style=\"tab\"]/wrapper:border-t-0": true,
    "group-data-[tab-style=\"tab\"]/wrapper:rounded-tl-none": true,
    "group-data-[tab-style=\"tab\"]/wrapper:rounded-tr-none": true,
    "[&_[data-type]]:max-w-none": true
  },
  "step__stepActions": {
    "flex": true,
    "justify-between": true,
    "[&>*]:grow-0": true
  },
  "step__stepPrevious": {
    "mr-1.5": true
  },
  "step__stepNext": {
    "ml-auto": true
  }
};

/**
 * Globals are merged prior to generating this file — these are included for
 * any other non-matching inputs.
 **/
const globals: Record<string, Record<string, boolean>> = {
  "outer": {
    "group": true,
    "mb-4": true,
    "data-[disabled]:opacity-50": true,
    "data-[disabled]:select-none": true,
    "data-[disabled]:pointer-events-none": true
  },
  "label": {
    "block": true,
    "mb-1": true,
    "font-medium": true,
    "text-sm": true,
    "text-neutral-700": true,
    "dark:text-neutral-100": true
  },
  "legend": {
    "font-bold": true,
    "text-md": true,
    "text-neutral-600": true,
    "dark:text-neutral-100": true
  },
  "input": {
    "appearance-none": true,
    "border-none": true,
    "focus:outline-none": true,
    "focus:ring-0": true,
    "focus:shadow-none": true,
    "sm:text-sm": true,
    "text-black": true,
    "placeholder:italic": true,
    "transition-colors": true,
    "[color-scheme:light]": true,
    "dark:[color-scheme:dark]": true,
    "dark:placeholder:text-neutral-500": true,
    "dark:text-neutral-100": true
  },
  "prefixIcon": {
    "flex": true,
    "shrink-0": true,
    "items-center": true,
    "text-base": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "[&>svg]:w-full": true
  },
  "suffixIcon": {
    "flex": true,
    "items-center": true,
    "text-base": true,
    "h-[1em]": true,
    "w-[1em]": true,
    "shrink-0": true,
    "[&>svg]:w-full": true
  },
  "loaderIcon": {
    "inline-flex": true,
    "items-center": true,
    "w-4": true,
    "text-neutral-600": true,
    "animate-spin": true,
    "dark:text-neutral-100": true
  },
  "help": {
    "text-xs": true,
    "text-neutral-500": true,
    "whitespace-pre-line": true,
    "dark:text-neutral-100": true
  },
  "messages": {
    "list-none": true,
    "p-0": true,
    "mt-1": true,
    "mb-0": true
  },
  "message": {
    "text-red-600": true,
    "mb-1": true,
    "text-sm": true,
    "dark:text-red-400": true
  },
  "overlay": {
    "text-neutral-900": true,
    "dark:text-neutral-100": true
  },
  "overlayPlaceholder": {
    "text-neutral-900": true,
    "dark:text-neutral-100": true
  },
  "overlayLiteral": {
    "text-neutral-900": true,
    "dark:text-neutral-100": true
  },
  "overlayChar": {
    "text-neutral-900": true,
    "dark:text-neutral-100": true
  },
  "overlayEnum": {
    "text-neutral-900": true,
    "dark:text-neutral-100": true
  },
  "form": {
    "flex": true,
    "flex-1": true,
    "flex-col": true,
    "overflow-auto": true,
    "sm:overflow-hidden": true
  },
  "fieldset": {
    "border": true,
    "border-neutral-400": true,
    "rounded-md": true,
    "px-2": true,
    "pb-1": true
  }
};
