import { toFixed } from "@/utils/helpers/mathUtils.js";
import { MaybeRef, unref } from "vue";

const insertCommas = (num: number | string) => num?.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");

export interface formatNumberConfig {
  decimalPlaces?: number;
  toFixed?: boolean;
  includeCommas?: boolean;
}

/**
 * Nicely formats JS Numbers - adds commas, rounds, and zero pads.
 *
 * @param {number} num Value to modify
 * @returns {string} If the input is falsey, returns the input. Otherwise returns a formatted number
 */
export const formatNumber = (
  num: MaybeRef<number | null | undefined>,
  config?: formatNumberConfig,
): string | null | undefined => {
  num = unref(num);
  if (num === null || num === undefined) return num;
  if (!Number.isFinite(num)) return num?.toString();

  config = {
    decimalPlaces: 2,
    toFixed: true,
    includeCommas: true,
    ...config,
  };
  let result: string | number = num;

  if (config.toFixed) {
    result = toFixed(num, config.decimalPlaces);
  }

  if (config.includeCommas) return insertCommas(result);
  else return result.toString();
};

export const formatDollar = (num: number) => {
  if (!Number.isFinite(num)) return num;

  return "$" + formatNumber(num);
};
