<template>
  <ReadFieldBase v-bind="baseProps">
    {{ parsedValue }}
  </ReadFieldBase>
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";
import ReadFieldBase from "@/components/read/ReadFieldBase.vue";
/** Component to parse and display value.
 * Most common component used inside the `ReadCard`.
 */
export default defineComponent({
  name: "ReadField",
  components: {
    ReadFieldBase,
  },
  props: {
    /** Label for the item to be displayed */
    label: {
      type: String,
      required: true,
    },
    /** Controls the column span for the item
     * @values 1, 2
     */
    span: {
      type: Number,
      default: 1,
    },
    /** Determines whether to useMonoFont */

    useMonoFont: {
      type: Boolean,
      default: false,
    },
    /** Allows the item to use multiple lines (commonly used for descriptions, comments, and other long values) */

    multiline: {
      type: Boolean,
      default: false,
    },
    /** Value to be parsed and then displayed */
    value: {},
  },
  setup(props, context) {
    const { value, ...baseProps } = props;

    const parsedValue = computed(() => {
      if (props.value === null || props.value === undefined || props.value === "") {
        return "—";
      } else if (props.value === true) {
        return "Yes";
      } else if (props.value === false) {
        return "No";
      } else if (Array.isArray(props.value)) {
        return props.value.join(", ");
      } else {
        return props.value;
        // if (typeof props.value === "number" && isFinite(props.value)) {
        //   monoFontRef.value = true;
        // }
      }
    });

    return { parsedValue, baseProps };
  },
});
</script>

<style module></style>
