<template>
  <div class="flex-auto h-1 overflow-y-auto px-2 pb-2" :class="viewerStore.showSidebar ? 'visible' : 'invisible'">
    <Disclosure v-slot="{ open }" v-for="dataset in viewerStore.datasets.values()" :default-open="true">
      <DisclosureButton
        class="flex w-full justify-between rounded-lg bg-neutral-100 px-4 py-2 text-left text-sm font-medium text-neutral-900 hover:bg-neutral-200 focus:outline-none focus-visible:ring focus-visible:ring-neutral-500/75 mt-2"
      >
        <div class="inline-flex justify-between w-full">
          {{ dataset.label }}
          <PinIcon :viewer-color="dataset.datasetGroup.colors" class="inline-block -mb-1 mr-3" />
        </div>
        <ChevronUpIcon
          :class="open ? '' : 'rotate-180'"
          class="h-5 w-5 text-neutral-500 transform ease-out duration-200"
        />
      </DisclosureButton>
      <transition
        enter-active-class="transition duration-100 ease-out"
        enter-from-class="transform -translate-y-4 opacity-0"
        enter-to-class="transform translate-y-0 opacity-100"
        leave-active-class="transition duration-75 ease-out"
        leave-from-class="transform translate-y-0 opacity-100"
        leave-to-class="transform -translate-y-4 opacity-0"
      >
        <DisclosurePanel class="px-4 pb-2 pt-4 text-sm text-neutral-500">
          <div class="mb-2">{{ dataset.description }}</div>

          <!-- TODO: use a different control for visibility -->
          <FormKit
            type="checkbox"
            label="Show"
            validation="has_no_validation"
            :model-value="dataset.isVisible"
            @update:model-value="toggleFilter(dataset as ViewerDataset, Boolean($event))"
          ></FormKit>
        </DisclosurePanel>
      </transition>
    </Disclosure>
  </div>
</template>

<script lang="ts">
import { useAppSettingsStore } from "@/components/common";
import { ViewerDataset, ViewerListProps } from "@/components/viewer";
import PinIcon from "@/components/viewer/PinIcon.vue";
import injectionSymbols from "@/components/viewer/injectionSymbols";
import { useViewerStore } from "@/components/viewer/viewerStore";
import { Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/vue";
import { ChevronUpIcon } from "@heroicons/vue/20/solid";
import { defineComponent, inject, ref } from "vue";

/**
 * Viewer Panel for its Datasets (allows for search, filtering, show/hide of datasets).
 */
export default defineComponent({
  name: "ViewerPanelDatasets",
  components: {
    PinIcon,
    ChevronUpIcon,
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
  },
  setup(props) {
    const viewerStore = useViewerStore();
    const appSettingsStore = useAppSettingsStore();

    const { datasetsQueryRef } = inject(injectionSymbols.ViewerListPropsKey) as ViewerListProps;

    //this function also fires when a dataset button is used because this runs on model update
    const toggleFilter = (dataset: ViewerDataset, $event: Boolean) => {
      /* 
      viewerStore.progUpdateMap is an indicator that this was triggered from the reset - this
        needs to be done for each one seperately to stay in sync. 
      */
      if (viewerStore.progUpdateMap.get(dataset.id)) {
        viewerStore.progUpdateMap.set(dataset.id, false);
      } else {
        const hasDataset = datasetsQueryRef.value.includes(dataset.id);
        if (!$event && hasDataset) {
          datasetsQueryRef.value = datasetsQueryRef.value.filter((id) => id !== dataset.id);
        } else if ($event && !hasDataset) {
          datasetsQueryRef.value = [...datasetsQueryRef.value, dataset.id];
        }
      }
    };

    return {
      appSettingsStore,
      viewerStore,
      toggleFilter,
    };
  },
});
</script>

<style module></style>
