import { AUTH_REQUEST_PREEMPTED } from "@/utils/auth/authorization/constants.js";

/**
 * Handles pre-empted auth requests, while passing on other errors.
 *
 * @param {Object} error Prop value to validate
 * @throws {Object} The error, if it's not caught.
 */
export const handlePreempts = (error: any) => {
  if (error.type === AUTH_REQUEST_PREEMPTED) {
    // Swallow error, the request has been superseded by a subsequent AR.
    // console.warn("authorize Preempted", error.type, error.requestKey); // Debugging
  } else {
    throw error;
  }
};
