<template>
  <div
    class="flex h-screen w-screen items-center justify-center"
    :class="[$style.promptBackground]"
    :style="{
      backgroundImage: agency && 'url(' + getBgUrl(agency) + ')',
      '--backgroundAttribution': getAttribution(),
    }"
  >
    <div
      class="[@media(max-width: 500px)]:w-full [@media(max-width: 500px)]:min-w-0 mx-auto min-w-[500px] bg-white px-8 py-8 shadow-lg"
    >
      <slot></slot>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

// TODO: Eventually we'll need to concern ourselves with variants, etc
/** This component is a work in progress for a login screen - currently not in use */
export default defineComponent({
  name: "FixedPrompt",
  props: {
    agency: {
      type: String,
      default: null,
    },
  },
  //inheritAttrs: false,
  methods: {
    getAttribution() {
      if (!this.agency) return undefined;

      const attributions: { [key: string]: string } = {
        cc: '"Photo: Ken Yam (https://unsplash.com/photos/qr8Ci2tzyR8)"',
        nv: '"Photo: Trevor Bexon (https://www.flickr.com/photos/trevorbexon/)"',
      };
      return attributions[this.agency];
    },
    getBgUrl(agency: string | null) {
      return new URL(`/images/backgrounds/${agency}.jpg`, import.meta.url).href;
    },
  },
});
</script>

<style module>
.promptBackground {
  background-color: lightgray;
  /* background-image: url("/images/prompt-bg.jpg");  Fall back BG */
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
}

.promptBackground::after {
  position: absolute;
  right: 0;
  bottom: 0;
  padding: 0.125em 0.25em;
  font-size: 10px;
  color: white;
  content: var(--backgroundAttribution);
  background-color: rgba(0, 0, 0, 0.3);
}

/* Ugly but necessary until we can get Tailwind JIT working */
/* TODO: Confirm that the change in the markup has fixed this */
/* .promptWidth {
  min-width: 500px;

  @media (max-width: 500px) {
    width: 100%;
    min-width: 0;
  }
} */
</style>
