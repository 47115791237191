<template>
  <LayoutChildList>
    <ListCardsLocal
      :entity-label="entityAuditConfigStore.auditLabel"
      :entity-label-plural="entityAuditConfigStore.auditLabel"
      :items="entityAuditStore.entityAudits"
      is-child-list
      hide-filters
    >
      <template #cards="cardsProps">
        <li v-for="item in cardsProps.items" :key="item.dayDisplay">
          <div class="mx-0 mt-6 sm:mx-5 2xl:mx-10">
            <div class="2xl:grid 2xl:grid-cols-3">
              <div class="mb-2 mt-5 flex justify-between pl-2 2xl:col-span-2 2xl:mt-0">
                <h3 class="text-lg font-medium leading-6 text-neutral-900">
                  {{ item.dayDisplay }}
                </h3>
                <!-- For Future details support -->
                <!-- <FormKit
                  v-if="withToggles"
                  type="toggle"
                  label="Details"
                  name="materialTypes"
                  thumb-icon="circle"
                  thumb-icon-class="text-white"
                  validation="has_no_validation"
                  outer-class="!mb-0"
                  v-model="item.showDetails"
                ></FormKit> -->
              </div>
              <div class="2xl:col-span-2 2xl:mt-0">
                <div class="bg-white shadow sm:overflow-visible sm:rounded-md">
                  <div class="px-4 py-5 sm:p-6">
                    <!-- <slot><b>Put one or more FieldSet or other form components here</b></slot> -->
                    <ul role="list" class="mt-2 grid grid-cols-1 gap-3 md:gap-2">
                      <li v-for="(subItem, subItemIdx) in item.items" :key="subItem.id" class="group">
                        <div class="relative ml-1 pb-5 group-last:pb-0">
                          <span
                            v-if="subItemIdx !== item.items.length - 1"
                            class="absolute left-2.5 top-4 -ml-px h-full w-0.5 bg-neutral-300"
                            aria-hidden="true"
                          ></span>
                          <div class="relative mb-2 flex space-x-3">
                            <div>
                              <span
                                :class="[
                                  'subItems-center flex justify-center rounded-full ring-8 ring-white',
                                  subItem.type.icon
                                    ? 'h-5 w-5 bg-white'
                                    : 'mx-0.5 my-0.5 h-4 w-4 border border-neutral-300 bg-neutral-100',
                                ]"
                              >
                                <component
                                  v-if="subItem.type.icon"
                                  :is="subItem.type.icon"
                                  class="h-5 w-5"
                                  :class="[subItem.type.textColorClass]"
                                  aria-hidden="true"
                                />
                              </span>
                            </div>
                            <div class="flex min-w-0 flex-1 justify-between space-x-4 pt-0">
                              <div>
                                <p class="text-sm text-neutral-500">
                                  <span class="font-medium text-neutral-900">
                                    {{ subItem.action }}
                                  </span>
                                  by
                                  <span class="font-medium text-neutral-900">{{ subItem.auditUserName }}</span>
                                  <!-- at
                                  <span class="font-medium text-neutral-700">{{
                                    subItem.time
                                  }}</span> -->
                                </p>
                              </div>
                              <div class="whitespace-nowrap text-right text-sm text-neutral-500">
                                {{ subItem.displayTime }}
                              </div>
                            </div>
                          </div>
                          <!-- For Future details support -->
                          <!-- <div
                            class="ml-10 rounded-md bg-neutral-100 px-2 text-sm text-neutral-700 transition-all"
                            :class="[item.showDetails ? `py-1` : `h-0 overflow-hidden`]"
                            v-if="subItem.changes && withToggles"
                          >
                            <table class="w-full">
                              <thead>
                                <tr class="border-b-2 border-neutral-200">
                                  <th class="font-medium">Field</th>
                                  <th class="px-4 font-medium">Replaced</th>
                                  <th class="font-medium">With</th>
                                </tr>
                              </thead>
                              <tbody class="">
                                <tr v-for="change in subItem.changes" class="">
                                  <td class="py-2 align-top">
                                    {{ change.field }}
                                  </td>
                                  <td class="px-4 py-2 align-top">
                                    {{ change.oldValue || "—" }}
                                  </td>
                                  <td class="whitespace-pre-wrap py-2 align-top">
                                    {{ change.newValue || "—" }}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div> -->
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </li>
      </template>
    </ListCardsLocal>
  </LayoutChildList>
</template>

<script lang="ts">
import { defineComponent } from "vue";
// import { LayoutChildList, ListCardsLocal } from "@/components";
import LayoutChildList from "@/components/layouts/LayoutChildList.vue";
import ListCardsLocal from "@/components/list/listCards/ListCardsLocal.vue";
import { useApi } from "@/utils/api";
import { watch } from "vue";
import { getAudits } from "@/components/entity/audit/entityAuditApi";
import { useEntityAuditConfigStore } from "@/components/entity/audit/entityAuditConfigStore";
import { useEntityAuditStore } from "@/components/entity/audit/entityAuditStore";

/** Component to display the audit history for an entity. This will display the 'cleaned up/simple' version that we want to display to the user */
export default defineComponent({
  name: "EntityAudit",
  props: {
    /** the entity type (as it is known the to audit history) */
    entityType: {
      type: String,
      required: true,
    },
    // For future details support
    // withToggles: {
    //   type: Boolean,
    //   default: false,
    // },
  },
  setup(props, context) {
    // TODO: ADD PAGING w/ infinite scroll.

    // Assumes this has been initialized in the Base component somewhere above EntityAudit in the DOM.
    const entityAuditStore = useEntityAuditStore();
    const entityAuditConfigStore = useEntityAuditConfigStore();

    const { exec: fetchAudits } = useApi(getAudits);

    watch(
      () => entityAuditStore?.auditEntries,
      async (newValue, oldValue) => {
        if (entityAuditStore.count !== entityAuditStore.totalResultsCount) {
          // If total results count differs, go fetch 500
          const takeRegx = /(take=[\d]+)/gi;
          const urlRegx = /^(https:\/\/[^\/]+\/api\/)/i;
          let initialPageUrl = entityAuditStore.href.replace(takeRegx, "take=500"); //TODO: add sorting. and paging.
          initialPageUrl = initialPageUrl.replace(urlRegx, "/");
          const result = await fetchAudits(initialPageUrl);
          result &&
            entityAuditStore.fill(
              { auditHistory: result },
              { entityType: props.entityType, entityId: entityAuditStore.entityId },
            );
        }
      },
      { immediate: true },
    );

    return { entityAuditStore, entityAuditConfigStore };
  },
  components: {
    LayoutChildList,
    ListCardsLocal,
  },
});
</script>
