<template>
  <div class="col-span-3 mb-4">
    <label for="search-field" class="sr-only">{{ searchLabel }}</label>

    <div class="flex w-full">
      <div
        class="relative w-full text-neutral-400 rounded-md ring-1 focus-within:ring-2 ring-neutral-300 bg-white focus-within:ring-neutral-400 transition-all"
        v-auto-animate
      >
        <div class="pointer-events-none absolute inset-y-0 left-2.5 flex mt-3.5 sm:mt-2.5 items-start">
          <MagnifyingGlassIcon class="h-4 w-4 flex-shrink-0" aria-hidden="true" />
        </div>
        <input
          name="search-field"
          id="search-field"
          class="w-full rounded-md peer py-2 pl-8 pr-3 sm:text-sm text-neutral-900 bg-transparent placeholder-neutral-500 focus:placeholder-opacity-0 focus:outline-none focus:ring-0"
          type="search"
          autocomplete="off"
          autocorrect="off"
          spellcheck="false"
          ref="searchInputRef"
          :value="keywordsRef"
          aria-describedby="search-help"
          @input="keywordInputHandler(($event.target as HTMLInputElement)?.value)"
          @focusin="
            () => {
              showPlaceholder = true;
            }
          "
          @focusout="
            () => {
              showPlaceholder = false;
            }
          "
          placeholder="Keyword"
        />
        <div
          v-if="showPlaceholder"
          id="search-help"
          class="text-xs ml-1 pointer-events-none h-0 text-transparent px-1 pb-0.5 peer-focus:text-neutral-500 peer-focus:h-auto transition-all"
        >
          {{ placeholder }}
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { ListFilterLabels, ListFiltersHelpers, ListTableProps } from "@/components/list/genericList";
import injectionSymbols from "@/components/list/genericList/injectionSymbols";
import { TransitionChild, TransitionRoot } from "@headlessui/vue";
import { MagnifyingGlassIcon } from "@heroicons/vue/24/solid";
import { useSessionStorage } from "@vueuse/core";
import { useRouteQuery } from "@vueuse/router";
import { computed, defineComponent, inject, ref } from "vue";
import { onBeforeRouteUpdate, useRoute } from "vue-router";
import { LIST_KEYWORD_FILTER } from "@/components/list/genericList/componentNames";
// import { useFocus } from "@vueuse/core";

/** Provides a input component that allows the list filtering of an entity based on input.
 * Updates as input is typed.
 */
export default defineComponent({
  name: LIST_KEYWORD_FILTER,
  components: {
    TransitionChild,
    TransitionRoot,
    MagnifyingGlassIcon,
  },
  props: {
    /**
     * Placeholder text rendered with the search box. Prefixed with "Search by ".
     */
    placeholderLabel: {
      type: String,
      required: true,
    },
    /**
     * Optional default value. Default is "".
     */
    defaultId: {
      type: String,
      default: () => "",
    },
    /** Allows you to prevent a value (e.g. defaultId) from being sent in the query. Default is "" */
    ignoredId: {
      type: String,
      default: () => "",
    },
    /**
     * Optional label override used to label the search field for screenreaders. Defaults to ListTableProps.entityLabelPlural
     */
    label: {
      type: String,
    },
    /**
     * Optional name of the property to be searched on. Defaults to "keywords".
     */
    field: {
      type: String,
      default: () => "keywords",
    },
  },
  setup(props) {
    const route = useRoute();
    const storeName = route.name?.toString() + "-Keywords";

    // For now, assume that all props are provided before rendering.
    const { filterChangeHandler, entityLabelPlural } = inject(injectionSymbols.GenericListPropsKey) as ListTableProps;
    const { registerAndUpdateFilters } = inject(injectionSymbols.ListFilterLabelsKey) as ListFilterLabels;
    const { getIndexOfFilterComponent } = inject(injectionSymbols.ListFiltersHelpersKey) as ListFiltersHelpers;

    const isQueryBlank = Object.entries(route.query).length === 0;
    const keywordsQueryRef = useRouteQuery(props.field, props.defaultId);

    const keywordsSessionRef = useSessionStorage(storeName, props.defaultId);
    if (isQueryBlank && keywordsSessionRef.value) {
      keywordsQueryRef.value = keywordsSessionRef.value;
    }

    const keywordsRef = ref(keywordsQueryRef.value);

    registerAndUpdateFilters({
      label: "Keyword",
      value: keywordsRef.value.trim() !== "" ? [keywordsRef.value] : [],
      field: props.field,
      sortOrder: getIndexOfFilterComponent(props.field),
    });

    const placeholder = computed(() => "Search by " + props.placeholderLabel);

    const isDefault = computed<boolean>(() => keywordsRef.value === props.defaultId);
    const isIgnored = computed<boolean>(() => keywordsRef.value === props.ignoredId);

    const searchLabel = computed(() => "Search " + (props.label || entityLabelPlural));

    //autofocus in the searchInput
    const searchInputRef = ref(null);
    // TODO: This ends up hiding the placeholder. Maybe make it a user option?
    // useFocus({ target: searchInputRef, initialValue: true });

    const keywordInputHandler = (filterValue: string) => {
      keywordsRef.value = filterValue;
      const queryValue: any = isIgnored.value ? undefined : filterValue; // TODO cleanup types.

      keywordsQueryRef.value = queryValue;
    };

    onBeforeRouteUpdate((to, from) => {
      const newValue = to.query[props.field];
      if (newValue) {
        keywordsRef.value = newValue.toString(); // TODO: Add support for arrays.
      } else if (Object.entries(to.query).length === 0) {
        keywordsRef.value = props.defaultId;
      } else {
        keywordsRef.value = props.ignoredId;
      }

      keywordsSessionRef.value = keywordsRef.value;

      registerAndUpdateFilters({
        label: "Keyword",
        value: keywordsRef.value.trim() !== "" ? [keywordsRef.value] : [],
        field: props.field,
        sortOrder: getIndexOfFilterComponent(props.field),
      });
      filterChangeHandler({
        field: props.field,
        filterValue: keywordsRef.value,
        isDefault: isDefault.value,
        isIgnored: isIgnored.value,
      });
    });

    if (!isIgnored.value) {
      filterChangeHandler({
        field: props.field,
        filterValue: keywordsRef.value,
        isDefault: isDefault.value,
        isIgnored: isIgnored.value,
      });
    }

    const showPlaceholder = ref(false);

    return {
      showPlaceholder,
      keywordsRef,
      isDefault,
      isIgnored,
      placeholder,
      searchLabel,
      searchInputRef,
      keywordInputHandler,
    };
  },
});
</script>

<style module></style>
