<template>
  <div class="mb-1 pt-2 text-sm text-neutral-600 md:hidden"><slot></slot></div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
/** bypasses need for tailwind in schema. Should be used inside the repeatedfield - this shows on mobile. Note - you still need a seperate repeatedHeader component for non-mobile */
export default defineComponent({
  name: "RepeatedFieldHeader",
  emits: [],

  setup(props, context) {},
  components: {},
});
</script>
