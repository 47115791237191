/** Provides a constant with the value of:
 * [{ value: true, label: "Yes" },{ value: false, label: "No" }]
 *
 */
export const booleanOptions = [
  { value: true, label: "Yes" },
  { value: false, label: "No" },
];

/** Provides a constant with the value of:
 * [{ value: true, label: "Yes" },{ value: false, label: "No" },{ value: null, label: "N/A" }]
 *
 */
export const nullableBooleanOptions = [
  { value: true, label: "Yes" },
  { value: false, label: "No" },
  { value: null, label: "N/A" },
];

/** Provides a constant with the value of:
 * [{ value: "Yes", label: "Yes" },{ value: "No", label: "No" }]
 *
 */
export const booleanStringOptions = [
  { value: "Yes", label: "Yes" },
  { value: "No", label: "No" },
];

/** Provides a constant with the value of:
 * [{ value: "Yes", label: "Yes" },{ value: "No", label: "No" },{ value: "N/A", label: "N/A" }]
 *
 */
export const threeBooleanStringOptions = [
  { value: "Yes", label: "Yes" },
  { value: "No", label: "No" },
  { value: "N/A", label: "N/A" },
];
