<template>
  <LayoutAppContent>
    <div class="p-5 text-center sm:pt-10">
      <h1 class="relative inline-block w-full p-12 text-center sm:w-1/2">
        <ExclamationTriangleIcon class="mx-auto h-12 w-12 text-gray-400"></ExclamationTriangleIcon>
        <span class="mt-4 block text-xl font-medium text-gray-900"> Not Found </span>
      </h1>
    </div>
  </LayoutAppContent>
</template>

<script lang="ts">
import LayoutAppContent from "@/components/layouts/LayoutAppContent.vue";
import { ExclamationTriangleIcon } from "@heroicons/vue/24/outline";
import { defineComponent } from "vue";
/**
 * Displays a not found screen to the user when the route does not exist.
 */
export default defineComponent({
  name: "NotFound",
  components: { ExclamationTriangleIcon, LayoutAppContent },
  setup() {
    return {};
  },
});
</script>
