import { apiHosts } from "@casa/api/constants/apiHosts";
import { IPagingApiAndNotifierConfig, apiService } from "@mui/utils/api";
import { serializeQueryParams } from "@mui/utils/helpers";
import { NotifyOptionsError, NotifyOptionsSuccess } from "@mui/components/notifications";
const URL = "staffs";
const ENTITY_NAME = "Staff";

const axiosParams = {
  // Set different base URL based on the environment
  baseURL: import.meta.env.VITE_CASA_API_BASE_URL,
};
const api = apiService(apiHosts.CASA, axiosParams);

/**
 * @param {Object} params Query parameters, varies (TODO fix)

 */
const getStaffs = (params: any, config: IPagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.hide = true;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.fetched = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };
  const url = config.pagingUrl ? config.pagingUrl : URL;
  return api.get(url, {
    params,
    paramsSerializer: serializeQueryParams,
    abort: config?.abortMethod,
    successNotifCb,
    errorNotifCb,
  });
};

const getStaffById = (id: string | number, config: IPagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.hide = true;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.fetched = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };

  return api.get(URL + "/" + id, {
    successNotifCb,
    errorNotifCb,
  });
};

export { getStaffs, getStaffById };
