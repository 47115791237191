/**
 * Flattens an enum Map to an array compatible with FormKit. If both id and description are falsey, they won't be added.
 *
 * @param {Map} enum Enum that you want to convert.
 * @param {any} [id] Optional id to insert as the first record along with a description
 * @param {string} [description] Optional description to insert as the first record along with an id
 * @returns {{label: any;value: any;}[]} Array of FormKit compatible objects
 */
export const enumToDropdown = (enumMap: Map<string, any>, id?: any, description?: string) => {
  const result = [...enumMap.values()].map((x) => ({
    label: x.description,
    value: x.id,
  }));
  if (id || description) {
    result.unshift({ value: id, label: description });
  }
  return result;
};
