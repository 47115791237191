<template>
  <div :class="spanClassRef">
    <dt class="text-sm font-medium text-neutral-500">{{ label }}</dt>
    <!-- prettier-ignore -->
    <dd
      class="mt-1 text-sm text-neutral-900"
      :class="{ 'font-mono': monoFontRef, 'whitespace-pre-wrap': multiline }"
    >
    <!-- @slot Slot to place the value of your item -->
    <slot></slot></dd>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
/** Wrapper component used in the ReadField and ReadFieldList components.
 *  Can also be used directly in the `ReadCard` component for more control over the content if neither of the other two components suit your needs. (Such as needing to add extra html)
 */
export default defineComponent({
  name: "ReadFieldBase",
  props: {
    /** Label for the item to be displayed */
    label: {
      type: String,
      required: true,
    },
    /** Controls the column span for the item
     * @values 1, 2
     */
    span: {
      type: Number,
      default: 1,
    },
    /** Determines whether to useMonoFont */
    useMonoFont: {
      type: Boolean,
      default: false,
    },
    /** Allows the item to use multiple lines (commonly used for descriptions, comments, and other long values) */
    multiline: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const spanClassRef = ref("");

    switch (props.span) {
      case 1:
        spanClassRef.value = "sm:col-span-1";
        break;
      case 2:
        spanClassRef.value = "sm:col-span-2";
        break;
      default:
        spanClassRef.value = "sm:col-span-1";
        break;
    }

    const monoFontRef = ref(props.useMonoFont);

    return { spanClassRef, monoFontRef };
  },
});
</script>

<style module></style>
