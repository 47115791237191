import type { notifyErrorCb, notifySuccessCb } from "@/components/notifications/index.js";
import { NotifyOptionsError, NotifyOptionsSuccess, useNotifier } from "@/components/notifications/index.js";
import type { IPaging, TypeOfPromise } from "@/utils/api/index.js";
import { AxiosError, AxiosInstance, AxiosResponse, CreateAxiosDefaults, default as axios } from "axios";

const { notifySuccess, notifyError } = useNotifier();

/**
 * Abort callback
 *
 * @callback abortCallback
 * @param {Function} abort Request canceler method
 */

// console.log(import.meta.env.MODE);

// Default config for the axios instance
//TODO: merge default params with whatever is passed in?
// const axiosParams = {
// };

/**
 * Also passes through axios config options
 */
export class ApiOptions {
  /**
   *
   * @param {NotifyOptionsSuccess} successNotifCb
   * @param {NotifyOptionsError} errorNotifCb
   */
  constructor(successNotifCb: NotifyOptionsSuccess, errorNotifCb: NotifyOptionsError) {
    this.successNotifCb = successNotifCb;
    this.errorNotifCb = errorNotifCb;
  }
  /**
   * @description Notification configuration for success messages
   * @type {NotifyOptionsSuccess}  */
  successNotifCb: NotifyOptionsSuccess;
  /**
   * @description Notification configuration for error messages
   * @type {NotifyOptionsError}  */
  errorNotifCb: NotifyOptionsError;
}

const isCancel = (error: any) => axios.isCancel(error);
export const didAbort = (error: any) => error?.aborted;

const withAbort =
  <T>(
    axiosCall: typeof axios.get | typeof axios.put | typeof axios.post | typeof axios.patch | typeof axios.delete,
    hasOneArg = false,
  ) =>
  async (...args: any[]) => {
    const originalConfig = args[args.length - 1];
    // Extract abort property from the config
    let { abort, ...config } = originalConfig;

    // Add AbortController signal and abort method only if abort function was passed in
    if (typeof abort === "function") {
      const controller = new AbortController();
      config.signal = controller.signal;
      // The Abort method *must* be bound to its controller or the fn invocation will fail when we call it outside of this context.
      abort(controller.abort.bind(controller));
    }

    try {
      // Pass all arguments from args besides the original config
      // Axios wraps their methods, so we can't rely on any properties of the method to determine arg requirements.
      if (hasOneArg) {
        return await axiosCall<T>(args[0], config);
      } else {
        return await axiosCall<T>(args[0], args[1], config);
      }
    } catch (error: any) {
      // Add "aborted" property to the error if the request was canceled
      let newError = { ...error };
      isCancel(error) && (newError.aborted = true);
      throw newError;
    }
  };

// Main api function
const api = <T, PagingType>(axios: AxiosInstance) => {
  const withLogger = async <
    LogType,
    P extends Promise<AxiosResponse<LogType, any>> = Promise<AxiosResponse<LogType, any>>,
  >(
    promise: P,
  ) =>
    promise.catch((error: any | AxiosError) => {
      if (didAbort(error)) throw error; // as AxiosError<LogType, any>; //Don't log aborted requests.

      // Always log errors in dev environment
      if (process.env.NODE_ENV !== "development") throw error;
      // Log error only if VUE_APP_DEBUG_API env is set to true
      //if (!process.env.VUE_APP_DEBUG_API) throw error;
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log("ErrorData", error.response.data);
        console.log("ErrorStatus", error.response.status);
        console.log("ErrorHeaders", error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest
        // in the browser and an instance of
        // http.ClientRequest in node.js
        console.log("ErrorRequest", error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", error.message);
      }
      console.log("ErrorConfig", error.config);
      throw error;
    });

  /**
   * Wraps a promise with handlers for showing success / error notifs.
   *
   * successNotifCb Configuration - title is required
   */

  const withNotifier = <T extends Promise<TypeOfPromise<T>>>(
    promise: T,
    successNotifCb: notifySuccessCb,
    errorNotifCb: notifyErrorCb,
  ) => {
    if (successNotifCb) {
      promise
        .then((result) => {
          notifySuccess(successNotifCb);
        })
        .catch(() => {
          //Do nothing, errors will be getting handled elsewhere.
        });
    }
    if (errorNotifCb) {
      promise.catch((error) => {
        if (!didAbort(error)) {
          notifyError(errorNotifCb, error?.response?.status);
        }
        // This isn't swallowing the error because we aren't returning the .catch
      });
    }
    return promise;
  };

  return {
    /**
     * @param {string} url
     * @param {any} config
     * @returns {withNotifier}
     */
    getPaged: <PT = PagingType>(url: string, config: any = {}) =>
      withNotifier(
        withLogger<PT, Promise<AxiosResponse<PT, any>>>(withAbort<PT>(axios.get, true)(url, config)),
        config.successNotifCb,
        config.errorNotifCb,
      ),
    get: (url: string, config: any = {}) =>
      withNotifier(
        withLogger<T>(withAbort<T>(axios.get, true)(url, config)),
        config.successNotifCb,
        config.errorNotifCb,
      ),
    post: (url: string, body: any, config: any = {}) =>
      withNotifier(
        withLogger<T>(withAbort<T>(axios.post)(url, body, config)),
        config.successNotifCb,
        config.errorNotifCb,
      ),
    put: (url: string, body: any, config: any = {}) =>
      withNotifier(
        withLogger<T>(withAbort<T>(axios.put)(url, body, config)),
        config.successNotifCb,
        config.errorNotifCb,
      ),
    patch: (url: string, body: any, config: any = {}) =>
      withNotifier(
        withLogger<T>(withAbort<T>(axios.patch)(url, body, config)),
        config.successNotifCb,
        config.errorNotifCb,
      ),
    delete: (url: string, config: any = {}) =>
      withNotifier(
        withLogger<T>(withAbort<T>(axios.delete, true)(url, config)),
        config.successNotifCb,
        config.errorNotifCb,
      ),
  };
};

type apisMap = {
  [key: symbol]: ReturnType<typeof api>;
};

const apis: apisMap = {};

/** Recusively renames a property (defaults to "value") provided by the server to "_items" on all children */
export function adaptValueToItems(response: any, fieldName: string = "value") {
  if (response !== null && typeof response == "object") {
    Object.entries(response).forEach(([key, value]: any) => {
      if (key === fieldName && Array.isArray(value)) {
        response._items = value;
        delete response[fieldName];
      }
      adaptValueToItems(value, fieldName);
    });
  }
  return response;
}

/**
 * @param {symbol} apiHost A unique key specifying an apiHost, usually found in constants/apiHosts.js
 * @param {CreateAxiosDefaults} axiosParams Axios parameters object (e.g. baseURL). See axios docs.
 */
const apiService = <T, PagingType = IPaging<T>>(apiHost: symbol, axiosParams: CreateAxiosDefaults) => {
  const instance = axios.create(axiosParams);
  instance.interceptors.response.use(adaptValueToItems);
  if (!apis[apiHost]) {
    apis[apiHost] = api<T, PagingType>(instance);
  }
  return apis[apiHost] as ReturnType<typeof api<T, PagingType>>;
};

export default apiService;
