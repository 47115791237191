<template>
  <button
    :class="[
      `inline-flex group/button items-center justify-center text-sm font-medium transition-colors duration-150 ease-in disabled:cursor-not-allowed`,
      ` rounded-r-md rounded-l-2xl border shadow pr-2.5 h-7`,
      variants[variant],
    ]"
    v-bind="$attrs"
    type="button"
    @click="toggleFilter()"
  >
    <div class="h-7 w-7 bg-neutral-300 rounded-full relative flex items-center justify-center">
      <div
        v-if="!dataset.isVisible"
        class="absolute w-4 h-4 -ml-px -mt-px z-10"
        :style="[
          `background: linear-gradient(
            to top right,
            rgba(255, 255, 255, 0) calc(50% - 1px),
            ${
              dataset.datasetGroup.colors.isDarkForeground
                ? dataset.datasetGroup.colors.background
                : dataset.datasetGroup.colors.foreground
            },
            ${
              dataset.datasetGroup.colors.isDarkForeground
                ? dataset.datasetGroup.colors.foreground
                : dataset.datasetGroup.colors.background
            },
            rgba(255, 255, 255, 0) calc(50% + 2px)
          );
      `,
        ]"
      ></div>
      <PinIcon class="mt-px scale-90" :viewer-color="dataset.datasetGroup.colors" />
    </div>
    <span class="ml-1.5 whitespace-nowrap text-xs">{{ dataset.label }}</span>
  </button>
</template>

<script lang="ts">
import { PropType, defineComponent, inject, ref } from "vue";
import PinIcon from "@/components/viewer/PinIcon.vue";
import injectionSymbols from "@/components/viewer/injectionSymbols";
import { ViewerDataset, ViewerListProps } from "@/components/viewer";
import { useViewerStore } from "@/components/viewer/viewerStore";

const variants: { [key: string]: string } = {
  plain: `border-neutral-300 text-neutral-700 bg-white hover:bg-neutral-200 hover:text-neutral-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-neutral-400 disabled:bg-neutral-200 disabled:text-neutral-400 `,
};

/**
 * Specialized buttons used to render the show/hide dataset buttons over the Viewer Map.
 */
export default defineComponent({
  name: "ViewerMapDatasetButton",
  //inheritAttrs: false,
  components: {
    PinIcon,
  },
  props: {
    /**
     * The dataset this button is showing/hiding.
     */
    dataset: {
      type: Object as PropType<ViewerDataset>,
      required: true,
    },
    /**
     * The dataset's index in its parent array. Used for choosing the icon.
     */
    index: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const viewerStore = useViewerStore();
    const variant = ref("plain");

    const { datasetsQueryRef } = inject(injectionSymbols.ViewerListPropsKey) as ViewerListProps;

    const toggleFilter = () => {
      const isVisible = !props.dataset.isVisible;
      if (!isVisible) {
        datasetsQueryRef.value = datasetsQueryRef.value.filter((id) => id !== props.dataset.id);
      } else {
        datasetsQueryRef.value = [...datasetsQueryRef.value, props.dataset.id];
      }
    };

    return { variants, variant, viewerStore, toggleFilter };
  },
});
</script>

<style module></style>
