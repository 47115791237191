<template>
  <FlagIcon class="mx-auto h-12 w-12 text-gray-400" aria-hidden="true"> </FlagIcon>
  <div class="mt-2 text-center text-base font-medium text-gray-900">{{ label }}</div>
</template>

<script lang="ts">
import { FlagIcon } from "@heroicons/vue/24/outline";
import { defineComponent } from "vue";
/** Styling for the display when no results were found. Typically shows 'None found' with the flag, but the message can be changed. */
export default defineComponent({
  name: "NoneFoundFlag",
  components: { FlagIcon },
  props: {
    /** optional replacement label for "None Found" */
    label: {
      type: String,
      default: "None Found",
    },
  },
  setup(props) {
    return {};
  },
});
</script>

<style module></style>
