<template>
  <div class="sm:grid sm:grid-cols-12 sm:gap-6">
    <!-- @slot Slot for all the field components that should be in this set. 
        Should already be styled - likely using FieldCol -->
    <slot></slot>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
/**
 * Renders form fields as a group using the desired amount of columns (**not a FormKit group).
 */
export default defineComponent({
  name: "FieldSetBase",
  setup() {
    return {};
  },
});
</script>

<style module></style>
