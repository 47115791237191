import { apiService } from "@/utils/api/index.js";
import { serializeQueryParams } from "@/utils/helpers/index.js";

import type { IPagingApiAndNotifierConfig } from "@/utils/api/index.js";
import { useEntityAuditConfigStore } from "@/components/entity/audit/entityAuditConfigStore.js";
import type { NotifyOptionsError, NotifyOptionsSuccess } from "@/components/notifications/index.js";
import type { AuditHistory, IAuditQuery } from "@/components/entity/audit/index.js";

let api: ReturnType<typeof apiService<AuditHistory>>;

const getApi = () => {
  if (api) return api;

  const entityAuditConfigStore = useEntityAuditConfigStore();
  const axiosParams = {
    // Set different base URL based on the environment
    baseURL: entityAuditConfigStore.baseURL,
  };
  api = apiService(entityAuditConfigStore.apiHost, axiosParams);
  return api;
};

const getEntityName = () => {
  const entityAuditConfigStore = useEntityAuditConfigStore();
  return entityAuditConfigStore.auditLabel;
};

const getAudits = (Url: string, params: IAuditQuery, config: IPagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.hide = true;
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.fetched = getEntityName();
  };

  return getApi().get(Url, {
    params,
    paramsSerializer: serializeQueryParams,
    abort: config?.abortMethod,
    successNotifCb,
    errorNotifCb,
  });
};

export { getAudits };
