import type { FormKitNode } from "@formkit/core";

let radioInstance = 0;
/**
 * Renames a radio inputs `altName` with a unique identifier consisting of the `node.name` and next count of `radioInstance`.
 * @param {FormKitNode} node - The form node to process.
 */
export function renameRadios(node: FormKitNode) {
  if (node.props.type === "radio") {
    node.props.altName = `${node.name}_${radioInstance++}`;
  }
  return true;
}
