<template>
  <BaseButton
    class="mt-0.5 mb-0.5 h-8 w-full md:w-8 flex-none px-1"
    icon
    borderless
    variant="plain-subtle"
    :label="label"
    @click="clickFunction()"
  >
    <template #iconBefore
      ><FlagIcon class="w-5" v-if="solidCondition" /><FlagIconOutline class="w-5" v-if="!solidCondition" /></template
  ></BaseButton>
</template>

<script lang="ts">
import { defineComponent, ref, unref } from "vue";

import { FlagIcon as FlagIconOutline } from "@heroicons/vue/24/outline";
import { FlagIcon } from "@heroicons/vue/24/solid";
import { BaseButton } from "@/components/button";

/** Component to allow the use of the flag icon in schema.  */
export default defineComponent({
  name: "FlagButton",
  emits: [],
  props: {
    label: {
      type: String,
      required: true,
    },
    clickFunction: {
      type: Function,
      required: true,
    },
    solidCondition: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, context) {
    return {};
  },
  components: { FlagIcon, BaseButton, FlagIconOutline },
});
</script>
