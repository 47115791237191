import { AxiosResponse } from "axios";
import { ComputedRef, Ref, computed, ref } from "vue";
import { didAbort } from "@/utils/api/apiService.js";
import { apiStatus } from "@/utils/api/constants/apiStatus.js";
import type { IExecPaged, IPaging } from "@/utils/api/index.js";

const { IDLE, SUCCESS, PENDING, ERROR } = apiStatus;

export type UseApiResult<T> = {
  dataRef: Ref<T | undefined>;
  statusRef: Ref<symbol>;
  errorRef: any;
  exec: (...args: any[]) => Promise<T | undefined>;
  setStatus: (newStatus: symbol) => symbol;
  isStatusIdle: ComputedRef<boolean>;
  isStatusSuccess: ComputedRef<boolean>;
  isStatusPending: ComputedRef<boolean>;
  isStatusError: ComputedRef<boolean>;
};

export type UsePagedApiResult<BareType, T extends IPaging<BareType> = IPaging<BareType>> = UseApiResult<T> & {
  //dataRef: Ref<T | undefined>;
  resetData: () => void;
  execPaged: (...args: any[]) => Promise<T | undefined>;
};

// type UseApiConfigWithAdapter<T = Object, R = T> = {
//   initialData?: T;
//   responseAdapter: (response: AxiosResponse<R, any>) => T;
// };

// type UseApiConfigWithoutAdapter<T = Object, R = T> = {
//   initialData?: T;
// };

// type UseApiConfig<T = Object, R = T> = UseApiConfigWithAdapter<T, R> | UseApiConfigWithoutAdapter<T, R>;

// type Config<T, R> = T extends R ? UseApiConfigWithoutAdapter<T, R> : UseApiConfigWithAdapter<T, R>;

// UseApiConfig comments are in property tags and above the property's themselves to satisfy both IntelliSense and TypeDoc. They want different things :(
/**
 * @property initialData - Generally generated using the Schema
 * @property responseAdapter - Used to manipulate the data after it's returned and before it's tied to the view
 */
export type UseApiConfig<R = Object, T = R> = {
  /** Generally generated using the Schema */
  initialData?: T;
  /** Used to manipulate the data after it's returned and before it's tied to the view */
  responseAdapter?: (response: AxiosResponse<R, any>) => T;
};

function defaultResponseAdapter<R, T>(response: AxiosResponse<R, any>): T {
  return response.data as unknown as T;
}

// TODO: Paged API is a bit different. You have to handle errors yourself.
// T is the type after response adapter has changed it. R is the response type from the server.

/**
 * Wrapper function for paged API calls.
 *
 * R is response type, dataRef uses T, which defaults to R
 *
 * @param {function} apiCall Method to call
 * @param {UseApiConfig<R, T>} config
 */
export function usePagedApi<
  BareResponse,
  BareDataRef = BareResponse,
  R extends IPaging<BareResponse> = IPaging<BareResponse>,
  T extends IPaging<BareDataRef> = IPaging<BareDataRef>,
>(
  apiCall: (...args: any[]) => Promise<AxiosResponse<R, any>>,
  config?: UseApiConfig<R, T>,
): UsePagedApiResult<BareDataRef> {
  const initialData = config?.initialData;
  const responseAdapter = config?.responseAdapter || defaultResponseAdapter;
  // Reactive values to store data and API status
  const dataRef = ref<T | undefined>(initialData) as Ref<T | undefined>; // Goofy 'as' statement makes TS weirdness happy.
  const statusRef = ref(IDLE);
  const errorRef = ref<any | null>(null);

  const resetData = () => {
    dataRef.value = initialData;
  };
  const setStatus = (newStatus: symbol) => (statusRef.value = newStatus);

  /**
   * Run the api request
   */
  const exec = async (...args: any[]) => {
    try {
      statusRef.value = PENDING;
      const response = await apiCall(...args);

      dataRef.value = responseAdapter(response);
      statusRef.value = SUCCESS;

      return dataRef.value;
    } catch (error: any) {
      if (didAbort(error)) {
        return; //for aborts, a subsequent request will be updating everything, so don't do that now.
      }
      errorRef.value = error;
      statusRef.value = ERROR;
      resetData();
      throw error;
    }
  };

  // Paged API methods differ from other API methods in that they must have a standard method signature in order to work with paging components.
  const execPaged: IExecPaged = async (params, apiConfig) => {
    try {
      statusRef.value = PENDING;
      //const response = await apiCall(params, apiConfig);
      apiConfig.pagingUrl = dataRef.value?.next.href;
      const response = await apiCall({}, apiConfig);

      const result = responseAdapter(response);

      if (!dataRef.value) {
        dataRef.value = result;
      } else {
        dataRef.value._items.push(...result._items);
        const _items = dataRef.value._items;
        dataRef.value = {
          ...result,
          _items,
        };
      }
      statusRef.value = SUCCESS;

      return dataRef.value;
    } catch (error: any) {
      if (didAbort(error)) {
        return; //for aborts, a subsequent request will be updating everything, so don't do that now.
      }
      errorRef.value = error;
      statusRef.value = ERROR;
      resetData();
      throw error;
    }
  };

  const result = {
    dataRef,
    resetData,
    statusRef,
    errorRef,
    exec,
    execPaged,
    setStatus,
    isStatusIdle: computed(() => IDLE === statusRef.value),
    isStatusSuccess: computed(() => SUCCESS === statusRef.value),
    isStatusPending: computed(() => PENDING === statusRef.value),
    isStatusError: computed(() => ERROR === statusRef.value),
  };
  return result;
}

// // TODO: Linked API is a bit different. You have to handle errors yourself.
// export const useLinkedApi = (apiName, apiCall, config = {}) => {
//   const { initialData, responseAdapter } = config;
//   // Reactive values to store data and API status
//   const dataRef = ref(initialData);
//   const statusRef = ref(IDLE);
//   const errorRef = ref(null);

//   const resetData = () => (dataRef.value = initialData);
//   const setStatus = (nextStatus) => (statusRef.value = nextStatus);

//   /**
//    * Run the api request
//    */
//   const exec = async (...args) => {
//     try {
//       statusRef.value = PENDING;
//       // resetData();
//       const response = await apiCall(...args);
//       dataRef.value = typeof responseAdapter === "function" ? responseAdapter(response) : response;
//       if (response === undefined) {
//         // This is probably an abort, so don't update the status.
//       } else {
//         statusRef.value = SUCCESS;
//       }
//     } catch (error) {
//       errorRef.value = error;
//       statusRef.value = ERROR;
//       resetData();
//       throw error;
//     }
//   };

//   const execPaged = async (...args) => {
//     try {
//       statusRef.value = PENDING;
//       const response = await apiCall(...args);
//       const result = typeof responseAdapter === "function" ? responseAdapter(response) : response;
//       // debugger;
//       if (!dataRef.value) {
//         dataRef.value = result;
//       } else {
//         dataRef.value._items.push(...result._items);
//         dataRef.value.skip = result.skip;
//       }
//       if (response === undefined) {
//         // This is probably an abort, so don't update the status.
//       } else {
//         statusRef.value = SUCCESS;
//       }
//     } catch (error) {
//       errorRef.value = error;
//       statusRef.value = ERROR;
//       resetData();
//       throw error;
//     }
//   };

//   return {
//     dataRef,
//     resetData,
//     statusRef,
//     errorRef,
//     exec,
//     execPaged,
//     setStatus,
//   };
// };

/**
 * Wrapper function for API calls.
 *
 * @param {function} apiCall Method to call
 * @param {UseApiConfig<R, T>} config
 */
export function useApi<R, T = R>(
  apiCall: (...args: any[]) => Promise<AxiosResponse<R, any>>,
  config?: UseApiConfig<R, T>,
): UseApiResult<T> {
  // console.warn(apiCall.name);
  const initialData = config?.initialData;
  const responseAdapter = config?.responseAdapter || defaultResponseAdapter;
  // Reactive values to store data and API status
  const dataRef = ref(initialData) as Ref<T | undefined>;
  const statusRef = ref(IDLE);
  const errorRef = ref<any | null>(null);

  /**
   * Run the api request
   */
  const exec = async (...args: any[]) => {
    try {
      statusRef.value = PENDING;
      const response = await apiCall(...args);
      dataRef.value = responseAdapter(response);
      statusRef.value = SUCCESS;

      return dataRef.value;
    } catch (error) {
      if (didAbort(error)) {
        return; //for aborts, a subsequent request will be updating everything, so don't do that now.
      }

      errorRef.value = error;
      statusRef.value = ERROR;
      //TODO: Render a notification as well?? Log it?
      throw error;
    }
  };

  const setStatus = (newStatus: symbol) => (statusRef.value = newStatus);

  return {
    dataRef,
    statusRef,
    errorRef,
    exec,
    setStatus,
    isStatusIdle: computed(() => IDLE === statusRef.value),
    isStatusSuccess: computed(() => SUCCESS === statusRef.value),
    isStatusPending: computed(() => PENDING === statusRef.value),
    isStatusError: computed(() => ERROR === statusRef.value),
  };
}
