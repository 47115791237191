import { format } from "date-fns/fp/format";
import { isValid } from "date-fns/fp/isValid";
import { parseISO } from "date-fns/fp/parseISO";
import { parseJSON } from "date-fns/fp/parseJSON";

export const longDateTime = format("PPPp");
export const shortDateTime = format("P p");
export const longDate = format("PPP");
export const shortDate = format("P");
export const timeOnly = format("p");

export const longDateTimeString = (dateString: string | null) => fromStringToFormat(dateString, longDateTime);
export const shortDateTimeString = (dateString: string | null) => fromStringToFormat(dateString, shortDateTime);
export const longDateString = (dateString: string | null) => fromStringToFormat(dateString, longDate);
export const shortDateString = (dateString: string | null) => fromStringToFormat(dateString, shortDate);

function fromStringToFormat(dateString: string | null, formatter: typeof longDateTime) {
  if (!dateString) return null;

  let parsed = parseJSON(dateString);
  if (isValid(parsed)) return formatter(parsed);

  parsed = parseISO(dateString);
  return isValid(parsed) ? formatter(parsed) : null;
}

// This method takes a separate date and time arg and formats the result. This is handy for local date / time values.
/**
 * Formats a separate date and time into a combined string using the specified formatter.
 * This method is useful for formatting local date/time values.
 * @param {string | null} date The date string to format.
 * @param {string | null} time The time string to format.
 * @param {function} [formatter=shortDate] The date formatter function to use. Defaults to {@link shortDate}
 * @returns {string | null} Returns the formatted date and time string, or null if both date and time are null.
 */
export function fromDateAndTimeToFormat(date: string | null, time: string | null, formatter = shortDate) {
  if (!date && !time) return null;

  let result = "";
  if (date) {
    result += fromStringToFormat(date, formatter);
  }
  if (time) {
    result += (result ? " " : "") + formatTime(time);
  }
  return result;
}

function formatTime(time: string | null) {
  if (!time) return time;

  let hours = parseInt(time.substring(0, 2));
  let suffix = "AM";
  if (hours > 12) {
    hours -= 12;
    suffix = "PM";
  }
  return `${hours}${time.substring(2, 5)} ${suffix}`;
}

/* 
  Parse local dates
  parse UTC dates
  render date in the system timezone.
   - render date in system timezone with conditional addition of timezone if user isn't in the system timezone.
*/

// export const convertUtcToSystemTime = (date, options) => {
//   if (!date) return date;

//   options = options || {
//     year: "2-digit",
//     month: "2-digit",
//     day: "2-digit",
//     //hour: '2-digit', minute: '2-digit'
//     //timeZoneName: 'short'
//   };

//   options.timeZone = appSettings.systemDateTimeZone;

//   var formatter = new Intl.DateTimeFormat("en-US", options);
//   var dateObj = new Date(date);

//   return formatter.format(dateObj);
// };
