<template>
  <th scope="col" class="sticky top-0 border-0 bg-neutral-50 p-0">
    <span class="sr-only">{{ label }}</span>
  </th>
</template>

<script lang="ts">
import { defineComponent } from "vue";

/** Component to to provide the column space for list actions (such as navigating to the view) */
export default defineComponent({
  name: "ListTableActionColumn",
  components: {},
  props: {
    /** Optional label for the screen reader - defaults to "Actions" */
    label: {
      type: String,
      default: "Actions",
    },
  },
  setup(props) {
    return {};
  },
});
</script>

<style module></style>
