import EntityAudits from "@casa/components/EntityAudits.vue";
import { EntityNotes } from "@mui/components/entity/note";
import { EntityDocuments } from "@mui/components/entity/document";

import { RouterView } from "vue-router";

// Troubleshoot your routes using this - https://paths.esm.dev

/** @type {import('vue-router').RouterOptions['routes']} */

const names = {
  appraisals: "Appraisals",
  appraisalList: "AppraisalList",
  appraisalNew: "AppraisalNew",
  appraisalBase: "AppraisalBase",
  appraisalView: "AppraisalView",
  appraisalEdit: "AppraisalEdit",
  appraisalEditSchema: "AppraisalEditSchema",
  appraisalComplete: "AppraisalComplete",
  appraisalCompleteSchema: "AppraisalCompleteSchema",
  appraisalNotes: "AppraisalNotes",
  appraisalNoteEdit: "AppraisalNoteEdit",
  appraisalNoteNew: "AppraisalNoteNew",
  appraisalDocuments: "AppraisalDocuments",
  appraisalDocumentEdit: "AppraisalDocumentEdit",
  appraisalDocumentNew: "AppraisalDocumentNew",
  appraisalAudit: "AppraisalAudit",
};

export { names as appraisalRouteNames };

const appraisalRoutes = [
  {
    path: "/appraisal",
    name: names.appraisals,
    component: {
      render: () => <RouterView />,
    },
    redirect: { name: names.appraisalList },
    children: [
      {
        path: "list",
        name: names.appraisalList,
        meta: { title: "Appraisals" },
        component: () => import("@casa/views/appraisal/AppraisalList.vue"),
      },
      {
        path: "new",
        component: () => import("@casa/views/appraisal/AppraisalBase.vue"),
        redirect: { name: names.appraisalNew },
        props: true,
        children: [
          {
            path: ":abodeId(\\d+)?",
            name: names.appraisalNew,
            meta: { title: "New Appraisal", isNew: true },
            component: () => import("@casa/views/appraisal/AppraisalEdit.vue"),
          },
        ],
      },
      {
        path: ":id(\\d+)",
        name: names.appraisalBase,
        component: () => import("@casa/views/appraisal/AppraisalBase.vue"),
        redirect: { name: names.appraisalView },
        children: [
          {
            path: "view",
            name: names.appraisalView,
            meta: { title: "Appraisal View" },
            component: () => import("@casa/views/appraisal/AppraisalView.vue"),
          },
          {
            path: "edit",
            name: names.appraisalEdit,
            meta: { title: "Appraisal Edit", isForm: true },
            component: () => import("@casa/views/appraisal/AppraisalEdit.vue"),
          },
          {
            path: "editSchema",
            name: names.appraisalEditSchema,
            meta: { title: "Appraisal Edit - Schema", isForm: true },
            component: () => import("@casa/views/appraisal/AppraisalEditSchema.vue"),
          },
          {
            path: "complete",
            name: names.appraisalComplete,
            meta: { title: "Complete Appraisal", isForm: true },
            component: () => import("@casa/views/appraisal/AppraisalComplete.vue"),
          },
          {
            path: "completeSchema",
            name: names.appraisalCompleteSchema,
            meta: { title: "Complete Appraisal - Schema", isForm: true },
            component: () => import("@casa/views/appraisal/AppraisalCompleteSchema.vue"),
          },
          {
            path: "notes",
            name: names.appraisalNotes,
            meta: { title: "Appraisal Notes" },
            component: {
              render: () => <EntityNotes idField="appraisalId" routeNamePrefix="AppraisalNote" />,
            },
          },
          {
            path: "notes/:annotationId(\\d+)",
            name: names.appraisalNoteEdit,
            meta: { title: "Edit Appraisal Note", isForm: true },
            component: () => import("@casa/views/appraisal/AppraisalNote.vue"),
          },
          {
            path: "notes/new",
            name: names.appraisalNoteNew,
            meta: { title: "Create Appraisal Note", isForm: true },
            component: () => import("@casa/views/appraisal/AppraisalNote.vue"),
          },
          {
            path: "documents",
            name: names.appraisalDocuments,
            meta: { title: "Appraisal Documents" },
            component: {
              render: () => <EntityDocuments idField="appraisalId" routeNamePrefix="AppraisalDocument" />,
            },
          },
          {
            path: "documents/:documentId(\\d+)",
            name: names.appraisalDocumentEdit,
            meta: { title: "Edit Appraisal Document", isForm: true },
            component: () => import("@casa/views/appraisal/AppraisalDocument.vue"),
          },
          {
            path: "documents/new",
            name: names.appraisalDocumentNew,
            meta: { title: "Create Appraisal Document", isForm: true },
            component: () => import("@casa/views/appraisal/AppraisalDocument.vue"),
          },
          {
            path: "activity",
            name: names.appraisalAudit,
            meta: { title: "Appraisal Audit Activity" },
            component: {
              render: () => <EntityAudits entityName="Appraisal" />,
            },
          },
        ],
      },
    ],
  },
];
export default appraisalRoutes;
