<template>
  <div class="flex h-screen flex-col">
    <AppNav />
    <div class="flex h-full flex-1 flex-col overflow-hidden print:overflow-visible">
      <!-- @slot Content area -->
      <slot></slot>
    </div>
  </div>
</template>

<script lang="ts">
import AppNav from "@/components/common/AppNav.vue";
import { defineComponent } from "vue";

/** Component for the base layout for the page; adds the navigation component. */
export default defineComponent({
  name: "LayoutAppContent",
  components: {
    AppNav,
  },
  setup() {
    return {};
  },
});
</script>
