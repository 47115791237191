import { unref } from "vue";
import { isPlainObject } from "@/utils/helpers/isPlainObject.js";

/**
 * Converts dates and removes refs from the provided object.
 * @param {object} obj Object to convert.
 * @param {object} resultObj Result object to populate.
 * @returns {object} Returns the result object with dates converted to ISO strings and refs removed.
 */
function convertDatesRemoveRefs(obj: { [key: string | number]: any }, resultObj: { [key: string | number]: any }) {
  for (let key in obj) {
    const val = unref(obj[key]);

    if (Array.isArray(val)) {
      resultObj[key] = convertDatesRemoveRefs(val, []);
    } else if (isPlainObject(val)) {
      resultObj[key] = convertDatesRemoveRefs(val, {});
    } else if (val?.toISOString) {
      resultObj[key] = val.toISOString();
    } else {
      resultObj[key] = val;
    }
  }

  return resultObj;
}

/**
 * Serializes query parameters into a URL-encoded string.
 * @param {object} params Parameters to serialize.
 * @returns {string} Returns the serialized query parameters string.
 */
export const serializeQueryParams = (params: { [key: string | number]: any }) => {
  let result = "";
  try {
    const resultObj = convertDatesRemoveRefs(params, {});

    for (let key in resultObj) {
      const val = resultObj[key];
      result += key + "=";

      if (Array.isArray(val) || isPlainObject(val)) {
        result += encodeURIComponent(JSON.stringify(val));
      } else {
        result += encodeURIComponent(val);
      }
      result += "&";
    }
    result = result.substring(0, result.length - 1); // trims the trailing &
  } catch (error) {
    console.error(error);
    throw error;
  }
  return result;
};

/**
 * Serializes query parameters into a URL-encoded string.
 * Currently just a wrapper function for {@link serializeQueryParams} allowing the ability handle dynamic queries differently in the future if needed.
 * @param {object} params Parameters to serialize.
 * @returns {string} Returns the serialized query parameters string.
 */
export const serializeDynamicQueryParams = (params: { [key: string | number]: any }) => {
  return serializeQueryParams(params);
};
