import { RouterView } from "vue-router";
// Troubleshoot your routes using this - https://paths.esm.dev

/** @type {import('vue-router').RouterOptions['routes']} */
const kitchenSinkRoutes = [
  {
    path: "/kitchenSink",
    name: "KitchenSink",
    component: () => import("@casa/views/kitchenSink/KitchenSink.vue"),
  },
];
export default kitchenSinkRoutes;
