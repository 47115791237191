<template>
  <!-- layout is different if its nested or not - so that's why we need this -->
  <component :is="!nested ? 'NonNestedRepeaterHeader' : 'NestedRepeaterHeader'" v-bind="$props">
    <template #default>
      <FormKit
        :name="name ?? frozenName"
        type="repeater"
        :draggable="draggable"
        label=""
        :down-control="downControl"
        :plugins="[addHandlers]"
        :up-control="upControl"
        :sections-schema="{
          controls: {
            attrs: {
              class: 'flex flex-col items-center justify-center md:flex-row md:items-end mr-5 md:mb-1 md:ml-0',
            },
          },
          item: {
            attrs: {
              class: 'flex w-full formkit-item  border-none mx-0 mb-1 py-2 even:bg-neutral-200 md:even:bg-inherit',
            },
          },
          fieldset: {
            attrs: {
              class: 'border-none',
            },
          },
          legend: {
            attrs: {
              class: 'hidden',
            },
          },
          content: {
            attrs: {
              class: 'grow px-3 py-0 my-0 flex flex-col align-center formkit-content',
            },
          },
          outer: outerClass,
        }"
      >
        <template #removeControl="{ fns, index, value }">
          <BaseButton
            class="ml-1 mb-0.5 mt-0.5 h-8 w-8 flex-none px-1"
            icon
            borderless
            variant="danger-subtle"
            :label="'removeLabel'"
            :disabled="index === 0 && value.length === 1"
            @click="fns.removeInnerItem(index)()"
          >
            <!-- TODO - Ask -> do we always want disabled on 0 or should this be a prop? -->
            <template #iconBefore><XMarkIcon class="w-5" /></template
          ></BaseButton>
        </template>

        <template #addButton="{ fns }">
          <div class="m-2 mb-3 rounded-lg border-dashed border-neutral-300 text-right md:mx-4 md:my-4">
            <BaseButton
              class="mt-1 md:flex-grow-0"
              variant="create-hollow"
              :label="addLabel"
              @click="fns.appendInnerItem()()"
            >
              <template #iconBefore><PlusIcon /></template
            ></BaseButton>
          </div>
        </template>

        <template #default="{ fns, index }">
          <slot :index="index"></slot>
        </template>
      </FormKit>
    </template>
  </component>
</template>

<script lang="ts">
import { PropType, defineComponent, inject, ref, unref } from "vue";

import { FlagIcon as FlagIconOutline } from "@heroicons/vue/24/outline";
import { FlagIcon, PlusIcon, XMarkIcon } from "@heroicons/vue/24/solid";
import NestedRepeaterHeader from "@/components/schema/NestedRepeaterHeader.vue";
import NonNestedRepeaterHeader from "@/components/schema/NonNestedRepeaterHeader.vue";

import { FormKitNode } from "@formkit/core";
import { BaseButton } from "@/components/button/index.js";

export default defineComponent({
  name: "InnerRepeater",
  emits: [],
  props: {
    /** left side header content  */
    leftHeader: {
      type: String,
    },
    /** right side header content  */
    rightHeader: {
      type: String,
    },
    /** the name of this repeater. If provided this will override the middle and outer repeater names. (used for non nested) */
    name: {
      type: String,
    },
    /** This is the name that was given to the entire repeater (passed as the name value to the outer repeater). used to make unqiue ids. not to be confused with name for outer, which is used to determine the  third repeater value (ex. county) - required if nested */
    repeaterName: {
      type: String,
    },
    /**  req'd if nested  - the name of this repeater if triggered/created by the outer repeater
     *  - for example - this repeater in the recycling example is for materials.
     * if a county and material were selected, this is the material input name from that grouped field.
     * (instead of the singular material input)
     */
    nameForOuter: {
      type: String,
      default: "OuterRepeater",
    },
    /**  req'd if nested  - the name of this repeater if triggered/created by the middle repeater */
    nameForMiddle: {
      type: String,
      default: "MiddleRepeater",
    },
    removeLabel: {
      type: String,
      default: "Remove",
    },
    addLabel: {
      type: String,
      default: "Add Item",
    },
    nested: {
      type: Boolean,
      default: false,
    },
    /** req'd if nested - this is the index of the middle repeater item the inner repeater sits inside. (example - the index of the material in respect to the county) */
    index: {
      type: Number,
    },
    /* used to determined if should be disabled - req'd if nested - (used in the NestedRepeaterHeader component) */
    // length: {
    //   type: Number,
    // },
    // default is false because we are not using a cameFromOuter prop -> therefore assumed if we don't specify it came from the middle repeater, it would have come from the outer repeater
    // should we add a cameFromOuter?
    cameFromMiddle: {
      type: Boolean,
      default: false,
    },
    /** this is an array of lengths for the the outer repeater (example - array of number of materials per county) - used in determining disabled */
    outerLengths: {
      type: Array as PropType<any[]>,
      default: [],
    },
    /** used to grab the corresponding length from the outerLengths prop for whichever outer repeater this is sitting inside  */
    outerIndex: {
      type: Number,
      default: -1,
    },
    //TODO
    // /** function to perform when something is appended  */
    // onAppend: {
    //   type: Function,
    // },
    // /** function to perform when something is removed  */
    // onRemove: {
    //   type: Function,
    // },
    //todo - props / customizations
    draggable: {
      type: Boolean,
      default: false,
    },
    downControl: {
      type: Boolean,
      default: false,
    },
    upControl: {
      type: Boolean,
      default: false,
    },

    numericRollup: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    //changes the class based on if repeater is nested.
    const outerClass = ref({});

    if (props.nested) {
      outerClass.value = {
        attrs: {
          class:
            "group mb-0 data-[disabled]:opacity-50 data-[disabled]:select-none data-[disabled]:pointer-events-none formkit-outer",
        },
      };
    }

    //grabs the value of the correct name prop (two options, one from middle and one from outer - depends which button is clicked)
    const frozenName = props.cameFromMiddle === true ? unref(props.nameForMiddle) : unref(props.nameForOuter);

    /** NOTE - it is important these fns names are different than the ones in the repeaters that wrap this. It will trigger the wrong function. */
    const addHandlers = (node: FormKitNode) => {
      node.context!.fns.appendInnerItem = () => () => {
        //start - formkits original append
        const _value = node._value as Array<Record<string, unknown>>;
        _value.push({});
        node
          .input(_value, false) //end - formkits original append
          .then(() => {
            //optional space for actions to perform
          })
          .catch((error) => {
            console.error("Error occurred:", error);
          });
      };

      node.context!.fns.removeInnerItem = (index: number) => () => {
        //start - formkits original remove
        const _value = node._value as Array<Record<string, unknown>>;
        _value.splice(index, 1);
        node
          .input(_value, false) //end - formkits original remove
          .then(() => {
            //optional space for actions to perform
          })
          .catch((error) => {
            console.error("Error occurred:", error);
          });
      };
    };

    return {
      outerClass,
      frozenName,
      addHandlers,
    };
  },
  components: {
    BaseButton,
    XMarkIcon,
    FlagIcon,
    FlagIconOutline,
    PlusIcon,
    NonNestedRepeaterHeader,
    NestedRepeaterHeader,
  },
});
</script>
