import { serializeQueryParams } from "@/utils/helpers/index.js";
import { apiService, getOtherQueryIdStrings } from "@/utils/api/index.js";

import type { IPagingApiAndNotifierConfig } from "@/utils/api/index.js";
import type { NotifyOptionsError, NotifyOptionsSuccess } from "@/components/notifications/index.js";

import type { IDocument, IDocumentNew, IDocumentQuery } from "@/components/entity/document/index.js";
import { useEntityDocumentConfigStore } from "@/components/entity/document/entityDocumentConfigStore.js";
import { AxiosResponse } from "axios";

const URL = "documents";

let api: ReturnType<typeof apiService<IDocument>>;

const getApi = () => {
  if (api) return api;

  const entityDocumentConfigStore = useEntityDocumentConfigStore();
  const axiosParams = {
    // Set different base URL based on the environment
    baseURL: entityDocumentConfigStore.baseURL,
  };
  api = apiService<IDocument>(entityDocumentConfigStore.apiHost, axiosParams);
  return api;
};

const getEntityName = () => {
  const entityDocumentConfigStore = useEntityDocumentConfigStore();
  return entityDocumentConfigStore.documentLabel;
};

/**
 * @param {Object} params Query parameters, varies (TODO improve this)

 */
const getDocuments = (params: IDocumentQuery, config: IPagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.hide = true;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.fetched = getEntityName();
    config?.errorNotifCb?.(options);
  };
  const url = config.pagingUrl ? config.pagingUrl : URL;

  return getApi().getPaged(url, {
    params,
    paramsSerializer: serializeQueryParams,
    abort: config?.abortMethod,
    successNotifCb,
    errorNotifCb,
  });
};

const getDocumentDownloadLink = (id: string | number) => {
  const entityDocumentConfigStore = useEntityDocumentConfigStore();
  return entityDocumentConfigStore.baseURL + URL + "/" + id + "/download";
};

/**
 * @param {Int} id
 */
const getDocumentById = (id: string | number, config: IPagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.hide = true;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.fetched = getEntityName();
    config?.errorNotifCb?.(options);
  };

  return getApi().get(URL + "/" + id, {
    successNotifCb,
    errorNotifCb,
  });
};

type DocumentIds = {
  id?: string | number;
};

const getNewDocument = async (ids: DocumentIds) => {
  return { data: { ...ids } } as AxiosResponse<IDocumentNew>;
};

const getDocumentByIdOrNew = (ids: DocumentIds, config: IPagingApiAndNotifierConfig = {}) => {
  if (!ids.id) {
    return getNewDocument(ids);
  } else {
    return getDocumentById(ids.id, config) as Promise<AxiosResponse<IDocumentNew, any>>;
  }
};

const createDocument = (document: IDocument, config: IPagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.created = getEntityName();
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.created = getEntityName();
    config?.errorNotifCb?.(options);
  };

  return getApi().post(URL, document, {
    successNotifCb,
    errorNotifCb,
  });
};

const updateDocument = (document: IDocument, config: IPagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.updated = getEntityName();
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.updated = getEntityName();
    config?.errorNotifCb?.(options);
  };

  return getApi().put(URL + "/" + document.id, document, {
    successNotifCb,
    errorNotifCb,
  });
};

/**
 * @param {Document} document This cannot be a 'ref' to a document
 */
const saveDocument = (document: IDocument, config: IPagingApiAndNotifierConfig = {}) => {
  if (document.id) {
    return updateDocument(document, config);
  } else {
    return createDocument(document, config);
  }
};

/**
 * @param {Int} id
 */
const deleteDocumentById = (ids: DocumentIds, config: IPagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.deleted = getEntityName();
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.deleted = getEntityName();
    config?.errorNotifCb?.(options);
  };

  const otherIdStrings = getOtherQueryIdStrings(ids);
  return getApi().delete(URL + "/" + ids.id + "?" + otherIdStrings, {
    successNotifCb,
    errorNotifCb,
  });
};

export {
  getDocumentDownloadLink,
  deleteDocumentById,
  getDocuments,
  getDocumentById,
  getNewDocument,
  getDocumentByIdOrNew,
  createDocument,
  updateDocument,
  saveDocument,
};
