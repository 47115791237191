import { IPagingApiAndNotifierConfig, apiService } from "@mui/utils/api";
import { serializeQueryParams } from "@mui/utils/helpers";
import { NotifyOptionsError, NotifyOptionsSuccess } from "@mui/components/notifications";
import { apiHosts } from "@casa/api/constants/apiHosts";
import { IUtility, IUtilityNew, IUtilityQuery } from "@casa/views/abode/abode";
import type { AxiosResponse } from "axios";

const URL = (abodeId: string | number) => `abodes/${abodeId}/utilities`;
const ENTITY_NAME = "Utility";

const axiosParams = {
  // Set different base URL based on the environment
  baseURL: import.meta.env.VITE_CASA_API_BASE_URL,
};
const api = apiService<IUtility>(apiHosts.CASA, axiosParams);

/**
 * @param {Object} params Query parameters, varies (TODO improve this)

 */
const getUtilities = (params: IUtilityQuery, config: IPagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.hide = true;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.fetched = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };

  const url = config.pagingUrl ? config.pagingUrl : URL(params.abodeId);

  return api.getPaged(url, {
    params,
    paramsSerializer: serializeQueryParams,
    abort: config?.abortMethod,
    successNotifCb,
    errorNotifCb,
  });
};

/**
 * @param {Int} id
 */

type UtilityIds = {
  id?: string | number;
  abodeId: string | number;
};
const getUtilityById = (ids: UtilityIds, config: IPagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.hide = true;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.fetched = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };
  return api.get(URL(ids.abodeId) + "/" + ids.id, {
    successNotifCb,
    errorNotifCb,
  });
};

const getNewUtility = async (ids: UtilityIds) => {
  //optionally make any other changes as necessary before returning.
  return { data: { ...ids } } as AxiosResponse<IUtilityNew>;
};

const getUtilityByIdOrNew = (ids: UtilityIds, config: IPagingApiAndNotifierConfig = {}) => {
  if (!ids.id) {
    return getNewUtility(ids);
  } else {
    return getUtilityById(ids, config) as Promise<AxiosResponse<IUtilityNew, any>>;
  }
};

const createUtility = (utility: IUtility, config: IPagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.created = ENTITY_NAME;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.created = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };

  return api.post(URL(utility.abodeId), utility, {
    successNotifCb,
    errorNotifCb,
  });
};

const updateUtility = (utility: IUtility, config: IPagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.updated = ENTITY_NAME;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.updated = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };
  return api.put(URL(utility.abodeId) + "/" + utility.id, utility, {
    successNotifCb,
    errorNotifCb,
  });
};

/**
 * @param {Utility} utility This cannot be a 'ref' to a utility
 */
const saveUtility = (utility: IUtility, config: IPagingApiAndNotifierConfig = {}) => {
  if (utility.id) {
    return updateUtility(utility, config);
  } else {
    return createUtility(utility, config);
  }
};

/**
 * @param {Int} id
 */
const deleteUtilityById = (fa: IUtility, config: IPagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.deleted = ENTITY_NAME;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.deleted = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };

  return api.delete(URL(fa.abodeId) + "/" + fa.id, {
    successNotifCb,
    errorNotifCb,
  });
};

export {
  deleteUtilityById,
  getUtilities,
  getUtilityById,
  getNewUtility,
  getUtilityByIdOrNew,
  createUtility,
  updateUtility,
  saveUtility,
};
