/**
 * Generates a query string from an object of IDs.
 * @param {{ [key: string]: string | number | null | undefined }} otherIds Object containing the other IDs.
 * @returns {string} Returns the query string generated from the other IDs.
 */
export function getOtherQueryIdStrings(otherIds: { [key: string]: string | number | null | undefined }): string {
  const otherIdStrings = [];
  for (let key in otherIds) {
    otherIdStrings.push(`${key}=${otherIds[key]}`);
  }
  return otherIdStrings.join("&");
}
