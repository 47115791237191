<template>
  <BaseButton @click="copyToClipboard(value, successMessage, errorMessage)" :label="label"></BaseButton>
</template>

<script lang="ts">
import BaseButton from "@/components/button/BaseButton.vue";
import { copyToClipboard } from "@/utils/helpers";
import { capitalizeString } from "@/utils/filters";
import { defineComponent } from "vue";

/**
 * Component for a button that contains the functionality to copy the provided value to the users clipboard.
 */
export default defineComponent({
  name: "ClipboardButton",
  components: {
    BaseButton,
  },
  props: {
    /**
     * Label for the entity. Used to created the button label, success message, and error message.
     */
    entityLabel: {
      type: String,
      required: true,
    },
    /**
     * Value to be copied to clipboard upon click.
     */
    value: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const errorMessage = capitalizeString(props.entityLabel) + " could not be copied.";
    const successMessage = capitalizeString(props.entityLabel) + " copied.";
    const label = "Copy " + props.entityLabel + " to clipboard";

    return { copyToClipboard, errorMessage, successMessage, label };
  },
});
</script>

<style module></style>
