<template>
  <ListCellBase>
    <div class="flex items-center">
      <div class="">
        <div class="text-sm font-medium text-neutral-900">
          <!-- @slot Slot for the primary contents, which are always shown -->
          <slot name="default"></slot>
        </div>
        <div class="table-cell text-sm text-neutral-500 sm:hidden">
          <!-- @slot Slot for the secondary contents, which are shown when the display is narrower than `sm`  -->
          <slot name="secondary"></slot>
        </div>
      </div>
    </div>
  </ListCellBase>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import ListCellBase from "./ListCellBase.vue";

/** Used for the Primary column in a ListTable */
export default defineComponent({
  name: "ListCellPrimary",
  components: {
    ListCellBase,
  },
  setup(props) {},
});
</script>

<style module></style>
