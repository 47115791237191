<template>
  <component
    :is="wrapper"
    colspan="100"
    class="bg-neutral-200 p-4"
    :class="[isListCards && 'flex min-h-full items-center justify-center']"
  >
    <div class="text-center" v-if="createTo">
      <NoneFoundFlag />
      <!-- <p class="mt-1 hidden text-sm text-gray-500">Get started by creating a new project.</p> -->
      <Authorize v-bind="createToAuth" hide-by-default>
        <div class="mt-6">
          <BaseButton variant="create" :label="createLabel + ' ' + entityLabel" @click="handleCreateClick()"
            ><template #iconBefore><PlusIcon /></template
          ></BaseButton>
        </div>
      </Authorize>
    </div>
    <div class="text-center" v-if="!createTo">
      <NoneFoundFlag />
    </div>
  </component>
</template>

<script lang="ts">
import type { ListTableProps } from "@/components/list/genericList";
import injectionSymbols from "@/components/list/genericList/injectionSymbols";
import { Authorize } from "@/utils/auth/authorization";
import { ExclamationTriangleIcon, FlagIcon, PlusIcon } from "@heroicons/vue/24/outline";
import { defineComponent, inject } from "vue";
import { useRouter } from "vue-router";
import { BaseButton } from "@/components/button";
import { NoneFoundFlag } from "@/components/common";

/** Component to display the none found flag when the results come back empty.
 *  Adds the opportunity to create an instance the entity when none are present.
 */
export default defineComponent({
  name: "ListNotFound",
  components: {
    FlagIcon,
    PlusIcon,
    BaseButton,
    ExclamationTriangleIcon,
    Authorize,
    NoneFoundFlag,
  },
  props: {
    /** Label to be shown on the create button */
    createLabel: {
      type: String,
      default: "Create",
    },
    /** Use this if this component is being used on ListCards  */
    isListCards: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { entityLabel, entityLabelPlural, createTo, createToAuth } = inject(
      injectionSymbols.GenericListPropsKey,
    ) as ListTableProps;

    const router = useRouter();
    const handleCreateClick = () => {
      if (createTo) router.push(createTo);
    };

    const wrapper = props.isListCards ? "div" : "td";

    return {
      createTo,
      createToAuth,
      entityLabel,
      entityLabelPlural,
      handleCreateClick,
      wrapper,
    };
  },
});
</script>

<style module></style>
