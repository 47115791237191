import { IPagingApiAndNotifierConfig, apiService } from "@mui/utils/api";
import { serializeQueryParams } from "@mui/utils/helpers";
import { NotifyOptionsError, NotifyOptionsSuccess } from "@mui/components/notifications";
import { apiHosts } from "@casa/api/constants/apiHosts";
import { IRoom, IRoomNew, IRoomQuery } from "@casa/views/abode/abode";
import type { AxiosResponse } from "axios";

const URL = (abodeId: string | number) => `abodes/${abodeId}/rooms`;
const ENTITY_NAME = "Room";

const axiosParams = {
  // Set different base URL based on the environment
  baseURL: import.meta.env.VITE_CASA_API_BASE_URL,
};
const api = apiService<IRoom>(apiHosts.CASA, axiosParams);

/**
 * @param {Object} params Query parameters, varies (TODO improve this)

 */

const getRooms = (params: IRoomQuery, config: IPagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.hide = true;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.fetched = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };

  const url = config.pagingUrl ? config.pagingUrl : URL(params.abodeId);

  return api.getPaged(url, {
    params,
    paramsSerializer: serializeQueryParams,
    abort: config?.abortMethod,
    successNotifCb,
    errorNotifCb,
  });
};

/**
 * @param {Int} id
 */

type RoomIds = {
  id?: string | number;
  abodeId: string | number;
};
const getRoomById = (ids: RoomIds, config: IPagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.hide = true;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.fetched = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };
  return api.get(URL(ids.abodeId) + "/" + ids.id, {
    successNotifCb,
    errorNotifCb,
  });
};

const getNewRoom = async (ids: RoomIds) => {
  //optionally make any other changes as necessary before returning.
  return { data: { ...ids } } as AxiosResponse<IRoomNew, any>;
};

const getRoomByIdOrNew = (ids: RoomIds, config: IPagingApiAndNotifierConfig = {}) => {
  if (!ids.id) {
    return getNewRoom(ids);
  } else {
    return getRoomById(ids, config) as Promise<AxiosResponse<IRoomNew, any>>;
  }
};

const createRoom = (room: IRoom, config: IPagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.created = ENTITY_NAME;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.created = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };

  return api.post(URL(room.abodeId), room, {
    successNotifCb,
    errorNotifCb,
  });
};

const updateRoom = (room: IRoom, config: IPagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.updated = ENTITY_NAME;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.updated = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };
  return api.put(URL(room.abodeId) + "/" + room.id, room, {
    successNotifCb,
    errorNotifCb,
  });
};

/**
 * @param {Room} room This cannot be a 'ref' to a room
 */
const saveRoom = (room: IRoom, config: IPagingApiAndNotifierConfig = {}) => {
  if (room.id) {
    return updateRoom(room, config);
  } else {
    return createRoom(room, config);
  }
};

/**
 * @param {Int} id
 */
const deleteRoomById = (fa: IRoom, config: IPagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.deleted = ENTITY_NAME;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.deleted = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };

  return api.delete(URL(fa.abodeId) + "/" + fa.id, {
    successNotifCb,
    errorNotifCb,
  });
};

export { deleteRoomById, getRooms, getRoomById, getNewRoom, createRoom, updateRoom, saveRoom, getRoomByIdOrNew };
