import type { FormKitNode } from "@formkit/core";

/**
 * Validates if a boolean value is provided.
 * @param {FormKitNode} node node to validate.
 * @returns {boolean} Returns true if the value is not undefined, otherwise false.
 */
const required_boolean = (node: FormKitNode) => {
  return node.value !== undefined;
};
// required_boolean.force = true;
required_boolean.blocking = true;
required_boolean.skipEmpty = false;

export { required_boolean };
