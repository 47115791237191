<template>
  <div class="mb-8 px-2 sm:px-0" v-if="entityAuditStore.entityAuditsForWidget.length > 0">
    <h2 class="text-sm font-medium text-neutral-500">Audit Activity</h2>
    <ol role="list" class="mt-2 grid grid-cols-1 gap-3 md:gap-2">
      <li v-for="(item, itemIdx) in entityAuditStore.entityAuditsForWidget" :key="item.id">
        <div class="relative ml-1 pb-5">
          <span
            v-if="itemIdx === entityAuditStore.entityAuditsForWidget.length - 1 && item.action !== 'Added'"
            class="absolute left-2.5 top-0 -ml-px h-full border-l-2 border-dotted border-neutral-300"
            aria-hidden="true"
          ></span>
          <span
            v-if="itemIdx !== entityAuditStore.entityAuditsForWidget.length - 1"
            class="absolute left-2.5 top-4 -ml-px h-full w-0.5 bg-neutral-300"
            aria-hidden="true"
          ></span>
          <div class="relative flex space-x-3">
            <div>
              <span
                :class="[
                  'flex items-center justify-center rounded-full ring-8 ring-neutral-100',
                  item.type.icon
                    ? 'h-5 w-5 bg-neutral-100'
                    : 'mx-0.5 my-0.5 h-4 w-4 border border-neutral-300 bg-white',
                ]"
              >
                <component
                  v-if="item.type.icon"
                  :is="item.type.icon"
                  class="h-5 w-5"
                  :class="[item.type.textColorClass]"
                  aria-hidden="true"
                />
              </span>
            </div>
            <div class="flex min-w-0 flex-1 justify-between space-x-4 pt-0">
              <div>
                <p class="text-sm text-neutral-500">
                  <span class="font-medium text-neutral-900">
                    {{ item.action }}
                  </span>
                  by
                  <span class="font-medium text-neutral-900">{{ item.auditUserName }}</span>
                </p>
              </div>
              <div class="whitespace-nowrap text-right text-sm text-neutral-500">{{ item.displayDate }} ago</div>
            </div>
          </div>
        </div>
      </li>
      <li>
        <BaseButton @click="viewAllActivity" label="View all Audit Activity" class=""></BaseButton>
      </li>
    </ol>
  </div>
</template>

<script lang="ts">
import { PlusCircleIcon, StarIcon, TrashIcon } from "@heroicons/vue/20/solid";
import { PropType, defineComponent } from "vue";
import { useRouter, type RouteLocationRaw } from "vue-router";
import { useEntityAuditStore } from "@/components/entity/audit/entityAuditStore";

/** This is a compact version of the EntityAudit that can fit on the entities view page.
 * It displays only a few of the most recent audit activities.
 *  From here, users can choose to go to the full audit page to see the full audit history. */
export default defineComponent({
  name: "EntityAuditWidget",
  props: {
    /** This should be the path to the EntityAudit page for the entity  */
    activityTo: {
      type: Object as PropType<RouteLocationRaw>,
      default: () => ({
        path: "./activity",
      }),
    },
  },
  setup(props, context) {
    const entityAuditStore = useEntityAuditStore();

    const router = useRouter();
    const viewAllActivity = () => {
      router.push(props.activityTo);
    };

    return { entityAuditStore, viewAllActivity };
  },
  components: {
    TrashIcon,
    PlusCircleIcon,
    StarIcon,
  },
});
</script>
