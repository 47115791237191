import type { AuthUser } from "@/utils/user/index.js";
import { readonly, ref } from "vue";

const userRef = ref<AuthUser>({
  isAuthenticated: false,
  isAuthPending: true,
  email: null,
  lastName: null,
  firstName: null,
  displayName: null,
  subjectId: null,
  claims: [],
  localClaims: [],
});

type UpdateableKeys = {
  [K in keyof AuthUser]: AuthUser[K] extends Array<any> ? never : K;
}[keyof AuthUser];

/**
 * Return a readonly user state to prevent direct mutation
 */
export const getUser = () => readonly(userRef);

/**
 * Set new user state
 */
export const setUser = (data: AuthUser) => (userRef.value = data);

/**
 * Update a property on the userRef with the given value
 */
export const updateUser = (key: UpdateableKeys, value: AuthUser[UpdateableKeys]) => {
  if (key in userRef.value) {
    userRef.value = {
      ...userRef.value,
      [key]: value,
    };
  }
};

const readonlyUser = getUser();

export const useUser = () => {
  return {
    userRef: readonlyUser,
    setUser,
    updateUser,
  };
};
