import { StateTree, defineStore } from "pinia";

export function definePrivateState<Id extends string, PrivateState extends StateTree, SetupReturn>(
  id: Id,
  privateStateFn: () => PrivateState,
  setup: (privateState: PrivateState) => SetupReturn,
) {
  const usePrivateState = defineStore(id + "-private", {
    state: privateStateFn,
  });

  return defineStore(id, () => {
    const privateState = usePrivateState();
    //TODO:TS (build error)
    //@ts-ignore
    return setup(privateState.$state);
  });
}
