import type { LayerSource } from "@/components/viewer/index.js";

const predefinedBaseLayers: LayerSource[] = [
  {
    label: "Open Street Maps",
    value: 1,
    source: {
      id: 1,
      url: "https://tile.openstreetmap.org/{z}/{x}/{y}.png",
      refUrl: null,
      type: "osm",
      attribution: "© OpenStreetMap contributors",
      //https://www.openstreetmap.org/copyright/
      maxZoom: 19, //20?
    },
  },
  {
    label: "World Streets",
    value: 2,
    source: {
      id: 2,
      url: "https://services.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer",
      refUrl: null,
      type: "arcgis",
      attribution:
        "Esri, HERE, Garmin, USGS, Intermap, INCREMENT P, NRCan, Esri Japan, METI, Mapwithyou, NOSTRA, © OpenStreetMap contributors, and the GIS User Community",
      //https://doc.arcgis.com/en/data-appliance/2022/maps/world-street-map.htm
      maxZoom: 18,
    },
  },

  {
    label: "World Imagery",
    value: 3,
    source: {
      id: 3,
      url: "https://services.arcgisonline.com/arcgis/rest/services/World_Imagery/MapServer",
      refUrl: "https://services.arcgisonline.com/arcgis/rest/services/Reference/World_Boundaries_and_Places/MapServer",

      type: "arcgis",
      attribution: "Esri, Maxar, Earthstar Geographics, and the GIS User Community",
      //  https://doc.arcgis.com/en/data-appliance/2022/maps/world-imagery.htm
      maxZoom: 18,
    },
  },
  {
    label: "World Topographical",
    value: 4,
    source: {
      id: 4,
      url: "https://services.arcgisonline.com/arcgis/rest/services/Elevation/World_Hillshade/MapServer",
      refUrl: "https://services.arcgisonline.com/arcgis/rest/services/World_Topo_Map/MapServer",
      type: "arcgis",
      attribution:
        "Esri, HERE, Garmin, Intermap, INCREMENT P, GEBCO, USGS, FAO, NPS, NRCan, GeoBase, IGN, Kadaster NL, Ordnance Survey, Esri Japan, METI, Mapwithyou, NOSTRA, © OpenStreetMapcontributors, and the GIS User Community",
      //https://doc.arcgis.com/en/data-appliance/2022/maps/world-topographic-map.htm
      maxZoom: 18,
    },
  },
  {
    label: "World Ocean",
    value: 5,
    source: {
      id: 5,
      url: "https://services.arcgisonline.com/arcgis/rest/services/Ocean/World_Ocean_Base/MapServer",
      refUrl: "https://services.arcgisonline.com/arcgis/rest/services/Ocean/World_Ocean_Reference/MapServer",
      type: "arcgis",
      attribution: "Esri, Garmin, GEBCO, NOAA NGDC, and other contributors",
      //https://doc.arcgis.com/en/data-appliance/2022/maps/world-ocean-base.htm
      maxZoom: 12,
    },
  },
  {
    label: "World Light Gray",
    value: 6,
    source: {
      id: 6,
      url: "https://services.arcgisonline.com/arcgis/rest/services/Canvas/World_Light_Gray_Base/MapServer",
      refUrl: "https://services.arcgisonline.com/arcgis/rest/services/Canvas/World_Light_Gray_Reference/MapServer",
      type: "arcgis",
      attribution: "Esri, HERE, Garmin, © OpenStreetMap contributors, and the GIS User Community",
      //https://doc.arcgis.com/en/data-appliance/2022/maps/world-light-gray-base.htm
      maxZoom: 15,
    },
  },
  {
    label: "World Dark Gray",
    value: 7,
    source: {
      id: 7,
      url: "https://services.arcgisonline.com/arcgis/rest/services/Canvas/World_Dark_Gray_Base/MapServer",
      refUrl: "https://services.arcgisonline.com/arcgis/rest/services/Canvas/World_Dark_Gray_Reference/MapServer",
      type: "arcgis",
      attribution: "Esri, HERE, Garmin, © OpenStreetMap contributors, and the GIS User Community",
      //https://doc.arcgis.com/en/data-appliance/2022/maps/world-dark-gray-base.htm
      maxZoom: 15,
    },
  },
  {
    label: "USA Topographical",
    value: 8,
    source: {
      id: 8,
      url: "https://services.arcgisonline.com/arcgis/rest/services/USA_Topo_Maps/MapServer",
      refUrl: null,
      type: "arcgis",
      attribution: "Copyright © 2021 National Geographic Society, i-cubed",
      //https://doc.arcgis.com/en/data-appliance/2022/maps/usa-topographic-maps.htm
      maxZoom: 16,
    },
  },
  {
    label: "National Geographic",
    value: 9,
    source: {
      id: 9,
      url: "https://services.arcgisonline.com/arcgis/rest/services/NatGeo_World_Map/MapServer",
      refUrl: null,
      type: "arcgis",
      attribution:
        " National Geographic, Esri, Garmin, HERE, UNEP-WCMC, USGS, NASA, ESA, METI, NRCan, GEBCO, NOAA, INCREMENT P",
      //https://doc.arcgis.com/en/data-appliance/2022/maps/national-geographic-world-map.htm
      maxZoom: 17,
    },
  },
  //TODO: figure out google attribution and re-enable these.
  // {
  //   label: "Hybrid",
  //   value: {
  //     id: 10,
  //     url: "https://mt{0-3}.google.com/vt/lyrs=y&x={x}&y={y}&z={z}&hl=en&gl=en&apikey=",
  //     refUrl: null,
  //     type: "google",
  //     attribution: "",
  //     thumbnail: "",
  //     maxZoom: 21,
  //   },
  // },
  // {
  //   label: "Physical",
  //   value: {
  //     id: 11,
  //     url: "https://mt{0-3}.google.com/vt/lyrs=p&x={x}&y={y}&z={z}&hl=en&gl=en&apikey=",
  //     refUrl: null,
  //     type: "google",
  //     attribution: "",
  //     thumbnail: "",
  //     maxZoom: 21,
  //   },
  // },
  // {
  //   label: "Satellite",
  //   value: {
  //     id: 12,
  //     url: "https://mt{0-3}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}&hl=en&gl=en&apikey=",
  //     refUrl: null,
  //     type: "google",
  //     attribution: "",
  //     thumbnail: "",
  //     maxZoom: 21,
  //   },
  // },
  // {
  //   label: "Street",
  //   value: {
  //     id: 13,
  //     url: "https://mt{0-3}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}&hl=en&gl=en&apikey=",
  //     refUrl: null,
  //     type: "google",
  //     attribution: "",
  //     thumbnail: "",
  //     maxZoom: 21,
  //   },
  // },
];
Object.freeze(predefinedBaseLayers);
export default predefinedBaseLayers;
