<template>
  <div class="hidden md:block">
    <div
      class="mb-1 pt-2 text-sm text-neutral-600 grid gap-4"
      v-if="index === 0"
      :class="{
        'grid-cols-12': cols === '12',
        'grid-cols-11': cols === '11',
        'grid-cols-10': cols === '10',
        'grid-cols-9': cols === '9',
        'grid-cols-8': cols === '8',
        'grid-cols-7': cols === '7',
        'grid-cols-6': cols === '6',
        'grid-cols-5': cols === '5',
        'grid-cols-4': cols === '4',
        'grid-cols-3': cols === '3',
        'grid-cols-2': cols === '2',
        'grid-cols-1': cols === '1',
      }"
    >
      <slot></slot>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
/** bypasses need for tailwind in schema. Should be used above repeatedBody - note - still need repeatedFieldHeader inside body
 */

export default defineComponent({
  name: "RepeatedHeader",
  emits: [],
  props: {
    index: {
      type: Number,
      required: true,
    },
    // todo make number?
    cols: {
      type: String,
      default: "10",
    },
  },
  setup(props, context) {
    return {};
  },
  components: {},
});
</script>
