<template>
  <li class="col-span-1 flex rounded-md shadow-sm">
    <router-link
      :to="{ name, params }"
      class="group w-full rounded-md border border-neutral-200 bg-white p-1 text-neutral-900 transition-all hover:border-sky-200 hover:bg-sky-100 focus:border-sky-200 focus:bg-sky-100"
    >
      <div class="flex flex-1 items-start justify-between pl-4 pr-3 pt-2">
        <div class="flex-1 pr-4 pb-2 text-sm group-hover:text-sky-900 group-focus:text-sky-900">
          <div class="font-medium">{{ heading }}</div>
          <div class="mt-1" v-if="subHeading">{{ subHeading }}</div>
        </div>
        <!-- @slot Slot used to place a status (most likely a status badge) -->
        <slot name="status" />
      </div>
      <div class="flex-1 space-y-0.5 px-4 py-2 text-sm empty:py-0" v-if="$slots.additional">
        <!-- @slot Slot to place additional content (most likely a RelatedEntityAdditional component) -->
        <slot name="additional" />
      </div>
    </router-link>
  </li>
</template>

<script lang="ts">
import { defineComponent } from "vue";
/** This component is for the individual related entities. It will link to the entity it is displaying.
 **This components usage has been replaced. Future use needs to be re-evaluated. */

export default defineComponent({
  name: "ReadField",
  props: {
    /** Heading for the related entity */
    heading: {
      type: String,
      required: true,
    },
    /** Sub-heading for the related entity */
    subHeading: {
      type: String,
      default: null,
    },
    /** Name of the route this component should link to */
    name: {
      type: String,
      required: true,
    },
    /** Optional params to be sent with with the route */
    params: {
      type: Object,
      default: () => {},
    },
  },
  setup(props, context) {
    return {};
  },
});
</script>

<style module></style>
