<template>
  <li class="col-span-1 flex rounded-md shadow-sm">
    <router-link
      :to="{ name, params }"
      class="group w-full rounded-md border border-neutral-200 bg-white p-1 text-neutral-900 transition-all hover:border-sky-200 hover:bg-sky-100 focus:border-sky-200 focus:bg-sky-100"
    >
      <div class="flex flex-1 items-start justify-between pl-4 pr-3 pt-2">
        <ArrowLeftCircleIcon class="-mt-0.5 mr-2 h-6 w-6 group-hover:text-sky-900 group-focus:text-sky-900" />
        <div class="flex-1 pb-2 pr-4 text-sm group-hover:text-sky-900 group-focus:text-sky-900">
          <div class="font-medium">{{ heading }}</div>
        </div>
      </div>
    </router-link>
  </li>
</template>

<script lang="ts">
import { ArrowLeftCircleIcon } from "@heroicons/vue/24/outline";
import { defineComponent } from "vue";
/** This component is used to link the user to the parent of the related entity.
 *  **This components usage has been replaced. Future use needs to be re-evaluated. */
export default defineComponent({
  name: "ReadField",
  props: {
    /** Heading for the parent link  */
    heading: {
      type: String,
      required: true,
    },
    /** Name of the route this component should link to */
    name: {
      type: String,
      required: true,
    },
    /** Optional params to send with the route */
    params: {
      type: Object,
      default: () => {},
    },
  },
  setup(props, context) {
    return {};
  },
  components: { ArrowLeftCircleIcon },
});
</script>

<style module></style>
