<template>
  <td class="whitespace-nowrap pl-4 pr-6 text-right align-middle text-sm font-medium" width="1rem">
    <div class="inline-flex flex-row">
      <router-link v-if="viewTo" :to="viewTo" @click.stop @click.middle.stop class="inline-block" :title="titleView">
        <ChevronRightIcon
          class="z-0 h-6 w-6 text-gray-300 transition-transform ease-in group-hover:translate-x-1 group-hover:text-gray-500"
        ></ChevronRightIcon>
        <!-- <span class="sr-only">View {{ person.name }}</span> -->
      </router-link>

      <div class="space-x-4 inline-flex flex-row">
        <slot>
          <!-- TODO: Expose classes for formatting child links as a slot var? -->
        </slot>
      </div>

      <div v-if="editTo !== undefined && !canEdit" class="w-7 ml-4"><!--edit placeholder--></div>
      <BaseButton
        v-if="canEdit && editTo"
        class="mt-1"
        variant="plain-subtle"
        icon
        square
        :label="titleEdit"
        borderless
        :to="editTo"
      >
        <template #iconBefore
          ><PencilIcon class="group-hover/button:hidden" /><PencilIconSolid class="hidden group-hover/button:block"
        /></template>
      </BaseButton>

      <div v-if="deleteAction !== undefined && !canDelete" class="w-11 ml-4"><!--delete placeholder--></div>
      <BaseButton
        v-if="canDelete"
        @click="openConfirmDelete"
        class="mt-1 ml-4"
        variant="danger-subtle"
        icon
        square
        :label="titleDelete"
        borderless
      >
        <template #iconBefore
          ><TrashIcon class="group-hover/button:hidden" /><TrashIconSolid class="hidden group-hover/button:block"
        /></template>
      </BaseButton>

      <ConfirmDelete
        :entity-label="entityLabel"
        @deleteClick="deleteConfirmed"
        ref="confirmDeleteRef"
        :condition="deleteConditionWithItem"
      ></ConfirmDelete>
    </div>
  </td>
</template>

<script lang="ts">
// Note: When updating this description - you must also update the md file.

/** Component for adding actions on to a list table row. Actions include navigating to the items view, editing, and deleting.  */
export default { name: "ListTableActionRow" };
</script>

<script setup lang="ts" generic="T">
import type { ListTableProps } from "@/components/list/genericList";
import injectionSymbols from "@/components/list/genericList/injectionSymbols";
import ConfirmDelete from "@/components/modals/ConfirmDelete.vue";
import { ChevronRightIcon, PencilIcon, TrashIcon } from "@heroicons/vue/24/outline";
import { PencilIcon as PencilIconSolid, TrashIcon as TrashIconSolid } from "@heroicons/vue/24/solid";
import { computed, inject, ref } from "vue";
import type { RouteLocationRaw } from "vue-router";

const props = withDefaults(
  defineProps<{
    /** Route to the items view screen */
    viewTo?: RouteLocationRaw;
    /** Title for the item. This will be used to pass to the router link and create the labels for edit and delete */
    viewTitle?: string;
    /** Whether or not the item can/should be editable  */
    canEdit?: boolean;
    /** Route to the items edit screen */
    editTo?: RouteLocationRaw;
    /** Whether or not the item can/should be deletable  */
    canDelete?: boolean;
    /** Function accepting the current item and performing the necessary call to delete it */
    deleteAction?: (itemToDelete: T) => Promise<any>;
    /** The item this row belongs to */
    item: T;
    /** This is an optional function to provide is there needs to be some validation before a delete can occur (E.g. Can't delete an entity if it is being used a FK on another entity) */
    deleteCondition?: (itemToDelete: T) => Promise<any>;
  }>(),
  {
    canEdit: false,
    canDelete: false,
  },
);

const titleView = computed(() => "View " + props.viewTitle);
const titleEdit = computed(() => "Edit " + props.viewTitle);
const titleDelete = computed(() => "Delete " + props.viewTitle);

const { entityLabel, removeItem } = inject(injectionSymbols.GenericListPropsKey) as ListTableProps;

const confirmDeleteRef = ref<typeof ConfirmDelete | null>(null);
const openConfirmDelete = function () {
  confirmDeleteRef.value?.openConfirmation();
};

const deleteConfirmed = function () {
  if (!props.deleteAction) return;

  props.deleteAction(props.item).then(() => {
    removeItem(props.item);
  });
};

const deleteConditionWithItem = function () {
  if (!props.deleteCondition) return;
  return props.deleteCondition(props.item);
};
</script>

<style module></style>
