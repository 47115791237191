/**
 * Dummy validation that exists just to get the [data-complete] attribute on a field for styling.
 * @returns {boolean} Always returns true
 */
const has_no_validation = () => {
  return true;
};
has_no_validation.skipEmpty = false;

export { has_no_validation };
