<template>
  <div
    class="mx-5 grid grid-cols-1 gap-5"
    :class="[
      entities.length === 2 && 'lg:max-xl:grid-cols-2 2xl:grid-cols-2',
      entities.length === 3 && 'lg:max-xl:grid-cols-3 2xl:grid-cols-3',
    ]"
  >
    <router-link
      v-for="(entity, entityIdx) in entities"
      :key="entity.label"
      :to="entity.to"
      v-slot="{ href, navigate, isActive }"
      custom
    >
      <div
        :class="[entityIdx > 0 && '']"
        class="border-black/5 shadow bg-neutral-50 rounded-md flex flex-col justify-between"
      >
        <div class="relative pb-4 pt-3 px-4 sm:px-6 lg:px-8 2xl:px-8 group">
          <a
            :href="href"
            @click="navigate"
            class="text-base font-semibold py-1 pr-5 lg:pr-2 hover:text-neutral-600 text-neutral-500 relative underline decoration-sky-300 underline-offset-2 hover:decoration-2"
          >
            {{ entity.label }}
          </a>
          <p class="text-sm font-medium leading-4 text-neutral-900">{{ entity.title }}</p>
          <p class="mt-1 text-sm text-neutral-400">
            <span class="font-semibold block tracking-tight text-neutral-500">{{ entity.prop1 }}</span>
            <span v-if="entity.prop2" class="block text-neutral-600">{{ entity.prop2 }}</span>
          </p>
        </div>
        <RouterLink
          v-if="entity.viewAllTo"
          :to="entity.viewAllTo"
          class="block bg-neutral-100 px-4 py-2 text-center text-sm font-medium text-neutral-500 hover:text-neutral-700 rounded-b-md"
          >View More {{ entityLabelPlural }}</RouterLink
        >
      </div>
    </router-link>
  </div>
</template>

<script lang="ts">
import { LinkIcon } from "@heroicons/vue/20/solid";
import { PropType, defineComponent } from "vue";
import { EntityPreview } from ".";

/** This component will show previews of related entities and allow the navigation to each entity displayed.
 * Optional navigation to view all of the current entity type for the previewed item is available if provided in the EntityPreview object. */
export default defineComponent({
  name: "ReadCardEntityPreviews",
  props: {
    /** This should be an array of EntityPreview items. No more than 3 entities should be provided */
    entities: {
      type: Object as PropType<Array<EntityPreview>>,
      required: true,
    },
    /** The plural form of the entity label this component belongs to */
    entityLabelPlural: {
      type: String,
      default: "entityLabelPlural unspecified",
    },
  },
  setup(props, context) {
    if (props.entities.length > 3) {
      throw new TypeError("ReadCardEntityPreviews component doesn't support rendering more than three entities.");
    }
    return {};
  },
  components: { LinkIcon },
});
</script>

<style module></style>
