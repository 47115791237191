<template>
  <div class="flex-auto h-1 min-h-0 overflow-y-auto">
    <div class="px-3 pb-2 md:px-6 lg:pt-5" :class="viewerStore.showSidebar ? 'visible' : 'invisible'">
      <h4 class="flex items-center font-medium mb-4">Geometry Layers</h4>
      <FormKit
        type="checkbox"
        label="Points"
        validation="has_no_validation"
        :model-value="viewerStore.showPoints"
        @update:model-value="viewerStore.togglePoints"
      ></FormKit>
      <FormKit
        type="checkbox"
        label="Lines"
        validation="has_no_validation"
        :model-value="viewerStore.showLines"
        @update:model-value="viewerStore.toggleLines"
      ></FormKit>
      <FormKit
        type="checkbox"
        label="Areas"
        validation="has_no_validation"
        :model-value="viewerStore.showPolygons"
        @update:model-value="viewerStore.togglePolygons"
      ></FormKit>

      <h4 class="flex items-center font-medium mt-8 mb-4">Base Layer</h4>
      <FormKit
        v-if="viewerStore.baseLayers.length > 0"
        type="dropdown"
        label="Base Layer"
        validation="has_no_validation"
        :options="viewerStore.baseLayers"
        :sections-schema="noLabel"
        :value="viewerConfigStore.defaultBaseLayerId"
        @input="viewerStore.updateBaseLayer($event, baseLayerGroup)"
      >
      </FormKit>

      <div v-if="viewerStore.hasReferenceLayers">
        <h4 class="flex items-center font-medium mt-8 mb-4">Reference Layers</h4>
        <FormKit
          type="checkbox"
          v-for="[key, layerOption] in viewerStore.referenceLayers"
          :key="key"
          :label="layerOption.label"
          validation="has_no_validation"
          :value="layerOption.source.active"
          @input="viewerStore.handleReferenceChange(layerOption.source.id, $event)"
        ></FormKit>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { useCssBreakpointStore } from "@/components/common/cssBreakpointStore";
import { noLabelSchema } from "@/utils/helpers";
import { useUser } from "@/utils/auth/authentication";
import { useAppSettingsStore } from "@/components/common";
import { FormKit } from "@formkit/vue";
import { computed, defineComponent, inject, ref } from "vue";
import injectionSymbols from "@/components/viewer/injectionSymbols";
import { ViewerListProps } from "@/components/viewer";
import { useViewerConfigStore } from "@/components/viewer/viewerConfigStore";
import { useViewerStore } from "@/components/viewer/viewerStore";

/** Viewer Panel to toggle Base and Reference layers shown on the Map
 */
export default defineComponent({
  name: "ViewerPanelLayers",
  setup(props, context) {
    const viewerStore = useViewerStore();
    const viewerConfigStore = useViewerConfigStore();
    const appSettingsStore = useAppSettingsStore();
    const cssBreakpointStore = useCssBreakpointStore();
    const { userRef } = useUser();
    const noLabel = noLabelSchema();

    const { baseLayerGroup } = inject(injectionSymbols.ViewerListPropsKey) as ViewerListProps;

    return {
      baseLayerGroup,
      appSettingsStore,
      userRef,
      viewerStore,
      viewerConfigStore,
      noLabel,
    };
  },
});
</script>

<style module></style>
