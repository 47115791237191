<template>
  <div
    class="flex items-center justify-center rounded-full bg-neutral-300 font-medium uppercase leading-none text-neutral-600"
    :class="[
      size === 'sm' ? 'h-6 min-w-6 text-sm px-1.5' : '',
      size === 'base' ? 'h-8 min-w-8 text-base px-2' : '',
      size === 'lg' ? 'h-10 min-w-10 text-lg px-3' : '',
      size === '2xl' ? ' h-16 min-w-16 text-2xl px-4' : '',
    ]"
  >
    <span class="sr-only" v-if="cAriaText">{{ cAriaText }}</span
    >{{ number }}
  </div>
</template>

<script lang="ts">
import { PropType, defineComponent } from "vue";
/**
 * Component for badge that displays a number
 */
export default defineComponent({
  name: "NumberBadge",
  props: {
    /**
     * Optional descriptive text for screenreaders that prefixes the number. Named to avoid collisions with aria attributes
     */
    cAriaText: {
      type: String,
      default: "",
    },
    /**
     * Number to be displayed
     */
    number: {
      type: [String, Number],
    },
    /**
     * Optional size specification
     * @values sm, base, lg, 2xl
     */
    size: {
      type: String as PropType<"sm" | "base" | "lg" | "2xl">,
      default: "sm",
      validator: (val: string) => ["sm", "base", "lg", "2xl"].includes(val),
    },
  },
  setup(props) {
    return {};
  },
});
</script>

<style module></style>
