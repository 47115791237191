import type { InjectionKey } from "vue";
import type {
  ListFilterLabels,
  ListFiltersHelpers,
  ListSharedFilters,
  ListTableProps,
} from "@/components/list/genericList/index.js";

export default {
  GenericListPropsKey: Symbol("ListTableProps") as InjectionKey<ListTableProps>,
  ListFilterLabelsKey: Symbol("ListFilterLabels") as InjectionKey<ListFilterLabels>,
  ListSharedFiltersKey: Symbol("ListSharedFilters") as InjectionKey<ListSharedFilters>,
  ListFiltersHelpersKey: Symbol("ListFiltersHelpers") as InjectionKey<ListFiltersHelpers>,
};
