<template>
  <li class="pl-0.5" v-if="hasValue || label">
    <span class="text-neutral-500" v-if="label">{{ label }}: </span>
    <span class="text-neutral-900">
      <template v-if="isEmail">
        <!-- prettier-ignore -->
        <EmailWbr :value="(value as string | string[] | null | undefined)"></EmailWbr>
      </template>
      <template v-else>
        {{ parsedValue }}
      </template>
    </span>
  </li>
</template>

<script lang="ts">
import { EmailWbr } from "@/components/read";
import { computed, defineComponent, inject, onUnmounted, watch } from "vue";
import ReadFieldBase from "@/components/read/ReadFieldBase.vue";
import injectionSymbols from "@/components/read/injectionSymbols";

/** This component will properly style and parse the list items for ReadFieldList.  */
export default defineComponent({
  name: "ReadFieldListItem",
  components: {
    ReadFieldBase,
    EmailWbr,
  },
  props: {
    /** Optional label for the list item. Typically only used if the value is not self explanatory (E.g. a list with multiple phone numbers that should be distinguished) */
    label: {
      type: String,
    },
    /** the value of the item to be displayed */
    value: {},
    /** this lets the component know if the value given is an email so that it can properly create a breakpoint at the @ symbol */
    isEmail: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    //TODO: This is the same as ReadField - pull it out into shared code.
    const parsedValue = computed(() => {
      if (props.value === null || props.value === undefined || props.value === "") {
        return "—";
      } else if (props.value === true) {
        return "Yes";
      } else if (props.value === false) {
        return "No";
      } else if (Array.isArray(props.value)) {
        let result = props.value.filter((item) => item || item === 0 || item === false).join(", ");
        if (result === "") return "—";
        return result;
      } else {
        return props.value;
        // if (typeof props.value === "number" && isFinite(props.value)) {
        //   monoFontRef.value = true;
        // }
      }
    });

    const hasValue = computed(() => {
      return (
        props.value !== null &&
        props.value !== undefined &&
        props.value !== "" &&
        (!Array.isArray(props.value) || props.value.filter((item) => item || item === 0 || item === false).length > 0)
      );
    });

    const childUpdateHandler = inject(injectionSymbols.ReadFieldListChildUpdateHandlerKey, () => {});

    watch(
      hasValue,
      (newValue) => {
        childUpdateHandler(newValue);
      },
      { immediate: true },
    );

    onUnmounted(() => {
      childUpdateHandler(false);
    });

    return { parsedValue, hasValue };
  },
});
</script>

<style module></style>
