<template>
  <tr
    class="group border-b border-b-neutral-200"
    :class="className"
    v-for="item in tableRows.items"
    :key="item.id"
    @click="clickHandler($event, item.id)"
    @click.middle="clickHandler($event, item.id, true)"
  >
    <!-- @slot Slot for the `<td>` elements -->
    <slot name="default" :item="item"></slot>
  </tr>
</template>

<script lang="ts">
// Note: When updating this description - you must also update the md file.

/** Used for a standard `<tr>` in a ListTable */
export default { name: "ListRow" };
</script>

<script setup lang="ts" generic="T extends { id: any }[] | undefined">
import { createRowClickHandler } from "@/utils/helpers/createRowClickHandler";
import { ref } from "vue";

const props = withDefaults(
  defineProps<{
    tableRows: { items: T };
    clickHandler?: ReturnType<typeof createRowClickHandler>;
  }>(),
  { clickHandler: undefined },
);

const className = ref("");

if (props.clickHandler) {
  className.value += " hover:cursor-pointer hover:bg-sky-100 ";
} else {
  className.value += " hover:bg-neutral-50 ";
}
</script>

<style module></style>
