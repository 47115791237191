import { formatDistance } from "date-fns/fp";
import { acceptHMRUpdate, defineStore } from "pinia";
import { computed, ref } from "vue";
import {
  IAnnotation,
  IAnnotationTimeline,
  IAnnotationTimelineItem,
  IEntityWithAnnotations,
} from "@/components/entity/note/index.js";

/** Returns a store for Entity Notes (Annotations) (Notes for the current entity), creates it if necessary. */
export const useEntityNoteStore = defineStore("EntityNoteStore", () => {
  // state
  const annotations = ref([] as Array<IAnnotation>);

  // Do we need the rest?
  const href = ref(null);
  const httpStatusCode = ref(null);
  const httpStatusMessage = ref(null);
  const links = ref(null);
  const rel = ref(null);

  // getters
  /** Generates the 3 most recent notes for the Notes Widget */
  const entityNotesForWidget = computed<IAnnotationTimeline>(() => {
    if (!annotations.value.length) return [];

    const timelineItems: IAnnotationTimelineItem[] = [];
    const now = new Date();

    const firstThree = annotations.value.slice(0, 3);
    firstThree.forEach((note) => {
      if (!note.createdOnUtc) return;

      const item: IAnnotationTimelineItem = {
        id: note.id,
        auditUserName: note.createdBy.name,
        noteText: note.noteText,
        displayDate: formatDistance(new Date(note.createdOnUtc), now),
      };
      timelineItems.push(item);
    });

    return timelineItems;
  });

  const count = computed(() => annotations.value.length);

  // actions
  /** Resets the values of the store. Used to prevent residual data from a previous entity appearing on screen while waiting for the new notes to load. */
  function reset() {
    annotations.value = [];

    href.value = null;
    httpStatusCode.value = null;
    httpStatusMessage.value = null;
    links.value = null;
    rel.value = null;
  }

  /** Fills the store with the notes that come back with the entity fom the BE
   * @param {IEntityWithAnnotations} entity Current entity that is being used
   */
  function fill(entity: IEntityWithAnnotations) {
    annotations.value = entity?.annotations?._items || [];
    //TODO: hydrate the other properties?
  }

  /** Adds a note to the list of notes (Used to update the store after a note has been created in the DB)
   * @param {IAnnotation} note Note to be removed
   */
  function addNote(note: IAnnotation) {
    annotations.value.unshift(note);
  }

  /** Updates a note in the list of notes (Used to update the store after a note has been updated in the DB)
   * @param {IAnnotation} note Note to be removed
   */
  function updateNote(note: IAnnotation) {
    const index = annotations.value.findIndex((x) => x.id === note.id);
    annotations.value.splice(index, 1, note);
  }

  /** Removes a note from the list of notes (Used to update the store after a note has been deleted from the DB)
   * @param {IAnnotation} note Note to be removed
   */
  function deleteNote(note: IAnnotation) {
    const index = annotations.value.findIndex((x) => x.id === note.id);
    annotations.value.splice(index, 1);
  }

  return {
    //state
    annotations,
    //getters
    count,
    entityNotesForWidget,
    //actions
    reset,
    fill,
    addNote,
    updateNote,
    deleteNote,
  };
});

export type UseEntityNoteStore = ReturnType<typeof useEntityNoteStore>;

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useEntityNoteStore, import.meta.hot));
}
