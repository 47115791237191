<template>
  <div
    class="flex flex-none items-center justify-between bg-white text-neutral-900 lg:w-48 lg:flex-col lg:items-stretch lg:justify-start lg:overflow-auto lg:pt-4 xl:w-60"
  >
    <TheEntityNavItems :navigation-items="navigationItems"></TheEntityNavItems>
  </div>
</template>

<script lang="ts">
import TheEntityNavItems from "@/components/common/TheEntityNavItems.vue";
import { PropType, defineComponent } from "vue";
import { EntityNavigationItem } from ".";
/** Wrapper component used to style `TheEntityNavItems`  */
export default defineComponent({
  name: "TheEntityNav",
  components: { TheEntityNavItems },
  props: {
    /** Array of the navigation items (typically the entities children, notes, and documents) */
    navigationItems: {
      type: Array as PropType<EntityNavigationItem[]>,
      required: true,
      validator: (propValue: EntityNavigationItem[]): boolean => {
        return propValue.every((x) => x.label && x.name);
      },
    },
  },
  setup(props) {
    return {};
  },
});
</script>

<style module></style>
