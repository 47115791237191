import { IPagingApiAndNotifierConfig, apiService } from "@/utils/api/index.js";
import { serializeQueryParams } from "@/utils/helpers/index.js";
import { NotifyOptionsError, NotifyOptionsSuccess } from "@/components/notifications/index.js";
import { useViewerConfigStore } from "@/components/viewer/index.js";

import type { IDataset, IDatasetQuery } from "@/components/viewer/dataset.js";

const URL = "datasets";
const ENTITY_NAME = "Dataset";

let api: ReturnType<typeof apiService<IDataset>>;

const getApi = () => {
  if (api) return api;

  const viewerConfigStore = useViewerConfigStore();
  const axiosParams = {
    // Set different base URL based on the environment
    baseURL: viewerConfigStore.baseURL,
  };
  api = apiService(viewerConfigStore.apiHost, axiosParams);
  return api;
};

/**
   * @param {IDatasetQuery} params Query parameters
     @param {IPagingApiAndNotifierConfig} config 
   */
const getDatasets = (params: IDatasetQuery, config: IPagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.hide = true;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.fetched = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };

  const url = config.pagingUrl ? config.pagingUrl : URL;

  return getApi().getPaged(url, {
    params,
    paramsSerializer: serializeQueryParams,
    abort: config?.abortMethod,
    successNotifCb,
    errorNotifCb,
  });
};

/**
 * @param {Int} id Dataset Id
 * @param {IPagingApiAndNotifierConfig} config
 */
const getDatasetById = (id: string | number, config: IPagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.hide = true;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.fetched = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };

  return getApi().get(URL + "/" + id, {
    successNotifCb,
    errorNotifCb,
  });
};

export { getDatasetById, getDatasets };
