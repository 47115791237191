<template>
  <p class="text-neutral-500">
    {{ label }} <span class="ml-2 text-neutral-600">{{ value }}</span>
  </p>
</template>

<script lang="ts">
import { PropType, defineComponent } from "vue";
/** This component is designed to sit inside the RelatedEntity component for details about the entity.
 **This components usage has been replaced. Future use needs to be re-evaluated. */
export default defineComponent({
  name: "ReadField",
  props: {
    /** Label for the property/detail */
    label: {
      type: String,
      required: true,
    },
    /** Value for the property/detail */
    value: {
      type: [String, Number] as PropType<string | number | null>,
    },
  },
  setup(props, context) {
    return {};
  },
});
</script>

<style module></style>
