<template>
  <EntityAudit :entity-type="entityName" />
</template>

<script lang="ts">
import { Ref, defineComponent, inject } from "vue";
import { EntityAudit, IEntityWithAudits } from "@mui/components/entity/audit";
import { entityAuditApi } from "@casa/services/endpoints";

export default defineComponent({
  name: "EntityAudits",
  components: {
    EntityAudit,
  },
  props: {
    entityName: {
      type: String,
      required: true,
    },
  },
  setup(props, context) {
    return {
      entityAuditApi,
    };
  },
});
</script>
