// Troubleshoot your routes using this - https://paths.esm.dev

/** @type {import('vue-router').RouterOptions['routes']} */

const names = {
  schema: "Schema",
};

export { names as schemaRouteNames };

const docGenRoutes = [
  {
    path: "/schema",
    name: "Schema",
    component: () => import("@casa/views/formKitSchema/Schema.vue"),
  },
];
export default docGenRoutes;
