<template>
  <ListHeader v-bind="$attrs" :total-results-count="totalResultsCount" :count-label="countLabel">
    <!-- @slot Slot for the header -->
    <slot name="header"></slot
  ></ListHeader>
  <!-- <div>items: {{ items }}</div> -->
  <ListFiltersAndData :hide-filters="hideFilters">
    <ol
      v-if="totalResultsCount !== 0"
      role="list"
      class="grid grid-cols-1 gap-y-8 py-4"
      :class="[
        cardCols === 1 ? `grid-cols-1` : ``,
        cardCols === 2 ? `lg:grid-cols-2` : ``,
        cardCols === 3 ? `lg:grid-cols-2 2xl:grid-cols-3` : ``,
      ]"
    >
      <!-- @slot Slot for the cards -->
      <slot name="cards" :items="items"></slot>
    </ol>

    <ListNotFound v-if="totalResultsCount === 0" is-list-cards :create-label="createLabel"></ListNotFound>
  </ListFiltersAndData>
  <!-- <table class="min-w-full" :class="[isErrorOrEmpty ? `min-h-full` : ``]">
      <caption class="sr-only">
        {{
          entityLabelPlural
        }}
      </caption>
      <thead class="sticky top-0 z-10 shadow">
        <slot name="tableHeadings"></slot>
      </thead>
      <tbody v-if="isStatusError">
        <ListTableErrorRow></ListTableErrorRow>
      </tbody>
      <tbody v-if="dataRef?.totalResultsCount === 0 && isStatusSuccess">
        <ListNotFound></ListNotFound>
      </tbody>
      <tbody v-if="!isStatusError" class="bg-white">
        <slot name="tableRows" :items="dataRef?._items"></slot>
      </tbody>
    </table> -->
  <!-- <InfiniteLoading target="#scrollingTableSection" @infinite="infiniteLoadHandler">
      <template #spinner>
        <div
          class="mb-2 mt-4 flex w-full place-content-center"
          v-if="!isStatusSuccess && !isStatusError"
        >
          <ArrowDownCircleIcon
            class="h-8 w-8 animate-bounce rounded-full text-neutral-600 shadow"
          ></ArrowDownCircleIcon>
        </div>
      </template>
    </InfiniteLoading> -->
  <!-- </section> -->
</template>

<script lang="ts">
// Note: When updating this description - you must also update the md file.

/**
 * Component to display list cards for an entity. Usually used to display the child instances of an entity.
 * This component should be used for entities where paging is not possible. (Such as children stored in json as a property on the parent.)
 */
export default {};
</script>

<script setup lang="ts" generic="T extends any">
// TODO: Consider refactor to support filtering or fetching from server?
import injectionSymbols from "@/components/list/genericList/injectionSymbols";
import { PagedType } from "@/utils/api";
import { AuthRequest } from "@/utils/auth/authorization";
import { computed, provide, readonly, ref } from "vue";
import ListFiltersAndData from "@/components/list/genericList/ListFiltersAndData.vue";
import ListHeader from "@/components/list/genericList/ListHeader.vue";
import ListNotFound from "@/components/list/listTable/ListNotFound.vue";

const props = withDefaults(
  defineProps<{
    /** Label for the entity being used in the list cards  */
    entityLabel: string;
    /** Plural version of the `entityLabel`. If not provided, this will default to appending an "s" to the `entityLabel` */
    entityLabelPlural?: string;
    /** Array of items to display in the list cards */
    items: T[] | undefined;
    // api: {
    //   type: Object,
    //   required: true,
    // },
    /** Route to the create screen for this entity  */
    createTo?: Record<string, any>;
    /** Request for authorizing if the create option is availble  */
    createToAuth?: AuthRequest;
    /** Label for the create function */
    createLabel?: string;
    // take: {
    //   type: Number,
    //   default: () => getGlobalSettings().value.listTableTake,
    // },
    // sortByDefault: {
    //   type: Array,
    //   required: true,
    //   validator: (propValue) => {
    //     return propValue && propValue.every((x) => x.field?.length > 0);
    //   },
    // },
    /** Renders the list as a child list  */
    isChildList?: boolean;
    /** Optional specification for the number of columns to render the cards in
     * @values 1,2,3
     */
    cardCols?: number;
    /** Optional prop to hide the filters. Currently should be used because filtering for ListCards is still in work.  */
    hideFilters?: boolean;
  }>(),
  {
    entityLabelPlural: (props: any) => props.entityLabel + "s",
    isChildList: false,
    cardCols: 1,
    hideFilters: false,
  },
);

defineEmits(["updateEntity"]);

defineSlots<{
  header?: (props: {}) => any;
  cards?: (props: { items: T[] | undefined }) => any;
}>();

const totalResultsCount = computed(() => props.items?.length);

const countLabel = computed(
  () =>
    (totalResultsCount.value || "0") +
    " " +
    (totalResultsCount.value === 1 ? props.entityLabel?.toLowerCase() : props.entityLabelPlural?.toLowerCase()),
);

const isErrorOrEmpty = computed(() => !totalResultsCount);

const sharedFilters = ref([]);
provide(injectionSymbols.ListSharedFiltersKey, sharedFilters);

provide(injectionSymbols.GenericListPropsKey, {
  createTo: props.createTo,
  createToAuth: props.createToAuth,
  entityLabel: props.entityLabel,
  entityLabelPlural: props.entityLabelPlural,
  isChildList: props.isChildList,
  // the following are not yet supported by ListCardsLocal
  filterChangeHandler: () => {},
  sortByMapRef: readonly(ref({})),
  sortChangeHandler: () => {},
  isDefaultState: computed(() => true),
  removeItem: (itemToDelete: PagedType<T>) => {}, //not original to legacy stuff.
  hideFilters: props.hideFilters,
});
</script>

<style module></style>
