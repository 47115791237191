<template>
  <LayoutAppContent>
    <Authorize permission-name="ReadDetailedEntityAudit" with-redirect>
      <div class="h-full overflow-auto bg-neutral-100">
        <div class="sm:mx-5 lg:mx-10 bg-white shadow rounded-lg p-5 my-2 md:my-5">
          <FormKit
            type="form"
            id="entityOneForm"
            :value="entityOne"
            v-model="entityOne"
            @submit="handleSubmitOne"
            :actions="false"
            autocomplete="off"
          >
            <template #default>
              <!-- <h2 class="mx-9 mb-2 text-lg font-medium leading-6 text-neutral-900">Entity 1 (left)</h2> -->
              <!-- <FormKitSchema :schema="conditionalSchema" :data="data" :library="library"></FormKitSchema> -->

              <div class="grid grid-cols-1 gap-x-4 sm:grid-cols-3 md:grid-cols-4 mx-10">
                <div class="ms-1">
                  <FormKit
                    type="dropdown"
                    label="Entity Type"
                    name="entityTypeSelect"
                    validation="required"
                    :options="$props.entityTypes"
                    @input="entityTypeOneChangeHandler($event)"
                    popover
                  />
                </div>

                <div :class="[customInputOne ? `d-block` : `hidden`]">
                  <FormKit type="text" label="Entity Type" name="entityTypeManual" validation="has_no_validation" />
                </div>

                <FormKit type="text" label="Entity Id" name="entityId" validation="required|number" />

                <div class="me-3 my-auto">
                  <BaseButton @click="getEntityOne" variant="priority-hollow" label="Get Audits" class="my-auto">
                  </BaseButton>
                </div>
              </div>
            </template>
          </FormKit>
          <FormKit
            type="form"
            id="entityTwoForm"
            :value="entityTwo"
            v-model="entityTwo"
            @submit="handleSubmitTwo"
            :actions="false"
            autocomplete="off"
          >
            <template #default>
              <!-- <h2 class="mx-9 mb-2 text-lg font-medium leading-6 text-neutral-900">Entity 2 (right)</h2> -->

              <div class="grid grid-cols-1 gap-x-4 sm:grid-cols-3 md:grid-cols-4 mx-10">
                <div class="ms-1">
                  <FormKit
                    type="dropdown"
                    label="Entity Type"
                    name="entityTypeSelect"
                    validation="required"
                    :options="$props.entityTypes"
                    @input="entityTypeTwoChangeHandler($event)"
                    popover
                  />
                </div>

                <FormKit
                  type="text"
                  label="Entity Type"
                  name="entityTypeManual"
                  validation="has_no_validation"
                  v-if="customInputTwo"
                />

                <FormKit type="text" label="Entity Id" name="entityId" validation="required" />
                <div class="me-3 my-auto">
                  <BaseButton @click="getEntityTwo" variant="priority-hollow" label="Get Audits" class="my-auto">
                  </BaseButton>
                </div>
              </div>
            </template>
          </FormKit>
        </div>

        <div class="grid grid-cols-1 xl:grid-cols-2 gap-x-4 h-full">
          <div class="pb-5 h-full overflow-auto" v-if="isAuditOnePending || isAuditOneSuccess">
            <SkeletonBlock class="h-40 sm:ms-5 lg:ms-10" v-if="isAuditOnePending" />

            <section aria-labelledby="" class="h-full" v-if="isAuditOneSuccess">
              <div class="sm:mx-5 lg:mx-10 xl:ms-10 xl:me-0 bg-white shadow rounded-lg h-full pb-20 mb-20">
                <div class="px-4 py-5 sm:px-6 border-b border-neutral-200">
                  <h2 class="text-lg font-medium leading-6">
                    Audit History - {{ auditEntityOne?.resourceType }} - {{ auditEntityOne?.resourceId }}
                  </h2>
                </div>
                <!-- <div class="h-full"> -->
                <pre class="h-full overflow-auto">{{ JSON.stringify(auditEntityOne?._items, null, 2) }}</pre>
                <!-- </div> -->
              </div>
            </section>
          </div>

          <div class="pb-5 h-full overflow-auto" v-if="isAuditTwoPending || isAuditTwoSuccess">
            <SkeletonBlock class="h-40 sm:ms-5 lg:me-10" v-if="isAuditTwoPending" />

            <section aria-labelledby="" class="h-full" v-if="isAuditTwoSuccess">
              <div class="sm:mx-5 lg:mx-10 xl:me-10 xl:ms-0 bg-white shadow rounded-lg h-full pb-20 mb-20">
                <div class="px-4 py-5 sm:px-6 border-b border-neutral-200">
                  <h2 class="text-lg font-medium leading-6">
                    Audit History - {{ auditEntityTwo?.resourceType }} - {{ auditEntityTwo?.resourceId }}
                  </h2>
                </div>
                <!-- <div class="h-full"> -->
                <pre class="h-full overflow-auto">{{ JSON.stringify(auditEntityTwo?._items, null, 2) }}</pre>
                <!-- </div> -->
              </div>
            </section>
          </div>
        </div>
      </div>
    </Authorize>
  </LayoutAppContent>
</template>

<script lang="ts">
import { BaseButton } from "@/components/button";
import { useApi } from "@/utils/api";
import { Authorize } from "@/utils/auth/authorization";
import { submitForm } from "@formkit/core";
import { FormKitSchema } from "@formkit/vue";
import { defineComponent, markRaw, ref } from "vue";
import { detailedAuditEntry } from ".";
import { getAudits } from "@/components/entity/audit/entityAuditApi";
// import { SkeletonBlock } from "@/components/common";
/** This component will show the raw audit history in json format for up to two entitys at a time. This is a a helpful tool for us to have in the app but should not be accessible by the user  */
export default defineComponent({
  name: "EntityAuditDetailed",
  props: {
    /** this should be an array of entity types for the app */
    entityTypes: {
      type: Array<{ label: string; value: string }>,
      required: true,
    },
  },
  setup(props, context) {
    const entityOne = ref({});
    const entityTwo = ref({});

    //for the display of the custom box for entity type
    const customInputOne = ref(false);
    const customInputTwo = ref(false);

    const entityTypeOneChangeHandler = ($event?: string) => {
      if ($event === "custom") {
        customInputOne.value = true;
      } else {
        customInputOne.value = false;
      }
    };

    const entityTypeTwoChangeHandler = ($event?: string) => {
      if ($event === "custom") {
        customInputTwo.value = true;
      } else {
        customInputTwo.value = false;
      }
    };

    const handleSubmitOne = async (data: detailedAuditEntry) => {
      if (data.entityTypeSelect === "custom") {
        data.entityType = data.entityTypeManual;
      } else {
        data.entityType = data.entityTypeSelect;
      }
      const result = await fetchAuditsOne(`${data.entityType}/${data.entityId}/audits`, {
        take: 500,
        orderBy: [{ propertyName: "dateTimeUtc" }],
      });

      auditEntityOne.value = result;
      entityOne.value = { ...data };
    };

    const handleSubmitTwo = async (data: detailedAuditEntry) => {
      if (data.entityTypeSelect === "custom") {
        data.entityType = data.entityTypeManual;
      } else {
        data.entityType = data.entityTypeSelect;
      }
      const result = await fetchAuditsTwo(`${data.entityType}/${data.entityId}/audits`, {
        take: 500,
        orderBy: [{ propertyName: "dateTimeUtc" }],
      });
      auditEntityTwo.value = result;
      entityTwo.value = { ...data };
    };

    const getEntityOne = function () {
      submitForm("entityOneForm");
    };

    const getEntityTwo = function () {
      submitForm("entityTwoForm");
    };

    const {
      dataRef: auditEntityOne,
      exec: fetchAuditsOne,
      isStatusPending: isAuditOnePending,
      isStatusSuccess: isAuditOneSuccess,
    } = useApi(getAudits, {
      responseAdapter: (response) => response.data,
    });

    const {
      dataRef: auditEntityTwo,
      exec: fetchAuditsTwo,
      isStatusPending: isAuditTwoPending,
      isStatusSuccess: isAuditTwoSuccess,
    } = useApi(getAudits, {
      responseAdapter: (response) => response.data,
    });

    const data = ref({
      entityTypes: props.entityTypes,
      getEntityOne: getEntityOne,
    });

    const library = markRaw({
      BaseButton: BaseButton,
    });

    // Schema - Shows conditional rendering and use of base button component
    // Currently commented out in code
    const conditionalSchema = [
      {
        $el: "div",
        attrs: {
          class: "grid grid-cols-1 gap-x-4 sm:grid-cols-3 md:grid-cols-4 mx-10",
        },
        children: [
          {
            $el: "div",
            attrs: {
              class: "ms-1",
            },
            id: "wrapperDiv",
            children: [
              {
                $formkit: "dropdown",
                label: "Entity Type",
                name: "entityTypeSelect",
                validation: "required",
                options: "$entityTypes",
                popover: true,
                id: "typeSelectOne",
              },
            ],
          },
          {
            $formkit: "text",
            label: "Entity Type",
            name: "entityTypeManual",
            validation: "required",
            if: "$get(typeSelectOne).value == 'custom'",
          },
          {
            $formkit: "text",
            label: "Entity Id",
            name: "entityId",
            validation: "required|number",
          },

          {
            $el: "div",
            children: [
              {
                $cmp: "BaseButton",
                props: {
                  variant: "priority-hollow",
                  label: "Get Audits",
                  class: "my-auto",
                  onClick: "$getEntityOne",
                },
              },
            ],
            attrs: {
              class: "me-3 my-auto",
            },
          },
        ],
      },
    ];

    return {
      entityOne,
      entityTwo,
      handleSubmitOne,
      handleSubmitTwo,
      getEntityOne,
      getEntityTwo,
      auditEntityOne,
      auditEntityTwo,
      isAuditOnePending,
      isAuditOneSuccess,
      isAuditTwoPending,
      isAuditTwoSuccess,
      entityTypeOneChangeHandler,
      entityTypeTwoChangeHandler,
      customInputOne,
      customInputTwo,
      conditionalSchema,
      data,
      library,
    };
  },
  components: {
    Authorize,
    BaseButton,
    FormKitSchema,
  },
});
</script>
