<template>
  <button
    class="block p-1 text-neutral-400 rounded-lg transition-colors duration-150 ease-in"
    :class="[active ? 'bg-neutral-600 text-white' : 'hover:bg-neutral-400 hover:text-white']"
    v-bind="$attrs"
    type="button"
    :title="label"
  >
    <component :is="icon" class="h-5 w-5`" aria-hidden="true" v-if="icon"></component>
    <!-- @slot Icon gives you more control over the icon than using the icon prop. It should be sized "h-5 w-5" -->
    <slot name="icon" v-if="!icon"></slot>
    <span class="sr-only"> {{ label }}</span>
  </button>
</template>

<script lang="ts">
import { FunctionalComponent, PropType, computed, defineComponent } from "vue";

/**
 * Buttons designed for selecting viewer panels
 */
export default defineComponent({
  name: "ViewerPanelButton",
  props: {
    /**
     * Label to be read for screenreaders
     */
    label: {
      type: String,
      required: true,
    },
    /**
     * The icon component to render
     */
    icon: {
      type: Function as PropType<FunctionalComponent>,
    },
    /**
     * Toggles the "active" styling of the button. Primarily used for highlighting the active panel.
     */
    active: {
      type: Boolean,
      required: true,
    },
  },
  setup(props) {
    return {};
  },
});
</script>

<style module></style>
