<template>
  <td class="px-6 py-4">
    <!-- @slot Slot for the `<td>` contents  -->
    <slot name="default"></slot>
  </td>
</template>

<script lang="ts">
import { defineComponent } from "vue";

/** Base component designed to be used by other ListCell* components in a ListTable.
 *  Provides base styling shared by all ListCell* components.
 */
export default defineComponent({
  name: "ListCellBase",
  setup(props) {},
});
</script>

<style module></style>
