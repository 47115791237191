import abodeService from "@casa/views/abode/abodeService";
import { apiService, IPagingApiAndNotifierConfig } from "@mui/utils/api";
import { serializeQueryParams } from "@mui/utils/helpers";
import { NotifyOptionsSuccess, NotifyOptionsError } from "@mui/components/notifications";
import { apiHosts } from "@casa/api/constants/apiHosts";

import { IAbode, IAbodeNew, IAbodeQuery } from "./abode";
import type { AxiosResponse } from "axios";

const URL = "abodes";
const ENTITY_NAME = "Abode";

const axiosParams = {
  // Set different base URL based on the environment
  baseURL: import.meta.env.VITE_CASA_API_BASE_URL,
};
const api = apiService<IAbode>(apiHosts.CASA, axiosParams);

/**
 * @param {Object} params Query parameters, varies (TODO improve this)

 */
const getAbodes = (params: IAbodeQuery, config: IPagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.hide = true;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.fetched = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };

  const url = config.pagingUrl ? config.pagingUrl : URL;

  return api.getPaged(url, {
    params,
    paramsSerializer: serializeQueryParams,
    abort: config?.abortMethod,
    successNotifCb,
    errorNotifCb,
  });
};

/**
 * @param {Int} id
 */
const getAbodeById = (id: string | number, config: IPagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.hide = true;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.fetched = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };

  return api.get(URL + "/" + id, {
    successNotifCb,
    errorNotifCb,
  });
};

const getNewAbode = async (ids: AbodeIds) => {
  const defaultApp = {
    data: {
      ...abodeService.getInitialData(),
      ...ids,
    },
  } as AxiosResponse<IAbodeNew, any>;

  return defaultApp;
};

/**
 *
 * @param {Object} ids Object having either an `id` for an Application or a `facilitySiteId`, `permitId`, and type
 * @param {ApiOptions} config
 * @returns {api.get}
 */

type AbodeIds = {
  id?: string | number;
};
const getAbodeByIdOrNew = (ids: AbodeIds, config = {}) => {
  if (!ids.id) {
    return getNewAbode(ids);
  } else {
    return getAbodeById(ids.id, config) as Promise<AxiosResponse<IAbodeNew, any>>;
  }
};

const createAbode = (abode: IAbode, config: IPagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.created = ENTITY_NAME;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.created = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };

  return api.post(URL, abode, {
    successNotifCb,
    errorNotifCb,
  });
};

const updateAbode = (abode: IAbode, config: IPagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.updated = ENTITY_NAME;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.updated = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };

  return api.put(URL + "/" + abode.id, abode, {
    successNotifCb,
    errorNotifCb,
  });
};

/**
 * @param {Abode} abode This cannot be a 'ref' to a abode
 */
const saveAbode = (abode: IAbode, config = {}) => {
  if (abode.id) {
    return updateAbode(abode, config);
  } else {
    return createAbode(abode, config);
  }
};

/**
 * @param {Int} id
 */
const deleteAbodeById = (id: string | number, config: IPagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.deleted = ENTITY_NAME;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.deleted = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };

  return api.delete(URL + "/" + id, {
    successNotifCb,
    errorNotifCb,
  });
};

export {
  deleteAbodeById,
  getAbodes,
  getAbodeById,
  getNewAbode,
  getAbodeByIdOrNew,
  createAbode,
  updateAbode,
  saveAbode,
};
