<template>
  <button
    type="button"
    class="transition-colors duration-150 ease-in flex items-center rounded-md shadow border p-1 text-neutral-400 border-neutral-300 hover:bg-neutral-400 hover:border-neutral-400 hover:text-white bg-white"
    v-bind="$attrs"
    :title="label"
  >
    <span class="sr-only">{{ label }}</span>
    <div class="h-5 w-5" aria-hidden="true">
      <slot name="default"></slot>
    </div>
  </button>
</template>

<script lang="ts">
import { defineComponent } from "vue";

/**
 * Icon only buttons for use as map controls
 */
export default defineComponent({
  name: "ViewerMapButton",
  //inheritAttrs: false,
  components: {},
  props: {
    /**
     * Label to be read for screenreaders and displayed as a tooltip
     */
    label: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    return {};
  },
});
</script>

<style module></style>
