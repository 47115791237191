<template>
  <div class="sm:grid sm:grid-cols-12 sm:gap-6">
    <div
      :class="{
        'sm:col-span-6': columns === 2,
        'sm:col-span-4': columns === 3,
        'sm:col-span-3': columns === 4,
      }"
      v-for="col in columnsArray"
    >
      <!-- @slot Field components you would like to be rendered as a group (**not a FormKit group) -->
      <slot :name="col"></slot>
    </div>
  </div>
</template>

<script lang="ts">
import { PropType, defineComponent } from "vue";
/**
 * Renders form fields as a group using the desired amount of columns (**not a FormKit group).
 */
export default defineComponent({
  name: "FieldSet",
  props: {
    /** Number of columns you would like for your fields.
     * @values 2,3,4
     */
    columns: {
      type: Number as PropType<2 | 3 | 4>,
      default: 2,
      validator: (value: number) => {
        return value > 1 && value < 5;
      },
    },
  },
  setup() {
    return {};
  },
  computed: {
    columnsArray() {
      const result = [];
      let i = 1;
      do {
        result.push(i++);
      } while (i <= this.columns);
      return result;
    },
  },
});
</script>

<style module></style>
