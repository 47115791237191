import type { FormKitNode } from "@formkit/core";
import type { FormKitInputProps, FormKitInputs } from "@formkit/inputs";
// import { createThemePlugin, generateClasses } from "@formkit/themes";
import { down, close, check, inputIcons, genesisIcons } from "@formkit/icons";
// import { findSection } from "@formkit/inputs";
// import { clone } from "@formkit/utils";
import { booleanRadio } from "@/utils/fk/booleanRadio.js";
import { booleanStringRadio } from "@/utils/fk/booleanStringRadio.js";
import { panelRadio } from "@/utils/fk/panelRadio.js";
import { required_boolean } from "@/utils/fk/requiredBoolean.js";
import { all_or_none_in_group_required } from "@/utils/fk/allOrNoneInGroup.js";
import { has_no_validation } from "@/utils/fk/hasNoValidation.js";
import { renameRadios } from "@/utils/fk/renameRadioPlugin.js";
import { DefaultConfigOptions, createInput, defineFormKitConfig } from "@formkit/vue";
import { MaybeRef } from "vue";
import {
  booleanOptions,
  booleanStringOptions,
  nullableBooleanOptions,
  threeBooleanStringOptions,
} from "@/utils/helpers/index.js";
// import { rootClasses } from "@formkit/theme-creator";
// import theme from "@modernary/formkit-theme-mui";
// import { rootClasses } from "@modernary/formkit-theme-mui";
import { rootClasses } from "@/utils/fk/formkit.theme.js";

export type BooleanRadioOptions = typeof booleanOptions | typeof nullableBooleanOptions;
export type BooleanStringRadioOptions = typeof booleanStringOptions | typeof threeBooleanStringOptions;
export type PanelRadioOptions = Array<{ value: number | string; label: string; help?: string }>;
declare module "@formkit/inputs" {
  interface FormKitInputProps<Props extends FormKitInputs<Props>> {
    booleanRadio: {
      type: "booleanRadio";
      value?: boolean;
      options: MaybeRef<BooleanRadioOptions>;
      // ... props here
    };
    booleanStringRadio: {
      type: "booleanStringRadio";
      value?: string;
      options: BooleanStringRadioOptions;
    };
    panelRadio: {
      type: "panelRadio";
      value?: string | number | boolean;
      options: MaybeRef<PanelRadioOptions>;
      // ... props here
    };
  }
}
//createThemePlugin(),
export const formKitConfig: DefaultConfigOptions = {
  plugins: [renameRadios],
  rules: {
    all_or_none_in_group_required,
    has_no_validation,
    required_boolean,
  },
  messages: {
    en: {
      validation: {
        all_or_none_in_group_required({ args, name, node }: { args: any; name: string; node: FormKitNode }) {
          return `${name} is required`;
        },
      },
    },
  },
  inputs: {
    booleanRadio,
    booleanStringRadio,
    panelRadio,
  },
  icons: {
    ...genesisIcons,
    checkCircleIcon: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-5 h-5"><path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z" clip-rule="evenodd" /></svg>`,
    xCircleIcon: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-5 h-5"><path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.28 7.22a.75.75 0 00-1.06 1.06L8.94 10l-1.72 1.72a.75.75 0 101.06 1.06L10 11.06l1.72 1.72a.75.75 0 101.06-1.06L11.06 10l1.72-1.72a.75.75 0 00-1.06-1.06L10 8.94 8.28 7.22z" clip-rule="evenodd" /></svg>`,
    noSymbolIcon: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-5 h-5"><path fill-rule="evenodd" d="M5.965 4.904l9.131 9.131a6.5 6.5 0 00-9.131-9.131zm8.07 10.192L4.904 5.965a6.5 6.5 0 009.131 9.131zM4.343 4.343a8 8 0 1111.314 11.314A8 8 0 014.343 4.343z" clip-rule="evenodd" /></svg>`,
  },
  config: {
    rootClasses, //: rootClasses(theme().tailwind()),
  },
};
