<template>
  <div class="flex min-h-0 flex-1 flex-col lg:rounded-tl-lg">
    <!-- @slot Slot to place your child list content (such as a ListCards or ListTables) -->
    <slot></slot>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
/** Base layout for child lists */
export default defineComponent({
  name: "LayoutChildList",
  setup() {
    return {};
  },
});
</script>
