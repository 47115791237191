<template>
  <button type="button" @click.prevent="register">Register</button>
</template>

<script lang="ts">
import { defineComponent } from "vue";

/** An unstyled button used for Registering. Styles will be automatically inherited. */
export default defineComponent({
  name: "RegisterButton",
  methods: {
    register() {
      this.$authn.redirectToRegister();
    },
  },
});
</script>
