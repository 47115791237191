// Decimal round (half away from zero)
/**
 * Rounds a decimal using the half away from zero method
 * @param {number} num The number to round up or down.
 * @param {number} [decimalPlaces] Optional number of decimal places to round to. Default will be 0
 * @returns {number} The rounded number to the given number of decimal places.
 */
export function round(num: number, decimalPlaces?: number) {
  var p = Math.pow(10, decimalPlaces || 0);
  var n = num * p * (1 + Number.EPSILON);
  return Math.round(n) / p;
}

// Decimal ceil
/**
 * Rounds up a number to the nearest integer
 * @param {number} num The number to round up.
 * @param {number} [decimalPlaces] Optional number of decimal places to round to. Default will be 0
 * @returns {number} The rounded-up number to the given number of decimal places.
 */
export function ceil(num: number, decimalPlaces?: number) {
  var p = Math.pow(10, decimalPlaces || 0);
  var n = num * p * (1 - Math.sign(num) * Number.EPSILON);
  return Math.ceil(n) / p;
}

// Decimal floor
/**
 * Rounds down a number to the nearest integer
 * @param {number} num The number to round down.
 * @param {number} [decimalPlaces] Optional number of decimal places to round to. Default will be 0
 * @returns {number} Returns the rounded-down number.
 */
export function floor(num: number, decimalPlaces?: number) {
  var p = Math.pow(10, decimalPlaces || 0);
  var n = num * p * (1 + Math.sign(num) * Number.EPSILON);
  return Math.floor(n) / p;
}

// Decimal trunc
/**
 * Truncates a number
 * @param {number} num The number to truncate.
 * @param {number} [decimalPlaces] Optional number of decimal places
 * @returns {number} Returns the truncated number.
 */
export function trunc(num: number, decimalPlaces?: number) {
  return (num < 0 ? ceil : floor)(num, decimalPlaces);
}

// Format using fixed-point notation
/**
 * Rounds and formats a number using fixed-point notation
 * @param {number} num The number to format.
 * @param {number} [decimalPlaces] Optional number of decimal places
 * @returns {string} Returns the formatted number as a string.
 */
export function toFixed(num: number, decimalPlaces?: number) {
  return round(num, decimalPlaces).toFixed(decimalPlaces);
}
