import { ComputedRef } from "vue";

export type ChildNavItem = {
  label: string;
  name: string;
  count?: ComputedRef | number;
  isHidden?: boolean;
  isPending?: boolean;
};

/**
 *
 * @param {Promise} apiPromise A call to get child data
 * @param {ChildNavItem[]} childNavItems
 * @param {String} routeName
 */
const updater = async (apiPromise: Promise<any>, routeName: string, childNavItems: ChildNavItem[]) => {
  await apiPromise
    .then(() => {
      const item = childNavItems.find((x) => x.name === routeName);
      if (!item) throw "No childNavItem named: " + routeName;
      item.isHidden = false;
      item.isPending = false;
    })
    .catch((error) => {
      const item = childNavItems.find((x) => x.name === routeName);
      if (!item) throw "No childNavItem named: " + routeName;
      item.isHidden = true;
      item.isPending = false;
    });
};

/**
 * Returns preconfigured childNav helper methods
 *
 * @returns {Object}
 */
export const useChildNav = (childNavItems: ChildNavItem[]) => {
  const updateChildNav = async (apiPromise: Promise<any>, routeName: string) =>
    updater(apiPromise, routeName, childNavItems);

  return {
    updateChildNav,
  };
};
