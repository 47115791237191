import { apiService, getOtherQueryIdStrings, IPagingApiAndNotifierConfig } from "@/utils/api/index.js";
import { serializeQueryParams } from "@/utils/helpers/index.js";

import { NotifyOptionsSuccess, NotifyOptionsError } from "@/components/notifications/index.js";
import { IAnnotation, IAnnotationNew, IAnnotationQuery } from "@/components/entity/note/index.js";
import { useEntityNoteConfigStore } from "@/components/entity/note/entityNoteConfigStore.js";
import { AxiosResponse } from "axios";

const URL = "annotations";

let api: ReturnType<typeof apiService<IAnnotation>>;

const getApi = () => {
  if (api) return api;

  const entityNoteConfigStore = useEntityNoteConfigStore();
  const axiosParams = {
    // Set different base URL based on the environment
    baseURL: entityNoteConfigStore.baseURL,
  };
  api = apiService(entityNoteConfigStore.apiHost, axiosParams);
  return api;
};

const getEntityName = () => {
  const entityNoteConfigStore = useEntityNoteConfigStore();
  return entityNoteConfigStore.noteLabel;
};

/**
 * @param {Object} params Query parameters, varies (TODO improve this)

 */
const getAnnotations = (params: IAnnotationQuery, config: IPagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.hide = true;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.fetched = getEntityName();
    config?.errorNotifCb?.(options);
  };

  const url = config.pagingUrl ? config.pagingUrl : URL;

  return getApi().getPaged(url, {
    params,
    paramsSerializer: serializeQueryParams,
    abort: config?.abortMethod,
    successNotifCb,
    errorNotifCb,
  });
};

/**
 * @param {Int} id
 */

type AnnotationIds = {
  id?: string | number;
};
const getAnnotationById = (ids: AnnotationIds = {}, config: IPagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.hide = true;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.fetched = getEntityName();
    config?.errorNotifCb?.(options);
  };

  const { id, ...otherIds } = ids;
  const otherIdStrings = getOtherQueryIdStrings(otherIds);

  return getApi().get(URL + "/" + id + "?" + otherIdStrings, {
    successNotifCb,
    errorNotifCb,
  });
};

/**
 * @param {Object} relatedEntityIds Key-value pairs with entity IDs. E.g. { reportId: 123 }
 */

type RelatedEntityIds = {
  [key: string]: any;
};
const getNewAnnotation = async (relatedEntityIds: RelatedEntityIds) => {
  //optionally make any other changes as necessary before returning.
  const defaultAnnotation = { ...relatedEntityIds };
  return { data: defaultAnnotation } as AxiosResponse<IAnnotationNew>;
};

const getAnnotationByIdOrNew = (ids: AnnotationIds, config: IPagingApiAndNotifierConfig = {}) => {
  if (!ids.id) {
    return getNewAnnotation(ids);
  } else {
    return getAnnotationById(ids, config) as Promise<AxiosResponse<IAnnotationNew, any>>;
  }
};

const createAnnotation = (annotation: IAnnotation, config: IPagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.created = getEntityName();
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.created = getEntityName();
    config?.errorNotifCb?.(options);
  };

  return getApi().post(URL, annotation, {
    successNotifCb,
    errorNotifCb,
  });
};

const updateAnnotation = (annotation: IAnnotation, config: IPagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.updated = getEntityName();
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.updated = getEntityName();
    config?.errorNotifCb?.(options);
  };

  return getApi().put(URL + "/" + annotation.id, annotation, {
    successNotifCb,
    errorNotifCb,
  });
};

/**
 * @param {Annotation} annotation This cannot be a 'ref' to a annotation
 */
const saveAnnotation = (annotation: IAnnotation, config: IPagingApiAndNotifierConfig = {}) => {
  if (annotation.id) {
    return updateAnnotation(annotation, config);
  } else {
    return createAnnotation(annotation, config);
  }
};

/**
 * @param {Int} id
 */
const deleteAnnotationById = (ids: AnnotationIds, config: IPagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.deleted = getEntityName();
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.deleted = getEntityName();
    config?.errorNotifCb?.(options);
  };

  const { id, ...otherIds } = ids;
  const otherIdStrings = getOtherQueryIdStrings(otherIds);

  return getApi().delete(URL + "/" + ids.id + "?" + otherIdStrings, {
    successNotifCb,
    errorNotifCb,
  });
};

export {
  deleteAnnotationById,
  getAnnotations,
  getAnnotationById,
  getNewAnnotation,
  getAnnotationByIdOrNew,
  createAnnotation,
  updateAnnotation,
  saveAnnotation,
};
