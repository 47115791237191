<template>
  <LayoutAppContent>
    <div class="flex min-h-0 flex-1 flex-col items-stretch">
      <main class="flex min-h-0 flex-1 flex-col">
        <!-- @slot Slot for the list content -->
        <slot></slot>
      </main>
    </div>
  </LayoutAppContent>
</template>

<script lang="ts">
import LayoutAppContent from "@/components/layouts/LayoutAppContent.vue";
import { defineComponent } from "vue";
/** Base styling for lists */
export default defineComponent({
  name: "LayoutList",
  components: {
    LayoutAppContent,
  },
  setup() {
    return {};
  },
});
</script>
