<template>
  <div class="animate-pulse rounded-md bg-neutral-300"></div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
/** Used as a placeholder while data is loading. Helps signifiy to the user that there is something there that just hasn't loaded yet.  */
export default defineComponent({
  name: "SkeletonBlock",
});
</script>

<style module></style>
