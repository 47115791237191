<template>
  <!-- 'bg-neutral-50': !isChildList, -->
  <div class="border-b border-b-neutral-300" v-bind="$attrs" :class="{ 'pb-0.5': isChildList }">
    <!-- <button @click="$emit('updateEntity', $event)" >test</button> -->
    <div class="flex flex-wrap justify-between px-4 sm:px-6 lg:px-8">
      <!-- pt-2 -->
      <div class="mr-0 h-auto flex-none flex-nowrap flex items-center py-2">
        <!-- md:flex-none md:pb-2 -->
        <div class="flex">
          <Authorize v-bind="createToAuth">
            <router-link
              v-if="createTo"
              :to="createTo"
              class="my-auto inline-flex mr-3 flex-none items-center rounded-full border border-transparent bg-green-500 p-0.5 text-white shadow-sm ring-offset-neutral-100 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 md:p-1"
            >
              <PlusIcon class="h-5 w-5" :class="[!isChildList && 'md:h-6 md:w-6']" aria-hidden="true" />
              <span class="sr-only">Create {{ entityLabel }}</span>
            </router-link>
            <template #pending>
              <SkeletonBlock class="h-7 w-7 rounded-full" :class="[!isChildList && 'md:h-9 md:w-9']" />
            </template>
          </Authorize>
          <component
            :is="isChildList ? 'h2' : 'h1'"
            id="table-label"
            class="clear-both my-auto mr-3 text-lg font-bold"
            :class="[isChildList ? 'text-neutral-700 md:text-xl' : 'md:text-2xl']"
          >
            {{ entityLabelPlural }}
          </component>
          <!-- md:hidden -->
          <!-- v-if="!isChildList" -->
          <div class="flex items-center">
            <span
              class="inline-flex items-center rounded bg-neutral-300 px-2 py-0.5 text-xs font-medium text-neutral-800"
            >
              {{ totalResultsCount || "0" }} <span class="sr-only">{{ entityLabelPlural }} shown</span>
            </span>
          </div>
        </div>
      </div>
      <!-- py-2 lg:pt-3 -->
      <div class="flex-1 items-center flex justify-end" :class="[isChildList ? `` : ``]">
        <!-- @slot Should be labeled as the "headerActions" slot in Lists using this component. See ListTable for an example -->
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { ListTableProps } from "@/components/list/genericList";
import injectionSymbols from "@/components/list/genericList/injectionSymbols";
import SkeletonBlock from "@/components/common/SkeletonBlock.vue";
import { Authorize } from "@/utils/auth/authorization";
import { FormKitIcon } from "@formkit/vue";
import { TransitionChild, TransitionRoot } from "@headlessui/vue";
import { PlusIcon } from "@heroicons/vue/20/solid";
import { defineComponent, inject } from "vue";
import BaseButton from "@/components/button/BaseButton.vue";
/** Component for standardized styling to show the list header and total results count */
export default defineComponent({
  name: "ListHeader",
  components: {
    TransitionChild,
    TransitionRoot,
    PlusIcon,
    Authorize,
    SkeletonBlock,
    BaseButton,
    FormKitIcon,
  },
  props: {
    /** Number of entities in the list (usually the totalResultsCount property from the API response)
     */
    totalResultsCount: {
      type: Number,
    },
    // countLabel: {
    //   type: String,
    // },
  },
  setup(props) {
    const { entityLabel, entityLabelPlural, isChildList, createTo, createToAuth, isDefaultState } = inject(
      injectionSymbols.GenericListPropsKey,
    ) as ListTableProps;

    return {
      entityLabel,
      entityLabelPlural,
      isChildList,
      createTo,
      createToAuth,
      isDefaultState,
    };
  },
});
</script>

<style module></style>
